import React from 'react';
import './SocialDisplayBox.css';


const SocialDisplayBox = ({ platform, handle, url }) => {


  const formatHandleDisplay = (platform, handle) => {
    switch (platform.toLowerCase()) {
      case 'spotify':
        return `Artist ID: ${handle}`;
      case 'linkedin':
        return `${handle}`;
      case 'website':
        return handle;  // Assuming handle is the full URL for websites.
      default:
        return `@${handle}`;
    }
  };


  return (
    <a href={url} 
       className="ttreusableBoxContainered" 
       target="_blank" 
       rel="noopener noreferrer"
    >
      <div className="tcontentViewsomuchInBox">
        <p className="tboldsterInBox">{platform}</p>
        <p className="tnormailerInBox">{formatHandleDisplay(platform, handle)}</p>
      </div>
    </a>
  );
};

export default SocialDisplayBox;
