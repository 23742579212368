import React from 'react';
import { Link } from 'react-router-dom';
import './BSFAQs.css';

function BSFAQs() {
  return (
    <div className="bsfaqs">
      <h3>Retail side FAQs</h3>
      <p>
        <b>
            1. How do we promote your site?
        </b>
      </p>
      <p>
        ShorterStories is a subnetwork that essentially is a publishing platform for writers. While simultaneously being an organic bookstore for the readers that directs them to your site. 
      </p>
      <p>
        The only promotional activity on the website is to promote books at the top and bottom of the pieces written on the platform. And on a writer's profile. 
      </p>
      <p>
      The writers can promote up to 4 books with the pieces they write, that are in some way related (as chosen and explained by the writer) to the piece. 
      </p>
      <p>
        <b>
            2. What does our traffic look like?
        </b>
      </p>
      <p>
        ShorterStories is an invite only/application model. Traffic is driven to the site by writers, who are by their nature creators and reluctant influencers. We vet the writers on our platform at the door for quality/compliance, which also ensures that we and you don’t have an army of bots or cookie stuffers to deal with. 
      </p>
      <p>
        Writers produce quality content that brings people to the party, but they also have a lot of cache with their audience. Their audience of book lovers. 
      </p>
      <p>
        I.e. The traffic to the platform is top of the marketing funnel, creating awareness and interest.
      </p>
      <p>
        All to a targeted audience of shoppers interested in books and writers!
      </p>
      <p>
        <b>
            3. How do we ensure your terms and conditions are adhered to?
        </b>
      </p>
      <p>
      We run a tight ship, and are happy to engage and rectify any stakeholder concerns. Forming good partnerships, built on trust and shared incentives is the aim of our company. If any patterns of bad behavior emerge then we take the right to remove writers from our platform. 
      </p>
      <p>
        See <Link to="/writers/application-whats-up" className="custom-link-bs">here</Link>  for a link of our terms for the writers when they join. And further backed up <Link to="/writers/finer-details" className="custom-link-bs">here</Link> in the docs on the writer's side of the platform.
      </p>
      <p>
        In addition, to join the platform, a writer must submit an application that includes questions about whether they've had a book published, for examples of social media and published articles and finally they are asked to give an example bit of their writing. We verify any published writers to ensure they aren’t trying to be anyone they ain’t.
      </p>
      <p>
        <b>
          4. How do we send back information to the network?
        </b>
      </p>
      <p>
        Through a hashing system, i.e., by including tags in our affiliate links. 
      </p>
      <p>
        Our tags work like this: sid = uniqueHash-clickType-paddedPosition-piece_hash-profile_hash-timestamp
      </p>

      <p>
        That is, the sid starts with a 12 digit click hash, which is followed by the click type (p or r), then the position of the book in the carousel of the piece (00-03). The next 8 digits are the piece hash or piece identifier. The next 8 are the profile hash or profile identifier of the writer on our platform.
      </p>
      <p>
        This means you can identify the position of the advertising, the piece that it was advertised on and the profile of the writer who published it. 
      </p>
      <p>
        For example, if we send something like this:
      </p>
      <p>
        362z0x24580v-P-00-0cbFzy3z-zIWNnQxB-24081210
      </p>
      <p>
        This click was from the first book advertised, from this piece:
      </p>
      <p>
      <a href="https://www.shorterstories.xyz/piece/0cbFzy3z" className="custom-link-bs">https://www.shorterstories.xyz/piece/0cbFzy3z</a>  
      </p>
      <p>
        The writer who wrote that piece has this profile: 
      </p>
      <p>
        <Link to="/writer/zIWNnQxB" className="custom-link-bs">https://www.shorterstories.xyz/writer/zIWNnQxB</Link>  
      </p>

    </div>
  );
}

export default BSFAQs;