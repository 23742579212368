import React from 'react';
import './PieceWords.css';

function numberToWord(num) {
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
  return words[num - 1] || num.toString();
}

function PieceWords({ title, authorName, content,isSerial, serialPartNumber, previousSerialHash, nextSerialHash, onNextClick, onPreviousClick  }) {
  return (
    <div className="pieces-words">
      <h3 className="pieces-title">
        {title}
        {isSerial && serialPartNumber && (
          <span className="profileFeed-serial-part">
            {" (part " + numberToWord(serialPartNumber) + ")"}
          </span>
        )} 
        <span className="authors-name">by {authorName}</span>
      </h3>
      {isSerial && (
          <div className="serial-navigation">
            <div className="serial-nav-link previous" onClick={previousSerialHash ? onPreviousClick : undefined}>
              {previousSerialHash && ( 
                <span>
                  <span className="arrow">&#8592;</span>
                  <span className="main-text">Previous piece</span>
                  <br />
                  <span className="sub-text">in series</span>
                </span>
              )}
            </div>
            <div className="serial-nav-link next" onClick={nextSerialHash ? onNextClick : undefined}>
              {nextSerialHash && (
                <span>
                  <span className="main-text">Next piece</span>
                  <span className="arrow">&#8594;</span>
                  <br />
                  <span className="sub-text">in series</span>
                </span>
              )}
            </div>
          </div>
        )}
      <div dangerouslySetInnerHTML={{ __html: content }} className="pieces-content" />
    </div>
  );
}

export default PieceWords;

