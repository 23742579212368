import React from 'react';
import { useSelector } from 'react-redux';
import AppFormIntro from '../../components/AppFormIntro/AppFormIntro';
import AppFormNamePublished from '../../components/AppFormNamePublished/AppFormNamePublished';
import AppFormSocials from '../../components/AppFormSocials/AppFormSocials';
import AppFormLastWords from '../../components/AppFormLastWords/AppFormLastWords';
import ApplicationPreview from '../../components/ApplicationPreview/ApplicationPreview';
import Header from '../../components/Header/Header';
import HeaderAppFlow from '../../components/HeaderAppFlow/HeaderAppFlow';
import './ApplyWriterScreen.css'; // Make sure to create this CSS file

const ApplyWriterScreen = () => {
  const { currentStep } = useSelector(state => state.applyWriter);

  return (
    <div className="applyWriterContainer">
      {currentStep === 0 ? <Header /> : <HeaderAppFlow />}

      <div className="applyWriterContent">
        {currentStep === 0 && <AppFormIntro />}
        {currentStep === 1 && <AppFormNamePublished />}
        {currentStep === 2 && <AppFormSocials />}
        {currentStep === 3 && <AppFormLastWords />}
        {currentStep === 4 && <ApplicationPreview />}
      </div>    
    </div>
  );
};

export default ApplyWriterScreen;
