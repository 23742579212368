import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import BSFAQs from '../../components/BSFAQs/BSFAQs';
import Footer from '../../components/FooterAboutWriter/FooterAboutWriter';
import './BooksellerFAQs.css';

function BooksellerFAQs() {
  return (
    <div className="booksellersfaqs">
      <Helmet>
        <title>Bookseller FAQs | ShorterStories</title>
        <meta name="description" content="Frequently asked questions for booksellers and affiliates about ShorterStories. Learn about our promotional strategies, traffic, terms and conditions, and tracking system." />
        <meta name="keywords" content="bookseller FAQs, retailer FAQs, affiliate FAQs, ShorterStories, book promotion, writer platform" />
        <meta property="og:title" content="Bookseller FAQs | ShorterStories" />
        <meta property="og:description" content="Frequently asked questions for booksellers, retailers, and affiliates about ShorterStories. Learn about our promotional strategies, traffic, terms and conditions, and tracking system." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.shorterstories.xyz/bookseller-faqs" />
        <link rel="canonical" href="https://www.shorterstories.xyz/bookseller-faqs" />
      </Helmet>
      <Header />
      <div className="booksellersfaqs-content">
        <BSFAQs />
      </div>
      <Footer />
    </div>
  );
}

export default BooksellerFAQs;