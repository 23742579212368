import React, { useState } from 'react';
import './PublicationFormModal.css';
import { useDispatch } from 'react-redux';
import {addMagPublishedEntry} from '../../store/applyWriterSlice';
import { hideGenericModal } from '../../store/modalSlice';

const PublicationFormModal = () => {
  const [magazine, setMagazine] = useState('');
  const [link, setLink] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    // Submit logic here, e.g., call onSubmit with platform and handle

    if (magazine.trim() === '' || link.trim() === '') {
        window.alert("Hold on! Neither the magazine or the link can be empty to add it to the pile.");
        return;
      }
    dispatch(addMagPublishedEntry({magazine, link}));
    setMagazine('');
    setLink('');
    dispatch(hideGenericModal());

  };

  return (
    <div className="publicationFormModal">
      <div className="formGroup">
        <label>Magazine or website:</label>
        <input
          type="text"
          value={magazine}
          onChange={(e) => setMagazine(e.target.value)}
          placeholder="e.g. The New Yorker, Vice, The Paris Review"
        />
      </div>
      <div className="formGroup">
        <label>Link:</label>
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="e.g. www.theinternet.com/your-article"
        />
      </div>
      <button onClick={handleSubmit}>Add</button>
    </div>
  );
};

export default PublicationFormModal;
