import React from "react";
import { useNavigate } from "react-router-dom";
import './GatewayToHell.css';
import { useDispatch } from 'react-redux';
import { resetFilters } from '../../store/theFeedSlice';


function GatewayToHell() {

  const dispatch = useDispatch();


  const navigate = useNavigate(); // Initialize useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    dispatch(resetFilters());
    navigate('/readers'); // Navigate to the feed page
    
  }

  
  return (
    <div className="gateway-box">
      <div className="gateway-content">
        <p className="gateway-text">To read some great stuff</p>
        <form onSubmit={handleSubmit}>
          <input className="gateway-button-login" type="submit" value="Read some stories" />
        </form>
      </div>
    </div>
  );
}


export default GatewayToHell;