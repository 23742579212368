export const countries = [
  {
    "country": "Afghanistan",
    "code": "AF",
    "flag": "🇦🇫"
  },
  {
    "country": "Albania",
    "code": "AL",
    "flag": "🇦🇱"
  },
  {
    "country": "Algeria",
    "code": "DZ",
    "flag": "🇩🇿"
  },
  {
    "country": "American Samoa",
    "code": "AS",
    "flag": "🇦🇸"
  },
  {
    "country": "Andorra",
    "code": "AD",
    "flag": "🇦🇩"
  },
  {
    "country": "Angola",
    "code": "AO",
    "flag": "🇦🇴"
  },
  {
    "country": "Anguilla",
    "code": "AI",
    "flag": "🇦🇮"
  },
  {
    "country": "Antarctica",
    "code": "AQ",
    "flag": "🇦🇶"
  },
  {
    "country": "Antigua and Barbuda",
    "code": "AG",
    "flag": "🇦🇬"
  },
  {
    "country": "Argentina",
    "code": "AR",
    "flag": "🇦🇷"
  },
  {
    "country": "Armenia",
    "code": "AM",
    "flag": "🇦🇲"
  },
  {
    "country": "Aruba",
    "code": "AW",
    "flag": "🇦🇼"
  },
  {
    "country": "Australia",
    "code": "AU",
    "flag": "🇦🇺"
  },
  {
    "country": "Austria",
    "code": "AT",
    "flag": "🇦🇹"
  },
  {
    "country": "Azerbaijan",
    "code": "AZ",
    "flag": "🇦🇿"
  },
  {
    "country": "Bahamas",
    "code": "BS",
    "flag": "🇧🇸"
  },
  {
    "country": "Bahrain",
    "code": "BH",
    "flag": "🇧🇭"
  },
  {
    "country": "Bangladesh",
    "code": "BD",
    "flag": "🇧🇩"
  },
  {
    "country": "Barbados",
    "code": "BB",
    "flag": "🇧🇧"
  },
  {
    "country": "Belarus",
    "code": "BY",
    "flag": "🇧🇾"
  },
  {
    "country": "Belgium",
    "code": "BE",
    "flag": "🇧🇪"
  },
  {
    "country": "Belize",
    "code": "BZ",
    "flag": "🇧🇿"
  },
  {
    "country": "Benin",
    "code": "BJ",
    "flag": "🇧🇯"
  },
  {
    "country": "Bermuda",
    "code": "BM",
    "flag": "🇧🇲"
  },
  {
    "country": "Bhutan",
    "code": "BT",
    "flag": "🇧🇹"
  },
  {
    "country": "Bolivia, Plurinational State of",
    "code": "BO",
    "flag": "🇧🇴"
  },
  {
    "country": "Bonaire, Sint Eustatius and Saba",
    "code": "BQ",
    "flag": "🇧🇶"
  },
  {
    "country": "Bosnia and Herzegovina",
    "code": "BA",
    "flag": "🇧🇦"
  },
  {
    "country": "Botswana",
    "code": "BW",
    "flag": "🇧🇼"
  },
  {
    "country": "Bouvet Island",
    "code": "BV",
    "flag": "🇧🇻"
  },
  {
    "country": "Brazil",
    "code": "BR",
    "flag": "🇧🇷"
  },
  {
    "country": "British Indian Ocean Territory",
    "code": "IO",
    "flag": "🇮🇴"
  },
  {
    "country": "Brunei Darussalam",
    "code": "BN",
    "flag": "🇧🇳"
  },
  {
    "country": "Bulgaria",
    "code": "BG",
    "flag": "🇧🇬"
  },
  {
    "country": "Burkina Faso",
    "code": "BF",
    "flag": "🇧🇫"
  },
  {
    "country": "Burundi",
    "code": "BI",
    "flag": "🇧🇮"
  },
  {
    "country": "Cabo Verde",
    "code": "CV",
    "flag": "🇨🇻"
  },
  {
    "country": "Cambodia",
    "code": "KH",
    "flag": "🇰🇭"
  },
  {
    "country": "Cameroon",
    "code": "CM",
    "flag": "🇨🇲"
  },
  {
    "country": "Canada",
    "code": "CA",
    "flag": "🇨🇦"
  },
  {
    "country": "Cayman Islands",
    "code": "KY",
    "flag": "🇰🇾"
  },
  {
    "country": "Central African Republic",
    "code": "CF",
    "flag": "🇨🇫"
  },
  {
    "country": "Chad",
    "code": "TD",
    "flag": "🇹🇩"
  },
  {
    "country": "Chile",
    "code": "CL",
    "flag": "🇨🇱"
  },
  {
    "country": "China",
    "code": "CN",
    "flag": "🇨🇳"
  },
  {
    "country": "Christmas Island",
    "code": "CX",
    "flag": "🇨🇽"
  },
  {
    "country": "Cocos (Keeling) Islands",
    "code": "CC",
    "flag": "🇨🇨"
  },
  {
    "country": "Colombia",
    "code": "CO",
    "flag": "🇨🇴"
  },
  {
    "country": "Comoros",
    "code": "KM",
    "flag": "🇰🇲"
  },
  {
    "country": "Congo",
    "code": "CG",
    "flag": "🇨🇬"
  },
  {
    "country": "Congo, The Democratic Republic of the",
    "code": "CD",
    "flag": "🇨🇩"
  },
  {
    "country": "Cook Islands",
    "code": "CK",
    "flag": "🇨🇰"
  },
  {
    "country": "Costa Rica",
    "code": "CR",
    "flag": "🇨🇷"
  },
  {
    "country": "Croatia",
    "code": "HR",
    "flag": "🇭🇷"
  },
  {
    "country": "Cuba",
    "code": "CU",
    "flag": "🇨🇺"
  },
  {
    "country": "Curaçao",
    "code": "CW",
    "flag": "🇨🇼"
  },
  {
    "country": "Cyprus",
    "code": "CY",
    "flag": "🇨🇾"
  },
  {
    "country": "Czechia",
    "code": "CZ",
    "flag": "🇨🇿"
  },
  {
    "country": "Côte d'Ivoire",
    "code": "CI",
    "flag": "🇨🇮"
  },
  {
    "country": "Denmark",
    "code": "DK",
    "flag": "🇩🇰"
  },
  {
    "country": "Djibouti",
    "code": "DJ",
    "flag": "🇩🇯"
  },
  {
    "country": "Dominica",
    "code": "DM",
    "flag": "🇩🇲"
  },
  {
    "country": "Dominican Republic",
    "code": "DO",
    "flag": "🇩🇴"
  },
  {
    "country": "Ecuador",
    "code": "EC",
    "flag": "🇪🇨"
  },
  {
    "country": "Egypt",
    "code": "EG",
    "flag": "🇪🇬"
  },
  {
    "country": "El Salvador",
    "code": "SV",
    "flag": "🇸🇻"
  },
  {
    "country": "Equatorial Guinea",
    "code": "GQ",
    "flag": "🇬🇶"
  },
  {
    "country": "Eritrea",
    "code": "ER",
    "flag": "🇪🇷"
  },
  {
    "country": "Estonia",
    "code": "EE",
    "flag": "🇪🇪"
  },
  {
    "country": "Eswatini",
    "code": "SZ",
    "flag": "🇸🇿"
  },
  {
    "country": "Ethiopia",
    "code": "ET",
    "flag": "🇪🇹"
  },
  {
    "country": "Falkland Islands (Malvinas)",
    "code": "FK",
    "flag": "🇫🇰"
  },
  {
    "country": "Faroe Islands",
    "code": "FO",
    "flag": "🇫🇴"
  },
  {
    "country": "Fiji",
    "code": "FJ",
    "flag": "🇫🇯"
  },
  {
    "country": "Finland",
    "code": "FI",
    "flag": "🇫🇮"
  },
  {
    "country": "France",
    "code": "FR",
    "flag": "🇫🇷"
  },
  {
    "country": "French Guiana",
    "code": "GF",
    "flag": "🇬🇫"
  },
  {
    "country": "French Polynesia",
    "code": "PF",
    "flag": "🇵🇫"
  },
  {
    "country": "French Southern Territories",
    "code": "TF",
    "flag": "🇹🇫"
  },
  {
    "country": "Gabon",
    "code": "GA",
    "flag": "🇬🇦"
  },
  {
    "country": "Gambia",
    "code": "GM",
    "flag": "🇬🇲"
  },
  {
    "country": "Georgia",
    "code": "GE",
    "flag": "🇬🇪"
  },
  {
    "country": "Germany",
    "code": "DE",
    "flag": "🇩🇪"
  },
  {
    "country": "Ghana",
    "code": "GH",
    "flag": "🇬🇭"
  },
  {
    "country": "Gibraltar",
    "code": "GI",
    "flag": "🇬🇮"
  },
  {
    "country": "Greece",
    "code": "GR",
    "flag": "🇬🇷"
  },
  {
    "country": "Greenland",
    "code": "GL",
    "flag": "🇬🇱"
  },
  {
    "country": "Grenada",
    "code": "GD",
    "flag": "🇬🇩"
  },
  {
    "country": "Guadeloupe",
    "code": "GP",
    "flag": "🇬🇵"
  },
  {
    "country": "Guam",
    "code": "GU",
    "flag": "🇬🇺"
  },
  {
    "country": "Guatemala",
    "code": "GT",
    "flag": "🇬🇹"
  },
  {
    "country": "Guernsey",
    "code": "GG",
    "flag": "🇬🇬"
  },
  {
    "country": "Guinea",
    "code": "GN",
    "flag": "🇬🇳"
  },
  {
    "country": "Guinea-Bissau",
    "code": "GW",
    "flag": "🇬🇼"
  },
  {
    "country": "Guyana",
    "code": "GY",
    "flag": "🇬🇾"
  },
  {
    "country": "Haiti",
    "code": "HT",
    "flag": "🇭🇹"
  },
  {
    "country": "Heard Island and McDonald Islands",
    "code": "HM",
    "flag": "🇭🇲"
  },
  {
    "country": "Holy See (Vatican City State)",
    "code": "VA",
    "flag": "🇻🇦"
  },
  {
    "country": "Honduras",
    "code": "HN",
    "flag": "🇭🇳"
  },
  {
    "country": "Hong Kong",
    "code": "HK",
    "flag": "🇭🇰"
  },
  {
    "country": "Hungary",
    "code": "HU",
    "flag": "🇭🇺"
  },
  {
    "country": "Iceland",
    "code": "IS",
    "flag": "🇮🇸"
  },
  {
    "country": "India",
    "code": "IN",
    "flag": "🇮🇳"
  },
  {
    "country": "Indonesia",
    "code": "ID",
    "flag": "🇮🇩"
  },
  {
    "country": "Iran, Islamic Republic of",
    "code": "IR",
    "flag": "🇮🇷"
  },
  {
    "country": "Iraq",
    "code": "IQ",
    "flag": "🇮🇶"
  },
  {
    "country": "Ireland",
    "code": "IE",
    "flag": "🇮🇪"
  },
  {
    "country": "Isle of Man",
    "code": "IM",
    "flag": "🇮🇲"
  },
  {
    "country": "Israel",
    "code": "IL",
    "flag": "🇮🇱"
  },
  {
    "country": "Italy",
    "code": "IT",
    "flag": "🇮🇹"
  },
  {
    "country": "Jamaica",
    "code": "JM",
    "flag": "🇯🇲"
  },
  {
    "country": "Japan",
    "code": "JP",
    "flag": "🇯🇵"
  },
  {
    "country": "Jersey",
    "code": "JE",
    "flag": "🇯🇪"
  },
  {
    "country": "Jordan",
    "code": "JO",
    "flag": "🇯🇴"
  },
  {
    "country": "Kazakhstan",
    "code": "KZ",
    "flag": "🇰🇿"
  },
  {
    "country": "Kenya",
    "code": "KE",
    "flag": "🇰🇪"
  },
  {
    "country": "Kiribati",
    "code": "KI",
    "flag": "🇰🇮"
  },
  {
    "country": "Korea, Democratic People's Republic of",
    "code": "KP",
    "flag": "🇰🇵"
  },
  {
    "country": "Korea, Republic of",
    "code": "KR",
    "flag": "🇰🇷"
  },
  {
    "country": "Kuwait",
    "code": "KW",
    "flag": "🇰🇼"
  },
  {
    "country": "Kyrgyzstan",
    "code": "KG",
    "flag": "🇰🇬"
  },
  {
    "country": "Lao People's Democratic Republic",
    "code": "LA",
    "flag": "🇱🇦"
  },
  {
    "country": "Latvia",
    "code": "LV",
    "flag": "🇱🇻"
  },
  {
    "country": "Lebanon",
    "code": "LB",
    "flag": "🇱🇧"
  },
  {
    "country": "Lesotho",
    "code": "LS",
    "flag": "🇱🇸"
  },
  {
    "country": "Liberia",
    "code": "LR",
    "flag": "🇱🇷"
  },
  {
    "country": "Libya",
    "code": "LY",
    "flag": "🇱🇾"
  },
  {
    "country": "Liechtenstein",
    "code": "LI",
    "flag": "🇱🇮"
  },
  {
    "country": "Lithuania",
    "code": "LT",
    "flag": "🇱🇹"
  },
  {
    "country": "Luxembourg",
    "code": "LU",
    "flag": "🇱🇺"
  },
  {
    "country": "Macao",
    "code": "MO",
    "flag": "🇲🇴"
  },
  {
    "country": "Madagascar",
    "code": "MG",
    "flag": "🇲🇬"
  },
  {
    "country": "Malawi",
    "code": "MW",
    "flag": "🇲🇼"
  },
  {
    "country": "Malaysia",
    "code": "MY",
    "flag": "🇲🇾"
  },
  {
    "country": "Maldives",
    "code": "MV",
    "flag": "🇲🇻"
  },
  {
    "country": "Mali",
    "code": "ML",
    "flag": "🇲🇱"
  },
  {
    "country": "Malta",
    "code": "MT",
    "flag": "🇲🇹"
  },
  {
    "country": "Marshall Islands",
    "code": "MH",
    "flag": "🇲🇭"
  },
  {
    "country": "Martinique",
    "code": "MQ",
    "flag": "🇲🇶"
  },
  {
    "country": "Mauritania",
    "code": "MR",
    "flag": "🇲🇷"
  },
  {
    "country": "Mauritius",
    "code": "MU",
    "flag": "🇲🇺"
  },
  {
    "country": "Mayotte",
    "code": "YT",
    "flag": "🇾🇹"
  },
  {
    "country": "Mexico",
    "code": "MX",
    "flag": "🇲🇽"
  },
  {
    "country": "Micronesia, Federated States of",
    "code": "FM",
    "flag": "🇫🇲"
  },
  {
    "country": "Moldova, Republic of",
    "code": "MD",
    "flag": "🇲🇩"
  },
  {
    "country": "Monaco",
    "code": "MC",
    "flag": "🇲🇨"
  },
  {
    "country": "Mongolia",
    "code": "MN",
    "flag": "🇲🇳"
  },
  {
    "country": "Montenegro",
    "code": "ME",
    "flag": "🇲🇪"
  },
  {
    "country": "Montserrat",
    "code": "MS",
    "flag": "🇲🇸"
  },
  {
    "country": "Morocco",
    "code": "MA",
    "flag": "🇲🇦"
  },
  {
    "country": "Mozambique",
    "code": "MZ",
    "flag": "🇲🇿"
  },
  {
    "country": "Myanmar",
    "code": "MM",
    "flag": "🇲🇲"
  },
  {
    "country": "Namibia",
    "code": "NA",
    "flag": "🇳🇦"
  },
  {
    "country": "Nauru",
    "code": "NR",
    "flag": "🇳🇷"
  },
  {
    "country": "Nepal",
    "code": "NP",
    "flag": "🇳🇵"
  },
  {
    "country": "Netherlands",
    "code": "NL",
    "flag": "🇳🇱"
  },
  {
    "country": "New Caledonia",
    "code": "NC",
    "flag": "🇳🇨"
  },
  {
    "country": "New Zealand",
    "code": "NZ",
    "flag": "🇳🇿"
  },
  {
    "country": "Nicaragua",
    "code": "NI",
    "flag": "🇳🇮"
  },
  {
    "country": "Niger",
    "code": "NE",
    "flag": "🇳🇪"
  },
  {
    "country": "Nigeria",
    "code": "NG",
    "flag": "🇳🇬"
  },
  {
    "country": "Niue",
    "code": "NU",
    "flag": "🇳🇺"
  },
  {
    "country": "Norfolk Island",
    "code": "NF",
    "flag": "🇳🇫"
  },
  {
    "country": "North Macedonia",
    "code": "MK",
    "flag": "🇲🇰"
  },
  {
    "country": "Northern Mariana Islands",
    "code": "MP",
    "flag": "🇲🇵"
  },
  {
    "country": "Norway",
    "code": "NO",
    "flag": "🇳🇴"
  },
  {
    "country": "Oman",
    "code": "OM",
    "flag": "🇴🇲"
  },
  {
    "country": "Pakistan",
    "code": "PK",
    "flag": "🇵🇰"
  },
  {
    "country": "Palau",
    "code": "PW",
    "flag": "🇵🇼"
  },
  {
    "country": "Palestine, State of",
    "code": "PS",
    "flag": "🇵🇸"
  },
  {
    "country": "Panama",
    "code": "PA",
    "flag": "🇵🇦"
  },
  {
    "country": "Papua New Guinea",
    "code": "PG",
    "flag": "🇵🇬"
  },
  {
    "country": "Paraguay",
    "code": "PY",
    "flag": "🇵🇾"
  },
  {
    "country": "Peru",
    "code": "PE",
    "flag": "🇵🇪"
  },
  {
    "country": "Philippines",
    "code": "PH",
    "flag": "🇵🇭"
  },
  {
    "country": "Pitcairn",
    "code": "PN",
    "flag": "🇵🇳"
  },
  {
    "country": "Poland",
    "code": "PL",
    "flag": "🇵🇱"
  },
  {
    "country": "Portugal",
    "code": "PT",
    "flag": "🇵🇹"
  },
  {
    "country": "Puerto Rico",
    "code": "PR",
    "flag": "🇵🇷"
  },
  {
    "country": "Qatar",
    "code": "QA",
    "flag": "🇶🇦"
  },
  {
    "country": "Romania",
    "code": "RO",
    "flag": "🇷🇴"
  },
  {
    "country": "Russian Federation",
    "code": "RU",
    "flag": "🇷🇺"
  },
  {
    "country": "Rwanda",
    "code": "RW",
    "flag": "🇷🇼"
  },
  {
    "country": "Réunion",
    "code": "RE",
    "flag": "🇷🇪"
  },
  {
    "country": "Saint Barthélemy",
    "code": "BL",
    "flag": "🇧🇱"
  },
  {
    "country": "Saint Helena, Ascension and Tristan da Cunha",
    "code": "SH",
    "flag": "🇸🇭"
  },
  {
    "country": "Saint Kitts and Nevis",
    "code": "KN",
    "flag": "🇰🇳"
  },
  {
    "country": "Saint Lucia",
    "code": "LC",
    "flag": "🇱🇨"
  },
  {
    "country": "Saint Martin (French part)",
    "code": "MF",
    "flag": "🇲🇫"
  },
  {
    "country": "Saint Pierre and Miquelon",
    "code": "PM",
    "flag": "🇵🇲"
  },
  {
    "country": "Saint Vincent and the Grenadines",
    "code": "VC",
    "flag": "🇻🇨"
  },
  {
    "country": "Samoa",
    "code": "WS",
    "flag": "🇼🇸"
  },
  {
    "country": "San Marino",
    "code": "SM",
    "flag": "🇸🇲"
  },
  {
    "country": "Sao Tome and Principe",
    "code": "ST",
    "flag": "🇸🇹"
  },
  {
    "country": "Saudi Arabia",
    "code": "SA",
    "flag": "🇸🇦"
  },
  {
    "country": "Senegal",
    "code": "SN",
    "flag": "🇸🇳"
  },
  {
    "country": "Serbia",
    "code": "RS",
    "flag": "🇷🇸"
  },
  {
    "country": "Seychelles",
    "code": "SC",
    "flag": "🇸🇨"
  },
  {
    "country": "Sierra Leone",
    "code": "SL",
    "flag": "🇸🇱"
  },
  {
    "country": "Singapore",
    "code": "SG",
    "flag": "🇸🇬"
  },
  {
    "country": "Sint Maarten (Dutch part)",
    "code": "SX",
    "flag": "🇸🇽"
  },
  {
    "country": "Slovakia",
    "code": "SK",
    "flag": "🇸🇰"
  },
  {
    "country": "Slovenia",
    "code": "SI",
    "flag": "🇸🇮"
  },
  {
    "country": "Solomon Islands",
    "code": "SB",
    "flag": "🇸🇧"
  },
  {
    "country": "Somalia",
    "code": "SO",
    "flag": "🇸🇴"
  },
  {
    "country": "South Africa",
    "code": "ZA",
    "flag": "🇿🇦"
  },
  {
    "country": "South Georgia and the South Sandwich Islands",
    "code": "GS",
    "flag": "🇬🇸"
  },
  {
    "country": "South Sudan",
    "code": "SS",
    "flag": "🇸🇸"
  },
  {
    "country": "Spain",
    "code": "ES",
    "flag": "🇪🇸"
  },
  {
    "country": "Sri Lanka",
    "code": "LK",
    "flag": "🇱🇰"
  },
  {
    "country": "Sudan",
    "code": "SD",
    "flag": "🇸🇩"
  },
  {
    "country": "Suriname",
    "code": "SR",
    "flag": "🇸🇷"
  },
  {
    "country": "Svalbard and Jan Mayen",
    "code": "SJ",
    "flag": "🇸🇯"
  },
  {
    "country": "Sweden",
    "code": "SE",
    "flag": "🇸🇪"
  },
  {
    "country": "Switzerland",
    "code": "CH",
    "flag": "🇨🇭"
  },
  {
    "country": "Syrian Arab Republic",
    "code": "SY",
    "flag": "🇸🇾"
  },
  {
    "country": "Taiwan, Province of China",
    "code": "TW",
    "flag": "🇹🇼"
  },
  {
    "country": "Tajikistan",
    "code": "TJ",
    "flag": "🇹🇯"
  },
  {
    "country": "Tanzania, United Republic of",
    "code": "TZ",
    "flag": "🇹🇿"
  },
  {
    "country": "Thailand",
    "code": "TH",
    "flag": "🇹🇭"
  },
  {
    "country": "Timor-Leste",
    "code": "TL",
    "flag": "🇹🇱"
  },
  {
    "country": "Togo",
    "code": "TG",
    "flag": "🇹🇬"
  },
  {
    "country": "Tokelau",
    "code": "TK",
    "flag": "🇹🇰"
  },
  {
    "country": "Tonga",
    "code": "TO",
    "flag": "🇹🇴"
  },
  {
    "country": "Trinidad and Tobago",
    "code": "TT",
    "flag": "🇹🇹"
  },
  {
    "country": "Tunisia",
    "code": "TN",
    "flag": "🇹🇳"
  },
  {
    "country": "Turkey",
    "code": "TR",
    "flag": "🇹🇷"
  },
  {
    "country": "Turkmenistan",
    "code": "TM",
    "flag": "🇹🇲"
  },
  {
    "country": "Turks and Caicos Islands",
    "code": "TC",
    "flag": "🇹🇨"
  },
  {
    "country": "Tuvalu",
    "code": "TV",
    "flag": "🇹🇻"
  },
  {
    "country": "Uganda",
    "code": "UG",
    "flag": "🇺🇬"
  },
  {
    "country": "Ukraine",
    "code": "UA",
    "flag": "🇺🇦"
  },
  {
    "country": "United Arab Emirates",
    "code": "AE",
    "flag": "🇦🇪"
  },
  {
    "country": "United Kingdom",
    "code": "GB",
    "flag": "🇬🇧"
  },
  {
    "country": "United States",
    "code": "US",
    "flag": "🇺🇸"
  },
  {
    "country": "United States Minor Outlying Islands",
    "code": "UM",
    "flag": "🇺🇲"
  },
  {
    "country": "Uruguay",
    "code": "UY",
    "flag": "🇺🇾"
  },
  {
    "country": "Uzbekistan",
    "code": "UZ",
    "flag": "🇺🇿"
  },
  {
    "country": "Vanuatu",
    "code": "VU",
    "flag": "🇻🇺"
  },
  {
    "country": "Venezuela, Bolivarian Republic of",
    "code": "VE",
    "flag": "🇻🇪"
  },
  {
    "country": "Viet Nam",
    "code": "VN",
    "flag": "🇻🇳"
  },
  {
    "country": "Virgin Islands, British",
    "code": "VG",
    "flag": "🇻🇬"
  },
  {
    "country": "Virgin Islands, U.S.",
    "code": "VI",
    "flag": "🇻🇮"
  },
  {
    "country": "Wallis and Futuna",
    "code": "WF",
    "flag": "🇼🇫"
  },
  {
    "country": "Western Sahara",
    "code": "EH",
    "flag": "🇪🇭"
  },
  {
    "country": "Yemen",
    "code": "YE",
    "flag": "🇾🇪"
  },
  {
    "country": "Zambia",
    "code": "ZM",
    "flag": "🇿🇲"
  },
  {
    "country": "Zimbabwe",
    "code": "ZW",
    "flag": "🇿🇼"
  },
  {
    "country": "Åland Islands",
    "code": "AX",
    "flag": "🇦🇽"
  }
];

export const regionsByCountry = {
  "AD": [
    {
      "region": "Andorra la Vella",
      "code": "AD-07",
      "type": "Parish"
    },
    {
      "region": "Canillo",
      "code": "AD-02",
      "type": "Parish"
    },
    {
      "region": "Encamp",
      "code": "AD-03",
      "type": "Parish"
    },
    {
      "region": "Escaldes-Engordany",
      "code": "AD-08",
      "type": "Parish"
    },
    {
      "region": "La Massana",
      "code": "AD-04",
      "type": "Parish"
    },
    {
      "region": "Ordino",
      "code": "AD-05",
      "type": "Parish"
    },
    {
      "region": "Sant Julià de Lòria",
      "code": "AD-06",
      "type": "Parish"
    }
  ],
  "AE": [
    {
      "region": "Abū Z̧aby",
      "code": "AE-AZ",
      "type": "Emirate"
    },
    {
      "region": "Al Fujayrah",
      "code": "AE-FU",
      "type": "Emirate"
    },
    {
      "region": "Ash Shāriqah",
      "code": "AE-SH",
      "type": "Emirate"
    },
    {
      "region": "Dubayy",
      "code": "AE-DU",
      "type": "Emirate"
    },
    {
      "region": "Ra’s al Khaymah",
      "code": "AE-RK",
      "type": "Emirate"
    },
    {
      "region": "Umm al Qaywayn",
      "code": "AE-UQ",
      "type": "Emirate"
    },
    {
      "region": "‘Ajmān",
      "code": "AE-AJ",
      "type": "Emirate"
    }
  ],
  "AF": [
    {
      "region": "Badakhshān",
      "code": "AF-BDS",
      "type": "Province"
    },
    {
      "region": "Baghlān",
      "code": "AF-BGL",
      "type": "Province"
    },
    {
      "region": "Balkh",
      "code": "AF-BAL",
      "type": "Province"
    },
    {
      "region": "Bādghīs",
      "code": "AF-BDG",
      "type": "Province"
    },
    {
      "region": "Bāmyān",
      "code": "AF-BAM",
      "type": "Province"
    },
    {
      "region": "Dāykundī",
      "code": "AF-DAY",
      "type": "Province"
    },
    {
      "region": "Farāh",
      "code": "AF-FRA",
      "type": "Province"
    },
    {
      "region": "Fāryāb",
      "code": "AF-FYB",
      "type": "Province"
    },
    {
      "region": "Ghaznī",
      "code": "AF-GHA",
      "type": "Province"
    },
    {
      "region": "Ghōr",
      "code": "AF-GHO",
      "type": "Province"
    },
    {
      "region": "Helmand",
      "code": "AF-HEL",
      "type": "Province"
    },
    {
      "region": "Herāt",
      "code": "AF-HER",
      "type": "Province"
    },
    {
      "region": "Jowzjān",
      "code": "AF-JOW",
      "type": "Province"
    },
    {
      "region": "Kandahār",
      "code": "AF-KAN",
      "type": "Province"
    },
    {
      "region": "Khōst",
      "code": "AF-KHO",
      "type": "Province"
    },
    {
      "region": "Kunaṟ",
      "code": "AF-KNR",
      "type": "Province"
    },
    {
      "region": "Kunduz",
      "code": "AF-KDZ",
      "type": "Province"
    },
    {
      "region": "Kābul",
      "code": "AF-KAB",
      "type": "Province"
    },
    {
      "region": "Kāpīsā",
      "code": "AF-KAP",
      "type": "Province"
    },
    {
      "region": "Laghmān",
      "code": "AF-LAG",
      "type": "Province"
    },
    {
      "region": "Lōgar",
      "code": "AF-LOG",
      "type": "Province"
    },
    {
      "region": "Nangarhār",
      "code": "AF-NAN",
      "type": "Province"
    },
    {
      "region": "Nīmrōz",
      "code": "AF-NIM",
      "type": "Province"
    },
    {
      "region": "Nūristān",
      "code": "AF-NUR",
      "type": "Province"
    },
    {
      "region": "Paktiyā",
      "code": "AF-PIA",
      "type": "Province"
    },
    {
      "region": "Paktīkā",
      "code": "AF-PKA",
      "type": "Province"
    },
    {
      "region": "Panjshayr",
      "code": "AF-PAN",
      "type": "Province"
    },
    {
      "region": "Parwān",
      "code": "AF-PAR",
      "type": "Province"
    },
    {
      "region": "Samangān",
      "code": "AF-SAM",
      "type": "Province"
    },
    {
      "region": "Sar-e Pul",
      "code": "AF-SAR",
      "type": "Province"
    },
    {
      "region": "Takhār",
      "code": "AF-TAK",
      "type": "Province"
    },
    {
      "region": "Uruzgān",
      "code": "AF-URU",
      "type": "Province"
    },
    {
      "region": "Wardak",
      "code": "AF-WAR",
      "type": "Province"
    },
    {
      "region": "Zābul",
      "code": "AF-ZAB",
      "type": "Province"
    }
  ],
  "AG": [
    {
      "region": "Barbuda",
      "code": "AG-10",
      "type": "Dependency"
    },
    {
      "region": "Redonda",
      "code": "AG-11",
      "type": "Dependency"
    },
    {
      "region": "Saint George",
      "code": "AG-03",
      "type": "Parish"
    },
    {
      "region": "Saint John",
      "code": "AG-04",
      "type": "Parish"
    },
    {
      "region": "Saint Mary",
      "code": "AG-05",
      "type": "Parish"
    },
    {
      "region": "Saint Paul",
      "code": "AG-06",
      "type": "Parish"
    },
    {
      "region": "Saint Peter",
      "code": "AG-07",
      "type": "Parish"
    },
    {
      "region": "Saint Philip",
      "code": "AG-08",
      "type": "Parish"
    }
  ],
  "AL": [
    {
      "region": "Berat",
      "code": "AL-01",
      "type": "County"
    },
    {
      "region": "Dibër",
      "code": "AL-09",
      "type": "County"
    },
    {
      "region": "Durrës",
      "code": "AL-02",
      "type": "County"
    },
    {
      "region": "Elbasan",
      "code": "AL-03",
      "type": "County"
    },
    {
      "region": "Fier",
      "code": "AL-04",
      "type": "County"
    },
    {
      "region": "Gjirokastër",
      "code": "AL-05",
      "type": "County"
    },
    {
      "region": "Korçë",
      "code": "AL-06",
      "type": "County"
    },
    {
      "region": "Kukës",
      "code": "AL-07",
      "type": "County"
    },
    {
      "region": "Lezhë",
      "code": "AL-08",
      "type": "County"
    },
    {
      "region": "Shkodër",
      "code": "AL-10",
      "type": "County"
    },
    {
      "region": "Tiranë",
      "code": "AL-11",
      "type": "County"
    },
    {
      "region": "Vlorë",
      "code": "AL-12",
      "type": "County"
    }
  ],
  "AM": [
    {
      "region": "Aragac̣otn",
      "code": "AM-AG",
      "type": "Region"
    },
    {
      "region": "Ararat",
      "code": "AM-AR",
      "type": "Region"
    },
    {
      "region": "Armavir",
      "code": "AM-AV",
      "type": "Region"
    },
    {
      "region": "Erevan",
      "code": "AM-ER",
      "type": "City"
    },
    {
      "region": "Geġark'unik'",
      "code": "AM-GR",
      "type": "Region"
    },
    {
      "region": "Kotayk'",
      "code": "AM-KT",
      "type": "Region"
    },
    {
      "region": "Loṙi",
      "code": "AM-LO",
      "type": "Region"
    },
    {
      "region": "Syunik'",
      "code": "AM-SU",
      "type": "Region"
    },
    {
      "region": "Tavuš",
      "code": "AM-TV",
      "type": "Region"
    },
    {
      "region": "Vayoć Jor",
      "code": "AM-VD",
      "type": "Region"
    },
    {
      "region": "Širak",
      "code": "AM-SH",
      "type": "Region"
    }
  ],
  "AO": [
    {
      "region": "Bengo",
      "code": "AO-BGO",
      "type": "Province"
    },
    {
      "region": "Benguela",
      "code": "AO-BGU",
      "type": "Province"
    },
    {
      "region": "Bié",
      "code": "AO-BIE",
      "type": "Province"
    },
    {
      "region": "Cabinda",
      "code": "AO-CAB",
      "type": "Province"
    },
    {
      "region": "Cuando Cubango",
      "code": "AO-CCU",
      "type": "Province"
    },
    {
      "region": "Cuanza-Norte",
      "code": "AO-CNO",
      "type": "Province"
    },
    {
      "region": "Cuanza-Sul",
      "code": "AO-CUS",
      "type": "Province"
    },
    {
      "region": "Cunene",
      "code": "AO-CNN",
      "type": "Province"
    },
    {
      "region": "Huambo",
      "code": "AO-HUA",
      "type": "Province"
    },
    {
      "region": "Huíla",
      "code": "AO-HUI",
      "type": "Province"
    },
    {
      "region": "Luanda",
      "code": "AO-LUA",
      "type": "Province"
    },
    {
      "region": "Lunda-Norte",
      "code": "AO-LNO",
      "type": "Province"
    },
    {
      "region": "Lunda-Sul",
      "code": "AO-LSU",
      "type": "Province"
    },
    {
      "region": "Malange",
      "code": "AO-MAL",
      "type": "Province"
    },
    {
      "region": "Moxico",
      "code": "AO-MOX",
      "type": "Province"
    },
    {
      "region": "Namibe",
      "code": "AO-NAM",
      "type": "Province"
    },
    {
      "region": "Uíge",
      "code": "AO-UIG",
      "type": "Province"
    },
    {
      "region": "Zaire",
      "code": "AO-ZAI",
      "type": "Province"
    }
  ],
  "AR": [
    {
      "region": "Buenos Aires",
      "code": "AR-B",
      "type": "Province"
    },
    {
      "region": "Catamarca",
      "code": "AR-K",
      "type": "Province"
    },
    {
      "region": "Chaco",
      "code": "AR-H",
      "type": "Province"
    },
    {
      "region": "Chubut",
      "code": "AR-U",
      "type": "Province"
    },
    {
      "region": "Ciudad Autónoma de Buenos Aires",
      "code": "AR-C",
      "type": "City"
    },
    {
      "region": "Corrientes",
      "code": "AR-W",
      "type": "Province"
    },
    {
      "region": "Córdoba",
      "code": "AR-X",
      "type": "Province"
    },
    {
      "region": "Entre Ríos",
      "code": "AR-E",
      "type": "Province"
    },
    {
      "region": "Formosa",
      "code": "AR-P",
      "type": "Province"
    },
    {
      "region": "Jujuy",
      "code": "AR-Y",
      "type": "Province"
    },
    {
      "region": "La Pampa",
      "code": "AR-L",
      "type": "Province"
    },
    {
      "region": "La Rioja",
      "code": "AR-F",
      "type": "Province"
    },
    {
      "region": "Mendoza",
      "code": "AR-M",
      "type": "Province"
    },
    {
      "region": "Misiones",
      "code": "AR-N",
      "type": "Province"
    },
    {
      "region": "Neuquén",
      "code": "AR-Q",
      "type": "Province"
    },
    {
      "region": "Río Negro",
      "code": "AR-R",
      "type": "Province"
    },
    {
      "region": "Salta",
      "code": "AR-A",
      "type": "Province"
    },
    {
      "region": "San Juan",
      "code": "AR-J",
      "type": "Province"
    },
    {
      "region": "San Luis",
      "code": "AR-D",
      "type": "Province"
    },
    {
      "region": "Santa Cruz",
      "code": "AR-Z",
      "type": "Province"
    },
    {
      "region": "Santa Fe",
      "code": "AR-S",
      "type": "Province"
    },
    {
      "region": "Santiago del Estero",
      "code": "AR-G",
      "type": "Province"
    },
    {
      "region": "Tierra del Fuego",
      "code": "AR-V",
      "type": "Province"
    },
    {
      "region": "Tucumán",
      "code": "AR-T",
      "type": "Province"
    }
  ],
  "AT": [
    {
      "region": "Burgenland",
      "code": "AT-1",
      "type": "State"
    },
    {
      "region": "Kärnten",
      "code": "AT-2",
      "type": "State"
    },
    {
      "region": "Niederösterreich",
      "code": "AT-3",
      "type": "State"
    },
    {
      "region": "Oberösterreich",
      "code": "AT-4",
      "type": "State"
    },
    {
      "region": "Salzburg",
      "code": "AT-5",
      "type": "State"
    },
    {
      "region": "Steiermark",
      "code": "AT-6",
      "type": "State"
    },
    {
      "region": "Tirol",
      "code": "AT-7",
      "type": "State"
    },
    {
      "region": "Vorarlberg",
      "code": "AT-8",
      "type": "State"
    },
    {
      "region": "Wien",
      "code": "AT-9",
      "type": "State"
    }
  ],
  "AU": [
    {
      "region": "Australian Capital Territory",
      "code": "AU-ACT",
      "type": "Territory"
    },
    {
      "region": "New South Wales",
      "code": "AU-NSW",
      "type": "State"
    },
    {
      "region": "Northern Territory",
      "code": "AU-NT",
      "type": "Territory"
    },
    {
      "region": "Queensland",
      "code": "AU-QLD",
      "type": "State"
    },
    {
      "region": "South Australia",
      "code": "AU-SA",
      "type": "State"
    },
    {
      "region": "Tasmania",
      "code": "AU-TAS",
      "type": "State"
    },
    {
      "region": "Victoria",
      "code": "AU-VIC",
      "type": "State"
    },
    {
      "region": "Western Australia",
      "code": "AU-WA",
      "type": "State"
    }
  ],
  "AZ": [
    {
      "region": "Abşeron",
      "code": "AZ-ABS",
      "type": "Rayon"
    },
    {
      "region": "Astara",
      "code": "AZ-AST",
      "type": "Rayon"
    },
    {
      "region": "Ağcabədi",
      "code": "AZ-AGC",
      "type": "Rayon"
    },
    {
      "region": "Ağdam",
      "code": "AZ-AGM",
      "type": "Rayon"
    },
    {
      "region": "Ağdaş",
      "code": "AZ-AGS",
      "type": "Rayon"
    },
    {
      "region": "Ağstafa",
      "code": "AZ-AGA",
      "type": "Rayon"
    },
    {
      "region": "Ağsu",
      "code": "AZ-AGU",
      "type": "Rayon"
    },
    {
      "region": "Bakı",
      "code": "AZ-BA",
      "type": "Municipality"
    },
    {
      "region": "Balakən",
      "code": "AZ-BAL",
      "type": "Rayon"
    },
    {
      "region": "Beyləqan",
      "code": "AZ-BEY",
      "type": "Rayon"
    },
    {
      "region": "Biləsuvar",
      "code": "AZ-BIL",
      "type": "Rayon"
    },
    {
      "region": "Bərdə",
      "code": "AZ-BAR",
      "type": "Rayon"
    },
    {
      "region": "Cəbrayıl",
      "code": "AZ-CAB",
      "type": "Rayon"
    },
    {
      "region": "Cəlilabad",
      "code": "AZ-CAL",
      "type": "Rayon"
    },
    {
      "region": "Daşkəsən",
      "code": "AZ-DAS",
      "type": "Rayon"
    },
    {
      "region": "Füzuli",
      "code": "AZ-FUZ",
      "type": "Rayon"
    },
    {
      "region": "Goranboy",
      "code": "AZ-GOR",
      "type": "Rayon"
    },
    {
      "region": "Göygöl",
      "code": "AZ-GYG",
      "type": "Rayon"
    },
    {
      "region": "Göyçay",
      "code": "AZ-GOY",
      "type": "Rayon"
    },
    {
      "region": "Gədəbəy",
      "code": "AZ-GAD",
      "type": "Rayon"
    },
    {
      "region": "Gəncə",
      "code": "AZ-GA",
      "type": "Municipality"
    },
    {
      "region": "Hacıqabul",
      "code": "AZ-HAC",
      "type": "Rayon"
    },
    {
      "region": "Kürdəmir",
      "code": "AZ-KUR",
      "type": "Rayon"
    },
    {
      "region": "Kəlbəcər",
      "code": "AZ-KAL",
      "type": "Rayon"
    },
    {
      "region": "Laçın",
      "code": "AZ-LAC",
      "type": "Rayon"
    },
    {
      "region": "Lerik",
      "code": "AZ-LER",
      "type": "Rayon"
    },
    {
      "region": "Lənkəran",
      "code": "AZ-LA",
      "type": "Municipality"
    },
    {
      "region": "Lənkəran",
      "code": "AZ-LAN",
      "type": "Rayon"
    },
    {
      "region": "Masallı",
      "code": "AZ-MAS",
      "type": "Rayon"
    },
    {
      "region": "Mingəçevir",
      "code": "AZ-MI",
      "type": "Municipality"
    },
    {
      "region": "Naftalan",
      "code": "AZ-NA",
      "type": "Municipality"
    },
    {
      "region": "Naxçıvan",
      "code": "AZ-NX",
      "type": "Autonomous republic"
    },
    {
      "region": "Neftçala",
      "code": "AZ-NEF",
      "type": "Rayon"
    },
    {
      "region": "Oğuz",
      "code": "AZ-OGU",
      "type": "Rayon"
    },
    {
      "region": "Qax",
      "code": "AZ-QAX",
      "type": "Rayon"
    },
    {
      "region": "Qazax",
      "code": "AZ-QAZ",
      "type": "Rayon"
    },
    {
      "region": "Qobustan",
      "code": "AZ-QOB",
      "type": "Rayon"
    },
    {
      "region": "Quba",
      "code": "AZ-QBA",
      "type": "Rayon"
    },
    {
      "region": "Qubadlı",
      "code": "AZ-QBI",
      "type": "Rayon"
    },
    {
      "region": "Qusar",
      "code": "AZ-QUS",
      "type": "Rayon"
    },
    {
      "region": "Qəbələ",
      "code": "AZ-QAB",
      "type": "Rayon"
    },
    {
      "region": "Saatlı",
      "code": "AZ-SAT",
      "type": "Rayon"
    },
    {
      "region": "Sabirabad",
      "code": "AZ-SAB",
      "type": "Rayon"
    },
    {
      "region": "Salyan",
      "code": "AZ-SAL",
      "type": "Rayon"
    },
    {
      "region": "Samux",
      "code": "AZ-SMX",
      "type": "Rayon"
    },
    {
      "region": "Siyəzən",
      "code": "AZ-SIY",
      "type": "Rayon"
    },
    {
      "region": "Sumqayıt",
      "code": "AZ-SM",
      "type": "Municipality"
    },
    {
      "region": "Tovuz",
      "code": "AZ-TOV",
      "type": "Rayon"
    },
    {
      "region": "Tərtər",
      "code": "AZ-TAR",
      "type": "Rayon"
    },
    {
      "region": "Ucar",
      "code": "AZ-UCA",
      "type": "Rayon"
    },
    {
      "region": "Xankəndi",
      "code": "AZ-XA",
      "type": "Municipality"
    },
    {
      "region": "Xaçmaz",
      "code": "AZ-XAC",
      "type": "Rayon"
    },
    {
      "region": "Xocalı",
      "code": "AZ-XCI",
      "type": "Rayon"
    },
    {
      "region": "Xocavənd",
      "code": "AZ-XVD",
      "type": "Rayon"
    },
    {
      "region": "Xızı",
      "code": "AZ-XIZ",
      "type": "Rayon"
    },
    {
      "region": "Yardımlı",
      "code": "AZ-YAR",
      "type": "Rayon"
    },
    {
      "region": "Yevlax",
      "code": "AZ-YE",
      "type": "Municipality"
    },
    {
      "region": "Yevlax",
      "code": "AZ-YEV",
      "type": "Rayon"
    },
    {
      "region": "Zaqatala",
      "code": "AZ-ZAQ",
      "type": "Rayon"
    },
    {
      "region": "Zəngilan",
      "code": "AZ-ZAN",
      "type": "Rayon"
    },
    {
      "region": "Zərdab",
      "code": "AZ-ZAR",
      "type": "Rayon"
    },
    {
      "region": "İmişli",
      "code": "AZ-IMI",
      "type": "Rayon"
    },
    {
      "region": "İsmayıllı",
      "code": "AZ-ISM",
      "type": "Rayon"
    },
    {
      "region": "Şabran",
      "code": "AZ-SBN",
      "type": "Rayon"
    },
    {
      "region": "Şamaxı",
      "code": "AZ-SMI",
      "type": "Rayon"
    },
    {
      "region": "Şirvan",
      "code": "AZ-SR",
      "type": "Municipality"
    },
    {
      "region": "Şuşa",
      "code": "AZ-SUS",
      "type": "Rayon"
    },
    {
      "region": "Şəki",
      "code": "AZ-SA",
      "type": "Municipality"
    },
    {
      "region": "Şəki",
      "code": "AZ-SAK",
      "type": "Rayon"
    },
    {
      "region": "Şəmkir",
      "code": "AZ-SKR",
      "type": "Rayon"
    }
  ],
  "BA": [
    {
      "region": "Brčko distrikt",
      "code": "BA-BRC",
      "type": "District with special status"
    },
    {
      "region": "Federacija Bosne i Hercegovine",
      "code": "BA-BIH",
      "type": "Entity"
    },
    {
      "region": "Republika Srpska",
      "code": "BA-SRP",
      "type": "Entity"
    }
  ],
  "BB": [
    {
      "region": "Christ Church",
      "code": "BB-01",
      "type": "Parish"
    },
    {
      "region": "Saint Andrew",
      "code": "BB-02",
      "type": "Parish"
    },
    {
      "region": "Saint George",
      "code": "BB-03",
      "type": "Parish"
    },
    {
      "region": "Saint James",
      "code": "BB-04",
      "type": "Parish"
    },
    {
      "region": "Saint John",
      "code": "BB-05",
      "type": "Parish"
    },
    {
      "region": "Saint Joseph",
      "code": "BB-06",
      "type": "Parish"
    },
    {
      "region": "Saint Lucy",
      "code": "BB-07",
      "type": "Parish"
    },
    {
      "region": "Saint Michael",
      "code": "BB-08",
      "type": "Parish"
    },
    {
      "region": "Saint Peter",
      "code": "BB-09",
      "type": "Parish"
    },
    {
      "region": "Saint Philip",
      "code": "BB-10",
      "type": "Parish"
    },
    {
      "region": "Saint Thomas",
      "code": "BB-11",
      "type": "Parish"
    }
  ],
  "BD": [
    {
      "region": "Barishal",
      "code": "BD-A",
      "type": "Division"
    },
    {
      "region": "Chattogram",
      "code": "BD-B",
      "type": "Division"
    },
    {
      "region": "Dhaka",
      "code": "BD-C",
      "type": "Division"
    },
    {
      "region": "Khulna",
      "code": "BD-D",
      "type": "Division"
    },
    {
      "region": "Mymensingh",
      "code": "BD-H",
      "type": "Division"
    },
    {
      "region": "Rajshahi",
      "code": "BD-E",
      "type": "Division"
    },
    {
      "region": "Rangpur",
      "code": "BD-F",
      "type": "Division"
    },
    {
      "region": "Sylhet",
      "code": "BD-G",
      "type": "Division"
    }
  ],
  "BE": [
    {
      "region": "Brussels Hoofdstedelijk Gewest",
      "code": "BE-BRU",
      "type": "Region"
    },
    {
      "region": "Vlaams Gewest",
      "code": "BE-VLG",
      "type": "Region"
    },
    {
      "region": "wallonne, Région",
      "code": "BE-WAL",
      "type": "Region"
    }
  ],
  "BF": [
    {
      "region": "Boucle du Mouhoun",
      "code": "BF-01",
      "type": "Region"
    },
    {
      "region": "Cascades",
      "code": "BF-02",
      "type": "Region"
    },
    {
      "region": "Centre",
      "code": "BF-03",
      "type": "Region"
    },
    {
      "region": "Centre-Est",
      "code": "BF-04",
      "type": "Region"
    },
    {
      "region": "Centre-Nord",
      "code": "BF-05",
      "type": "Region"
    },
    {
      "region": "Centre-Ouest",
      "code": "BF-06",
      "type": "Region"
    },
    {
      "region": "Centre-Sud",
      "code": "BF-07",
      "type": "Region"
    },
    {
      "region": "Est",
      "code": "BF-08",
      "type": "Region"
    },
    {
      "region": "Hauts-Bassins",
      "code": "BF-09",
      "type": "Region"
    },
    {
      "region": "Nord",
      "code": "BF-10",
      "type": "Region"
    },
    {
      "region": "Plateau-Central",
      "code": "BF-11",
      "type": "Region"
    },
    {
      "region": "Sahel",
      "code": "BF-12",
      "type": "Region"
    },
    {
      "region": "Sud-Ouest",
      "code": "BF-13",
      "type": "Region"
    }
  ],
  "BG": [
    {
      "region": "Blagoevgrad",
      "code": "BG-01",
      "type": "District"
    },
    {
      "region": "Burgas",
      "code": "BG-02",
      "type": "District"
    },
    {
      "region": "Dobrich",
      "code": "BG-08",
      "type": "District"
    },
    {
      "region": "Gabrovo",
      "code": "BG-07",
      "type": "District"
    },
    {
      "region": "Haskovo",
      "code": "BG-26",
      "type": "District"
    },
    {
      "region": "Kardzhali",
      "code": "BG-09",
      "type": "District"
    },
    {
      "region": "Kyustendil",
      "code": "BG-10",
      "type": "District"
    },
    {
      "region": "Lovech",
      "code": "BG-11",
      "type": "District"
    },
    {
      "region": "Montana",
      "code": "BG-12",
      "type": "District"
    },
    {
      "region": "Pazardzhik",
      "code": "BG-13",
      "type": "District"
    },
    {
      "region": "Pernik",
      "code": "BG-14",
      "type": "District"
    },
    {
      "region": "Pleven",
      "code": "BG-15",
      "type": "District"
    },
    {
      "region": "Plovdiv",
      "code": "BG-16",
      "type": "District"
    },
    {
      "region": "Razgrad",
      "code": "BG-17",
      "type": "District"
    },
    {
      "region": "Ruse",
      "code": "BG-18",
      "type": "District"
    },
    {
      "region": "Shumen",
      "code": "BG-27",
      "type": "District"
    },
    {
      "region": "Silistra",
      "code": "BG-19",
      "type": "District"
    },
    {
      "region": "Sliven",
      "code": "BG-20",
      "type": "District"
    },
    {
      "region": "Smolyan",
      "code": "BG-21",
      "type": "District"
    },
    {
      "region": "Sofia",
      "code": "BG-23",
      "type": "District"
    },
    {
      "region": "Sofia (stolitsa)",
      "code": "BG-22",
      "type": "District"
    },
    {
      "region": "Stara Zagora",
      "code": "BG-24",
      "type": "District"
    },
    {
      "region": "Targovishte",
      "code": "BG-25",
      "type": "District"
    },
    {
      "region": "Varna",
      "code": "BG-03",
      "type": "District"
    },
    {
      "region": "Veliko Tarnovo",
      "code": "BG-04",
      "type": "District"
    },
    {
      "region": "Vidin",
      "code": "BG-05",
      "type": "District"
    },
    {
      "region": "Vratsa",
      "code": "BG-06",
      "type": "District"
    },
    {
      "region": "Yambol",
      "code": "BG-28",
      "type": "District"
    }
  ],
  "BH": [
    {
      "region": "Al Janūbīyah",
      "code": "BH-14",
      "type": "Governorate"
    },
    {
      "region": "Al Muḩarraq",
      "code": "BH-15",
      "type": "Governorate"
    },
    {
      "region": "Al ‘Āşimah",
      "code": "BH-13",
      "type": "Governorate"
    },
    {
      "region": "Ash Shamālīyah",
      "code": "BH-17",
      "type": "Governorate"
    }
  ],
  "BI": [
    {
      "region": "Bubanza",
      "code": "BI-BB",
      "type": "Province"
    },
    {
      "region": "Bujumbura Mairie",
      "code": "BI-BM",
      "type": "Province"
    },
    {
      "region": "Bujumbura Rural",
      "code": "BI-BL",
      "type": "Province"
    },
    {
      "region": "Bururi",
      "code": "BI-BR",
      "type": "Province"
    },
    {
      "region": "Cankuzo",
      "code": "BI-CA",
      "type": "Province"
    },
    {
      "region": "Cibitoke",
      "code": "BI-CI",
      "type": "Province"
    },
    {
      "region": "Gitega",
      "code": "BI-GI",
      "type": "Province"
    },
    {
      "region": "Karuzi",
      "code": "BI-KR",
      "type": "Province"
    },
    {
      "region": "Kayanza",
      "code": "BI-KY",
      "type": "Province"
    },
    {
      "region": "Kirundo",
      "code": "BI-KI",
      "type": "Province"
    },
    {
      "region": "Makamba",
      "code": "BI-MA",
      "type": "Province"
    },
    {
      "region": "Muramvya",
      "code": "BI-MU",
      "type": "Province"
    },
    {
      "region": "Muyinga",
      "code": "BI-MY",
      "type": "Province"
    },
    {
      "region": "Mwaro",
      "code": "BI-MW",
      "type": "Province"
    },
    {
      "region": "Ngozi",
      "code": "BI-NG",
      "type": "Province"
    },
    {
      "region": "Rumonge",
      "code": "BI-RM",
      "type": "Province"
    },
    {
      "region": "Rutana",
      "code": "BI-RT",
      "type": "Province"
    },
    {
      "region": "Ruyigi",
      "code": "BI-RY",
      "type": "Province"
    }
  ],
  "BJ": [
    {
      "region": "Alibori",
      "code": "BJ-AL",
      "type": "Department"
    },
    {
      "region": "Atacora",
      "code": "BJ-AK",
      "type": "Department"
    },
    {
      "region": "Atlantique",
      "code": "BJ-AQ",
      "type": "Department"
    },
    {
      "region": "Borgou",
      "code": "BJ-BO",
      "type": "Department"
    },
    {
      "region": "Collines",
      "code": "BJ-CO",
      "type": "Department"
    },
    {
      "region": "Couffo",
      "code": "BJ-KO",
      "type": "Department"
    },
    {
      "region": "Donga",
      "code": "BJ-DO",
      "type": "Department"
    },
    {
      "region": "Littoral",
      "code": "BJ-LI",
      "type": "Department"
    },
    {
      "region": "Mono",
      "code": "BJ-MO",
      "type": "Department"
    },
    {
      "region": "Ouémé",
      "code": "BJ-OU",
      "type": "Department"
    },
    {
      "region": "Plateau",
      "code": "BJ-PL",
      "type": "Department"
    },
    {
      "region": "Zou",
      "code": "BJ-ZO",
      "type": "Department"
    }
  ],
  "BN": [
    {
      "region": "Belait",
      "code": "BN-BE",
      "type": "District"
    },
    {
      "region": "Brunei-Muara",
      "code": "BN-BM",
      "type": "District"
    },
    {
      "region": "Temburong",
      "code": "BN-TE",
      "type": "District"
    },
    {
      "region": "Tutong",
      "code": "BN-TU",
      "type": "District"
    }
  ],
  "BO": [
    {
      "region": "Chuquisaca",
      "code": "BO-H",
      "type": "Department"
    },
    {
      "region": "Cochabamba",
      "code": "BO-C",
      "type": "Department"
    },
    {
      "region": "El Beni",
      "code": "BO-B",
      "type": "Department"
    },
    {
      "region": "La Paz",
      "code": "BO-L",
      "type": "Department"
    },
    {
      "region": "Oruro",
      "code": "BO-O",
      "type": "Department"
    },
    {
      "region": "Pando",
      "code": "BO-N",
      "type": "Department"
    },
    {
      "region": "Potosí",
      "code": "BO-P",
      "type": "Department"
    },
    {
      "region": "Santa Cruz",
      "code": "BO-S",
      "type": "Department"
    },
    {
      "region": "Tarija",
      "code": "BO-T",
      "type": "Department"
    }
  ],
  "BQ": [
    {
      "region": "Bonaire",
      "code": "BQ-BO",
      "type": "Special municipality"
    },
    {
      "region": "Saba",
      "code": "BQ-SA",
      "type": "Special municipality"
    },
    {
      "region": "Sint Eustatius",
      "code": "BQ-SE",
      "type": "Special municipality"
    }
  ],
  "BR": [
    {
      "region": "Acre",
      "code": "BR-AC",
      "type": "State"
    },
    {
      "region": "Alagoas",
      "code": "BR-AL",
      "type": "State"
    },
    {
      "region": "Amapá",
      "code": "BR-AP",
      "type": "State"
    },
    {
      "region": "Amazonas",
      "code": "BR-AM",
      "type": "State"
    },
    {
      "region": "Bahia",
      "code": "BR-BA",
      "type": "State"
    },
    {
      "region": "Ceará",
      "code": "BR-CE",
      "type": "State"
    },
    {
      "region": "Distrito Federal",
      "code": "BR-DF",
      "type": "Federal district"
    },
    {
      "region": "Espírito Santo",
      "code": "BR-ES",
      "type": "State"
    },
    {
      "region": "Goiás",
      "code": "BR-GO",
      "type": "State"
    },
    {
      "region": "Maranhão",
      "code": "BR-MA",
      "type": "State"
    },
    {
      "region": "Mato Grosso",
      "code": "BR-MT",
      "type": "State"
    },
    {
      "region": "Mato Grosso do Sul",
      "code": "BR-MS",
      "type": "State"
    },
    {
      "region": "Minas Gerais",
      "code": "BR-MG",
      "type": "State"
    },
    {
      "region": "Paraná",
      "code": "BR-PR",
      "type": "State"
    },
    {
      "region": "Paraíba",
      "code": "BR-PB",
      "type": "State"
    },
    {
      "region": "Pará",
      "code": "BR-PA",
      "type": "State"
    },
    {
      "region": "Pernambuco",
      "code": "BR-PE",
      "type": "State"
    },
    {
      "region": "Piauí",
      "code": "BR-PI",
      "type": "State"
    },
    {
      "region": "Rio Grande do Norte",
      "code": "BR-RN",
      "type": "State"
    },
    {
      "region": "Rio Grande do Sul",
      "code": "BR-RS",
      "type": "State"
    },
    {
      "region": "Rio de Janeiro",
      "code": "BR-RJ",
      "type": "State"
    },
    {
      "region": "Rondônia",
      "code": "BR-RO",
      "type": "State"
    },
    {
      "region": "Roraima",
      "code": "BR-RR",
      "type": "State"
    },
    {
      "region": "Santa Catarina",
      "code": "BR-SC",
      "type": "State"
    },
    {
      "region": "Sergipe",
      "code": "BR-SE",
      "type": "State"
    },
    {
      "region": "São Paulo",
      "code": "BR-SP",
      "type": "State"
    },
    {
      "region": "Tocantins",
      "code": "BR-TO",
      "type": "State"
    }
  ],
  "BS": [
    {
      "region": "Acklins",
      "code": "BS-AK",
      "type": "District"
    },
    {
      "region": "Berry Islands",
      "code": "BS-BY",
      "type": "District"
    },
    {
      "region": "Bimini",
      "code": "BS-BI",
      "type": "District"
    },
    {
      "region": "Black Point",
      "code": "BS-BP",
      "type": "District"
    },
    {
      "region": "Cat Island",
      "code": "BS-CI",
      "type": "District"
    },
    {
      "region": "Central Abaco",
      "code": "BS-CO",
      "type": "District"
    },
    {
      "region": "Central Andros",
      "code": "BS-CS",
      "type": "District"
    },
    {
      "region": "Central Eleuthera",
      "code": "BS-CE",
      "type": "District"
    },
    {
      "region": "City of Freeport",
      "code": "BS-FP",
      "type": "District"
    },
    {
      "region": "Crooked Island and Long Cay",
      "code": "BS-CK",
      "type": "District"
    },
    {
      "region": "East Grand Bahama",
      "code": "BS-EG",
      "type": "District"
    },
    {
      "region": "Exuma",
      "code": "BS-EX",
      "type": "District"
    },
    {
      "region": "Grand Cay",
      "code": "BS-GC",
      "type": "District"
    },
    {
      "region": "Harbour Island",
      "code": "BS-HI",
      "type": "District"
    },
    {
      "region": "Hope Town",
      "code": "BS-HT",
      "type": "District"
    },
    {
      "region": "Inagua",
      "code": "BS-IN",
      "type": "District"
    },
    {
      "region": "Long Island",
      "code": "BS-LI",
      "type": "District"
    },
    {
      "region": "Mangrove Cay",
      "code": "BS-MC",
      "type": "District"
    },
    {
      "region": "Mayaguana",
      "code": "BS-MG",
      "type": "District"
    },
    {
      "region": "Moore's Island",
      "code": "BS-MI",
      "type": "District"
    },
    {
      "region": "New Providence",
      "code": "BS-NP",
      "type": "Island"
    },
    {
      "region": "North Abaco",
      "code": "BS-NO",
      "type": "District"
    },
    {
      "region": "North Andros",
      "code": "BS-NS",
      "type": "District"
    },
    {
      "region": "North Eleuthera",
      "code": "BS-NE",
      "type": "District"
    },
    {
      "region": "Ragged Island",
      "code": "BS-RI",
      "type": "District"
    },
    {
      "region": "Rum Cay",
      "code": "BS-RC",
      "type": "District"
    },
    {
      "region": "San Salvador",
      "code": "BS-SS",
      "type": "District"
    },
    {
      "region": "South Abaco",
      "code": "BS-SO",
      "type": "District"
    },
    {
      "region": "South Andros",
      "code": "BS-SA",
      "type": "District"
    },
    {
      "region": "South Eleuthera",
      "code": "BS-SE",
      "type": "District"
    },
    {
      "region": "Spanish Wells",
      "code": "BS-SW",
      "type": "District"
    },
    {
      "region": "West Grand Bahama",
      "code": "BS-WG",
      "type": "District"
    }
  ],
  "BT": [
    {
      "region": "Bumthang",
      "code": "BT-33",
      "type": "District"
    },
    {
      "region": "Chhukha",
      "code": "BT-12",
      "type": "District"
    },
    {
      "region": "Dagana",
      "code": "BT-22",
      "type": "District"
    },
    {
      "region": "Gasa",
      "code": "BT-GA",
      "type": "District"
    },
    {
      "region": "Haa",
      "code": "BT-13",
      "type": "District"
    },
    {
      "region": "Lhuentse",
      "code": "BT-44",
      "type": "District"
    },
    {
      "region": "Monggar",
      "code": "BT-42",
      "type": "District"
    },
    {
      "region": "Paro",
      "code": "BT-11",
      "type": "District"
    },
    {
      "region": "Pema Gatshel",
      "code": "BT-43",
      "type": "District"
    },
    {
      "region": "Punakha",
      "code": "BT-23",
      "type": "District"
    },
    {
      "region": "Samdrup Jongkhar",
      "code": "BT-45",
      "type": "District"
    },
    {
      "region": "Samtse",
      "code": "BT-14",
      "type": "District"
    },
    {
      "region": "Sarpang",
      "code": "BT-31",
      "type": "District"
    },
    {
      "region": "Thimphu",
      "code": "BT-15",
      "type": "District"
    },
    {
      "region": "Trashi Yangtse",
      "code": "BT-TY",
      "type": "District"
    },
    {
      "region": "Trashigang",
      "code": "BT-41",
      "type": "District"
    },
    {
      "region": "Trongsa",
      "code": "BT-32",
      "type": "District"
    },
    {
      "region": "Tsirang",
      "code": "BT-21",
      "type": "District"
    },
    {
      "region": "Wangdue Phodrang",
      "code": "BT-24",
      "type": "District"
    },
    {
      "region": "Zhemgang",
      "code": "BT-34",
      "type": "District"
    }
  ],
  "BW": [
    {
      "region": "Central",
      "code": "BW-CE",
      "type": "District"
    },
    {
      "region": "Chobe",
      "code": "BW-CH",
      "type": "District"
    },
    {
      "region": "Francistown",
      "code": "BW-FR",
      "type": "City"
    },
    {
      "region": "Gaborone",
      "code": "BW-GA",
      "type": "City"
    },
    {
      "region": "Ghanzi",
      "code": "BW-GH",
      "type": "District"
    },
    {
      "region": "Jwaneng",
      "code": "BW-JW",
      "type": "Town"
    },
    {
      "region": "Kgalagadi",
      "code": "BW-KG",
      "type": "District"
    },
    {
      "region": "Kgatleng",
      "code": "BW-KL",
      "type": "District"
    },
    {
      "region": "Kweneng",
      "code": "BW-KW",
      "type": "District"
    },
    {
      "region": "Lobatse",
      "code": "BW-LO",
      "type": "Town"
    },
    {
      "region": "North East",
      "code": "BW-NE",
      "type": "District"
    },
    {
      "region": "North West",
      "code": "BW-NW",
      "type": "District"
    },
    {
      "region": "Selibe Phikwe",
      "code": "BW-SP",
      "type": "Town"
    },
    {
      "region": "South East",
      "code": "BW-SE",
      "type": "District"
    },
    {
      "region": "Southern",
      "code": "BW-SO",
      "type": "District"
    },
    {
      "region": "Sowa Town",
      "code": "BW-ST",
      "type": "Town"
    }
  ],
  "BY": [
    {
      "region": "Bresckaja voblasć",
      "code": "BY-BR",
      "type": "Oblast"
    },
    {
      "region": "Gomel'skaja oblast'",
      "code": "BY-HO",
      "type": "Oblast"
    },
    {
      "region": "Gorod Minsk",
      "code": "BY-HM",
      "type": "City"
    },
    {
      "region": "Grodnenskaja oblast'",
      "code": "BY-HR",
      "type": "Oblast"
    },
    {
      "region": "Mahilioŭskaja voblasć",
      "code": "BY-MA",
      "type": "Oblast"
    },
    {
      "region": "Minskaja oblast'",
      "code": "BY-MI",
      "type": "Oblast"
    },
    {
      "region": "Viciebskaja voblasć",
      "code": "BY-VI",
      "type": "Oblast"
    }
  ],
  "BZ": [
    {
      "region": "Belize",
      "code": "BZ-BZ",
      "type": "District"
    },
    {
      "region": "Cayo",
      "code": "BZ-CY",
      "type": "District"
    },
    {
      "region": "Corozal",
      "code": "BZ-CZL",
      "type": "District"
    },
    {
      "region": "Orange Walk",
      "code": "BZ-OW",
      "type": "District"
    },
    {
      "region": "Stann Creek",
      "code": "BZ-SC",
      "type": "District"
    },
    {
      "region": "Toledo",
      "code": "BZ-TOL",
      "type": "District"
    }
  ],
  "CA": [
    {
      "region": "Alberta",
      "code": "CA-AB",
      "type": "Province"
    },
    {
      "region": "British Columbia",
      "code": "CA-BC",
      "type": "Province"
    },
    {
      "region": "Manitoba",
      "code": "CA-MB",
      "type": "Province"
    },
    {
      "region": "New Brunswick",
      "code": "CA-NB",
      "type": "Province"
    },
    {
      "region": "Newfoundland and Labrador",
      "code": "CA-NL",
      "type": "Province"
    },
    {
      "region": "Northwest Territories",
      "code": "CA-NT",
      "type": "Territory"
    },
    {
      "region": "Nova Scotia",
      "code": "CA-NS",
      "type": "Province"
    },
    {
      "region": "Nunavut",
      "code": "CA-NU",
      "type": "Territory"
    },
    {
      "region": "Ontario",
      "code": "CA-ON",
      "type": "Province"
    },
    {
      "region": "Prince Edward Island",
      "code": "CA-PE",
      "type": "Province"
    },
    {
      "region": "Quebec",
      "code": "CA-QC",
      "type": "Province"
    },
    {
      "region": "Saskatchewan",
      "code": "CA-SK",
      "type": "Province"
    },
    {
      "region": "Yukon",
      "code": "CA-YT",
      "type": "Territory"
    }
  ],
  "CD": [
    {
      "region": "Bas-Uélé",
      "code": "CD-BU",
      "type": "Province"
    },
    {
      "region": "Haut-Katanga",
      "code": "CD-HK",
      "type": "Province"
    },
    {
      "region": "Haut-Lomami",
      "code": "CD-HL",
      "type": "Province"
    },
    {
      "region": "Haut-Uélé",
      "code": "CD-HU",
      "type": "Province"
    },
    {
      "region": "Ituri",
      "code": "CD-IT",
      "type": "Province"
    },
    {
      "region": "Kasaï",
      "code": "CD-KS",
      "type": "Province"
    },
    {
      "region": "Kasaï Central",
      "code": "CD-KC",
      "type": "Province"
    },
    {
      "region": "Kasaï Oriental",
      "code": "CD-KE",
      "type": "Province"
    },
    {
      "region": "Kinshasa",
      "code": "CD-KN",
      "type": "City"
    },
    {
      "region": "Kongo Central",
      "code": "CD-BC",
      "type": "Province"
    },
    {
      "region": "Kwango",
      "code": "CD-KG",
      "type": "Province"
    },
    {
      "region": "Kwilu",
      "code": "CD-KL",
      "type": "Province"
    },
    {
      "region": "Lomami",
      "code": "CD-LO",
      "type": "Province"
    },
    {
      "region": "Lualaba",
      "code": "CD-LU",
      "type": "Province"
    },
    {
      "region": "Mai-Ndombe",
      "code": "CD-MN",
      "type": "Province"
    },
    {
      "region": "Maniema",
      "code": "CD-MA",
      "type": "Province"
    },
    {
      "region": "Mongala",
      "code": "CD-MO",
      "type": "Province"
    },
    {
      "region": "Nord-Kivu",
      "code": "CD-NK",
      "type": "Province"
    },
    {
      "region": "Nord-Ubangi",
      "code": "CD-NU",
      "type": "Province"
    },
    {
      "region": "Sankuru",
      "code": "CD-SA",
      "type": "Province"
    },
    {
      "region": "Sud-Kivu",
      "code": "CD-SK",
      "type": "Province"
    },
    {
      "region": "Sud-Ubangi",
      "code": "CD-SU",
      "type": "Province"
    },
    {
      "region": "Tanganyika",
      "code": "CD-TA",
      "type": "Province"
    },
    {
      "region": "Tshopo",
      "code": "CD-TO",
      "type": "Province"
    },
    {
      "region": "Tshuapa",
      "code": "CD-TU",
      "type": "Province"
    },
    {
      "region": "Équateur",
      "code": "CD-EQ",
      "type": "Province"
    }
  ],
  "CF": [
    {
      "region": "Bamingui-Bangoran",
      "code": "CF-BB",
      "type": "Prefecture"
    },
    {
      "region": "Bangui",
      "code": "CF-BGF",
      "type": "Commune"
    },
    {
      "region": "Basse-Kotto",
      "code": "CF-BK",
      "type": "Prefecture"
    },
    {
      "region": "Gribingui",
      "code": "CF-KB",
      "type": "Economic prefecture"
    },
    {
      "region": "Haut-Mbomou",
      "code": "CF-HM",
      "type": "Prefecture"
    },
    {
      "region": "Haute-Kotto",
      "code": "CF-HK",
      "type": "Prefecture"
    },
    {
      "region": "Haute-Sangha / Mambéré-Kadéï",
      "code": "CF-HS",
      "type": "Prefecture"
    },
    {
      "region": "Kemö-Gïrïbïngï",
      "code": "CF-KG",
      "type": "Prefecture"
    },
    {
      "region": "Lobaye",
      "code": "CF-LB",
      "type": "Prefecture"
    },
    {
      "region": "Mbomou",
      "code": "CF-MB",
      "type": "Prefecture"
    },
    {
      "region": "Nana-Mambéré",
      "code": "CF-NM",
      "type": "Prefecture"
    },
    {
      "region": "Ombella-Mpoko",
      "code": "CF-MP",
      "type": "Prefecture"
    },
    {
      "region": "Ouaka",
      "code": "CF-UK",
      "type": "Prefecture"
    },
    {
      "region": "Ouham",
      "code": "CF-AC",
      "type": "Prefecture"
    },
    {
      "region": "Ouham-Pendé",
      "code": "CF-OP",
      "type": "Prefecture"
    },
    {
      "region": "Sangha",
      "code": "CF-SE",
      "type": "Economic prefecture"
    },
    {
      "region": "Vakaga",
      "code": "CF-VK",
      "type": "Prefecture"
    }
  ],
  "CG": [
    {
      "region": "Bouenza",
      "code": "CG-11",
      "type": "Department"
    },
    {
      "region": "Brazzaville",
      "code": "CG-BZV",
      "type": "Department"
    },
    {
      "region": "Cuvette",
      "code": "CG-8",
      "type": "Department"
    },
    {
      "region": "Cuvette-Ouest",
      "code": "CG-15",
      "type": "Department"
    },
    {
      "region": "Kouilou",
      "code": "CG-5",
      "type": "Department"
    },
    {
      "region": "Likouala",
      "code": "CG-7",
      "type": "Department"
    },
    {
      "region": "Lékoumou",
      "code": "CG-2",
      "type": "Department"
    },
    {
      "region": "Niari",
      "code": "CG-9",
      "type": "Department"
    },
    {
      "region": "Plateaux",
      "code": "CG-14",
      "type": "Department"
    },
    {
      "region": "Pointe-Noire",
      "code": "CG-16",
      "type": "Department"
    },
    {
      "region": "Pool",
      "code": "CG-12",
      "type": "Department"
    },
    {
      "region": "Sangha",
      "code": "CG-13",
      "type": "Department"
    }
  ],
  "CH": [
    {
      "region": "Aargau",
      "code": "CH-AG",
      "type": "Canton"
    },
    {
      "region": "Appenzell Ausserrhoden",
      "code": "CH-AR",
      "type": "Canton"
    },
    {
      "region": "Appenzell Innerrhoden",
      "code": "CH-AI",
      "type": "Canton"
    },
    {
      "region": "Basel-Landschaft",
      "code": "CH-BL",
      "type": "Canton"
    },
    {
      "region": "Basel-Stadt",
      "code": "CH-BS",
      "type": "Canton"
    },
    {
      "region": "Bern",
      "code": "CH-BE",
      "type": "Canton"
    },
    {
      "region": "Freiburg",
      "code": "CH-FR",
      "type": "Canton"
    },
    {
      "region": "Genève",
      "code": "CH-GE",
      "type": "Canton"
    },
    {
      "region": "Glarus",
      "code": "CH-GL",
      "type": "Canton"
    },
    {
      "region": "Graubünden",
      "code": "CH-GR",
      "type": "Canton"
    },
    {
      "region": "Jura",
      "code": "CH-JU",
      "type": "Canton"
    },
    {
      "region": "Luzern",
      "code": "CH-LU",
      "type": "Canton"
    },
    {
      "region": "Neuchâtel",
      "code": "CH-NE",
      "type": "Canton"
    },
    {
      "region": "Nidwalden",
      "code": "CH-NW",
      "type": "Canton"
    },
    {
      "region": "Obwalden",
      "code": "CH-OW",
      "type": "Canton"
    },
    {
      "region": "Sankt Gallen",
      "code": "CH-SG",
      "type": "Canton"
    },
    {
      "region": "Schaffhausen",
      "code": "CH-SH",
      "type": "Canton"
    },
    {
      "region": "Schwyz",
      "code": "CH-SZ",
      "type": "Canton"
    },
    {
      "region": "Solothurn",
      "code": "CH-SO",
      "type": "Canton"
    },
    {
      "region": "Thurgau",
      "code": "CH-TG",
      "type": "Canton"
    },
    {
      "region": "Ticino",
      "code": "CH-TI",
      "type": "Canton"
    },
    {
      "region": "Uri",
      "code": "CH-UR",
      "type": "Canton"
    },
    {
      "region": "Valais",
      "code": "CH-VS",
      "type": "Canton"
    },
    {
      "region": "Vaud",
      "code": "CH-VD",
      "type": "Canton"
    },
    {
      "region": "Zug",
      "code": "CH-ZG",
      "type": "Canton"
    },
    {
      "region": "Zürich",
      "code": "CH-ZH",
      "type": "Canton"
    }
  ],
  "CI": [
    {
      "region": "Abidjan",
      "code": "CI-AB",
      "type": "Autonomous district"
    },
    {
      "region": "Bas-Sassandra",
      "code": "CI-BS",
      "type": "District"
    },
    {
      "region": "Comoé",
      "code": "CI-CM",
      "type": "District"
    },
    {
      "region": "Denguélé",
      "code": "CI-DN",
      "type": "District"
    },
    {
      "region": "Gôh-Djiboua",
      "code": "CI-GD",
      "type": "District"
    },
    {
      "region": "Lacs",
      "code": "CI-LC",
      "type": "District"
    },
    {
      "region": "Lagunes",
      "code": "CI-LG",
      "type": "District"
    },
    {
      "region": "Montagnes",
      "code": "CI-MG",
      "type": "District"
    },
    {
      "region": "Sassandra-Marahoué",
      "code": "CI-SM",
      "type": "District"
    },
    {
      "region": "Savanes",
      "code": "CI-SV",
      "type": "District"
    },
    {
      "region": "Vallée du Bandama",
      "code": "CI-VB",
      "type": "District"
    },
    {
      "region": "Woroba",
      "code": "CI-WR",
      "type": "District"
    },
    {
      "region": "Yamoussoukro",
      "code": "CI-YM",
      "type": "Autonomous district"
    },
    {
      "region": "Zanzan",
      "code": "CI-ZZ",
      "type": "District"
    }
  ],
  "CL": [
    {
      "region": "Aisén del General Carlos Ibañez del Campo",
      "code": "CL-AI",
      "type": "Region"
    },
    {
      "region": "Antofagasta",
      "code": "CL-AN",
      "type": "Region"
    },
    {
      "region": "Arica y Parinacota",
      "code": "CL-AP",
      "type": "Region"
    },
    {
      "region": "Atacama",
      "code": "CL-AT",
      "type": "Region"
    },
    {
      "region": "Biobío",
      "code": "CL-BI",
      "type": "Region"
    },
    {
      "region": "Coquimbo",
      "code": "CL-CO",
      "type": "Region"
    },
    {
      "region": "La Araucanía",
      "code": "CL-AR",
      "type": "Region"
    },
    {
      "region": "Libertador General Bernardo O'Higgins",
      "code": "CL-LI",
      "type": "Region"
    },
    {
      "region": "Los Lagos",
      "code": "CL-LL",
      "type": "Region"
    },
    {
      "region": "Los Ríos",
      "code": "CL-LR",
      "type": "Region"
    },
    {
      "region": "Magallanes",
      "code": "CL-MA",
      "type": "Region"
    },
    {
      "region": "Maule",
      "code": "CL-ML",
      "type": "Region"
    },
    {
      "region": "Región Metropolitana de Santiago",
      "code": "CL-RM",
      "type": "Region"
    },
    {
      "region": "Tarapacá",
      "code": "CL-TA",
      "type": "Region"
    },
    {
      "region": "Valparaíso",
      "code": "CL-VS",
      "type": "Region"
    },
    {
      "region": "Ñuble",
      "code": "CL-NB",
      "type": "Region"
    }
  ],
  "CM": [
    {
      "region": "Adamaoua",
      "code": "CM-AD",
      "type": "Region"
    },
    {
      "region": "Centre",
      "code": "CM-CE",
      "type": "Region"
    },
    {
      "region": "East",
      "code": "CM-ES",
      "type": "Region"
    },
    {
      "region": "Far North",
      "code": "CM-EN",
      "type": "Region"
    },
    {
      "region": "Littoral",
      "code": "CM-LT",
      "type": "Region"
    },
    {
      "region": "North",
      "code": "CM-NO",
      "type": "Region"
    },
    {
      "region": "North-West",
      "code": "CM-NW",
      "type": "Region"
    },
    {
      "region": "South",
      "code": "CM-SU",
      "type": "Region"
    },
    {
      "region": "South-West",
      "code": "CM-SW",
      "type": "Region"
    },
    {
      "region": "West",
      "code": "CM-OU",
      "type": "Region"
    }
  ],
  "CN": [
    {
      "region": "Anhui Sheng",
      "code": "CN-AH",
      "type": "Province"
    },
    {
      "region": "Beijing Shi",
      "code": "CN-BJ",
      "type": "Municipality"
    },
    {
      "region": "Chongqing Shi",
      "code": "CN-CQ",
      "type": "Municipality"
    },
    {
      "region": "Fujian Sheng",
      "code": "CN-FJ",
      "type": "Province"
    },
    {
      "region": "Gansu Sheng",
      "code": "CN-GS",
      "type": "Province"
    },
    {
      "region": "Guangdong Sheng",
      "code": "CN-GD",
      "type": "Province"
    },
    {
      "region": "Guangxi Zhuangzu Zizhiqu",
      "code": "CN-GX",
      "type": "Autonomous region"
    },
    {
      "region": "Guizhou Sheng",
      "code": "CN-GZ",
      "type": "Province"
    },
    {
      "region": "Hainan Sheng",
      "code": "CN-HI",
      "type": "Province"
    },
    {
      "region": "Hebei Sheng",
      "code": "CN-HE",
      "type": "Province"
    },
    {
      "region": "Heilongjiang Sheng",
      "code": "CN-HL",
      "type": "Province"
    },
    {
      "region": "Henan Sheng",
      "code": "CN-HA",
      "type": "Province"
    },
    {
      "region": "Hong Kong SAR",
      "code": "CN-HK",
      "type": "Special administrative region"
    },
    {
      "region": "Hubei Sheng",
      "code": "CN-HB",
      "type": "Province"
    },
    {
      "region": "Hunan Sheng",
      "code": "CN-HN",
      "type": "Province"
    },
    {
      "region": "Jiangsu Sheng",
      "code": "CN-JS",
      "type": "Province"
    },
    {
      "region": "Jiangxi Sheng",
      "code": "CN-JX",
      "type": "Province"
    },
    {
      "region": "Jilin Sheng",
      "code": "CN-JL",
      "type": "Province"
    },
    {
      "region": "Liaoning Sheng",
      "code": "CN-LN",
      "type": "Province"
    },
    {
      "region": "Macao SAR",
      "code": "CN-MO",
      "type": "Special administrative region"
    },
    {
      "region": "Nei Mongol Zizhiqu",
      "code": "CN-NM",
      "type": "Autonomous region"
    },
    {
      "region": "Ningxia Huizi Zizhiqu",
      "code": "CN-NX",
      "type": "Autonomous region"
    },
    {
      "region": "Qinghai Sheng",
      "code": "CN-QH",
      "type": "Province"
    },
    {
      "region": "Shaanxi Sheng",
      "code": "CN-SN",
      "type": "Province"
    },
    {
      "region": "Shandong Sheng",
      "code": "CN-SD",
      "type": "Province"
    },
    {
      "region": "Shanghai Shi",
      "code": "CN-SH",
      "type": "Municipality"
    },
    {
      "region": "Shanxi Sheng",
      "code": "CN-SX",
      "type": "Province"
    },
    {
      "region": "Sichuan Sheng",
      "code": "CN-SC",
      "type": "Province"
    },
    {
      "region": "Taiwan Sheng",
      "code": "CN-TW",
      "type": "Province"
    },
    {
      "region": "Tianjin Shi",
      "code": "CN-TJ",
      "type": "Municipality"
    },
    {
      "region": "Xinjiang Uygur Zizhiqu",
      "code": "CN-XJ",
      "type": "Autonomous region"
    },
    {
      "region": "Xizang Zizhiqu",
      "code": "CN-XZ",
      "type": "Autonomous region"
    },
    {
      "region": "Yunnan Sheng",
      "code": "CN-YN",
      "type": "Province"
    },
    {
      "region": "Zhejiang Sheng",
      "code": "CN-ZJ",
      "type": "Province"
    }
  ],
  "CO": [
    {
      "region": "Amazonas",
      "code": "CO-AMA",
      "type": "Department"
    },
    {
      "region": "Antioquia",
      "code": "CO-ANT",
      "type": "Department"
    },
    {
      "region": "Arauca",
      "code": "CO-ARA",
      "type": "Department"
    },
    {
      "region": "Atlántico",
      "code": "CO-ATL",
      "type": "Department"
    },
    {
      "region": "Bolívar",
      "code": "CO-BOL",
      "type": "Department"
    },
    {
      "region": "Boyacá",
      "code": "CO-BOY",
      "type": "Department"
    },
    {
      "region": "Caldas",
      "code": "CO-CAL",
      "type": "Department"
    },
    {
      "region": "Caquetá",
      "code": "CO-CAQ",
      "type": "Department"
    },
    {
      "region": "Casanare",
      "code": "CO-CAS",
      "type": "Department"
    },
    {
      "region": "Cauca",
      "code": "CO-CAU",
      "type": "Department"
    },
    {
      "region": "Cesar",
      "code": "CO-CES",
      "type": "Department"
    },
    {
      "region": "Chocó",
      "code": "CO-CHO",
      "type": "Department"
    },
    {
      "region": "Cundinamarca",
      "code": "CO-CUN",
      "type": "Department"
    },
    {
      "region": "Córdoba",
      "code": "CO-COR",
      "type": "Department"
    },
    {
      "region": "Distrito Capital de Bogotá",
      "code": "CO-DC",
      "type": "Capital district"
    },
    {
      "region": "Guainía",
      "code": "CO-GUA",
      "type": "Department"
    },
    {
      "region": "Guaviare",
      "code": "CO-GUV",
      "type": "Department"
    },
    {
      "region": "Huila",
      "code": "CO-HUI",
      "type": "Department"
    },
    {
      "region": "La Guajira",
      "code": "CO-LAG",
      "type": "Department"
    },
    {
      "region": "Magdalena",
      "code": "CO-MAG",
      "type": "Department"
    },
    {
      "region": "Meta",
      "code": "CO-MET",
      "type": "Department"
    },
    {
      "region": "Nariño",
      "code": "CO-NAR",
      "type": "Department"
    },
    {
      "region": "Norte de Santander",
      "code": "CO-NSA",
      "type": "Department"
    },
    {
      "region": "Putumayo",
      "code": "CO-PUT",
      "type": "Department"
    },
    {
      "region": "Quindío",
      "code": "CO-QUI",
      "type": "Department"
    },
    {
      "region": "Risaralda",
      "code": "CO-RIS",
      "type": "Department"
    },
    {
      "region": "San Andrés, Providencia y Santa Catalina",
      "code": "CO-SAP",
      "type": "Department"
    },
    {
      "region": "Santander",
      "code": "CO-SAN",
      "type": "Department"
    },
    {
      "region": "Sucre",
      "code": "CO-SUC",
      "type": "Department"
    },
    {
      "region": "Tolima",
      "code": "CO-TOL",
      "type": "Department"
    },
    {
      "region": "Valle del Cauca",
      "code": "CO-VAC",
      "type": "Department"
    },
    {
      "region": "Vaupés",
      "code": "CO-VAU",
      "type": "Department"
    },
    {
      "region": "Vichada",
      "code": "CO-VID",
      "type": "Department"
    }
  ],
  "CR": [
    {
      "region": "Alajuela",
      "code": "CR-A",
      "type": "Province"
    },
    {
      "region": "Cartago",
      "code": "CR-C",
      "type": "Province"
    },
    {
      "region": "Guanacaste",
      "code": "CR-G",
      "type": "Province"
    },
    {
      "region": "Heredia",
      "code": "CR-H",
      "type": "Province"
    },
    {
      "region": "Limón",
      "code": "CR-L",
      "type": "Province"
    },
    {
      "region": "Puntarenas",
      "code": "CR-P",
      "type": "Province"
    },
    {
      "region": "San José",
      "code": "CR-SJ",
      "type": "Province"
    }
  ],
  "CU": [
    {
      "region": "Artemisa",
      "code": "CU-15",
      "type": "Province"
    },
    {
      "region": "Camagüey",
      "code": "CU-09",
      "type": "Province"
    },
    {
      "region": "Ciego de Ávila",
      "code": "CU-08",
      "type": "Province"
    },
    {
      "region": "Cienfuegos",
      "code": "CU-06",
      "type": "Province"
    },
    {
      "region": "Granma",
      "code": "CU-12",
      "type": "Province"
    },
    {
      "region": "Guantánamo",
      "code": "CU-14",
      "type": "Province"
    },
    {
      "region": "Holguín",
      "code": "CU-11",
      "type": "Province"
    },
    {
      "region": "Isla de la Juventud",
      "code": "CU-99",
      "type": "Special municipality"
    },
    {
      "region": "La Habana",
      "code": "CU-03",
      "type": "Province"
    },
    {
      "region": "Las Tunas",
      "code": "CU-10",
      "type": "Province"
    },
    {
      "region": "Matanzas",
      "code": "CU-04",
      "type": "Province"
    },
    {
      "region": "Mayabeque",
      "code": "CU-16",
      "type": "Province"
    },
    {
      "region": "Pinar del Río",
      "code": "CU-01",
      "type": "Province"
    },
    {
      "region": "Sancti Spíritus",
      "code": "CU-07",
      "type": "Province"
    },
    {
      "region": "Santiago de Cuba",
      "code": "CU-13",
      "type": "Province"
    },
    {
      "region": "Villa Clara",
      "code": "CU-05",
      "type": "Province"
    }
  ],
  "CV": [
    {
      "region": "Ilhas de Barlavento",
      "code": "CV-B",
      "type": "Geographical region"
    },
    {
      "region": "Ilhas de Sotavento",
      "code": "CV-S",
      "type": "Geographical region"
    }
  ],
  "CY": [
    {
      "region": "Ammochostos",
      "code": "CY-04",
      "type": "District"
    },
    {
      "region": "Baf",
      "code": "CY-05",
      "type": "District"
    },
    {
      "region": "Girne",
      "code": "CY-06",
      "type": "District"
    },
    {
      "region": "Larnaka",
      "code": "CY-03",
      "type": "District"
    },
    {
      "region": "Lefkosia",
      "code": "CY-01",
      "type": "District"
    },
    {
      "region": "Lemesos",
      "code": "CY-02",
      "type": "District"
    }
  ],
  "CZ": [
    {
      "region": "Jihomoravský kraj",
      "code": "CZ-64",
      "type": "Region"
    },
    {
      "region": "Jihočeský kraj",
      "code": "CZ-31",
      "type": "Region"
    },
    {
      "region": "Karlovarský kraj",
      "code": "CZ-41",
      "type": "Region"
    },
    {
      "region": "Kraj Vysočina",
      "code": "CZ-63",
      "type": "Region"
    },
    {
      "region": "Královéhradecký kraj",
      "code": "CZ-52",
      "type": "Region"
    },
    {
      "region": "Liberecký kraj",
      "code": "CZ-51",
      "type": "Region"
    },
    {
      "region": "Moravskoslezský kraj",
      "code": "CZ-80",
      "type": "Region"
    },
    {
      "region": "Olomoucký kraj",
      "code": "CZ-71",
      "type": "Region"
    },
    {
      "region": "Pardubický kraj",
      "code": "CZ-53",
      "type": "Region"
    },
    {
      "region": "Plzeňský kraj",
      "code": "CZ-32",
      "type": "Region"
    },
    {
      "region": "Praha, Hlavní město",
      "code": "CZ-10",
      "type": "Capital city"
    },
    {
      "region": "Středočeský kraj",
      "code": "CZ-20",
      "type": "Region"
    },
    {
      "region": "Zlínský kraj",
      "code": "CZ-72",
      "type": "Region"
    },
    {
      "region": "Ústecký kraj",
      "code": "CZ-42",
      "type": "Region"
    }
  ],
  "DE": [
    {
      "region": "Baden-Württemberg",
      "code": "DE-BW",
      "type": "Land"
    },
    {
      "region": "Bayern",
      "code": "DE-BY",
      "type": "Land"
    },
    {
      "region": "Berlin",
      "code": "DE-BE",
      "type": "Land"
    },
    {
      "region": "Brandenburg",
      "code": "DE-BB",
      "type": "Land"
    },
    {
      "region": "Bremen",
      "code": "DE-HB",
      "type": "Land"
    },
    {
      "region": "Hamburg",
      "code": "DE-HH",
      "type": "Land"
    },
    {
      "region": "Hessen",
      "code": "DE-HE",
      "type": "Land"
    },
    {
      "region": "Mecklenburg-Vorpommern",
      "code": "DE-MV",
      "type": "Land"
    },
    {
      "region": "Niedersachsen",
      "code": "DE-NI",
      "type": "Land"
    },
    {
      "region": "Nordrhein-Westfalen",
      "code": "DE-NW",
      "type": "Land"
    },
    {
      "region": "Rheinland-Pfalz",
      "code": "DE-RP",
      "type": "Land"
    },
    {
      "region": "Saarland",
      "code": "DE-SL",
      "type": "Land"
    },
    {
      "region": "Sachsen",
      "code": "DE-SN",
      "type": "Land"
    },
    {
      "region": "Sachsen-Anhalt",
      "code": "DE-ST",
      "type": "Land"
    },
    {
      "region": "Schleswig-Holstein",
      "code": "DE-SH",
      "type": "Land"
    },
    {
      "region": "Thüringen",
      "code": "DE-TH",
      "type": "Land"
    }
  ],
  "DJ": [
    {
      "region": "Ali Sabieh",
      "code": "DJ-AS",
      "type": "Region"
    },
    {
      "region": "Arta",
      "code": "DJ-AR",
      "type": "Region"
    },
    {
      "region": "Awbūk",
      "code": "DJ-OB",
      "type": "Region"
    },
    {
      "region": "Dikhil",
      "code": "DJ-DI",
      "type": "Region"
    },
    {
      "region": "Djibouti",
      "code": "DJ-DJ",
      "type": "City"
    },
    {
      "region": "Tadjourah",
      "code": "DJ-TA",
      "type": "Region"
    }
  ],
  "DK": [
    {
      "region": "Hovedstaden",
      "code": "DK-84",
      "type": "Region"
    },
    {
      "region": "Midtjylland",
      "code": "DK-82",
      "type": "Region"
    },
    {
      "region": "Nordjylland",
      "code": "DK-81",
      "type": "Region"
    },
    {
      "region": "Sjælland",
      "code": "DK-85",
      "type": "Region"
    },
    {
      "region": "Syddanmark",
      "code": "DK-83",
      "type": "Region"
    }
  ],
  "DM": [
    {
      "region": "Saint Andrew",
      "code": "DM-02",
      "type": "Parish"
    },
    {
      "region": "Saint David",
      "code": "DM-03",
      "type": "Parish"
    },
    {
      "region": "Saint George",
      "code": "DM-04",
      "type": "Parish"
    },
    {
      "region": "Saint John",
      "code": "DM-05",
      "type": "Parish"
    },
    {
      "region": "Saint Joseph",
      "code": "DM-06",
      "type": "Parish"
    },
    {
      "region": "Saint Luke",
      "code": "DM-07",
      "type": "Parish"
    },
    {
      "region": "Saint Mark",
      "code": "DM-08",
      "type": "Parish"
    },
    {
      "region": "Saint Patrick",
      "code": "DM-09",
      "type": "Parish"
    },
    {
      "region": "Saint Paul",
      "code": "DM-10",
      "type": "Parish"
    },
    {
      "region": "Saint Peter",
      "code": "DM-11",
      "type": "Parish"
    }
  ],
  "DO": [
    {
      "region": "Cibao Nordeste",
      "code": "DO-33",
      "type": "Region"
    },
    {
      "region": "Cibao Noroeste",
      "code": "DO-34",
      "type": "Region"
    },
    {
      "region": "Cibao Norte",
      "code": "DO-35",
      "type": "Region"
    },
    {
      "region": "Cibao Sur",
      "code": "DO-36",
      "type": "Region"
    },
    {
      "region": "El Valle",
      "code": "DO-37",
      "type": "Region"
    },
    {
      "region": "Enriquillo",
      "code": "DO-38",
      "type": "Region"
    },
    {
      "region": "Higuamo",
      "code": "DO-39",
      "type": "Region"
    },
    {
      "region": "Ozama",
      "code": "DO-40",
      "type": "Region"
    },
    {
      "region": "Valdesia",
      "code": "DO-41",
      "type": "Region"
    },
    {
      "region": "Yuma",
      "code": "DO-42",
      "type": "Region"
    }
  ],
  "DZ": [
    {
      "region": "Adrar",
      "code": "DZ-01",
      "type": "Province"
    },
    {
      "region": "Alger",
      "code": "DZ-16",
      "type": "Province"
    },
    {
      "region": "Annaba",
      "code": "DZ-23",
      "type": "Province"
    },
    {
      "region": "Aïn Defla",
      "code": "DZ-44",
      "type": "Province"
    },
    {
      "region": "Aïn Témouchent",
      "code": "DZ-46",
      "type": "Province"
    },
    {
      "region": "Batna",
      "code": "DZ-05",
      "type": "Province"
    },
    {
      "region": "Biskra",
      "code": "DZ-07",
      "type": "Province"
    },
    {
      "region": "Blida",
      "code": "DZ-09",
      "type": "Province"
    },
    {
      "region": "Bordj Bou Arréridj",
      "code": "DZ-34",
      "type": "Province"
    },
    {
      "region": "Bouira",
      "code": "DZ-10",
      "type": "Province"
    },
    {
      "region": "Boumerdès",
      "code": "DZ-35",
      "type": "Province"
    },
    {
      "region": "Béchar",
      "code": "DZ-08",
      "type": "Province"
    },
    {
      "region": "Béjaïa",
      "code": "DZ-06",
      "type": "Province"
    },
    {
      "region": "Chlef",
      "code": "DZ-02",
      "type": "Province"
    },
    {
      "region": "Constantine",
      "code": "DZ-25",
      "type": "Province"
    },
    {
      "region": "Djelfa",
      "code": "DZ-17",
      "type": "Province"
    },
    {
      "region": "El Bayadh",
      "code": "DZ-32",
      "type": "Province"
    },
    {
      "region": "El Oued",
      "code": "DZ-39",
      "type": "Province"
    },
    {
      "region": "El Tarf",
      "code": "DZ-36",
      "type": "Province"
    },
    {
      "region": "Ghardaïa",
      "code": "DZ-47",
      "type": "Province"
    },
    {
      "region": "Guelma",
      "code": "DZ-24",
      "type": "Province"
    },
    {
      "region": "Illizi",
      "code": "DZ-33",
      "type": "Province"
    },
    {
      "region": "Jijel",
      "code": "DZ-18",
      "type": "Province"
    },
    {
      "region": "Khenchela",
      "code": "DZ-40",
      "type": "Province"
    },
    {
      "region": "Laghouat",
      "code": "DZ-03",
      "type": "Province"
    },
    {
      "region": "M'sila",
      "code": "DZ-28",
      "type": "Province"
    },
    {
      "region": "Mascara",
      "code": "DZ-29",
      "type": "Province"
    },
    {
      "region": "Mila",
      "code": "DZ-43",
      "type": "Province"
    },
    {
      "region": "Mostaganem",
      "code": "DZ-27",
      "type": "Province"
    },
    {
      "region": "Médéa",
      "code": "DZ-26",
      "type": "Province"
    },
    {
      "region": "Naama",
      "code": "DZ-45",
      "type": "Province"
    },
    {
      "region": "Oran",
      "code": "DZ-31",
      "type": "Province"
    },
    {
      "region": "Ouargla",
      "code": "DZ-30",
      "type": "Province"
    },
    {
      "region": "Oum el Bouaghi",
      "code": "DZ-04",
      "type": "Province"
    },
    {
      "region": "Relizane",
      "code": "DZ-48",
      "type": "Province"
    },
    {
      "region": "Saïda",
      "code": "DZ-20",
      "type": "Province"
    },
    {
      "region": "Sidi Bel Abbès",
      "code": "DZ-22",
      "type": "Province"
    },
    {
      "region": "Skikda",
      "code": "DZ-21",
      "type": "Province"
    },
    {
      "region": "Souk Ahras",
      "code": "DZ-41",
      "type": "Province"
    },
    {
      "region": "Sétif",
      "code": "DZ-19",
      "type": "Province"
    },
    {
      "region": "Tamanrasset",
      "code": "DZ-11",
      "type": "Province"
    },
    {
      "region": "Tiaret",
      "code": "DZ-14",
      "type": "Province"
    },
    {
      "region": "Tindouf",
      "code": "DZ-37",
      "type": "Province"
    },
    {
      "region": "Tipaza",
      "code": "DZ-42",
      "type": "Province"
    },
    {
      "region": "Tissemsilt",
      "code": "DZ-38",
      "type": "Province"
    },
    {
      "region": "Tizi Ouzou",
      "code": "DZ-15",
      "type": "Province"
    },
    {
      "region": "Tlemcen",
      "code": "DZ-13",
      "type": "Province"
    },
    {
      "region": "Tébessa",
      "code": "DZ-12",
      "type": "Province"
    }
  ],
  "EC": [
    {
      "region": "Azuay",
      "code": "EC-A",
      "type": "Province"
    },
    {
      "region": "Bolívar",
      "code": "EC-B",
      "type": "Province"
    },
    {
      "region": "Carchi",
      "code": "EC-C",
      "type": "Province"
    },
    {
      "region": "Cañar",
      "code": "EC-F",
      "type": "Province"
    },
    {
      "region": "Chimborazo",
      "code": "EC-H",
      "type": "Province"
    },
    {
      "region": "Cotopaxi",
      "code": "EC-X",
      "type": "Province"
    },
    {
      "region": "El Oro",
      "code": "EC-O",
      "type": "Province"
    },
    {
      "region": "Esmeraldas",
      "code": "EC-E",
      "type": "Province"
    },
    {
      "region": "Galápagos",
      "code": "EC-W",
      "type": "Province"
    },
    {
      "region": "Guayas",
      "code": "EC-G",
      "type": "Province"
    },
    {
      "region": "Imbabura",
      "code": "EC-I",
      "type": "Province"
    },
    {
      "region": "Loja",
      "code": "EC-L",
      "type": "Province"
    },
    {
      "region": "Los Ríos",
      "code": "EC-R",
      "type": "Province"
    },
    {
      "region": "Manabí",
      "code": "EC-M",
      "type": "Province"
    },
    {
      "region": "Morona Santiago",
      "code": "EC-S",
      "type": "Province"
    },
    {
      "region": "Napo",
      "code": "EC-N",
      "type": "Province"
    },
    {
      "region": "Orellana",
      "code": "EC-D",
      "type": "Province"
    },
    {
      "region": "Pastaza",
      "code": "EC-Y",
      "type": "Province"
    },
    {
      "region": "Pichincha",
      "code": "EC-P",
      "type": "Province"
    },
    {
      "region": "Santa Elena",
      "code": "EC-SE",
      "type": "Province"
    },
    {
      "region": "Santo Domingo de los Tsáchilas",
      "code": "EC-SD",
      "type": "Province"
    },
    {
      "region": "Sucumbíos",
      "code": "EC-U",
      "type": "Province"
    },
    {
      "region": "Tungurahua",
      "code": "EC-T",
      "type": "Province"
    },
    {
      "region": "Zamora Chinchipe",
      "code": "EC-Z",
      "type": "Province"
    }
  ],
  "EE": [
    {
      "region": "Harjumaa",
      "code": "EE-37",
      "type": "County"
    },
    {
      "region": "Hiiumaa",
      "code": "EE-39",
      "type": "County"
    },
    {
      "region": "Ida-Virumaa",
      "code": "EE-45",
      "type": "County"
    },
    {
      "region": "Järvamaa",
      "code": "EE-52",
      "type": "County"
    },
    {
      "region": "Jõgevamaa",
      "code": "EE-50",
      "type": "County"
    },
    {
      "region": "Lääne-Virumaa",
      "code": "EE-60",
      "type": "County"
    },
    {
      "region": "Läänemaa",
      "code": "EE-56",
      "type": "County"
    },
    {
      "region": "Pärnumaa",
      "code": "EE-68",
      "type": "County"
    },
    {
      "region": "Põlvamaa",
      "code": "EE-64",
      "type": "County"
    },
    {
      "region": "Raplamaa",
      "code": "EE-71",
      "type": "County"
    },
    {
      "region": "Saaremaa",
      "code": "EE-74",
      "type": "County"
    },
    {
      "region": "Tartumaa",
      "code": "EE-79",
      "type": "County"
    },
    {
      "region": "Valgamaa",
      "code": "EE-81",
      "type": "County"
    },
    {
      "region": "Viljandimaa",
      "code": "EE-84",
      "type": "County"
    },
    {
      "region": "Võrumaa",
      "code": "EE-87",
      "type": "County"
    }
  ],
  "EG": [
    {
      "region": "Ad Daqahlīyah",
      "code": "EG-DK",
      "type": "Governorate"
    },
    {
      "region": "Al Baḩr al Aḩmar",
      "code": "EG-BA",
      "type": "Governorate"
    },
    {
      "region": "Al Buḩayrah",
      "code": "EG-BH",
      "type": "Governorate"
    },
    {
      "region": "Al Fayyūm",
      "code": "EG-FYM",
      "type": "Governorate"
    },
    {
      "region": "Al Gharbīyah",
      "code": "EG-GH",
      "type": "Governorate"
    },
    {
      "region": "Al Iskandarīyah",
      "code": "EG-ALX",
      "type": "Governorate"
    },
    {
      "region": "Al Ismā'īlīyah",
      "code": "EG-IS",
      "type": "Governorate"
    },
    {
      "region": "Al Jīzah",
      "code": "EG-GZ",
      "type": "Governorate"
    },
    {
      "region": "Al Minyā",
      "code": "EG-MN",
      "type": "Governorate"
    },
    {
      "region": "Al Minūfīyah",
      "code": "EG-MNF",
      "type": "Governorate"
    },
    {
      "region": "Al Qalyūbīyah",
      "code": "EG-KB",
      "type": "Governorate"
    },
    {
      "region": "Al Qāhirah",
      "code": "EG-C",
      "type": "Governorate"
    },
    {
      "region": "Al Uqşur",
      "code": "EG-LX",
      "type": "Governorate"
    },
    {
      "region": "Al Wādī al Jadīd",
      "code": "EG-WAD",
      "type": "Governorate"
    },
    {
      "region": "As Suways",
      "code": "EG-SUZ",
      "type": "Governorate"
    },
    {
      "region": "Ash Sharqīyah",
      "code": "EG-SHR",
      "type": "Governorate"
    },
    {
      "region": "Aswān",
      "code": "EG-ASN",
      "type": "Governorate"
    },
    {
      "region": "Asyūţ",
      "code": "EG-AST",
      "type": "Governorate"
    },
    {
      "region": "Banī Suwayf",
      "code": "EG-BNS",
      "type": "Governorate"
    },
    {
      "region": "Būr Sa‘īd",
      "code": "EG-PTS",
      "type": "Governorate"
    },
    {
      "region": "Dumyāţ",
      "code": "EG-DT",
      "type": "Governorate"
    },
    {
      "region": "Janūb Sīnā'",
      "code": "EG-JS",
      "type": "Governorate"
    },
    {
      "region": "Kafr ash Shaykh",
      "code": "EG-KFS",
      "type": "Governorate"
    },
    {
      "region": "Maţrūḩ",
      "code": "EG-MT",
      "type": "Governorate"
    },
    {
      "region": "Qinā",
      "code": "EG-KN",
      "type": "Governorate"
    },
    {
      "region": "Shamāl Sīnā'",
      "code": "EG-SIN",
      "type": "Governorate"
    },
    {
      "region": "Sūhāj",
      "code": "EG-SHG",
      "type": "Governorate"
    }
  ],
  "ER": [
    {
      "region": "Al Awsaţ",
      "code": "ER-MA",
      "type": "Region"
    },
    {
      "region": "Al Janūbī",
      "code": "ER-DU",
      "type": "Region"
    },
    {
      "region": "Ansabā",
      "code": "ER-AN",
      "type": "Region"
    },
    {
      "region": "Debubawi K’eyyĭḥ Baḥri",
      "code": "ER-DK",
      "type": "Region"
    },
    {
      "region": "Gash-Barka",
      "code": "ER-GB",
      "type": "Region"
    },
    {
      "region": "Semienawi K’eyyĭḥ Baḥri",
      "code": "ER-SK",
      "type": "Region"
    }
  ],
  "ES": [
    {
      "region": "Andalucía",
      "code": "ES-AN",
      "type": "Autonomous community"
    },
    {
      "region": "Aragón",
      "code": "ES-AR",
      "type": "Autonomous community"
    },
    {
      "region": "Asturias, Principado de",
      "code": "ES-AS",
      "type": "Autonomous community"
    },
    {
      "region": "Canarias",
      "code": "ES-CN",
      "type": "Autonomous community"
    },
    {
      "region": "Cantabria",
      "code": "ES-CB",
      "type": "Autonomous community"
    },
    {
      "region": "Castilla y León",
      "code": "ES-CL",
      "type": "Autonomous community"
    },
    {
      "region": "Castilla-La Mancha",
      "code": "ES-CM",
      "type": "Autonomous community"
    },
    {
      "region": "Catalunya [Cataluña]",
      "code": "ES-CT",
      "type": "Autonomous community"
    },
    {
      "region": "Ceuta",
      "code": "ES-CE",
      "type": "Autonomous city in north africa"
    },
    {
      "region": "Euskal Herria",
      "code": "ES-PV",
      "type": "Autonomous community"
    },
    {
      "region": "Extremadura",
      "code": "ES-EX",
      "type": "Autonomous community"
    },
    {
      "region": "Galicia [Galicia]",
      "code": "ES-GA",
      "type": "Autonomous community"
    },
    {
      "region": "Illes Balears [Islas Baleares]",
      "code": "ES-IB",
      "type": "Autonomous community"
    },
    {
      "region": "La Rioja",
      "code": "ES-RI",
      "type": "Autonomous community"
    },
    {
      "region": "Madrid, Comunidad de",
      "code": "ES-MD",
      "type": "Autonomous community"
    },
    {
      "region": "Melilla",
      "code": "ES-ML",
      "type": "Autonomous city in north africa"
    },
    {
      "region": "Murcia, Región de",
      "code": "ES-MC",
      "type": "Autonomous community"
    },
    {
      "region": "Nafarroako Foru Komunitatea*",
      "code": "ES-NC",
      "type": "Autonomous community"
    },
    {
      "region": "Valenciana, Comunidad",
      "code": "ES-VC",
      "type": "Autonomous community"
    }
  ],
  "ET": [
    {
      "region": "Addis Ababa",
      "code": "ET-AA",
      "type": "Administration"
    },
    {
      "region": "Afar",
      "code": "ET-AF",
      "type": "Regional state"
    },
    {
      "region": "Amara",
      "code": "ET-AM",
      "type": "Regional state"
    },
    {
      "region": "Benshangul-Gumaz",
      "code": "ET-BE",
      "type": "Regional state"
    },
    {
      "region": "Dire Dawa",
      "code": "ET-DD",
      "type": "Administration"
    },
    {
      "region": "Gambela Peoples",
      "code": "ET-GA",
      "type": "Regional state"
    },
    {
      "region": "Harari People",
      "code": "ET-HA",
      "type": "Regional state"
    },
    {
      "region": "Oromia",
      "code": "ET-OR",
      "type": "Regional state"
    },
    {
      "region": "Somali",
      "code": "ET-SO",
      "type": "Regional state"
    },
    {
      "region": "Southern Nations, Nationalities and Peoples",
      "code": "ET-SN",
      "type": "Regional state"
    },
    {
      "region": "Tigrai",
      "code": "ET-TI",
      "type": "Regional state"
    }
  ],
  "FI": [
    {
      "region": "Ahvenanmaan maakunta",
      "code": "FI-01",
      "type": "Region"
    },
    {
      "region": "Birkaland",
      "code": "FI-11",
      "type": "Region"
    },
    {
      "region": "Egentliga Finland",
      "code": "FI-19",
      "type": "Region"
    },
    {
      "region": "Egentliga Tavastland",
      "code": "FI-06",
      "type": "Region"
    },
    {
      "region": "Etelä-Karjala",
      "code": "FI-02",
      "type": "Region"
    },
    {
      "region": "Etelä-Pohjanmaa",
      "code": "FI-03",
      "type": "Region"
    },
    {
      "region": "Etelä-Savo",
      "code": "FI-04",
      "type": "Region"
    },
    {
      "region": "Kainuu",
      "code": "FI-05",
      "type": "Region"
    },
    {
      "region": "Keski-Pohjanmaa",
      "code": "FI-07",
      "type": "Region"
    },
    {
      "region": "Keski-Suomi",
      "code": "FI-08",
      "type": "Region"
    },
    {
      "region": "Kymenlaakso",
      "code": "FI-09",
      "type": "Region"
    },
    {
      "region": "Lappi",
      "code": "FI-10",
      "type": "Region"
    },
    {
      "region": "Norra Karelen",
      "code": "FI-13",
      "type": "Region"
    },
    {
      "region": "Norra Savolax",
      "code": "FI-15",
      "type": "Region"
    },
    {
      "region": "Norra Österbotten",
      "code": "FI-14",
      "type": "Region"
    },
    {
      "region": "Nyland",
      "code": "FI-18",
      "type": "Region"
    },
    {
      "region": "Pohjanmaa",
      "code": "FI-12",
      "type": "Region"
    },
    {
      "region": "Päijänne-Tavastland",
      "code": "FI-16",
      "type": "Region"
    },
    {
      "region": "Satakunda",
      "code": "FI-17",
      "type": "Region"
    }
  ],
  "FJ": [
    {
      "region": "Central",
      "code": "FJ-C",
      "type": "Division"
    },
    {
      "region": "Eastern",
      "code": "FJ-E",
      "type": "Division"
    },
    {
      "region": "Northern",
      "code": "FJ-N",
      "type": "Division"
    },
    {
      "region": "Rotuma",
      "code": "FJ-R",
      "type": "Dependency"
    },
    {
      "region": "Western",
      "code": "FJ-W",
      "type": "Division"
    }
  ],
  "FM": [
    {
      "region": "Chuuk",
      "code": "FM-TRK",
      "type": "State"
    },
    {
      "region": "Kosrae",
      "code": "FM-KSA",
      "type": "State"
    },
    {
      "region": "Pohnpei",
      "code": "FM-PNI",
      "type": "State"
    },
    {
      "region": "Yap",
      "code": "FM-YAP",
      "type": "State"
    }
  ],
  "FR": [
    {
      "region": "Auvergne-Rhône-Alpes",
      "code": "FR-ARA",
      "type": "Metropolitan region"
    },
    {
      "region": "Bourgogne-Franche-Comté",
      "code": "FR-BFC",
      "type": "Metropolitan region"
    },
    {
      "region": "Bretagne",
      "code": "FR-BRE",
      "type": "Metropolitan region"
    },
    {
      "region": "Centre-Val de Loire",
      "code": "FR-CVL",
      "type": "Metropolitan region"
    },
    {
      "region": "Clipperton",
      "code": "FR-CP",
      "type": "Dependency"
    },
    {
      "region": "Corse",
      "code": "FR-20R",
      "type": "Metropolitan collectivity with special status"
    },
    {
      "region": "Grand-Est",
      "code": "FR-GES",
      "type": "Metropolitan region"
    },
    {
      "region": "Guadeloupe",
      "code": "FR-GP",
      "type": "Overseas region"
    },
    {
      "region": "Guyane (française)",
      "code": "FR-GF",
      "type": "Overseas region"
    },
    {
      "region": "Hauts-de-France",
      "code": "FR-HDF",
      "type": "Metropolitan region"
    },
    {
      "region": "La Réunion",
      "code": "FR-RE",
      "type": "Overseas region"
    },
    {
      "region": "Martinique",
      "code": "FR-MQ",
      "type": "Overseas region"
    },
    {
      "region": "Mayotte",
      "code": "FR-YT",
      "type": "Overseas region"
    },
    {
      "region": "Normandie",
      "code": "FR-NOR",
      "type": "Metropolitan region"
    },
    {
      "region": "Nouvelle-Aquitaine",
      "code": "FR-NAQ",
      "type": "Metropolitan region"
    },
    {
      "region": "Nouvelle-Calédonie",
      "code": "FR-NC",
      "type": "Overseas collectivity with special status"
    },
    {
      "region": "Occitanie",
      "code": "FR-OCC",
      "type": "Metropolitan region"
    },
    {
      "region": "Pays-de-la-Loire",
      "code": "FR-PDL",
      "type": "Metropolitan region"
    },
    {
      "region": "Polynésie française",
      "code": "FR-PF",
      "type": "Overseas collectivity"
    },
    {
      "region": "Provence-Alpes-Côte-d’Azur",
      "code": "FR-PAC",
      "type": "Metropolitan region"
    },
    {
      "region": "Saint-Barthélemy",
      "code": "FR-BL",
      "type": "Overseas collectivity"
    },
    {
      "region": "Saint-Martin",
      "code": "FR-MF",
      "type": "Overseas collectivity"
    },
    {
      "region": "Saint-Pierre-et-Miquelon",
      "code": "FR-PM",
      "type": "Overseas collectivity"
    },
    {
      "region": "Terres australes françaises",
      "code": "FR-TF",
      "type": "Overseas territory"
    },
    {
      "region": "Wallis-et-Futuna",
      "code": "FR-WF",
      "type": "Overseas collectivity"
    },
    {
      "region": "Île-de-France",
      "code": "FR-IDF",
      "type": "Metropolitan region"
    }
  ],
  "GA": [
    {
      "region": "Estuaire",
      "code": "GA-1",
      "type": "Province"
    },
    {
      "region": "Haut-Ogooué",
      "code": "GA-2",
      "type": "Province"
    },
    {
      "region": "Moyen-Ogooué",
      "code": "GA-3",
      "type": "Province"
    },
    {
      "region": "Ngounié",
      "code": "GA-4",
      "type": "Province"
    },
    {
      "region": "Nyanga",
      "code": "GA-5",
      "type": "Province"
    },
    {
      "region": "Ogooué-Ivindo",
      "code": "GA-6",
      "type": "Province"
    },
    {
      "region": "Ogooué-Lolo",
      "code": "GA-7",
      "type": "Province"
    },
    {
      "region": "Ogooué-Maritime",
      "code": "GA-8",
      "type": "Province"
    },
    {
      "region": "Woleu-Ntem",
      "code": "GA-9",
      "type": "Province"
    }
  ],
  "GB": [
    {
      "region": "Aberdeen City",
      "code": "GB-ABE",
      "type": "Council area"
    },
    {
      "region": "Aberdeenshire",
      "code": "GB-ABD",
      "type": "Council area"
    },
    {
      "region": "Angus",
      "code": "GB-ANS",
      "type": "Council area"
    },
    {
      "region": "Antrim and Newtownabbey",
      "code": "GB-ANN",
      "type": "District"
    },
    {
      "region": "Ards and North Down",
      "code": "GB-AND",
      "type": "District"
    },
    {
      "region": "Argyll and Bute",
      "code": "GB-AGB",
      "type": "Council area"
    },
    {
      "region": "Armagh City, Banbridge and Craigavon",
      "code": "GB-ABC",
      "type": "District"
    },
    {
      "region": "Barking and Dagenham",
      "code": "GB-BDG",
      "type": "London borough"
    },
    {
      "region": "Barnet",
      "code": "GB-BNE",
      "type": "London borough"
    },
    {
      "region": "Barnsley",
      "code": "GB-BNS",
      "type": "Metropolitan district"
    },
    {
      "region": "Bath and North East Somerset",
      "code": "GB-BAS",
      "type": "Unitary authority"
    },
    {
      "region": "Bedford",
      "code": "GB-BDF",
      "type": "Unitary authority"
    },
    {
      "region": "Belfast City",
      "code": "GB-BFS",
      "type": "District"
    },
    {
      "region": "Bexley",
      "code": "GB-BEX",
      "type": "London borough"
    },
    {
      "region": "Birmingham",
      "code": "GB-BIR",
      "type": "Metropolitan district"
    },
    {
      "region": "Blackburn with Darwen",
      "code": "GB-BBD",
      "type": "Unitary authority"
    },
    {
      "region": "Blackpool",
      "code": "GB-BPL",
      "type": "Unitary authority"
    },
    {
      "region": "Blaenau Gwent",
      "code": "GB-BGW",
      "type": "Unitary authority"
    },
    {
      "region": "Bolton",
      "code": "GB-BOL",
      "type": "Metropolitan district"
    },
    {
      "region": "Bournemouth, Christchurch and Poole",
      "code": "GB-BCP",
      "type": "Unitary authority"
    },
    {
      "region": "Bracknell Forest",
      "code": "GB-BRC",
      "type": "Unitary authority"
    },
    {
      "region": "Bradford",
      "code": "GB-BRD",
      "type": "Metropolitan district"
    },
    {
      "region": "Brent",
      "code": "GB-BEN",
      "type": "London borough"
    },
    {
      "region": "Bridgend [Pen-y-bont ar Ogwr GB-POG]",
      "code": "GB-BGE",
      "type": "Unitary authority"
    },
    {
      "region": "Brighton and Hove",
      "code": "GB-BNH",
      "type": "Unitary authority"
    },
    {
      "region": "Bristol, City of",
      "code": "GB-BST",
      "type": "Unitary authority"
    },
    {
      "region": "Bromley",
      "code": "GB-BRY",
      "type": "London borough"
    },
    {
      "region": "Buckinghamshire",
      "code": "GB-BKM",
      "type": "Two-tier county"
    },
    {
      "region": "Bury",
      "code": "GB-BUR",
      "type": "Metropolitan district"
    },
    {
      "region": "Caerphilly [Caerffili GB-CAF]",
      "code": "GB-CAY",
      "type": "Unitary authority"
    },
    {
      "region": "Calderdale",
      "code": "GB-CLD",
      "type": "Metropolitan district"
    },
    {
      "region": "Cambridgeshire",
      "code": "GB-CAM",
      "type": "Two-tier county"
    },
    {
      "region": "Camden",
      "code": "GB-CMD",
      "type": "London borough"
    },
    {
      "region": "Cardiff [Caerdydd GB-CRD]",
      "code": "GB-CRF",
      "type": "Unitary authority"
    },
    {
      "region": "Carmarthenshire [Sir Gaerfyrddin GB-GFY]",
      "code": "GB-CMN",
      "type": "Unitary authority"
    },
    {
      "region": "Causeway Coast and Glens",
      "code": "GB-CCG",
      "type": "District"
    },
    {
      "region": "Central Bedfordshire",
      "code": "GB-CBF",
      "type": "Unitary authority"
    },
    {
      "region": "Ceredigion [Sir Ceredigion]",
      "code": "GB-CGN",
      "type": "Unitary authority"
    },
    {
      "region": "Cheshire East",
      "code": "GB-CHE",
      "type": "Unitary authority"
    },
    {
      "region": "Cheshire West and Chester",
      "code": "GB-CHW",
      "type": "Unitary authority"
    },
    {
      "region": "Clackmannanshire",
      "code": "GB-CLK",
      "type": "Council area"
    },
    {
      "region": "Conwy",
      "code": "GB-CWY",
      "type": "Unitary authority"
    },
    {
      "region": "Cornwall",
      "code": "GB-CON",
      "type": "Unitary authority"
    },
    {
      "region": "Coventry",
      "code": "GB-COV",
      "type": "Metropolitan district"
    },
    {
      "region": "Croydon",
      "code": "GB-CRY",
      "type": "London borough"
    },
    {
      "region": "Cumbria",
      "code": "GB-CMA",
      "type": "Two-tier county"
    },
    {
      "region": "Darlington",
      "code": "GB-DAL",
      "type": "Unitary authority"
    },
    {
      "region": "Denbighshire [Sir Ddinbych GB-DDB]",
      "code": "GB-DEN",
      "type": "Unitary authority"
    },
    {
      "region": "Derby",
      "code": "GB-DER",
      "type": "Unitary authority"
    },
    {
      "region": "Derbyshire",
      "code": "GB-DBY",
      "type": "Two-tier county"
    },
    {
      "region": "Derry and Strabane",
      "code": "GB-DRS",
      "type": "District"
    },
    {
      "region": "Devon",
      "code": "GB-DEV",
      "type": "Two-tier county"
    },
    {
      "region": "Doncaster",
      "code": "GB-DNC",
      "type": "Metropolitan district"
    },
    {
      "region": "Dorset",
      "code": "GB-DOR",
      "type": "Two-tier county"
    },
    {
      "region": "Dudley",
      "code": "GB-DUD",
      "type": "Metropolitan district"
    },
    {
      "region": "Dumfries and Galloway",
      "code": "GB-DGY",
      "type": "Council area"
    },
    {
      "region": "Dundee City",
      "code": "GB-DND",
      "type": "Council area"
    },
    {
      "region": "Durham, County",
      "code": "GB-DUR",
      "type": "Unitary authority"
    },
    {
      "region": "Ealing",
      "code": "GB-EAL",
      "type": "London borough"
    },
    {
      "region": "East Ayrshire",
      "code": "GB-EAY",
      "type": "Council area"
    },
    {
      "region": "East Dunbartonshire",
      "code": "GB-EDU",
      "type": "Council area"
    },
    {
      "region": "East Lothian",
      "code": "GB-ELN",
      "type": "Council area"
    },
    {
      "region": "East Renfrewshire",
      "code": "GB-ERW",
      "type": "Council area"
    },
    {
      "region": "East Riding of Yorkshire",
      "code": "GB-ERY",
      "type": "Unitary authority"
    },
    {
      "region": "East Sussex",
      "code": "GB-ESX",
      "type": "Two-tier county"
    },
    {
      "region": "Edinburgh, City of",
      "code": "GB-EDH",
      "type": "Council area"
    },
    {
      "region": "Eilean Siar",
      "code": "GB-ELS",
      "type": "Council area"
    },
    {
      "region": "Enfield",
      "code": "GB-ENF",
      "type": "London borough"
    },
    {
      "region": "Essex",
      "code": "GB-ESS",
      "type": "Two-tier county"
    },
    {
      "region": "Falkirk",
      "code": "GB-FAL",
      "type": "Council area"
    },
    {
      "region": "Fermanagh and Omagh",
      "code": "GB-FMO",
      "type": "District"
    },
    {
      "region": "Fife",
      "code": "GB-FIF",
      "type": "Council area"
    },
    {
      "region": "Flintshire [Sir y Fflint GB-FFL]",
      "code": "GB-FLN",
      "type": "Unitary authority"
    },
    {
      "region": "Gateshead",
      "code": "GB-GAT",
      "type": "Metropolitan district"
    },
    {
      "region": "Glasgow City",
      "code": "GB-GLG",
      "type": "Council area"
    },
    {
      "region": "Gloucestershire",
      "code": "GB-GLS",
      "type": "Two-tier county"
    },
    {
      "region": "Greenwich",
      "code": "GB-GRE",
      "type": "London borough"
    },
    {
      "region": "Gwynedd",
      "code": "GB-GWN",
      "type": "Unitary authority"
    },
    {
      "region": "Hackney",
      "code": "GB-HCK",
      "type": "London borough"
    },
    {
      "region": "Halton",
      "code": "GB-HAL",
      "type": "Unitary authority"
    },
    {
      "region": "Hammersmith and Fulham",
      "code": "GB-HMF",
      "type": "London borough"
    },
    {
      "region": "Hampshire",
      "code": "GB-HAM",
      "type": "Two-tier county"
    },
    {
      "region": "Haringey",
      "code": "GB-HRY",
      "type": "London borough"
    },
    {
      "region": "Harrow",
      "code": "GB-HRW",
      "type": "London borough"
    },
    {
      "region": "Hartlepool",
      "code": "GB-HPL",
      "type": "Unitary authority"
    },
    {
      "region": "Havering",
      "code": "GB-HAV",
      "type": "London borough"
    },
    {
      "region": "Herefordshire",
      "code": "GB-HEF",
      "type": "Unitary authority"
    },
    {
      "region": "Hertfordshire",
      "code": "GB-HRT",
      "type": "Two-tier county"
    },
    {
      "region": "Highland",
      "code": "GB-HLD",
      "type": "Council area"
    },
    {
      "region": "Hillingdon",
      "code": "GB-HIL",
      "type": "London borough"
    },
    {
      "region": "Hounslow",
      "code": "GB-HNS",
      "type": "London borough"
    },
    {
      "region": "Inverclyde",
      "code": "GB-IVC",
      "type": "Council area"
    },
    {
      "region": "Isle of Anglesey [Sir Ynys Môn GB-YNM]",
      "code": "GB-AGY",
      "type": "Unitary authority"
    },
    {
      "region": "Isle of Wight",
      "code": "GB-IOW",
      "type": "Unitary authority"
    },
    {
      "region": "Isles of Scilly",
      "code": "GB-IOS",
      "type": "Unitary authority"
    },
    {
      "region": "Islington",
      "code": "GB-ISL",
      "type": "London borough"
    },
    {
      "region": "Kensington and Chelsea",
      "code": "GB-KEC",
      "type": "London borough"
    },
    {
      "region": "Kent",
      "code": "GB-KEN",
      "type": "Two-tier county"
    },
    {
      "region": "Kingston upon Hull",
      "code": "GB-KHL",
      "type": "Unitary authority"
    },
    {
      "region": "Kingston upon Thames",
      "code": "GB-KTT",
      "type": "London borough"
    },
    {
      "region": "Kirklees",
      "code": "GB-KIR",
      "type": "Metropolitan district"
    },
    {
      "region": "Knowsley",
      "code": "GB-KWL",
      "type": "Metropolitan district"
    },
    {
      "region": "Lambeth",
      "code": "GB-LBH",
      "type": "London borough"
    },
    {
      "region": "Lancashire",
      "code": "GB-LAN",
      "type": "Two-tier county"
    },
    {
      "region": "Leeds",
      "code": "GB-LDS",
      "type": "Metropolitan district"
    },
    {
      "region": "Leicester",
      "code": "GB-LCE",
      "type": "Unitary authority"
    },
    {
      "region": "Leicestershire",
      "code": "GB-LEC",
      "type": "Two-tier county"
    },
    {
      "region": "Lewisham",
      "code": "GB-LEW",
      "type": "London borough"
    },
    {
      "region": "Lincolnshire",
      "code": "GB-LIN",
      "type": "Two-tier county"
    },
    {
      "region": "Lisburn and Castlereagh",
      "code": "GB-LBC",
      "type": "District"
    },
    {
      "region": "Liverpool",
      "code": "GB-LIV",
      "type": "Metropolitan district"
    },
    {
      "region": "London, City of",
      "code": "GB-LND",
      "type": "City corporation"
    },
    {
      "region": "Luton",
      "code": "GB-LUT",
      "type": "Unitary authority"
    },
    {
      "region": "Manchester",
      "code": "GB-MAN",
      "type": "Metropolitan district"
    },
    {
      "region": "Medway",
      "code": "GB-MDW",
      "type": "Unitary authority"
    },
    {
      "region": "Merthyr Tydfil [Merthyr Tudful GB-MTU]",
      "code": "GB-MTY",
      "type": "Unitary authority"
    },
    {
      "region": "Merton",
      "code": "GB-MRT",
      "type": "London borough"
    },
    {
      "region": "Mid and East Antrim",
      "code": "GB-MEA",
      "type": "District"
    },
    {
      "region": "Mid-Ulster",
      "code": "GB-MUL",
      "type": "District"
    },
    {
      "region": "Middlesbrough",
      "code": "GB-MDB",
      "type": "Unitary authority"
    },
    {
      "region": "Midlothian",
      "code": "GB-MLN",
      "type": "Council area"
    },
    {
      "region": "Milton Keynes",
      "code": "GB-MIK",
      "type": "Unitary authority"
    },
    {
      "region": "Monmouthshire [Sir Fynwy GB-FYN]",
      "code": "GB-MON",
      "type": "Unitary authority"
    },
    {
      "region": "Moray",
      "code": "GB-MRY",
      "type": "Council area"
    },
    {
      "region": "Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]",
      "code": "GB-NTL",
      "type": "Unitary authority"
    },
    {
      "region": "Newcastle upon Tyne",
      "code": "GB-NET",
      "type": "Metropolitan district"
    },
    {
      "region": "Newham",
      "code": "GB-NWM",
      "type": "London borough"
    },
    {
      "region": "Newport [Casnewydd GB-CNW]",
      "code": "GB-NWP",
      "type": "Unitary authority"
    },
    {
      "region": "Newry, Mourne and Down",
      "code": "GB-NMD",
      "type": "District"
    },
    {
      "region": "Norfolk",
      "code": "GB-NFK",
      "type": "Two-tier county"
    },
    {
      "region": "North Ayrshire",
      "code": "GB-NAY",
      "type": "Council area"
    },
    {
      "region": "North East Lincolnshire",
      "code": "GB-NEL",
      "type": "Unitary authority"
    },
    {
      "region": "North Lanarkshire",
      "code": "GB-NLK",
      "type": "Council area"
    },
    {
      "region": "North Lincolnshire",
      "code": "GB-NLN",
      "type": "Unitary authority"
    },
    {
      "region": "North Somerset",
      "code": "GB-NSM",
      "type": "Unitary authority"
    },
    {
      "region": "North Tyneside",
      "code": "GB-NTY",
      "type": "Metropolitan district"
    },
    {
      "region": "North Yorkshire",
      "code": "GB-NYK",
      "type": "Two-tier county"
    },
    {
      "region": "Northamptonshire",
      "code": "GB-NTH",
      "type": "Two-tier county"
    },
    {
      "region": "Northumberland",
      "code": "GB-NBL",
      "type": "Unitary authority"
    },
    {
      "region": "Nottingham",
      "code": "GB-NGM",
      "type": "Unitary authority"
    },
    {
      "region": "Nottinghamshire",
      "code": "GB-NTT",
      "type": "Two-tier county"
    },
    {
      "region": "Oldham",
      "code": "GB-OLD",
      "type": "Metropolitan district"
    },
    {
      "region": "Orkney Islands",
      "code": "GB-ORK",
      "type": "Council area"
    },
    {
      "region": "Oxfordshire",
      "code": "GB-OXF",
      "type": "Two-tier county"
    },
    {
      "region": "Pembrokeshire [Sir Benfro GB-BNF]",
      "code": "GB-PEM",
      "type": "Unitary authority"
    },
    {
      "region": "Perth and Kinross",
      "code": "GB-PKN",
      "type": "Council area"
    },
    {
      "region": "Peterborough",
      "code": "GB-PTE",
      "type": "Unitary authority"
    },
    {
      "region": "Plymouth",
      "code": "GB-PLY",
      "type": "Unitary authority"
    },
    {
      "region": "Portsmouth",
      "code": "GB-POR",
      "type": "Unitary authority"
    },
    {
      "region": "Powys",
      "code": "GB-POW",
      "type": "Unitary authority"
    },
    {
      "region": "Reading",
      "code": "GB-RDG",
      "type": "Unitary authority"
    },
    {
      "region": "Redbridge",
      "code": "GB-RDB",
      "type": "London borough"
    },
    {
      "region": "Redcar and Cleveland",
      "code": "GB-RCC",
      "type": "Unitary authority"
    },
    {
      "region": "Renfrewshire",
      "code": "GB-RFW",
      "type": "Council area"
    },
    {
      "region": "Rhondda Cynon Taff [Rhondda CynonTaf]",
      "code": "GB-RCT",
      "type": "Unitary authority"
    },
    {
      "region": "Richmond upon Thames",
      "code": "GB-RIC",
      "type": "London borough"
    },
    {
      "region": "Rochdale",
      "code": "GB-RCH",
      "type": "Metropolitan district"
    },
    {
      "region": "Rotherham",
      "code": "GB-ROT",
      "type": "Metropolitan district"
    },
    {
      "region": "Rutland",
      "code": "GB-RUT",
      "type": "Unitary authority"
    },
    {
      "region": "Salford",
      "code": "GB-SLF",
      "type": "Metropolitan district"
    },
    {
      "region": "Sandwell",
      "code": "GB-SAW",
      "type": "Metropolitan district"
    },
    {
      "region": "Scottish Borders",
      "code": "GB-SCB",
      "type": "Council area"
    },
    {
      "region": "Sefton",
      "code": "GB-SFT",
      "type": "Metropolitan district"
    },
    {
      "region": "Sheffield",
      "code": "GB-SHF",
      "type": "Metropolitan district"
    },
    {
      "region": "Shetland Islands",
      "code": "GB-ZET",
      "type": "Council area"
    },
    {
      "region": "Shropshire",
      "code": "GB-SHR",
      "type": "Unitary authority"
    },
    {
      "region": "Slough",
      "code": "GB-SLG",
      "type": "Unitary authority"
    },
    {
      "region": "Solihull",
      "code": "GB-SOL",
      "type": "Metropolitan district"
    },
    {
      "region": "Somerset",
      "code": "GB-SOM",
      "type": "Two-tier county"
    },
    {
      "region": "South Ayrshire",
      "code": "GB-SAY",
      "type": "Council area"
    },
    {
      "region": "South Gloucestershire",
      "code": "GB-SGC",
      "type": "Unitary authority"
    },
    {
      "region": "South Lanarkshire",
      "code": "GB-SLK",
      "type": "Council area"
    },
    {
      "region": "South Tyneside",
      "code": "GB-STY",
      "type": "Metropolitan district"
    },
    {
      "region": "Southampton",
      "code": "GB-STH",
      "type": "Unitary authority"
    },
    {
      "region": "Southend-on-Sea",
      "code": "GB-SOS",
      "type": "Unitary authority"
    },
    {
      "region": "Southwark",
      "code": "GB-SWK",
      "type": "London borough"
    },
    {
      "region": "St. Helens",
      "code": "GB-SHN",
      "type": "Metropolitan district"
    },
    {
      "region": "Staffordshire",
      "code": "GB-STS",
      "type": "Two-tier county"
    },
    {
      "region": "Stirling",
      "code": "GB-STG",
      "type": "Council area"
    },
    {
      "region": "Stockport",
      "code": "GB-SKP",
      "type": "Metropolitan district"
    },
    {
      "region": "Stockton-on-Tees",
      "code": "GB-STT",
      "type": "Unitary authority"
    },
    {
      "region": "Stoke-on-Trent",
      "code": "GB-STE",
      "type": "Unitary authority"
    },
    {
      "region": "Suffolk",
      "code": "GB-SFK",
      "type": "Two-tier county"
    },
    {
      "region": "Sunderland",
      "code": "GB-SND",
      "type": "Metropolitan district"
    },
    {
      "region": "Surrey",
      "code": "GB-SRY",
      "type": "Two-tier county"
    },
    {
      "region": "Sutton",
      "code": "GB-STN",
      "type": "London borough"
    },
    {
      "region": "Swansea [Abertawe GB-ATA]",
      "code": "GB-SWA",
      "type": "Unitary authority"
    },
    {
      "region": "Swindon",
      "code": "GB-SWD",
      "type": "Unitary authority"
    },
    {
      "region": "Tameside",
      "code": "GB-TAM",
      "type": "Metropolitan district"
    },
    {
      "region": "Telford and Wrekin",
      "code": "GB-TFW",
      "type": "Unitary authority"
    },
    {
      "region": "Thurrock",
      "code": "GB-THR",
      "type": "Unitary authority"
    },
    {
      "region": "Torbay",
      "code": "GB-TOB",
      "type": "Unitary authority"
    },
    {
      "region": "Torfaen [Tor-faen]",
      "code": "GB-TOF",
      "type": "Unitary authority"
    },
    {
      "region": "Tower Hamlets",
      "code": "GB-TWH",
      "type": "London borough"
    },
    {
      "region": "Trafford",
      "code": "GB-TRF",
      "type": "Metropolitan district"
    },
    {
      "region": "Vale of Glamorgan, The [Bro Morgannwg GB-BMG]",
      "code": "GB-VGL",
      "type": "Unitary authority"
    },
    {
      "region": "Wakefield",
      "code": "GB-WKF",
      "type": "Metropolitan district"
    },
    {
      "region": "Walsall",
      "code": "GB-WLL",
      "type": "Metropolitan district"
    },
    {
      "region": "Waltham Forest",
      "code": "GB-WFT",
      "type": "London borough"
    },
    {
      "region": "Wandsworth",
      "code": "GB-WND",
      "type": "London borough"
    },
    {
      "region": "Warrington",
      "code": "GB-WRT",
      "type": "Unitary authority"
    },
    {
      "region": "Warwickshire",
      "code": "GB-WAR",
      "type": "Two-tier county"
    },
    {
      "region": "West Berkshire",
      "code": "GB-WBK",
      "type": "Unitary authority"
    },
    {
      "region": "West Dunbartonshire",
      "code": "GB-WDU",
      "type": "Council area"
    },
    {
      "region": "West Lothian",
      "code": "GB-WLN",
      "type": "Council area"
    },
    {
      "region": "West Sussex",
      "code": "GB-WSX",
      "type": "Two-tier county"
    },
    {
      "region": "Westminster",
      "code": "GB-WSM",
      "type": "London borough"
    },
    {
      "region": "Wigan",
      "code": "GB-WGN",
      "type": "Metropolitan district"
    },
    {
      "region": "Wiltshire",
      "code": "GB-WIL",
      "type": "Unitary authority"
    },
    {
      "region": "Windsor and Maidenhead",
      "code": "GB-WNM",
      "type": "Unitary authority"
    },
    {
      "region": "Wirral",
      "code": "GB-WRL",
      "type": "Metropolitan district"
    },
    {
      "region": "Wokingham",
      "code": "GB-WOK",
      "type": "Unitary authority"
    },
    {
      "region": "Wolverhampton",
      "code": "GB-WLV",
      "type": "Metropolitan district"
    },
    {
      "region": "Worcestershire",
      "code": "GB-WOR",
      "type": "Two-tier county"
    },
    {
      "region": "Wrexham [Wrecsam GB-WRC]",
      "code": "GB-WRX",
      "type": "Unitary authority"
    },
    {
      "region": "York",
      "code": "GB-YOR",
      "type": "Unitary authority"
    }
  ],
  "GD": [
    {
      "region": "Saint Andrew",
      "code": "GD-01",
      "type": "Parish"
    },
    {
      "region": "Saint David",
      "code": "GD-02",
      "type": "Parish"
    },
    {
      "region": "Saint George",
      "code": "GD-03",
      "type": "Parish"
    },
    {
      "region": "Saint John",
      "code": "GD-04",
      "type": "Parish"
    },
    {
      "region": "Saint Mark",
      "code": "GD-05",
      "type": "Parish"
    },
    {
      "region": "Saint Patrick",
      "code": "GD-06",
      "type": "Parish"
    },
    {
      "region": "Southern Grenadine Islands",
      "code": "GD-10",
      "type": "Dependency"
    }
  ],
  "GE": [
    {
      "region": "Abkhazia",
      "code": "GE-AB",
      "type": "Autonomous republic"
    },
    {
      "region": "Ajaria",
      "code": "GE-AJ",
      "type": "Autonomous republic"
    },
    {
      "region": "Guria",
      "code": "GE-GU",
      "type": "Region"
    },
    {
      "region": "Imereti",
      "code": "GE-IM",
      "type": "Region"
    },
    {
      "region": "K'akheti",
      "code": "GE-KA",
      "type": "Region"
    },
    {
      "region": "Kvemo Kartli",
      "code": "GE-KK",
      "type": "Region"
    },
    {
      "region": "Mtskheta-Mtianeti",
      "code": "GE-MM",
      "type": "Region"
    },
    {
      "region": "Rach'a-Lechkhumi-Kvemo Svaneti",
      "code": "GE-RL",
      "type": "Region"
    },
    {
      "region": "Samegrelo-Zemo Svaneti",
      "code": "GE-SZ",
      "type": "Region"
    },
    {
      "region": "Samtskhe-Javakheti",
      "code": "GE-SJ",
      "type": "Region"
    },
    {
      "region": "Shida Kartli",
      "code": "GE-SK",
      "type": "Region"
    },
    {
      "region": "Tbilisi",
      "code": "GE-TB",
      "type": "City"
    }
  ],
  "GH": [
    {
      "region": "Ahafo",
      "code": "GH-AF",
      "type": "Region"
    },
    {
      "region": "Ashanti",
      "code": "GH-AH",
      "type": "Region"
    },
    {
      "region": "Bono",
      "code": "GH-BO",
      "type": "Region"
    },
    {
      "region": "Bono East",
      "code": "GH-BE",
      "type": "Region"
    },
    {
      "region": "Central",
      "code": "GH-CP",
      "type": "Region"
    },
    {
      "region": "Eastern",
      "code": "GH-EP",
      "type": "Region"
    },
    {
      "region": "Greater Accra",
      "code": "GH-AA",
      "type": "Region"
    },
    {
      "region": "North East",
      "code": "GH-NE",
      "type": "Region"
    },
    {
      "region": "Northern",
      "code": "GH-NP",
      "type": "Region"
    },
    {
      "region": "Oti",
      "code": "GH-OT",
      "type": "Region"
    },
    {
      "region": "Savannah",
      "code": "GH-SV",
      "type": "Region"
    },
    {
      "region": "Upper East",
      "code": "GH-UE",
      "type": "Region"
    },
    {
      "region": "Upper West",
      "code": "GH-UW",
      "type": "Region"
    },
    {
      "region": "Volta",
      "code": "GH-TV",
      "type": "Region"
    },
    {
      "region": "Western",
      "code": "GH-WP",
      "type": "Region"
    },
    {
      "region": "Western North",
      "code": "GH-WN",
      "type": "Region"
    }
  ],
  "GL": [
    {
      "region": "Avannaata Kommunia",
      "code": "GL-AV",
      "type": "Municipality"
    },
    {
      "region": "Kommune Kujalleq",
      "code": "GL-KU",
      "type": "Municipality"
    },
    {
      "region": "Kommune Qeqertalik",
      "code": "GL-QT",
      "type": "Municipality"
    },
    {
      "region": "Kommuneqarfik Sermersooq",
      "code": "GL-SM",
      "type": "Municipality"
    },
    {
      "region": "Qeqqata Kommunia",
      "code": "GL-QE",
      "type": "Municipality"
    }
  ],
  "GM": [
    {
      "region": "Banjul",
      "code": "GM-B",
      "type": "City"
    },
    {
      "region": "Central River",
      "code": "GM-M",
      "type": "Division"
    },
    {
      "region": "Lower River",
      "code": "GM-L",
      "type": "Division"
    },
    {
      "region": "North Bank",
      "code": "GM-N",
      "type": "Division"
    },
    {
      "region": "Upper River",
      "code": "GM-U",
      "type": "Division"
    },
    {
      "region": "Western",
      "code": "GM-W",
      "type": "Division"
    }
  ],
  "GN": [
    {
      "region": "Boké",
      "code": "GN-B",
      "type": "Administrative region"
    },
    {
      "region": "Conakry",
      "code": "GN-C",
      "type": "Governorate"
    },
    {
      "region": "Faranah",
      "code": "GN-F",
      "type": "Administrative region"
    },
    {
      "region": "Kankan",
      "code": "GN-K",
      "type": "Administrative region"
    },
    {
      "region": "Kindia",
      "code": "GN-D",
      "type": "Administrative region"
    },
    {
      "region": "Labé",
      "code": "GN-L",
      "type": "Administrative region"
    },
    {
      "region": "Mamou",
      "code": "GN-M",
      "type": "Administrative region"
    },
    {
      "region": "Nzérékoré",
      "code": "GN-N",
      "type": "Administrative region"
    }
  ],
  "GQ": [
    {
      "region": "Região Continental",
      "code": "GQ-C",
      "type": "Region"
    },
    {
      "region": "Região Insular",
      "code": "GQ-I",
      "type": "Region"
    }
  ],
  "GR": [
    {
      "region": "Anatolikí Makedonía kai Thráki",
      "code": "GR-A",
      "type": "Administrative region"
    },
    {
      "region": "Attikí",
      "code": "GR-I",
      "type": "Administrative region"
    },
    {
      "region": "Dytikí Elláda",
      "code": "GR-G",
      "type": "Administrative region"
    },
    {
      "region": "Dytikí Makedonía",
      "code": "GR-C",
      "type": "Administrative region"
    },
    {
      "region": "Ionía Nísia",
      "code": "GR-F",
      "type": "Administrative region"
    },
    {
      "region": "Kentrikí Makedonía",
      "code": "GR-B",
      "type": "Administrative region"
    },
    {
      "region": "Kríti",
      "code": "GR-M",
      "type": "Administrative region"
    },
    {
      "region": "Nótio Aigaío",
      "code": "GR-L",
      "type": "Administrative region"
    },
    {
      "region": "Pelopónnisos",
      "code": "GR-J",
      "type": "Administrative region"
    },
    {
      "region": "Stereá Elláda",
      "code": "GR-H",
      "type": "Administrative region"
    },
    {
      "region": "Thessalía",
      "code": "GR-E",
      "type": "Administrative region"
    },
    {
      "region": "Vóreio Aigaío",
      "code": "GR-K",
      "type": "Administrative region"
    },
    {
      "region": "Ágion Óros",
      "code": "GR-69",
      "type": "Self-governed part"
    },
    {
      "region": "Ípeiros",
      "code": "GR-D",
      "type": "Administrative region"
    }
  ],
  "GT": [
    {
      "region": "Alta Verapaz",
      "code": "GT-AV",
      "type": "Department"
    },
    {
      "region": "Baja Verapaz",
      "code": "GT-BV",
      "type": "Department"
    },
    {
      "region": "Chimaltenango",
      "code": "GT-CM",
      "type": "Department"
    },
    {
      "region": "Chiquimula",
      "code": "GT-CQ",
      "type": "Department"
    },
    {
      "region": "El Progreso",
      "code": "GT-PR",
      "type": "Department"
    },
    {
      "region": "Escuintla",
      "code": "GT-ES",
      "type": "Department"
    },
    {
      "region": "Guatemala",
      "code": "GT-GU",
      "type": "Department"
    },
    {
      "region": "Huehuetenango",
      "code": "GT-HU",
      "type": "Department"
    },
    {
      "region": "Izabal",
      "code": "GT-IZ",
      "type": "Department"
    },
    {
      "region": "Jalapa",
      "code": "GT-JA",
      "type": "Department"
    },
    {
      "region": "Jutiapa",
      "code": "GT-JU",
      "type": "Department"
    },
    {
      "region": "Petén",
      "code": "GT-PE",
      "type": "Department"
    },
    {
      "region": "Quetzaltenango",
      "code": "GT-QZ",
      "type": "Department"
    },
    {
      "region": "Quiché",
      "code": "GT-QC",
      "type": "Department"
    },
    {
      "region": "Retalhuleu",
      "code": "GT-RE",
      "type": "Department"
    },
    {
      "region": "Sacatepéquez",
      "code": "GT-SA",
      "type": "Department"
    },
    {
      "region": "San Marcos",
      "code": "GT-SM",
      "type": "Department"
    },
    {
      "region": "Santa Rosa",
      "code": "GT-SR",
      "type": "Department"
    },
    {
      "region": "Sololá",
      "code": "GT-SO",
      "type": "Department"
    },
    {
      "region": "Suchitepéquez",
      "code": "GT-SU",
      "type": "Department"
    },
    {
      "region": "Totonicapán",
      "code": "GT-TO",
      "type": "Department"
    },
    {
      "region": "Zacapa",
      "code": "GT-ZA",
      "type": "Department"
    }
  ],
  "GW": [
    {
      "region": "Bissau",
      "code": "GW-BS",
      "type": "Autonomous sector"
    },
    {
      "region": "Leste",
      "code": "GW-L",
      "type": "Province"
    },
    {
      "region": "Norte",
      "code": "GW-N",
      "type": "Province"
    },
    {
      "region": "Sul",
      "code": "GW-S",
      "type": "Province"
    }
  ],
  "GY": [
    {
      "region": "Barima-Waini",
      "code": "GY-BA",
      "type": "Region"
    },
    {
      "region": "Cuyuni-Mazaruni",
      "code": "GY-CU",
      "type": "Region"
    },
    {
      "region": "Demerara-Mahaica",
      "code": "GY-DE",
      "type": "Region"
    },
    {
      "region": "East Berbice-Corentyne",
      "code": "GY-EB",
      "type": "Region"
    },
    {
      "region": "Essequibo Islands-West Demerara",
      "code": "GY-ES",
      "type": "Region"
    },
    {
      "region": "Mahaica-Berbice",
      "code": "GY-MA",
      "type": "Region"
    },
    {
      "region": "Pomeroon-Supenaam",
      "code": "GY-PM",
      "type": "Region"
    },
    {
      "region": "Potaro-Siparuni",
      "code": "GY-PT",
      "type": "Region"
    },
    {
      "region": "Upper Demerara-Berbice",
      "code": "GY-UD",
      "type": "Region"
    },
    {
      "region": "Upper Takutu-Upper Essequibo",
      "code": "GY-UT",
      "type": "Region"
    }
  ],
  "HN": [
    {
      "region": "Atlántida",
      "code": "HN-AT",
      "type": "Department"
    },
    {
      "region": "Choluteca",
      "code": "HN-CH",
      "type": "Department"
    },
    {
      "region": "Colón",
      "code": "HN-CL",
      "type": "Department"
    },
    {
      "region": "Comayagua",
      "code": "HN-CM",
      "type": "Department"
    },
    {
      "region": "Copán",
      "code": "HN-CP",
      "type": "Department"
    },
    {
      "region": "Cortés",
      "code": "HN-CR",
      "type": "Department"
    },
    {
      "region": "El Paraíso",
      "code": "HN-EP",
      "type": "Department"
    },
    {
      "region": "Francisco Morazán",
      "code": "HN-FM",
      "type": "Department"
    },
    {
      "region": "Gracias a Dios",
      "code": "HN-GD",
      "type": "Department"
    },
    {
      "region": "Intibucá",
      "code": "HN-IN",
      "type": "Department"
    },
    {
      "region": "Islas de la Bahía",
      "code": "HN-IB",
      "type": "Department"
    },
    {
      "region": "La Paz",
      "code": "HN-LP",
      "type": "Department"
    },
    {
      "region": "Lempira",
      "code": "HN-LE",
      "type": "Department"
    },
    {
      "region": "Ocotepeque",
      "code": "HN-OC",
      "type": "Department"
    },
    {
      "region": "Olancho",
      "code": "HN-OL",
      "type": "Department"
    },
    {
      "region": "Santa Bárbara",
      "code": "HN-SB",
      "type": "Department"
    },
    {
      "region": "Valle",
      "code": "HN-VA",
      "type": "Department"
    },
    {
      "region": "Yoro",
      "code": "HN-YO",
      "type": "Department"
    }
  ],
  "HR": [
    {
      "region": "Bjelovarsko-bilogorska županija",
      "code": "HR-07",
      "type": "County"
    },
    {
      "region": "Brodsko-posavska županija",
      "code": "HR-12",
      "type": "County"
    },
    {
      "region": "Dubrovačko-neretvanska županija",
      "code": "HR-19",
      "type": "County"
    },
    {
      "region": "Grad Zagreb",
      "code": "HR-21",
      "type": "City"
    },
    {
      "region": "Istarska županija",
      "code": "HR-18",
      "type": "County"
    },
    {
      "region": "Karlovačka županija",
      "code": "HR-04",
      "type": "County"
    },
    {
      "region": "Koprivničko-križevačka županija",
      "code": "HR-06",
      "type": "County"
    },
    {
      "region": "Krapinsko-zagorska županija",
      "code": "HR-02",
      "type": "County"
    },
    {
      "region": "Ličko-senjska županija",
      "code": "HR-09",
      "type": "County"
    },
    {
      "region": "Međimurska županija",
      "code": "HR-20",
      "type": "County"
    },
    {
      "region": "Osječko-baranjska županija",
      "code": "HR-14",
      "type": "County"
    },
    {
      "region": "Požeško-slavonska županija",
      "code": "HR-11",
      "type": "County"
    },
    {
      "region": "Primorsko-goranska županija",
      "code": "HR-08",
      "type": "County"
    },
    {
      "region": "Sisačko-moslavačka županija",
      "code": "HR-03",
      "type": "County"
    },
    {
      "region": "Splitsko-dalmatinska županija",
      "code": "HR-17",
      "type": "County"
    },
    {
      "region": "Varaždinska županija",
      "code": "HR-05",
      "type": "County"
    },
    {
      "region": "Virovitičko-podravska županija",
      "code": "HR-10",
      "type": "County"
    },
    {
      "region": "Vukovarsko-srijemska županija",
      "code": "HR-16",
      "type": "County"
    },
    {
      "region": "Zadarska županija",
      "code": "HR-13",
      "type": "County"
    },
    {
      "region": "Zagrebačka županija",
      "code": "HR-01",
      "type": "County"
    },
    {
      "region": "Šibensko-kninska županija",
      "code": "HR-15",
      "type": "County"
    }
  ],
  "HT": [
    {
      "region": "Artibonite",
      "code": "HT-AR",
      "type": "Department"
    },
    {
      "region": "Centre",
      "code": "HT-CE",
      "type": "Department"
    },
    {
      "region": "Grandans",
      "code": "HT-GA",
      "type": "Department"
    },
    {
      "region": "Lwès",
      "code": "HT-OU",
      "type": "Department"
    },
    {
      "region": "Nip",
      "code": "HT-NI",
      "type": "Department"
    },
    {
      "region": "Nord",
      "code": "HT-ND",
      "type": "Department"
    },
    {
      "region": "Nord-Est",
      "code": "HT-NE",
      "type": "Department"
    },
    {
      "region": "Nord-Ouest",
      "code": "HT-NO",
      "type": "Department"
    },
    {
      "region": "Sid",
      "code": "HT-SD",
      "type": "Department"
    },
    {
      "region": "Sidès",
      "code": "HT-SE",
      "type": "Department"
    }
  ],
  "HU": [
    {
      "region": "Baranya",
      "code": "HU-BA",
      "type": "County"
    },
    {
      "region": "Borsod-Abaúj-Zemplén",
      "code": "HU-BZ",
      "type": "County"
    },
    {
      "region": "Budapest",
      "code": "HU-BU",
      "type": "Capital city"
    },
    {
      "region": "Bács-Kiskun",
      "code": "HU-BK",
      "type": "County"
    },
    {
      "region": "Békés",
      "code": "HU-BE",
      "type": "County"
    },
    {
      "region": "Békéscsaba",
      "code": "HU-BC",
      "type": "City with county rights"
    },
    {
      "region": "Csongrád",
      "code": "HU-CS",
      "type": "County"
    },
    {
      "region": "Debrecen",
      "code": "HU-DE",
      "type": "City with county rights"
    },
    {
      "region": "Dunaújváros",
      "code": "HU-DU",
      "type": "City with county rights"
    },
    {
      "region": "Eger",
      "code": "HU-EG",
      "type": "City with county rights"
    },
    {
      "region": "Fejér",
      "code": "HU-FE",
      "type": "County"
    },
    {
      "region": "Győr",
      "code": "HU-GY",
      "type": "City with county rights"
    },
    {
      "region": "Győr-Moson-Sopron",
      "code": "HU-GS",
      "type": "County"
    },
    {
      "region": "Hajdú-Bihar",
      "code": "HU-HB",
      "type": "County"
    },
    {
      "region": "Heves",
      "code": "HU-HE",
      "type": "County"
    },
    {
      "region": "Hódmezővásárhely",
      "code": "HU-HV",
      "type": "City with county rights"
    },
    {
      "region": "Jász-Nagykun-Szolnok",
      "code": "HU-JN",
      "type": "County"
    },
    {
      "region": "Kaposvár",
      "code": "HU-KV",
      "type": "City with county rights"
    },
    {
      "region": "Kecskemét",
      "code": "HU-KM",
      "type": "City with county rights"
    },
    {
      "region": "Komárom-Esztergom",
      "code": "HU-KE",
      "type": "County"
    },
    {
      "region": "Miskolc",
      "code": "HU-MI",
      "type": "City with county rights"
    },
    {
      "region": "Nagykanizsa",
      "code": "HU-NK",
      "type": "City with county rights"
    },
    {
      "region": "Nyíregyháza",
      "code": "HU-NY",
      "type": "City with county rights"
    },
    {
      "region": "Nógrád",
      "code": "HU-NO",
      "type": "County"
    },
    {
      "region": "Pest",
      "code": "HU-PE",
      "type": "County"
    },
    {
      "region": "Pécs",
      "code": "HU-PS",
      "type": "City with county rights"
    },
    {
      "region": "Salgótarján",
      "code": "HU-ST",
      "type": "City with county rights"
    },
    {
      "region": "Somogy",
      "code": "HU-SO",
      "type": "County"
    },
    {
      "region": "Sopron",
      "code": "HU-SN",
      "type": "City with county rights"
    },
    {
      "region": "Szabolcs-Szatmár-Bereg",
      "code": "HU-SZ",
      "type": "County"
    },
    {
      "region": "Szeged",
      "code": "HU-SD",
      "type": "City with county rights"
    },
    {
      "region": "Szekszárd",
      "code": "HU-SS",
      "type": "City with county rights"
    },
    {
      "region": "Szolnok",
      "code": "HU-SK",
      "type": "City with county rights"
    },
    {
      "region": "Szombathely",
      "code": "HU-SH",
      "type": "City with county rights"
    },
    {
      "region": "Székesfehérvár",
      "code": "HU-SF",
      "type": "City with county rights"
    },
    {
      "region": "Tatabánya",
      "code": "HU-TB",
      "type": "City with county rights"
    },
    {
      "region": "Tolna",
      "code": "HU-TO",
      "type": "County"
    },
    {
      "region": "Vas",
      "code": "HU-VA",
      "type": "County"
    },
    {
      "region": "Veszprém",
      "code": "HU-VE",
      "type": "County"
    },
    {
      "region": "Veszprém",
      "code": "HU-VM",
      "type": "City with county rights"
    },
    {
      "region": "Zala",
      "code": "HU-ZA",
      "type": "County"
    },
    {
      "region": "Zalaegerszeg",
      "code": "HU-ZE",
      "type": "City with county rights"
    },
    {
      "region": "Érd",
      "code": "HU-ER",
      "type": "City with county rights"
    }
  ],
  "ID": [
    {
      "region": "Jawa",
      "code": "ID-JW",
      "type": "Geographical unit"
    },
    {
      "region": "Kalimantan",
      "code": "ID-KA",
      "type": "Geographical unit"
    },
    {
      "region": "Maluku",
      "code": "ID-ML",
      "type": "Geographical unit"
    },
    {
      "region": "Nusa Tenggara",
      "code": "ID-NU",
      "type": "Geographical unit"
    },
    {
      "region": "Papua",
      "code": "ID-PP",
      "type": "Geographical unit"
    },
    {
      "region": "Sulawesi",
      "code": "ID-SL",
      "type": "Geographical unit"
    },
    {
      "region": "Sumatera",
      "code": "ID-SM",
      "type": "Geographical unit"
    }
  ],
  "IE": [
    {
      "region": "Connaught",
      "code": "IE-C",
      "type": "Province"
    },
    {
      "region": "Leinster",
      "code": "IE-L",
      "type": "Province"
    },
    {
      "region": "Munster",
      "code": "IE-M",
      "type": "Province"
    },
    {
      "region": "Ulster",
      "code": "IE-U",
      "type": "Province"
    }
  ],
  "IL": [
    {
      "region": "Al Awsaţ",
      "code": "IL-M",
      "type": "District"
    },
    {
      "region": "Al Janūbī",
      "code": "IL-D",
      "type": "District"
    },
    {
      "region": "Al Quds",
      "code": "IL-JM",
      "type": "District"
    },
    {
      "region": "Ash Shamālī",
      "code": "IL-Z",
      "type": "District"
    },
    {
      "region": "H̱efa",
      "code": "IL-HA",
      "type": "District"
    },
    {
      "region": "Tall Abīb",
      "code": "IL-TA",
      "type": "District"
    }
  ],
  "IN": [
    {
      "region": "Andaman and Nicobar Islands",
      "code": "IN-AN",
      "type": "Union territory"
    },
    {
      "region": "Andhra Pradesh",
      "code": "IN-AP",
      "type": "State"
    },
    {
      "region": "Arunāchal Pradesh",
      "code": "IN-AR",
      "type": "State"
    },
    {
      "region": "Assam",
      "code": "IN-AS",
      "type": "State"
    },
    {
      "region": "Bihār",
      "code": "IN-BR",
      "type": "State"
    },
    {
      "region": "Chandīgarh",
      "code": "IN-CH",
      "type": "Union territory"
    },
    {
      "region": "Chhattīsgarh",
      "code": "IN-CT",
      "type": "State"
    },
    {
      "region": "Delhi",
      "code": "IN-DL",
      "type": "Union territory"
    },
    {
      "region": "Dādra and Nagar Haveli and Damān and Diu",
      "code": "IN-DH",
      "type": "Union territory"
    },
    {
      "region": "Goa",
      "code": "IN-GA",
      "type": "State"
    },
    {
      "region": "Gujarāt",
      "code": "IN-GJ",
      "type": "State"
    },
    {
      "region": "Haryāna",
      "code": "IN-HR",
      "type": "State"
    },
    {
      "region": "Himāchal Pradesh",
      "code": "IN-HP",
      "type": "State"
    },
    {
      "region": "Jammu and Kashmīr",
      "code": "IN-JK",
      "type": "Union territory"
    },
    {
      "region": "Jhārkhand",
      "code": "IN-JH",
      "type": "State"
    },
    {
      "region": "Karnātaka",
      "code": "IN-KA",
      "type": "State"
    },
    {
      "region": "Kerala",
      "code": "IN-KL",
      "type": "State"
    },
    {
      "region": "Ladākh",
      "code": "IN-LA",
      "type": "Union territory"
    },
    {
      "region": "Lakshadweep",
      "code": "IN-LD",
      "type": "Union territory"
    },
    {
      "region": "Madhya Pradesh",
      "code": "IN-MP",
      "type": "State"
    },
    {
      "region": "Mahārāshtra",
      "code": "IN-MH",
      "type": "State"
    },
    {
      "region": "Manipur",
      "code": "IN-MN",
      "type": "State"
    },
    {
      "region": "Meghālaya",
      "code": "IN-ML",
      "type": "State"
    },
    {
      "region": "Mizoram",
      "code": "IN-MZ",
      "type": "State"
    },
    {
      "region": "Nāgāland",
      "code": "IN-NL",
      "type": "State"
    },
    {
      "region": "Odisha",
      "code": "IN-OR",
      "type": "State"
    },
    {
      "region": "Puducherry",
      "code": "IN-PY",
      "type": "Union territory"
    },
    {
      "region": "Punjab",
      "code": "IN-PB",
      "type": "State"
    },
    {
      "region": "Rājasthān",
      "code": "IN-RJ",
      "type": "State"
    },
    {
      "region": "Sikkim",
      "code": "IN-SK",
      "type": "State"
    },
    {
      "region": "Tamil Nādu",
      "code": "IN-TN",
      "type": "State"
    },
    {
      "region": "Telangāna",
      "code": "IN-TG",
      "type": "State"
    },
    {
      "region": "Tripura",
      "code": "IN-TR",
      "type": "State"
    },
    {
      "region": "Uttar Pradesh",
      "code": "IN-UP",
      "type": "State"
    },
    {
      "region": "Uttarākhand",
      "code": "IN-UT",
      "type": "State"
    },
    {
      "region": "West Bengal",
      "code": "IN-WB",
      "type": "State"
    }
  ],
  "IQ": [
    {
      "region": "Al Anbār",
      "code": "IQ-AN",
      "type": "Governorate"
    },
    {
      "region": "Al Başrah",
      "code": "IQ-BA",
      "type": "Governorate"
    },
    {
      "region": "Al Muthanná",
      "code": "IQ-MU",
      "type": "Governorate"
    },
    {
      "region": "Al Qādisīyah",
      "code": "IQ-QA",
      "type": "Governorate"
    },
    {
      "region": "An Najaf",
      "code": "IQ-NA",
      "type": "Governorate"
    },
    {
      "region": "Arbīl",
      "code": "IQ-AR",
      "type": "Governorate"
    },
    {
      "region": "As Sulaymānīyah",
      "code": "IQ-SU",
      "type": "Governorate"
    },
    {
      "region": "Baghdād",
      "code": "IQ-BG",
      "type": "Governorate"
    },
    {
      "region": "Bābil",
      "code": "IQ-BB",
      "type": "Governorate"
    },
    {
      "region": "Dahūk",
      "code": "IQ-DA",
      "type": "Governorate"
    },
    {
      "region": "Dhī Qār",
      "code": "IQ-DQ",
      "type": "Governorate"
    },
    {
      "region": "Diyālá",
      "code": "IQ-DI",
      "type": "Governorate"
    },
    {
      "region": "Karbalā’",
      "code": "IQ-KA",
      "type": "Governorate"
    },
    {
      "region": "Kirkūk",
      "code": "IQ-KI",
      "type": "Governorate"
    },
    {
      "region": "Maysān",
      "code": "IQ-MA",
      "type": "Governorate"
    },
    {
      "region": "Nīnawá",
      "code": "IQ-NI",
      "type": "Governorate"
    },
    {
      "region": "Wāsiţ",
      "code": "IQ-WA",
      "type": "Governorate"
    },
    {
      "region": "Şalāḩ ad Dīn",
      "code": "IQ-SD",
      "type": "Governorate"
    }
  ],
  "IR": [
    {
      "region": "Alborz",
      "code": "IR-30",
      "type": "Province"
    },
    {
      "region": "Ardabīl",
      "code": "IR-24",
      "type": "Province"
    },
    {
      "region": "Būshehr",
      "code": "IR-18",
      "type": "Province"
    },
    {
      "region": "Chahār Maḩāl va Bakhtīārī",
      "code": "IR-14",
      "type": "Province"
    },
    {
      "region": "Eşfahān",
      "code": "IR-10",
      "type": "Province"
    },
    {
      "region": "Fārs",
      "code": "IR-07",
      "type": "Province"
    },
    {
      "region": "Golestān",
      "code": "IR-27",
      "type": "Province"
    },
    {
      "region": "Gīlān",
      "code": "IR-01",
      "type": "Province"
    },
    {
      "region": "Hamadān",
      "code": "IR-13",
      "type": "Province"
    },
    {
      "region": "Hormozgān",
      "code": "IR-22",
      "type": "Province"
    },
    {
      "region": "Kermān",
      "code": "IR-08",
      "type": "Province"
    },
    {
      "region": "Kermānshāh",
      "code": "IR-05",
      "type": "Province"
    },
    {
      "region": "Khorāsān-e Jonūbī",
      "code": "IR-29",
      "type": "Province"
    },
    {
      "region": "Khorāsān-e Raẕavī",
      "code": "IR-09",
      "type": "Province"
    },
    {
      "region": "Khorāsān-e Shomālī",
      "code": "IR-28",
      "type": "Province"
    },
    {
      "region": "Khūzestān",
      "code": "IR-06",
      "type": "Province"
    },
    {
      "region": "Kohgīlūyeh va Bowyer Aḩmad",
      "code": "IR-17",
      "type": "Province"
    },
    {
      "region": "Kordestān",
      "code": "IR-12",
      "type": "Province"
    },
    {
      "region": "Lorestān",
      "code": "IR-15",
      "type": "Province"
    },
    {
      "region": "Markazī",
      "code": "IR-00",
      "type": "Province"
    },
    {
      "region": "Māzandarān",
      "code": "IR-02",
      "type": "Province"
    },
    {
      "region": "Qazvīn",
      "code": "IR-26",
      "type": "Province"
    },
    {
      "region": "Qom",
      "code": "IR-25",
      "type": "Province"
    },
    {
      "region": "Semnān",
      "code": "IR-20",
      "type": "Province"
    },
    {
      "region": "Sīstān va Balūchestān",
      "code": "IR-11",
      "type": "Province"
    },
    {
      "region": "Tehrān",
      "code": "IR-23",
      "type": "Province"
    },
    {
      "region": "Yazd",
      "code": "IR-21",
      "type": "Province"
    },
    {
      "region": "Zanjān",
      "code": "IR-19",
      "type": "Province"
    },
    {
      "region": "Āz̄ārbāyjān-e Ghārbī",
      "code": "IR-04",
      "type": "Province"
    },
    {
      "region": "Āz̄ārbāyjān-e Shārqī",
      "code": "IR-03",
      "type": "Province"
    },
    {
      "region": "Īlām",
      "code": "IR-16",
      "type": "Province"
    }
  ],
  "IS": [
    {
      "region": "Austurland",
      "code": "IS-7",
      "type": "Region"
    },
    {
      "region": "Höfuðborgarsvæði",
      "code": "IS-1",
      "type": "Region"
    },
    {
      "region": "Norðurland eystra",
      "code": "IS-6",
      "type": "Region"
    },
    {
      "region": "Norðurland vestra",
      "code": "IS-5",
      "type": "Region"
    },
    {
      "region": "Suðurland",
      "code": "IS-8",
      "type": "Region"
    },
    {
      "region": "Suðurnes",
      "code": "IS-2",
      "type": "Region"
    },
    {
      "region": "Vestfirðir",
      "code": "IS-4",
      "type": "Region"
    },
    {
      "region": "Vesturland",
      "code": "IS-3",
      "type": "Region"
    }
  ],
  "IT": [
    {
      "region": "Abruzzo",
      "code": "IT-65",
      "type": "Region"
    },
    {
      "region": "Basilicata",
      "code": "IT-77",
      "type": "Region"
    },
    {
      "region": "Calabria",
      "code": "IT-78",
      "type": "Region"
    },
    {
      "region": "Campania",
      "code": "IT-72",
      "type": "Region"
    },
    {
      "region": "Emilia-Romagna",
      "code": "IT-45",
      "type": "Region"
    },
    {
      "region": "Friuli Venezia Giulia",
      "code": "IT-36",
      "type": "Autonomous region"
    },
    {
      "region": "Lazio",
      "code": "IT-62",
      "type": "Region"
    },
    {
      "region": "Liguria",
      "code": "IT-42",
      "type": "Region"
    },
    {
      "region": "Lombardia",
      "code": "IT-25",
      "type": "Region"
    },
    {
      "region": "Marche",
      "code": "IT-57",
      "type": "Region"
    },
    {
      "region": "Molise",
      "code": "IT-67",
      "type": "Region"
    },
    {
      "region": "Piemonte",
      "code": "IT-21",
      "type": "Region"
    },
    {
      "region": "Puglia",
      "code": "IT-75",
      "type": "Region"
    },
    {
      "region": "Sardegna",
      "code": "IT-88",
      "type": "Autonomous region"
    },
    {
      "region": "Sicilia",
      "code": "IT-82",
      "type": "Autonomous region"
    },
    {
      "region": "Toscana",
      "code": "IT-52",
      "type": "Region"
    },
    {
      "region": "Trentino-Alto Adige",
      "code": "IT-32",
      "type": "Autonomous region"
    },
    {
      "region": "Umbria",
      "code": "IT-55",
      "type": "Region"
    },
    {
      "region": "Val d'Aoste",
      "code": "IT-23",
      "type": "Autonomous region"
    },
    {
      "region": "Veneto",
      "code": "IT-34",
      "type": "Region"
    }
  ],
  "JM": [
    {
      "region": "Clarendon",
      "code": "JM-13",
      "type": "Parish"
    },
    {
      "region": "Hanover",
      "code": "JM-09",
      "type": "Parish"
    },
    {
      "region": "Kingston",
      "code": "JM-01",
      "type": "Parish"
    },
    {
      "region": "Manchester",
      "code": "JM-12",
      "type": "Parish"
    },
    {
      "region": "Portland",
      "code": "JM-04",
      "type": "Parish"
    },
    {
      "region": "Saint Andrew",
      "code": "JM-02",
      "type": "Parish"
    },
    {
      "region": "Saint Ann",
      "code": "JM-06",
      "type": "Parish"
    },
    {
      "region": "Saint Catherine",
      "code": "JM-14",
      "type": "Parish"
    },
    {
      "region": "Saint Elizabeth",
      "code": "JM-11",
      "type": "Parish"
    },
    {
      "region": "Saint James",
      "code": "JM-08",
      "type": "Parish"
    },
    {
      "region": "Saint Mary",
      "code": "JM-05",
      "type": "Parish"
    },
    {
      "region": "Saint Thomas",
      "code": "JM-03",
      "type": "Parish"
    },
    {
      "region": "Trelawny",
      "code": "JM-07",
      "type": "Parish"
    },
    {
      "region": "Westmoreland",
      "code": "JM-10",
      "type": "Parish"
    }
  ],
  "JO": [
    {
      "region": "Al Balqā’",
      "code": "JO-BA",
      "type": "Governorate"
    },
    {
      "region": "Al Karak",
      "code": "JO-KA",
      "type": "Governorate"
    },
    {
      "region": "Al Mafraq",
      "code": "JO-MA",
      "type": "Governorate"
    },
    {
      "region": "Al ‘Aqabah",
      "code": "JO-AQ",
      "type": "Governorate"
    },
    {
      "region": "Al ‘A̅şimah",
      "code": "JO-AM",
      "type": "Governorate"
    },
    {
      "region": "Az Zarqā’",
      "code": "JO-AZ",
      "type": "Governorate"
    },
    {
      "region": "Aţ Ţafīlah",
      "code": "JO-AT",
      "type": "Governorate"
    },
    {
      "region": "Irbid",
      "code": "JO-IR",
      "type": "Governorate"
    },
    {
      "region": "Jarash",
      "code": "JO-JA",
      "type": "Governorate"
    },
    {
      "region": "Ma‘ān",
      "code": "JO-MN",
      "type": "Governorate"
    },
    {
      "region": "Mādabā",
      "code": "JO-MD",
      "type": "Governorate"
    },
    {
      "region": "‘Ajlūn",
      "code": "JO-AJ",
      "type": "Governorate"
    }
  ],
  "JP": [
    {
      "region": "Aichi",
      "code": "JP-23",
      "type": "Prefecture"
    },
    {
      "region": "Akita",
      "code": "JP-05",
      "type": "Prefecture"
    },
    {
      "region": "Aomori",
      "code": "JP-02",
      "type": "Prefecture"
    },
    {
      "region": "Chiba",
      "code": "JP-12",
      "type": "Prefecture"
    },
    {
      "region": "Ehime",
      "code": "JP-38",
      "type": "Prefecture"
    },
    {
      "region": "Fukui",
      "code": "JP-18",
      "type": "Prefecture"
    },
    {
      "region": "Fukuoka",
      "code": "JP-40",
      "type": "Prefecture"
    },
    {
      "region": "Fukushima",
      "code": "JP-07",
      "type": "Prefecture"
    },
    {
      "region": "Gifu",
      "code": "JP-21",
      "type": "Prefecture"
    },
    {
      "region": "Gunma",
      "code": "JP-10",
      "type": "Prefecture"
    },
    {
      "region": "Hiroshima",
      "code": "JP-34",
      "type": "Prefecture"
    },
    {
      "region": "Hokkaido",
      "code": "JP-01",
      "type": "Prefecture"
    },
    {
      "region": "Hyogo",
      "code": "JP-28",
      "type": "Prefecture"
    },
    {
      "region": "Ibaraki",
      "code": "JP-08",
      "type": "Prefecture"
    },
    {
      "region": "Ishikawa",
      "code": "JP-17",
      "type": "Prefecture"
    },
    {
      "region": "Iwate",
      "code": "JP-03",
      "type": "Prefecture"
    },
    {
      "region": "Kagawa",
      "code": "JP-37",
      "type": "Prefecture"
    },
    {
      "region": "Kagoshima",
      "code": "JP-46",
      "type": "Prefecture"
    },
    {
      "region": "Kanagawa",
      "code": "JP-14",
      "type": "Prefecture"
    },
    {
      "region": "Kochi",
      "code": "JP-39",
      "type": "Prefecture"
    },
    {
      "region": "Kumamoto",
      "code": "JP-43",
      "type": "Prefecture"
    },
    {
      "region": "Kyoto",
      "code": "JP-26",
      "type": "Prefecture"
    },
    {
      "region": "Mie",
      "code": "JP-24",
      "type": "Prefecture"
    },
    {
      "region": "Miyagi",
      "code": "JP-04",
      "type": "Prefecture"
    },
    {
      "region": "Miyazaki",
      "code": "JP-45",
      "type": "Prefecture"
    },
    {
      "region": "Nagano",
      "code": "JP-20",
      "type": "Prefecture"
    },
    {
      "region": "Nagasaki",
      "code": "JP-42",
      "type": "Prefecture"
    },
    {
      "region": "Nara",
      "code": "JP-29",
      "type": "Prefecture"
    },
    {
      "region": "Niigata",
      "code": "JP-15",
      "type": "Prefecture"
    },
    {
      "region": "Oita",
      "code": "JP-44",
      "type": "Prefecture"
    },
    {
      "region": "Okayama",
      "code": "JP-33",
      "type": "Prefecture"
    },
    {
      "region": "Okinawa",
      "code": "JP-47",
      "type": "Prefecture"
    },
    {
      "region": "Osaka",
      "code": "JP-27",
      "type": "Prefecture"
    },
    {
      "region": "Saga",
      "code": "JP-41",
      "type": "Prefecture"
    },
    {
      "region": "Saitama",
      "code": "JP-11",
      "type": "Prefecture"
    },
    {
      "region": "Shiga",
      "code": "JP-25",
      "type": "Prefecture"
    },
    {
      "region": "Shimane",
      "code": "JP-32",
      "type": "Prefecture"
    },
    {
      "region": "Shizuoka",
      "code": "JP-22",
      "type": "Prefecture"
    },
    {
      "region": "Tochigi",
      "code": "JP-09",
      "type": "Prefecture"
    },
    {
      "region": "Tokushima",
      "code": "JP-36",
      "type": "Prefecture"
    },
    {
      "region": "Tokyo",
      "code": "JP-13",
      "type": "Prefecture"
    },
    {
      "region": "Tottori",
      "code": "JP-31",
      "type": "Prefecture"
    },
    {
      "region": "Toyama",
      "code": "JP-16",
      "type": "Prefecture"
    },
    {
      "region": "Wakayama",
      "code": "JP-30",
      "type": "Prefecture"
    },
    {
      "region": "Yamagata",
      "code": "JP-06",
      "type": "Prefecture"
    },
    {
      "region": "Yamaguchi",
      "code": "JP-35",
      "type": "Prefecture"
    },
    {
      "region": "Yamanashi",
      "code": "JP-19",
      "type": "Prefecture"
    }
  ],
  "KE": [
    {
      "region": "Baringo",
      "code": "KE-01",
      "type": "County"
    },
    {
      "region": "Bomet",
      "code": "KE-02",
      "type": "County"
    },
    {
      "region": "Bungoma",
      "code": "KE-03",
      "type": "County"
    },
    {
      "region": "Busia",
      "code": "KE-04",
      "type": "County"
    },
    {
      "region": "Elgeyo/Marakwet",
      "code": "KE-05",
      "type": "County"
    },
    {
      "region": "Embu",
      "code": "KE-06",
      "type": "County"
    },
    {
      "region": "Garissa",
      "code": "KE-07",
      "type": "County"
    },
    {
      "region": "Homa Bay",
      "code": "KE-08",
      "type": "County"
    },
    {
      "region": "Isiolo",
      "code": "KE-09",
      "type": "County"
    },
    {
      "region": "Kajiado",
      "code": "KE-10",
      "type": "County"
    },
    {
      "region": "Kakamega",
      "code": "KE-11",
      "type": "County"
    },
    {
      "region": "Kericho",
      "code": "KE-12",
      "type": "County"
    },
    {
      "region": "Kiambu",
      "code": "KE-13",
      "type": "County"
    },
    {
      "region": "Kilifi",
      "code": "KE-14",
      "type": "County"
    },
    {
      "region": "Kirinyaga",
      "code": "KE-15",
      "type": "County"
    },
    {
      "region": "Kisii",
      "code": "KE-16",
      "type": "County"
    },
    {
      "region": "Kisumu",
      "code": "KE-17",
      "type": "County"
    },
    {
      "region": "Kitui",
      "code": "KE-18",
      "type": "County"
    },
    {
      "region": "Kwale",
      "code": "KE-19",
      "type": "County"
    },
    {
      "region": "Laikipia",
      "code": "KE-20",
      "type": "County"
    },
    {
      "region": "Lamu",
      "code": "KE-21",
      "type": "County"
    },
    {
      "region": "Machakos",
      "code": "KE-22",
      "type": "County"
    },
    {
      "region": "Makueni",
      "code": "KE-23",
      "type": "County"
    },
    {
      "region": "Mandera",
      "code": "KE-24",
      "type": "County"
    },
    {
      "region": "Marsabit",
      "code": "KE-25",
      "type": "County"
    },
    {
      "region": "Meru",
      "code": "KE-26",
      "type": "County"
    },
    {
      "region": "Migori",
      "code": "KE-27",
      "type": "County"
    },
    {
      "region": "Mombasa",
      "code": "KE-28",
      "type": "County"
    },
    {
      "region": "Murang'a",
      "code": "KE-29",
      "type": "County"
    },
    {
      "region": "Nairobi City",
      "code": "KE-30",
      "type": "County"
    },
    {
      "region": "Nakuru",
      "code": "KE-31",
      "type": "County"
    },
    {
      "region": "Nandi",
      "code": "KE-32",
      "type": "County"
    },
    {
      "region": "Narok",
      "code": "KE-33",
      "type": "County"
    },
    {
      "region": "Nyamira",
      "code": "KE-34",
      "type": "County"
    },
    {
      "region": "Nyandarua",
      "code": "KE-35",
      "type": "County"
    },
    {
      "region": "Nyeri",
      "code": "KE-36",
      "type": "County"
    },
    {
      "region": "Samburu",
      "code": "KE-37",
      "type": "County"
    },
    {
      "region": "Siaya",
      "code": "KE-38",
      "type": "County"
    },
    {
      "region": "Taita/Taveta",
      "code": "KE-39",
      "type": "County"
    },
    {
      "region": "Tana River",
      "code": "KE-40",
      "type": "County"
    },
    {
      "region": "Tharaka-Nithi",
      "code": "KE-41",
      "type": "County"
    },
    {
      "region": "Trans Nzoia",
      "code": "KE-42",
      "type": "County"
    },
    {
      "region": "Turkana",
      "code": "KE-43",
      "type": "County"
    },
    {
      "region": "Uasin Gishu",
      "code": "KE-44",
      "type": "County"
    },
    {
      "region": "Vihiga",
      "code": "KE-45",
      "type": "County"
    },
    {
      "region": "Wajir",
      "code": "KE-46",
      "type": "County"
    },
    {
      "region": "West Pokot",
      "code": "KE-47",
      "type": "County"
    }
  ],
  "KG": [
    {
      "region": "Batken",
      "code": "KG-B",
      "type": "Region"
    },
    {
      "region": "Bishkek Shaary",
      "code": "KG-GB",
      "type": "City"
    },
    {
      "region": "Chuyskaya oblast'",
      "code": "KG-C",
      "type": "Region"
    },
    {
      "region": "Dzhalal-Abadskaya oblast'",
      "code": "KG-J",
      "type": "Region"
    },
    {
      "region": "Gorod Osh",
      "code": "KG-GO",
      "type": "City"
    },
    {
      "region": "Issyk-Kul'skaja oblast'",
      "code": "KG-Y",
      "type": "Region"
    },
    {
      "region": "Naryn",
      "code": "KG-N",
      "type": "Region"
    },
    {
      "region": "Osh",
      "code": "KG-O",
      "type": "Region"
    },
    {
      "region": "Talas",
      "code": "KG-T",
      "type": "Region"
    }
  ],
  "KH": [
    {
      "region": "Baat Dambang",
      "code": "KH-2",
      "type": "Province"
    },
    {
      "region": "Banteay Mean Choăy",
      "code": "KH-1",
      "type": "Province"
    },
    {
      "region": "Kaeb",
      "code": "KH-23",
      "type": "Province"
    },
    {
      "region": "Kampong Chaam",
      "code": "KH-3",
      "type": "Province"
    },
    {
      "region": "Kampong Chhnang",
      "code": "KH-4",
      "type": "Province"
    },
    {
      "region": "Kampong Spueu",
      "code": "KH-5",
      "type": "Province"
    },
    {
      "region": "Kampong Thum",
      "code": "KH-6",
      "type": "Province"
    },
    {
      "region": "Kampot",
      "code": "KH-7",
      "type": "Province"
    },
    {
      "region": "Kandaal",
      "code": "KH-8",
      "type": "Province"
    },
    {
      "region": "Kaoh Kong",
      "code": "KH-9",
      "type": "Province"
    },
    {
      "region": "Kracheh",
      "code": "KH-10",
      "type": "Province"
    },
    {
      "region": "Mondol Kiri",
      "code": "KH-11",
      "type": "Province"
    },
    {
      "region": "Otdar Mean Chey",
      "code": "KH-22",
      "type": "Province"
    },
    {
      "region": "Pailin",
      "code": "KH-24",
      "type": "Province"
    },
    {
      "region": "Phnom Penh",
      "code": "KH-12",
      "type": "Autonomous municipality"
    },
    {
      "region": "Pousaat",
      "code": "KH-15",
      "type": "Province"
    },
    {
      "region": "Preah Sihanouk",
      "code": "KH-18",
      "type": "Province"
    },
    {
      "region": "Preah Vihear",
      "code": "KH-13",
      "type": "Province"
    },
    {
      "region": "Prey Veaeng",
      "code": "KH-14",
      "type": "Province"
    },
    {
      "region": "Rotanak Kiri",
      "code": "KH-16",
      "type": "Province"
    },
    {
      "region": "Siem Reab",
      "code": "KH-17",
      "type": "Province"
    },
    {
      "region": "Stoĕng Trêng",
      "code": "KH-19",
      "type": "Province"
    },
    {
      "region": "Svaay Rieng",
      "code": "KH-20",
      "type": "Province"
    },
    {
      "region": "Taakaev",
      "code": "KH-21",
      "type": "Province"
    },
    {
      "region": "Tbong Khmum",
      "code": "KH-25",
      "type": "Province"
    }
  ],
  "KI": [
    {
      "region": "Gilbert Islands",
      "code": "KI-G",
      "type": "Group of islands (20 inhabited islands)"
    },
    {
      "region": "Line Islands",
      "code": "KI-L",
      "type": "Group of islands (20 inhabited islands)"
    },
    {
      "region": "Phoenix Islands",
      "code": "KI-P",
      "type": "Group of islands (20 inhabited islands)"
    }
  ],
  "KM": [
    {
      "region": "Andjazîdja",
      "code": "KM-G",
      "type": "Island"
    },
    {
      "region": "Andjouân",
      "code": "KM-A",
      "type": "Island"
    },
    {
      "region": "Mohéli",
      "code": "KM-M",
      "type": "Island"
    }
  ],
  "KN": [
    {
      "region": "Nevis",
      "code": "KN-N",
      "type": "State"
    },
    {
      "region": "Saint Kitts",
      "code": "KN-K",
      "type": "State"
    }
  ],
  "KP": [
    {
      "region": "Chagang-do",
      "code": "KP-04",
      "type": "Province"
    },
    {
      "region": "Hamgyǒng-bukto",
      "code": "KP-09",
      "type": "Province"
    },
    {
      "region": "Hamgyǒng-namdo",
      "code": "KP-08",
      "type": "Province"
    },
    {
      "region": "Hwanghae-bukto",
      "code": "KP-06",
      "type": "Province"
    },
    {
      "region": "Hwanghae-namdo",
      "code": "KP-05",
      "type": "Province"
    },
    {
      "region": "Kangweonto",
      "code": "KP-07",
      "type": "Province"
    },
    {
      "region": "Nampho",
      "code": "KP-14",
      "type": "Metropolitan city"
    },
    {
      "region": "P'yǒngan-bukto",
      "code": "KP-03",
      "type": "Province"
    },
    {
      "region": "P'yǒngan-namdo",
      "code": "KP-02",
      "type": "Province"
    },
    {
      "region": "P'yǒngyang",
      "code": "KP-01",
      "type": "Capital city"
    },
    {
      "region": "Raseon",
      "code": "KP-13",
      "type": "Special city"
    },
    {
      "region": "Ryanggang-do",
      "code": "KP-10",
      "type": "Province"
    }
  ],
  "KR": [
    {
      "region": "Busan-gwangyeoksi",
      "code": "KR-26",
      "type": "Metropolitan city"
    },
    {
      "region": "Chungcheongbuk-do",
      "code": "KR-43",
      "type": "Province"
    },
    {
      "region": "Chungcheongnam-do",
      "code": "KR-44",
      "type": "Province"
    },
    {
      "region": "Daegu-gwangyeoksi",
      "code": "KR-27",
      "type": "Metropolitan city"
    },
    {
      "region": "Daejeon-gwangyeoksi",
      "code": "KR-30",
      "type": "Metropolitan city"
    },
    {
      "region": "Gangwon-do",
      "code": "KR-42",
      "type": "Province"
    },
    {
      "region": "Gwangju-gwangyeoksi",
      "code": "KR-29",
      "type": "Metropolitan city"
    },
    {
      "region": "Gyeonggi-do",
      "code": "KR-41",
      "type": "Province"
    },
    {
      "region": "Gyeongsangbuk-do",
      "code": "KR-47",
      "type": "Province"
    },
    {
      "region": "Gyeongsangnam-do",
      "code": "KR-48",
      "type": "Province"
    },
    {
      "region": "Incheon-gwangyeoksi",
      "code": "KR-28",
      "type": "Metropolitan city"
    },
    {
      "region": "Jeju-teukbyeoljachido",
      "code": "KR-49",
      "type": "Special self-governing province"
    },
    {
      "region": "Jeollabuk-do",
      "code": "KR-45",
      "type": "Province"
    },
    {
      "region": "Jeollanam-do",
      "code": "KR-46",
      "type": "Province"
    },
    {
      "region": "Sejong",
      "code": "KR-50",
      "type": "Special self-governing city"
    },
    {
      "region": "Seoul-teukbyeolsi",
      "code": "KR-11",
      "type": "Special city"
    },
    {
      "region": "Ulsan-gwangyeoksi",
      "code": "KR-31",
      "type": "Metropolitan city"
    }
  ],
  "KW": [
    {
      "region": "Al Aḩmadī",
      "code": "KW-AH",
      "type": "Governorate"
    },
    {
      "region": "Al Farwānīyah",
      "code": "KW-FA",
      "type": "Governorate"
    },
    {
      "region": "Al Jahrā’",
      "code": "KW-JA",
      "type": "Governorate"
    },
    {
      "region": "Al ‘Āşimah",
      "code": "KW-KU",
      "type": "Governorate"
    },
    {
      "region": "Mubārak al Kabīr",
      "code": "KW-MU",
      "type": "Governorate"
    },
    {
      "region": "Ḩawallī",
      "code": "KW-HA",
      "type": "Governorate"
    }
  ],
  "KZ": [
    {
      "region": "Akmolinskaja oblast'",
      "code": "KZ-AKM",
      "type": "Region"
    },
    {
      "region": "Aktjubinskaja oblast'",
      "code": "KZ-AKT",
      "type": "Region"
    },
    {
      "region": "Almatinskaja oblast'",
      "code": "KZ-ALM",
      "type": "Region"
    },
    {
      "region": "Almaty",
      "code": "KZ-ALA",
      "type": "City"
    },
    {
      "region": "Atyrauskaja oblast'",
      "code": "KZ-ATY",
      "type": "Region"
    },
    {
      "region": "Batys Qazaqstan oblysy",
      "code": "KZ-ZAP",
      "type": "Region"
    },
    {
      "region": "Karagandinskaja oblast'",
      "code": "KZ-KAR",
      "type": "Region"
    },
    {
      "region": "Kostanajskaja oblast'",
      "code": "KZ-KUS",
      "type": "Region"
    },
    {
      "region": "Kyzylordinskaja oblast'",
      "code": "KZ-KZY",
      "type": "Region"
    },
    {
      "region": "Mangghystaū oblysy",
      "code": "KZ-MAN",
      "type": "Region"
    },
    {
      "region": "Nur-Sultan",
      "code": "KZ-AST",
      "type": "City"
    },
    {
      "region": "Pavlodar oblysy",
      "code": "KZ-PAV",
      "type": "Region"
    },
    {
      "region": "Severo-Kazahstanskaja oblast'",
      "code": "KZ-SEV",
      "type": "Region"
    },
    {
      "region": "Shyghys Qazaqstan oblysy",
      "code": "KZ-VOS",
      "type": "Region"
    },
    {
      "region": "Shymkent",
      "code": "KZ-SHY",
      "type": "City"
    },
    {
      "region": "Turkestankaya oblast'",
      "code": "KZ-YUZ",
      "type": "Region"
    },
    {
      "region": "Zhambyl oblysy",
      "code": "KZ-ZHA",
      "type": "Region"
    }
  ],
  "LA": [
    {
      "region": "Attapu",
      "code": "LA-AT",
      "type": "Province"
    },
    {
      "region": "Bokèo",
      "code": "LA-BK",
      "type": "Province"
    },
    {
      "region": "Bolikhamxai",
      "code": "LA-BL",
      "type": "Province"
    },
    {
      "region": "Champasak",
      "code": "LA-CH",
      "type": "Province"
    },
    {
      "region": "Houaphan",
      "code": "LA-HO",
      "type": "Province"
    },
    {
      "region": "Khammouan",
      "code": "LA-KH",
      "type": "Province"
    },
    {
      "region": "Louang Namtha",
      "code": "LA-LM",
      "type": "Province"
    },
    {
      "region": "Louangphabang",
      "code": "LA-LP",
      "type": "Province"
    },
    {
      "region": "Oudômxai",
      "code": "LA-OU",
      "type": "Province"
    },
    {
      "region": "Phôngsali",
      "code": "LA-PH",
      "type": "Province"
    },
    {
      "region": "Salavan",
      "code": "LA-SL",
      "type": "Province"
    },
    {
      "region": "Savannakhét",
      "code": "LA-SV",
      "type": "Province"
    },
    {
      "region": "Viangchan",
      "code": "LA-VI",
      "type": "Province"
    },
    {
      "region": "Viangchan",
      "code": "LA-VT",
      "type": "Prefecture"
    },
    {
      "region": "Xaignabouli",
      "code": "LA-XA",
      "type": "Province"
    },
    {
      "region": "Xaisômboun",
      "code": "LA-XS",
      "type": "Province"
    },
    {
      "region": "Xiangkhouang",
      "code": "LA-XI",
      "type": "Province"
    },
    {
      "region": "Xékong",
      "code": "LA-XE",
      "type": "Province"
    }
  ],
  "LB": [
    {
      "region": "Aakkâr",
      "code": "LB-AK",
      "type": "Governorate"
    },
    {
      "region": "Al Biqā‘",
      "code": "LB-BI",
      "type": "Governorate"
    },
    {
      "region": "Al Janūb",
      "code": "LB-JA",
      "type": "Governorate"
    },
    {
      "region": "An Nabaţīyah",
      "code": "LB-NA",
      "type": "Governorate"
    },
    {
      "region": "Ash Shimāl",
      "code": "LB-AS",
      "type": "Governorate"
    },
    {
      "region": "Baalbek-Hermel",
      "code": "LB-BH",
      "type": "Governorate"
    },
    {
      "region": "Bayrūt",
      "code": "LB-BA",
      "type": "Governorate"
    },
    {
      "region": "Jabal Lubnān",
      "code": "LB-JL",
      "type": "Governorate"
    }
  ],
  "LC": [
    {
      "region": "Anse la Raye",
      "code": "LC-01",
      "type": "District"
    },
    {
      "region": "Canaries",
      "code": "LC-12",
      "type": "District"
    },
    {
      "region": "Castries",
      "code": "LC-02",
      "type": "District"
    },
    {
      "region": "Choiseul",
      "code": "LC-03",
      "type": "District"
    },
    {
      "region": "Dennery",
      "code": "LC-05",
      "type": "District"
    },
    {
      "region": "Gros Islet",
      "code": "LC-06",
      "type": "District"
    },
    {
      "region": "Laborie",
      "code": "LC-07",
      "type": "District"
    },
    {
      "region": "Micoud",
      "code": "LC-08",
      "type": "District"
    },
    {
      "region": "Soufrière",
      "code": "LC-10",
      "type": "District"
    },
    {
      "region": "Vieux Fort",
      "code": "LC-11",
      "type": "District"
    }
  ],
  "LI": [
    {
      "region": "Balzers",
      "code": "LI-01",
      "type": "Commune"
    },
    {
      "region": "Eschen",
      "code": "LI-02",
      "type": "Commune"
    },
    {
      "region": "Gamprin",
      "code": "LI-03",
      "type": "Commune"
    },
    {
      "region": "Mauren",
      "code": "LI-04",
      "type": "Commune"
    },
    {
      "region": "Planken",
      "code": "LI-05",
      "type": "Commune"
    },
    {
      "region": "Ruggell",
      "code": "LI-06",
      "type": "Commune"
    },
    {
      "region": "Schaan",
      "code": "LI-07",
      "type": "Commune"
    },
    {
      "region": "Schellenberg",
      "code": "LI-08",
      "type": "Commune"
    },
    {
      "region": "Triesen",
      "code": "LI-09",
      "type": "Commune"
    },
    {
      "region": "Triesenberg",
      "code": "LI-10",
      "type": "Commune"
    },
    {
      "region": "Vaduz",
      "code": "LI-11",
      "type": "Commune"
    }
  ],
  "LK": [
    {
      "region": "Central Province",
      "code": "LK-2",
      "type": "Province"
    },
    {
      "region": "Eastern Province",
      "code": "LK-5",
      "type": "Province"
    },
    {
      "region": "North Central Province",
      "code": "LK-7",
      "type": "Province"
    },
    {
      "region": "North Western Province",
      "code": "LK-6",
      "type": "Province"
    },
    {
      "region": "Northern Province",
      "code": "LK-4",
      "type": "Province"
    },
    {
      "region": "Sabaragamuwa Province",
      "code": "LK-9",
      "type": "Province"
    },
    {
      "region": "Southern Province",
      "code": "LK-3",
      "type": "Province"
    },
    {
      "region": "Uva Province",
      "code": "LK-8",
      "type": "Province"
    },
    {
      "region": "Western Province",
      "code": "LK-1",
      "type": "Province"
    }
  ],
  "LR": [
    {
      "region": "Bomi",
      "code": "LR-BM",
      "type": "County"
    },
    {
      "region": "Bong",
      "code": "LR-BG",
      "type": "County"
    },
    {
      "region": "Gbarpolu",
      "code": "LR-GP",
      "type": "County"
    },
    {
      "region": "Grand Bassa",
      "code": "LR-GB",
      "type": "County"
    },
    {
      "region": "Grand Cape Mount",
      "code": "LR-CM",
      "type": "County"
    },
    {
      "region": "Grand Gedeh",
      "code": "LR-GG",
      "type": "County"
    },
    {
      "region": "Grand Kru",
      "code": "LR-GK",
      "type": "County"
    },
    {
      "region": "Lofa",
      "code": "LR-LO",
      "type": "County"
    },
    {
      "region": "Margibi",
      "code": "LR-MG",
      "type": "County"
    },
    {
      "region": "Maryland",
      "code": "LR-MY",
      "type": "County"
    },
    {
      "region": "Montserrado",
      "code": "LR-MO",
      "type": "County"
    },
    {
      "region": "Nimba",
      "code": "LR-NI",
      "type": "County"
    },
    {
      "region": "River Cess",
      "code": "LR-RI",
      "type": "County"
    },
    {
      "region": "River Gee",
      "code": "LR-RG",
      "type": "County"
    },
    {
      "region": "Sinoe",
      "code": "LR-SI",
      "type": "County"
    }
  ],
  "LS": [
    {
      "region": "Berea",
      "code": "LS-D",
      "type": "District"
    },
    {
      "region": "Botha-Bothe",
      "code": "LS-B",
      "type": "District"
    },
    {
      "region": "Leribe",
      "code": "LS-C",
      "type": "District"
    },
    {
      "region": "Mafeteng",
      "code": "LS-E",
      "type": "District"
    },
    {
      "region": "Maseru",
      "code": "LS-A",
      "type": "District"
    },
    {
      "region": "Mohale's Hoek",
      "code": "LS-F",
      "type": "District"
    },
    {
      "region": "Mokhotlong",
      "code": "LS-J",
      "type": "District"
    },
    {
      "region": "Qacha's Nek",
      "code": "LS-H",
      "type": "District"
    },
    {
      "region": "Quthing",
      "code": "LS-G",
      "type": "District"
    },
    {
      "region": "Thaba-Tseka",
      "code": "LS-K",
      "type": "District"
    }
  ],
  "LT": [
    {
      "region": "Akmenė",
      "code": "LT-01",
      "type": "District municipality"
    },
    {
      "region": "Alytaus apskritis",
      "code": "LT-AL",
      "type": "County"
    },
    {
      "region": "Alytaus miestas",
      "code": "LT-02",
      "type": "City municipality"
    },
    {
      "region": "Alytus",
      "code": "LT-03",
      "type": "District municipality"
    },
    {
      "region": "Anykščiai",
      "code": "LT-04",
      "type": "District municipality"
    },
    {
      "region": "Birštono",
      "code": "LT-05",
      "type": "Municipality"
    },
    {
      "region": "Biržai",
      "code": "LT-06",
      "type": "District municipality"
    },
    {
      "region": "Druskininkai",
      "code": "LT-07",
      "type": "Municipality"
    },
    {
      "region": "Elektrėnai",
      "code": "LT-08",
      "type": "Municipality"
    },
    {
      "region": "Ignalina",
      "code": "LT-09",
      "type": "District municipality"
    },
    {
      "region": "Jonava",
      "code": "LT-10",
      "type": "District municipality"
    },
    {
      "region": "Joniškis",
      "code": "LT-11",
      "type": "District municipality"
    },
    {
      "region": "Jurbarkas",
      "code": "LT-12",
      "type": "District municipality"
    },
    {
      "region": "Kaišiadorys",
      "code": "LT-13",
      "type": "District municipality"
    },
    {
      "region": "Kalvarijos",
      "code": "LT-14",
      "type": "Municipality"
    },
    {
      "region": "Kaunas",
      "code": "LT-16",
      "type": "District municipality"
    },
    {
      "region": "Kauno apskritis",
      "code": "LT-KU",
      "type": "County"
    },
    {
      "region": "Kauno miestas",
      "code": "LT-15",
      "type": "City municipality"
    },
    {
      "region": "Kazlų Rūdos",
      "code": "LT-17",
      "type": "Municipality"
    },
    {
      "region": "Kelmė",
      "code": "LT-19",
      "type": "District municipality"
    },
    {
      "region": "Klaipėda",
      "code": "LT-21",
      "type": "District municipality"
    },
    {
      "region": "Klaipėdos apskritis",
      "code": "LT-KL",
      "type": "County"
    },
    {
      "region": "Klaipėdos miestas",
      "code": "LT-20",
      "type": "City municipality"
    },
    {
      "region": "Kretinga",
      "code": "LT-22",
      "type": "District municipality"
    },
    {
      "region": "Kupiškis",
      "code": "LT-23",
      "type": "District municipality"
    },
    {
      "region": "Kėdainiai",
      "code": "LT-18",
      "type": "District municipality"
    },
    {
      "region": "Lazdijai",
      "code": "LT-24",
      "type": "District municipality"
    },
    {
      "region": "Marijampolė",
      "code": "LT-25",
      "type": "District municipality"
    },
    {
      "region": "Marijampolės apskritis",
      "code": "LT-MR",
      "type": "County"
    },
    {
      "region": "Mažeikiai",
      "code": "LT-26",
      "type": "District municipality"
    },
    {
      "region": "Molėtai",
      "code": "LT-27",
      "type": "District municipality"
    },
    {
      "region": "Neringa",
      "code": "LT-28",
      "type": "Municipality"
    },
    {
      "region": "Pagėgiai",
      "code": "LT-29",
      "type": "Municipality"
    },
    {
      "region": "Pakruojis",
      "code": "LT-30",
      "type": "District municipality"
    },
    {
      "region": "Palangos miestas",
      "code": "LT-31",
      "type": "City municipality"
    },
    {
      "region": "Panevėžio apskritis",
      "code": "LT-PN",
      "type": "County"
    },
    {
      "region": "Panevėžio miestas",
      "code": "LT-32",
      "type": "City municipality"
    },
    {
      "region": "Panevėžys",
      "code": "LT-33",
      "type": "District municipality"
    },
    {
      "region": "Pasvalys",
      "code": "LT-34",
      "type": "District municipality"
    },
    {
      "region": "Plungė",
      "code": "LT-35",
      "type": "District municipality"
    },
    {
      "region": "Prienai",
      "code": "LT-36",
      "type": "District municipality"
    },
    {
      "region": "Radviliškis",
      "code": "LT-37",
      "type": "District municipality"
    },
    {
      "region": "Raseiniai",
      "code": "LT-38",
      "type": "District municipality"
    },
    {
      "region": "Rietavo",
      "code": "LT-39",
      "type": "Municipality"
    },
    {
      "region": "Rokiškis",
      "code": "LT-40",
      "type": "District municipality"
    },
    {
      "region": "Skuodas",
      "code": "LT-48",
      "type": "District municipality"
    },
    {
      "region": "Tauragė",
      "code": "LT-50",
      "type": "District municipality"
    },
    {
      "region": "Tauragės apskritis",
      "code": "LT-TA",
      "type": "County"
    },
    {
      "region": "Telšiai",
      "code": "LT-51",
      "type": "District municipality"
    },
    {
      "region": "Telšių apskritis",
      "code": "LT-TE",
      "type": "County"
    },
    {
      "region": "Trakai",
      "code": "LT-52",
      "type": "District municipality"
    },
    {
      "region": "Ukmergė",
      "code": "LT-53",
      "type": "District municipality"
    },
    {
      "region": "Utena",
      "code": "LT-54",
      "type": "District municipality"
    },
    {
      "region": "Utenos apskritis",
      "code": "LT-UT",
      "type": "County"
    },
    {
      "region": "Varėna",
      "code": "LT-55",
      "type": "District municipality"
    },
    {
      "region": "Vilkaviškis",
      "code": "LT-56",
      "type": "District municipality"
    },
    {
      "region": "Vilniaus apskritis",
      "code": "LT-VL",
      "type": "County"
    },
    {
      "region": "Vilniaus miestas",
      "code": "LT-57",
      "type": "City municipality"
    },
    {
      "region": "Vilnius",
      "code": "LT-58",
      "type": "District municipality"
    },
    {
      "region": "Visaginas",
      "code": "LT-59",
      "type": "Municipality"
    },
    {
      "region": "Zarasai",
      "code": "LT-60",
      "type": "District municipality"
    },
    {
      "region": "Šakiai",
      "code": "LT-41",
      "type": "District municipality"
    },
    {
      "region": "Šalčininkai",
      "code": "LT-42",
      "type": "District municipality"
    },
    {
      "region": "Šiauliai",
      "code": "LT-44",
      "type": "District municipality"
    },
    {
      "region": "Šiaulių apskritis",
      "code": "LT-SA",
      "type": "County"
    },
    {
      "region": "Šiaulių miestas",
      "code": "LT-43",
      "type": "City municipality"
    },
    {
      "region": "Šilalė",
      "code": "LT-45",
      "type": "District municipality"
    },
    {
      "region": "Šilutė",
      "code": "LT-46",
      "type": "District municipality"
    },
    {
      "region": "Širvintos",
      "code": "LT-47",
      "type": "District municipality"
    },
    {
      "region": "Švenčionys",
      "code": "LT-49",
      "type": "District municipality"
    }
  ],
  "LU": [
    {
      "region": "Capellen",
      "code": "LU-CA",
      "type": "Canton"
    },
    {
      "region": "Clerf",
      "code": "LU-CL",
      "type": "Canton"
    },
    {
      "region": "Diekirch",
      "code": "LU-DI",
      "type": "Canton"
    },
    {
      "region": "Echternach",
      "code": "LU-EC",
      "type": "Canton"
    },
    {
      "region": "Esch an der Alzette",
      "code": "LU-ES",
      "type": "Canton"
    },
    {
      "region": "Grevenmacher",
      "code": "LU-GR",
      "type": "Canton"
    },
    {
      "region": "Luxembourg",
      "code": "LU-LU",
      "type": "Canton"
    },
    {
      "region": "Mersch",
      "code": "LU-ME",
      "type": "Canton"
    },
    {
      "region": "Redange",
      "code": "LU-RD",
      "type": "Canton"
    },
    {
      "region": "Remich",
      "code": "LU-RM",
      "type": "Canton"
    },
    {
      "region": "Veianen",
      "code": "LU-VD",
      "type": "Canton"
    },
    {
      "region": "Wiltz",
      "code": "LU-WI",
      "type": "Canton"
    }
  ],
  "LV": [
    {
      "region": "Aglonas novads",
      "code": "LV-001",
      "type": "Municipality"
    },
    {
      "region": "Aizkraukles novads",
      "code": "LV-002",
      "type": "Municipality"
    },
    {
      "region": "Aizputes novads",
      "code": "LV-003",
      "type": "Municipality"
    },
    {
      "region": "Aknīstes novads",
      "code": "LV-004",
      "type": "Municipality"
    },
    {
      "region": "Alojas novads",
      "code": "LV-005",
      "type": "Municipality"
    },
    {
      "region": "Alsungas novads",
      "code": "LV-006",
      "type": "Municipality"
    },
    {
      "region": "Alūksnes novads",
      "code": "LV-007",
      "type": "Municipality"
    },
    {
      "region": "Amatas novads",
      "code": "LV-008",
      "type": "Municipality"
    },
    {
      "region": "Apes novads",
      "code": "LV-009",
      "type": "Municipality"
    },
    {
      "region": "Auces novads",
      "code": "LV-010",
      "type": "Municipality"
    },
    {
      "region": "Babītes novads",
      "code": "LV-012",
      "type": "Municipality"
    },
    {
      "region": "Baldones novads",
      "code": "LV-013",
      "type": "Municipality"
    },
    {
      "region": "Baltinavas novads",
      "code": "LV-014",
      "type": "Municipality"
    },
    {
      "region": "Balvu novads",
      "code": "LV-015",
      "type": "Municipality"
    },
    {
      "region": "Bauskas novads",
      "code": "LV-016",
      "type": "Municipality"
    },
    {
      "region": "Beverīnas novads",
      "code": "LV-017",
      "type": "Municipality"
    },
    {
      "region": "Brocēnu novads",
      "code": "LV-018",
      "type": "Municipality"
    },
    {
      "region": "Burtnieku novads",
      "code": "LV-019",
      "type": "Municipality"
    },
    {
      "region": "Carnikavas novads",
      "code": "LV-020",
      "type": "Municipality"
    },
    {
      "region": "Cesvaines novads",
      "code": "LV-021",
      "type": "Municipality"
    },
    {
      "region": "Ciblas novads",
      "code": "LV-023",
      "type": "Municipality"
    },
    {
      "region": "Cēsu novads",
      "code": "LV-022",
      "type": "Municipality"
    },
    {
      "region": "Dagdas novads",
      "code": "LV-024",
      "type": "Municipality"
    },
    {
      "region": "Daugavpils",
      "code": "LV-DGV",
      "type": "Republican city"
    },
    {
      "region": "Daugavpils novads",
      "code": "LV-025",
      "type": "Municipality"
    },
    {
      "region": "Dobeles novads",
      "code": "LV-026",
      "type": "Municipality"
    },
    {
      "region": "Dundagas novads",
      "code": "LV-027",
      "type": "Municipality"
    },
    {
      "region": "Durbes novads",
      "code": "LV-028",
      "type": "Municipality"
    },
    {
      "region": "Engures novads",
      "code": "LV-029",
      "type": "Municipality"
    },
    {
      "region": "Garkalnes novads",
      "code": "LV-031",
      "type": "Municipality"
    },
    {
      "region": "Grobiņas novads",
      "code": "LV-032",
      "type": "Municipality"
    },
    {
      "region": "Gulbenes novads",
      "code": "LV-033",
      "type": "Municipality"
    },
    {
      "region": "Iecavas novads",
      "code": "LV-034",
      "type": "Municipality"
    },
    {
      "region": "Ikšķiles novads",
      "code": "LV-035",
      "type": "Municipality"
    },
    {
      "region": "Ilūkstes novads",
      "code": "LV-036",
      "type": "Municipality"
    },
    {
      "region": "Inčukalna novads",
      "code": "LV-037",
      "type": "Municipality"
    },
    {
      "region": "Jaunjelgavas novads",
      "code": "LV-038",
      "type": "Municipality"
    },
    {
      "region": "Jaunpiebalgas novads",
      "code": "LV-039",
      "type": "Municipality"
    },
    {
      "region": "Jaunpils novads",
      "code": "LV-040",
      "type": "Municipality"
    },
    {
      "region": "Jelgava",
      "code": "LV-JEL",
      "type": "Republican city"
    },
    {
      "region": "Jelgavas novads",
      "code": "LV-041",
      "type": "Municipality"
    },
    {
      "region": "Jēkabpils",
      "code": "LV-JKB",
      "type": "Republican city"
    },
    {
      "region": "Jēkabpils novads",
      "code": "LV-042",
      "type": "Municipality"
    },
    {
      "region": "Jūrmala",
      "code": "LV-JUR",
      "type": "Republican city"
    },
    {
      "region": "Kandavas novads",
      "code": "LV-043",
      "type": "Municipality"
    },
    {
      "region": "Kocēnu novads",
      "code": "LV-045",
      "type": "Municipality"
    },
    {
      "region": "Kokneses novads",
      "code": "LV-046",
      "type": "Municipality"
    },
    {
      "region": "Krimuldas novads",
      "code": "LV-048",
      "type": "Municipality"
    },
    {
      "region": "Krustpils novads",
      "code": "LV-049",
      "type": "Municipality"
    },
    {
      "region": "Krāslavas novads",
      "code": "LV-047",
      "type": "Municipality"
    },
    {
      "region": "Kuldīgas novads",
      "code": "LV-050",
      "type": "Municipality"
    },
    {
      "region": "Kārsavas novads",
      "code": "LV-044",
      "type": "Municipality"
    },
    {
      "region": "Lielvārdes novads",
      "code": "LV-053",
      "type": "Municipality"
    },
    {
      "region": "Liepāja",
      "code": "LV-LPX",
      "type": "Republican city"
    },
    {
      "region": "Limbažu novads",
      "code": "LV-054",
      "type": "Municipality"
    },
    {
      "region": "Lubānas novads",
      "code": "LV-057",
      "type": "Municipality"
    },
    {
      "region": "Ludzas novads",
      "code": "LV-058",
      "type": "Municipality"
    },
    {
      "region": "Līgatnes novads",
      "code": "LV-055",
      "type": "Municipality"
    },
    {
      "region": "Līvānu novads",
      "code": "LV-056",
      "type": "Municipality"
    },
    {
      "region": "Madonas novads",
      "code": "LV-059",
      "type": "Municipality"
    },
    {
      "region": "Mazsalacas novads",
      "code": "LV-060",
      "type": "Municipality"
    },
    {
      "region": "Mālpils novads",
      "code": "LV-061",
      "type": "Municipality"
    },
    {
      "region": "Mārupes novads",
      "code": "LV-062",
      "type": "Municipality"
    },
    {
      "region": "Mērsraga novads",
      "code": "LV-063",
      "type": "Municipality"
    },
    {
      "region": "Naukšēnu novads",
      "code": "LV-064",
      "type": "Municipality"
    },
    {
      "region": "Neretas novads",
      "code": "LV-065",
      "type": "Municipality"
    },
    {
      "region": "Nīcas novads",
      "code": "LV-066",
      "type": "Municipality"
    },
    {
      "region": "Ogres novads",
      "code": "LV-067",
      "type": "Municipality"
    },
    {
      "region": "Olaines novads",
      "code": "LV-068",
      "type": "Municipality"
    },
    {
      "region": "Ozolnieku novads",
      "code": "LV-069",
      "type": "Municipality"
    },
    {
      "region": "Preiļu novads",
      "code": "LV-073",
      "type": "Municipality"
    },
    {
      "region": "Priekules novads",
      "code": "LV-074",
      "type": "Municipality"
    },
    {
      "region": "Priekuļu novads",
      "code": "LV-075",
      "type": "Municipality"
    },
    {
      "region": "Pārgaujas novads",
      "code": "LV-070",
      "type": "Municipality"
    },
    {
      "region": "Pāvilostas novads",
      "code": "LV-071",
      "type": "Municipality"
    },
    {
      "region": "Pļaviņu novads",
      "code": "LV-072",
      "type": "Municipality"
    },
    {
      "region": "Raunas novads",
      "code": "LV-076",
      "type": "Municipality"
    },
    {
      "region": "Riebiņu novads",
      "code": "LV-078",
      "type": "Municipality"
    },
    {
      "region": "Rojas novads",
      "code": "LV-079",
      "type": "Municipality"
    },
    {
      "region": "Ropažu novads",
      "code": "LV-080",
      "type": "Municipality"
    },
    {
      "region": "Rucavas novads",
      "code": "LV-081",
      "type": "Municipality"
    },
    {
      "region": "Rugāju novads",
      "code": "LV-082",
      "type": "Municipality"
    },
    {
      "region": "Rundāles novads",
      "code": "LV-083",
      "type": "Municipality"
    },
    {
      "region": "Rēzekne",
      "code": "LV-REZ",
      "type": "Republican city"
    },
    {
      "region": "Rēzeknes novads",
      "code": "LV-077",
      "type": "Municipality"
    },
    {
      "region": "Rīga",
      "code": "LV-RIX",
      "type": "Republican city"
    },
    {
      "region": "Rūjienas novads",
      "code": "LV-084",
      "type": "Municipality"
    },
    {
      "region": "Salacgrīvas novads",
      "code": "LV-086",
      "type": "Municipality"
    },
    {
      "region": "Salas novads",
      "code": "LV-085",
      "type": "Municipality"
    },
    {
      "region": "Salaspils novads",
      "code": "LV-087",
      "type": "Municipality"
    },
    {
      "region": "Saldus novads",
      "code": "LV-088",
      "type": "Municipality"
    },
    {
      "region": "Saulkrastu novads",
      "code": "LV-089",
      "type": "Municipality"
    },
    {
      "region": "Siguldas novads",
      "code": "LV-091",
      "type": "Municipality"
    },
    {
      "region": "Skrundas novads",
      "code": "LV-093",
      "type": "Municipality"
    },
    {
      "region": "Skrīveru novads",
      "code": "LV-092",
      "type": "Municipality"
    },
    {
      "region": "Smiltenes novads",
      "code": "LV-094",
      "type": "Municipality"
    },
    {
      "region": "Stopiņu novads",
      "code": "LV-095",
      "type": "Municipality"
    },
    {
      "region": "Strenču novads",
      "code": "LV-096",
      "type": "Municipality"
    },
    {
      "region": "Sējas novads",
      "code": "LV-090",
      "type": "Municipality"
    },
    {
      "region": "Talsu novads",
      "code": "LV-097",
      "type": "Municipality"
    },
    {
      "region": "Tukuma novads",
      "code": "LV-099",
      "type": "Municipality"
    },
    {
      "region": "Tērvetes novads",
      "code": "LV-098",
      "type": "Municipality"
    },
    {
      "region": "Vaiņodes novads",
      "code": "LV-100",
      "type": "Municipality"
    },
    {
      "region": "Valkas novads",
      "code": "LV-101",
      "type": "Municipality"
    },
    {
      "region": "Valmiera",
      "code": "LV-VMR",
      "type": "Republican city"
    },
    {
      "region": "Varakļānu novads",
      "code": "LV-102",
      "type": "Municipality"
    },
    {
      "region": "Vecpiebalgas novads",
      "code": "LV-104",
      "type": "Municipality"
    },
    {
      "region": "Vecumnieku novads",
      "code": "LV-105",
      "type": "Municipality"
    },
    {
      "region": "Ventspils",
      "code": "LV-VEN",
      "type": "Republican city"
    },
    {
      "region": "Ventspils novads",
      "code": "LV-106",
      "type": "Municipality"
    },
    {
      "region": "Viesītes novads",
      "code": "LV-107",
      "type": "Municipality"
    },
    {
      "region": "Viļakas novads",
      "code": "LV-108",
      "type": "Municipality"
    },
    {
      "region": "Viļānu novads",
      "code": "LV-109",
      "type": "Municipality"
    },
    {
      "region": "Vārkavas novads",
      "code": "LV-103",
      "type": "Municipality"
    },
    {
      "region": "Zilupes novads",
      "code": "LV-110",
      "type": "Municipality"
    },
    {
      "region": "Ādažu novads",
      "code": "LV-011",
      "type": "Municipality"
    },
    {
      "region": "Ērgļu novads",
      "code": "LV-030",
      "type": "Municipality"
    },
    {
      "region": "Ķeguma novads",
      "code": "LV-051",
      "type": "Municipality"
    },
    {
      "region": "Ķekavas novads",
      "code": "LV-052",
      "type": "Municipality"
    }
  ],
  "LY": [
    {
      "region": "Al Buţnān",
      "code": "LY-BU",
      "type": "Popularate"
    },
    {
      "region": "Al Jabal al Akhḑar",
      "code": "LY-JA",
      "type": "Popularate"
    },
    {
      "region": "Al Jabal al Gharbī",
      "code": "LY-JG",
      "type": "Popularate"
    },
    {
      "region": "Al Jafārah",
      "code": "LY-JI",
      "type": "Popularate"
    },
    {
      "region": "Al Jufrah",
      "code": "LY-JU",
      "type": "Popularate"
    },
    {
      "region": "Al Kufrah",
      "code": "LY-KF",
      "type": "Popularate"
    },
    {
      "region": "Al Marj",
      "code": "LY-MJ",
      "type": "Popularate"
    },
    {
      "region": "Al Marqab",
      "code": "LY-MB",
      "type": "Popularate"
    },
    {
      "region": "Al Wāḩāt",
      "code": "LY-WA",
      "type": "Popularate"
    },
    {
      "region": "An Nuqāţ al Khams",
      "code": "LY-NQ",
      "type": "Popularate"
    },
    {
      "region": "Az Zāwiyah",
      "code": "LY-ZA",
      "type": "Popularate"
    },
    {
      "region": "Banghāzī",
      "code": "LY-BA",
      "type": "Popularate"
    },
    {
      "region": "Darnah",
      "code": "LY-DR",
      "type": "Popularate"
    },
    {
      "region": "Ghāt",
      "code": "LY-GT",
      "type": "Popularate"
    },
    {
      "region": "Mişrātah",
      "code": "LY-MI",
      "type": "Popularate"
    },
    {
      "region": "Murzuq",
      "code": "LY-MQ",
      "type": "Popularate"
    },
    {
      "region": "Nālūt",
      "code": "LY-NL",
      "type": "Popularate"
    },
    {
      "region": "Sabhā",
      "code": "LY-SB",
      "type": "Popularate"
    },
    {
      "region": "Surt",
      "code": "LY-SR",
      "type": "Popularate"
    },
    {
      "region": "Wādī al Ḩayāt",
      "code": "LY-WD",
      "type": "Popularate"
    },
    {
      "region": "Wādī ash Shāţi’",
      "code": "LY-WS",
      "type": "Popularate"
    },
    {
      "region": "Ţarābulus",
      "code": "LY-TB",
      "type": "Popularate"
    }
  ],
  "MA": [
    {
      "region": "Béni Mellal-Khénifra",
      "code": "MA-05",
      "type": "Region"
    },
    {
      "region": "Casablanca-Settat",
      "code": "MA-06",
      "type": "Region"
    },
    {
      "region": "Dakhla-Oued Ed-Dahab (EH)",
      "code": "MA-12",
      "type": "Region"
    },
    {
      "region": "Drâa-Tafilalet",
      "code": "MA-08",
      "type": "Region"
    },
    {
      "region": "Fès-Meknès",
      "code": "MA-03",
      "type": "Region"
    },
    {
      "region": "Guelmim-Oued Noun (EH-partial)",
      "code": "MA-10",
      "type": "Region"
    },
    {
      "region": "L'Oriental",
      "code": "MA-02",
      "type": "Region"
    },
    {
      "region": "Laâyoune-Sakia El Hamra (EH-partial)",
      "code": "MA-11",
      "type": "Region"
    },
    {
      "region": "Marrakech-Safi",
      "code": "MA-07",
      "type": "Region"
    },
    {
      "region": "Rabat-Salé-Kénitra",
      "code": "MA-04",
      "type": "Region"
    },
    {
      "region": "Souss-Massa",
      "code": "MA-09",
      "type": "Region"
    },
    {
      "region": "Tanger-Tétouan-Al Hoceïma",
      "code": "MA-01",
      "type": "Region"
    }
  ],
  "MC": [
    {
      "region": "Fontvieille",
      "code": "MC-FO",
      "type": "Quarter"
    },
    {
      "region": "Jardin Exotique",
      "code": "MC-JE",
      "type": "Quarter"
    },
    {
      "region": "La Colle",
      "code": "MC-CL",
      "type": "Quarter"
    },
    {
      "region": "La Condamine",
      "code": "MC-CO",
      "type": "Quarter"
    },
    {
      "region": "La Gare",
      "code": "MC-GA",
      "type": "Quarter"
    },
    {
      "region": "La Source",
      "code": "MC-SO",
      "type": "Quarter"
    },
    {
      "region": "Larvotto",
      "code": "MC-LA",
      "type": "Quarter"
    },
    {
      "region": "Malbousquet",
      "code": "MC-MA",
      "type": "Quarter"
    },
    {
      "region": "Monaco-Ville",
      "code": "MC-MO",
      "type": "Quarter"
    },
    {
      "region": "Moneghetti",
      "code": "MC-MG",
      "type": "Quarter"
    },
    {
      "region": "Monte-Carlo",
      "code": "MC-MC",
      "type": "Quarter"
    },
    {
      "region": "Moulins",
      "code": "MC-MU",
      "type": "Quarter"
    },
    {
      "region": "Port-Hercule",
      "code": "MC-PH",
      "type": "Quarter"
    },
    {
      "region": "Saint-Roman",
      "code": "MC-SR",
      "type": "Quarter"
    },
    {
      "region": "Sainte-Dévote",
      "code": "MC-SD",
      "type": "Quarter"
    },
    {
      "region": "Spélugues",
      "code": "MC-SP",
      "type": "Quarter"
    },
    {
      "region": "Vallon de la Rousse",
      "code": "MC-VR",
      "type": "Quarter"
    }
  ],
  "MD": [
    {
      "region": "Anenii Noi",
      "code": "MD-AN",
      "type": "District"
    },
    {
      "region": "Basarabeasca",
      "code": "MD-BS",
      "type": "District"
    },
    {
      "region": "Bender [Tighina]",
      "code": "MD-BD",
      "type": "City"
    },
    {
      "region": "Briceni",
      "code": "MD-BR",
      "type": "District"
    },
    {
      "region": "Bălți",
      "code": "MD-BA",
      "type": "City"
    },
    {
      "region": "Cahul",
      "code": "MD-CA",
      "type": "District"
    },
    {
      "region": "Cantemir",
      "code": "MD-CT",
      "type": "District"
    },
    {
      "region": "Chișinău",
      "code": "MD-CU",
      "type": "City"
    },
    {
      "region": "Cimișlia",
      "code": "MD-CM",
      "type": "District"
    },
    {
      "region": "Criuleni",
      "code": "MD-CR",
      "type": "District"
    },
    {
      "region": "Călărași",
      "code": "MD-CL",
      "type": "District"
    },
    {
      "region": "Căușeni",
      "code": "MD-CS",
      "type": "District"
    },
    {
      "region": "Dondușeni",
      "code": "MD-DO",
      "type": "District"
    },
    {
      "region": "Drochia",
      "code": "MD-DR",
      "type": "District"
    },
    {
      "region": "Dubăsari",
      "code": "MD-DU",
      "type": "District"
    },
    {
      "region": "Edineț",
      "code": "MD-ED",
      "type": "District"
    },
    {
      "region": "Florești",
      "code": "MD-FL",
      "type": "District"
    },
    {
      "region": "Fălești",
      "code": "MD-FA",
      "type": "District"
    },
    {
      "region": "Glodeni",
      "code": "MD-GL",
      "type": "District"
    },
    {
      "region": "Găgăuzia, Unitatea teritorială autonomă (UTAG)",
      "code": "MD-GA",
      "type": "Autonomous territorial unit"
    },
    {
      "region": "Hîncești",
      "code": "MD-HI",
      "type": "District"
    },
    {
      "region": "Ialoveni",
      "code": "MD-IA",
      "type": "District"
    },
    {
      "region": "Leova",
      "code": "MD-LE",
      "type": "District"
    },
    {
      "region": "Nisporeni",
      "code": "MD-NI",
      "type": "District"
    },
    {
      "region": "Ocnița",
      "code": "MD-OC",
      "type": "District"
    },
    {
      "region": "Orhei",
      "code": "MD-OR",
      "type": "District"
    },
    {
      "region": "Rezina",
      "code": "MD-RE",
      "type": "District"
    },
    {
      "region": "Rîșcani",
      "code": "MD-RI",
      "type": "District"
    },
    {
      "region": "Soroca",
      "code": "MD-SO",
      "type": "District"
    },
    {
      "region": "Strășeni",
      "code": "MD-ST",
      "type": "District"
    },
    {
      "region": "Stînga Nistrului, unitatea teritorială din",
      "code": "MD-SN",
      "type": "Territorial unit"
    },
    {
      "region": "Sîngerei",
      "code": "MD-SI",
      "type": "District"
    },
    {
      "region": "Taraclia",
      "code": "MD-TA",
      "type": "District"
    },
    {
      "region": "Telenești",
      "code": "MD-TE",
      "type": "District"
    },
    {
      "region": "Ungheni",
      "code": "MD-UN",
      "type": "District"
    },
    {
      "region": "Șoldănești",
      "code": "MD-SD",
      "type": "District"
    },
    {
      "region": "Ștefan Vodă",
      "code": "MD-SV",
      "type": "District"
    }
  ],
  "ME": [
    {
      "region": "Andrijevica",
      "code": "ME-01",
      "type": "Municipality"
    },
    {
      "region": "Bar",
      "code": "ME-02",
      "type": "Municipality"
    },
    {
      "region": "Berane",
      "code": "ME-03",
      "type": "Municipality"
    },
    {
      "region": "Bijelo Polje",
      "code": "ME-04",
      "type": "Municipality"
    },
    {
      "region": "Budva",
      "code": "ME-05",
      "type": "Municipality"
    },
    {
      "region": "Cetinje",
      "code": "ME-06",
      "type": "Municipality"
    },
    {
      "region": "Danilovgrad",
      "code": "ME-07",
      "type": "Municipality"
    },
    {
      "region": "Gusinje",
      "code": "ME-22",
      "type": "Municipality"
    },
    {
      "region": "Herceg-Novi",
      "code": "ME-08",
      "type": "Municipality"
    },
    {
      "region": "Kolašin",
      "code": "ME-09",
      "type": "Municipality"
    },
    {
      "region": "Kotor",
      "code": "ME-10",
      "type": "Municipality"
    },
    {
      "region": "Mojkovac",
      "code": "ME-11",
      "type": "Municipality"
    },
    {
      "region": "Nikšić",
      "code": "ME-12",
      "type": "Municipality"
    },
    {
      "region": "Petnjica",
      "code": "ME-23",
      "type": "Municipality"
    },
    {
      "region": "Plav",
      "code": "ME-13",
      "type": "Municipality"
    },
    {
      "region": "Pljevlja",
      "code": "ME-14",
      "type": "Municipality"
    },
    {
      "region": "Plužine",
      "code": "ME-15",
      "type": "Municipality"
    },
    {
      "region": "Podgorica",
      "code": "ME-16",
      "type": "Municipality"
    },
    {
      "region": "Rožaje",
      "code": "ME-17",
      "type": "Municipality"
    },
    {
      "region": "Tivat",
      "code": "ME-19",
      "type": "Municipality"
    },
    {
      "region": "Tuzi",
      "code": "ME-24",
      "type": "Municipality"
    },
    {
      "region": "Ulcinj",
      "code": "ME-20",
      "type": "Municipality"
    },
    {
      "region": "Šavnik",
      "code": "ME-18",
      "type": "Municipality"
    },
    {
      "region": "Žabljak",
      "code": "ME-21",
      "type": "Municipality"
    }
  ],
  "MG": [
    {
      "region": "Antananarivo",
      "code": "MG-T",
      "type": "Province"
    },
    {
      "region": "Antsiranana",
      "code": "MG-D",
      "type": "Province"
    },
    {
      "region": "Fianarantsoa",
      "code": "MG-F",
      "type": "Province"
    },
    {
      "region": "Mahajanga",
      "code": "MG-M",
      "type": "Province"
    },
    {
      "region": "Toamasina",
      "code": "MG-A",
      "type": "Province"
    },
    {
      "region": "Toliara",
      "code": "MG-U",
      "type": "Province"
    }
  ],
  "MH": [
    {
      "region": "Ralik chain",
      "code": "MH-L",
      "type": "Chain (of islands)"
    },
    {
      "region": "Ratak chain",
      "code": "MH-T",
      "type": "Chain (of islands)"
    }
  ],
  "MK": [
    {
      "region": "Aerodrom †",
      "code": "MK-801",
      "type": "Municipality"
    },
    {
      "region": "Aračinovo",
      "code": "MK-802",
      "type": "Municipality"
    },
    {
      "region": "Berovo",
      "code": "MK-201",
      "type": "Municipality"
    },
    {
      "region": "Bitola",
      "code": "MK-501",
      "type": "Municipality"
    },
    {
      "region": "Bogdanci",
      "code": "MK-401",
      "type": "Municipality"
    },
    {
      "region": "Bogovinje",
      "code": "MK-601",
      "type": "Municipality"
    },
    {
      "region": "Bosilovo",
      "code": "MK-402",
      "type": "Municipality"
    },
    {
      "region": "Brvenica",
      "code": "MK-602",
      "type": "Municipality"
    },
    {
      "region": "Butel †",
      "code": "MK-803",
      "type": "Municipality"
    },
    {
      "region": "Centar Župa",
      "code": "MK-313",
      "type": "Municipality"
    },
    {
      "region": "Centar †",
      "code": "MK-814",
      "type": "Municipality"
    },
    {
      "region": "Debar",
      "code": "MK-303",
      "type": "Municipality"
    },
    {
      "region": "Debrca",
      "code": "MK-304",
      "type": "Municipality"
    },
    {
      "region": "Delčevo",
      "code": "MK-203",
      "type": "Municipality"
    },
    {
      "region": "Demir Hisar",
      "code": "MK-502",
      "type": "Municipality"
    },
    {
      "region": "Demir Kapija",
      "code": "MK-103",
      "type": "Municipality"
    },
    {
      "region": "Dojran",
      "code": "MK-406",
      "type": "Municipality"
    },
    {
      "region": "Dolneni",
      "code": "MK-503",
      "type": "Municipality"
    },
    {
      "region": "Gazi Baba †",
      "code": "MK-804",
      "type": "Municipality"
    },
    {
      "region": "Gevgelija",
      "code": "MK-405",
      "type": "Municipality"
    },
    {
      "region": "Gjorče Petrov †",
      "code": "MK-805",
      "type": "Municipality"
    },
    {
      "region": "Gostivar",
      "code": "MK-604",
      "type": "Municipality"
    },
    {
      "region": "Gradsko",
      "code": "MK-102",
      "type": "Municipality"
    },
    {
      "region": "Ilinden",
      "code": "MK-807",
      "type": "Municipality"
    },
    {
      "region": "Jegunovce",
      "code": "MK-606",
      "type": "Municipality"
    },
    {
      "region": "Karbinci",
      "code": "MK-205",
      "type": "Municipality"
    },
    {
      "region": "Karpoš †",
      "code": "MK-808",
      "type": "Municipality"
    },
    {
      "region": "Kavadarci",
      "code": "MK-104",
      "type": "Municipality"
    },
    {
      "region": "Kisela Voda †",
      "code": "MK-809",
      "type": "Municipality"
    },
    {
      "region": "Kičevo",
      "code": "MK-307",
      "type": "Municipality"
    },
    {
      "region": "Konče",
      "code": "MK-407",
      "type": "Municipality"
    },
    {
      "region": "Kočani",
      "code": "MK-206",
      "type": "Municipality"
    },
    {
      "region": "Kratovo",
      "code": "MK-701",
      "type": "Municipality"
    },
    {
      "region": "Kriva Palanka",
      "code": "MK-702",
      "type": "Municipality"
    },
    {
      "region": "Krivogaštani",
      "code": "MK-504",
      "type": "Municipality"
    },
    {
      "region": "Kruševo",
      "code": "MK-505",
      "type": "Municipality"
    },
    {
      "region": "Kumanovo",
      "code": "MK-703",
      "type": "Municipality"
    },
    {
      "region": "Lipkovo",
      "code": "MK-704",
      "type": "Municipality"
    },
    {
      "region": "Lozovo",
      "code": "MK-105",
      "type": "Municipality"
    },
    {
      "region": "Makedonska Kamenica",
      "code": "MK-207",
      "type": "Municipality"
    },
    {
      "region": "Makedonski Brod",
      "code": "MK-308",
      "type": "Municipality"
    },
    {
      "region": "Mavrovo i Rostuše",
      "code": "MK-607",
      "type": "Municipality"
    },
    {
      "region": "Mogila",
      "code": "MK-506",
      "type": "Municipality"
    },
    {
      "region": "Negotino",
      "code": "MK-106",
      "type": "Municipality"
    },
    {
      "region": "Novaci",
      "code": "MK-507",
      "type": "Municipality"
    },
    {
      "region": "Novo Selo",
      "code": "MK-408",
      "type": "Municipality"
    },
    {
      "region": "Ohrid",
      "code": "MK-310",
      "type": "Municipality"
    },
    {
      "region": "Pehčevo",
      "code": "MK-208",
      "type": "Municipality"
    },
    {
      "region": "Petrovec",
      "code": "MK-810",
      "type": "Municipality"
    },
    {
      "region": "Plasnica",
      "code": "MK-311",
      "type": "Municipality"
    },
    {
      "region": "Prilep",
      "code": "MK-508",
      "type": "Municipality"
    },
    {
      "region": "Probištip",
      "code": "MK-209",
      "type": "Municipality"
    },
    {
      "region": "Radoviš",
      "code": "MK-409",
      "type": "Municipality"
    },
    {
      "region": "Rankovce",
      "code": "MK-705",
      "type": "Municipality"
    },
    {
      "region": "Resen",
      "code": "MK-509",
      "type": "Municipality"
    },
    {
      "region": "Rosoman",
      "code": "MK-107",
      "type": "Municipality"
    },
    {
      "region": "Saraj †",
      "code": "MK-811",
      "type": "Municipality"
    },
    {
      "region": "Sopište",
      "code": "MK-812",
      "type": "Municipality"
    },
    {
      "region": "Staro Nagoričane",
      "code": "MK-706",
      "type": "Municipality"
    },
    {
      "region": "Struga",
      "code": "MK-312",
      "type": "Municipality"
    },
    {
      "region": "Strumica",
      "code": "MK-410",
      "type": "Municipality"
    },
    {
      "region": "Studeničani",
      "code": "MK-813",
      "type": "Municipality"
    },
    {
      "region": "Sveti Nikole",
      "code": "MK-108",
      "type": "Municipality"
    },
    {
      "region": "Tearce",
      "code": "MK-608",
      "type": "Municipality"
    },
    {
      "region": "Tetovo",
      "code": "MK-609",
      "type": "Municipality"
    },
    {
      "region": "Valandovo",
      "code": "MK-403",
      "type": "Municipality"
    },
    {
      "region": "Vasilevo",
      "code": "MK-404",
      "type": "Municipality"
    },
    {
      "region": "Veles",
      "code": "MK-101",
      "type": "Municipality"
    },
    {
      "region": "Vevčani",
      "code": "MK-301",
      "type": "Municipality"
    },
    {
      "region": "Vinica",
      "code": "MK-202",
      "type": "Municipality"
    },
    {
      "region": "Vrapčište",
      "code": "MK-603",
      "type": "Municipality"
    },
    {
      "region": "Zelenikovo",
      "code": "MK-806",
      "type": "Municipality"
    },
    {
      "region": "Zrnovci",
      "code": "MK-204",
      "type": "Municipality"
    },
    {
      "region": "Čair †",
      "code": "MK-815",
      "type": "Municipality"
    },
    {
      "region": "Čaška",
      "code": "MK-109",
      "type": "Municipality"
    },
    {
      "region": "Češinovo-Obleševo",
      "code": "MK-210",
      "type": "Municipality"
    },
    {
      "region": "Čučer-Sandevo",
      "code": "MK-816",
      "type": "Municipality"
    },
    {
      "region": "Štip",
      "code": "MK-211",
      "type": "Municipality"
    },
    {
      "region": "Šuto Orizari †",
      "code": "MK-817",
      "type": "Municipality"
    },
    {
      "region": "Želino",
      "code": "MK-605",
      "type": "Municipality"
    }
  ],
  "ML": [
    {
      "region": "Bamako",
      "code": "ML-BKO",
      "type": "District"
    },
    {
      "region": "Gao",
      "code": "ML-7",
      "type": "Region"
    },
    {
      "region": "Kayes",
      "code": "ML-1",
      "type": "Region"
    },
    {
      "region": "Kidal",
      "code": "ML-8",
      "type": "Region"
    },
    {
      "region": "Koulikoro",
      "code": "ML-2",
      "type": "Region"
    },
    {
      "region": "Mopti",
      "code": "ML-5",
      "type": "Region"
    },
    {
      "region": "Ménaka",
      "code": "ML-9",
      "type": "Region"
    },
    {
      "region": "Sikasso",
      "code": "ML-3",
      "type": "Region"
    },
    {
      "region": "Ségou",
      "code": "ML-4",
      "type": "Region"
    },
    {
      "region": "Taoudénit",
      "code": "ML-10",
      "type": "Region"
    },
    {
      "region": "Tombouctou",
      "code": "ML-6",
      "type": "Region"
    }
  ],
  "MM": [
    {
      "region": "Ayeyarwady",
      "code": "MM-07",
      "type": "Region"
    },
    {
      "region": "Bago",
      "code": "MM-02",
      "type": "Region"
    },
    {
      "region": "Chin",
      "code": "MM-14",
      "type": "State"
    },
    {
      "region": "Kachin",
      "code": "MM-11",
      "type": "State"
    },
    {
      "region": "Kayah",
      "code": "MM-12",
      "type": "State"
    },
    {
      "region": "Kayin",
      "code": "MM-13",
      "type": "State"
    },
    {
      "region": "Magway",
      "code": "MM-03",
      "type": "Region"
    },
    {
      "region": "Mandalay",
      "code": "MM-04",
      "type": "Region"
    },
    {
      "region": "Mon",
      "code": "MM-15",
      "type": "State"
    },
    {
      "region": "Nay Pyi Taw",
      "code": "MM-18",
      "type": "Union territory"
    },
    {
      "region": "Rakhine",
      "code": "MM-16",
      "type": "State"
    },
    {
      "region": "Sagaing",
      "code": "MM-01",
      "type": "Region"
    },
    {
      "region": "Shan",
      "code": "MM-17",
      "type": "State"
    },
    {
      "region": "Tanintharyi",
      "code": "MM-05",
      "type": "Region"
    },
    {
      "region": "Yangon",
      "code": "MM-06",
      "type": "Region"
    }
  ],
  "MN": [
    {
      "region": "Arhangay",
      "code": "MN-073",
      "type": "Province"
    },
    {
      "region": "Bayan-Ölgiy",
      "code": "MN-071",
      "type": "Province"
    },
    {
      "region": "Bayanhongor",
      "code": "MN-069",
      "type": "Province"
    },
    {
      "region": "Bulgan",
      "code": "MN-067",
      "type": "Province"
    },
    {
      "region": "Darhan uul",
      "code": "MN-037",
      "type": "Province"
    },
    {
      "region": "Dornod",
      "code": "MN-061",
      "type": "Province"
    },
    {
      "region": "Dornogovĭ",
      "code": "MN-063",
      "type": "Province"
    },
    {
      "region": "Dundgovĭ",
      "code": "MN-059",
      "type": "Province"
    },
    {
      "region": "Dzavhan",
      "code": "MN-057",
      "type": "Province"
    },
    {
      "region": "Govĭ-Altay",
      "code": "MN-065",
      "type": "Province"
    },
    {
      "region": "Govĭ-Sümber",
      "code": "MN-064",
      "type": "Province"
    },
    {
      "region": "Hentiy",
      "code": "MN-039",
      "type": "Province"
    },
    {
      "region": "Hovd",
      "code": "MN-043",
      "type": "Province"
    },
    {
      "region": "Hövsgöl",
      "code": "MN-041",
      "type": "Province"
    },
    {
      "region": "Orhon",
      "code": "MN-035",
      "type": "Province"
    },
    {
      "region": "Selenge",
      "code": "MN-049",
      "type": "Province"
    },
    {
      "region": "Sühbaatar",
      "code": "MN-051",
      "type": "Province"
    },
    {
      "region": "Töv",
      "code": "MN-047",
      "type": "Province"
    },
    {
      "region": "Ulaanbaatar",
      "code": "MN-1",
      "type": "Capital city"
    },
    {
      "region": "Uvs",
      "code": "MN-046",
      "type": "Province"
    },
    {
      "region": "Ömnögovĭ",
      "code": "MN-053",
      "type": "Province"
    },
    {
      "region": "Övörhangay",
      "code": "MN-055",
      "type": "Province"
    }
  ],
  "MR": [
    {
      "region": "Adrar",
      "code": "MR-07",
      "type": "Region"
    },
    {
      "region": "Assaba",
      "code": "MR-03",
      "type": "Region"
    },
    {
      "region": "Brakna",
      "code": "MR-05",
      "type": "Region"
    },
    {
      "region": "Dakhlet Nouâdhibou",
      "code": "MR-08",
      "type": "Region"
    },
    {
      "region": "Gorgol",
      "code": "MR-04",
      "type": "Region"
    },
    {
      "region": "Guidimaka",
      "code": "MR-10",
      "type": "Region"
    },
    {
      "region": "Hodh ech Chargui",
      "code": "MR-01",
      "type": "Region"
    },
    {
      "region": "Hodh el Gharbi",
      "code": "MR-02",
      "type": "Region"
    },
    {
      "region": "Inchiri",
      "code": "MR-12",
      "type": "Region"
    },
    {
      "region": "Nouakchott Nord",
      "code": "MR-14",
      "type": "Region"
    },
    {
      "region": "Nouakchott Ouest",
      "code": "MR-13",
      "type": "Region"
    },
    {
      "region": "Nouakchott Sud",
      "code": "MR-15",
      "type": "Region"
    },
    {
      "region": "Tagant",
      "code": "MR-09",
      "type": "Region"
    },
    {
      "region": "Tiris Zemmour",
      "code": "MR-11",
      "type": "Region"
    },
    {
      "region": "Trarza",
      "code": "MR-06",
      "type": "Region"
    }
  ],
  "MT": [
    {
      "region": "Attard",
      "code": "MT-01",
      "type": "Local council"
    },
    {
      "region": "Balzan",
      "code": "MT-02",
      "type": "Local council"
    },
    {
      "region": "Birgu",
      "code": "MT-03",
      "type": "Local council"
    },
    {
      "region": "Birkirkara",
      "code": "MT-04",
      "type": "Local council"
    },
    {
      "region": "Birżebbuġa",
      "code": "MT-05",
      "type": "Local council"
    },
    {
      "region": "Bormla",
      "code": "MT-06",
      "type": "Local council"
    },
    {
      "region": "Dingli",
      "code": "MT-07",
      "type": "Local council"
    },
    {
      "region": "Fgura",
      "code": "MT-08",
      "type": "Local council"
    },
    {
      "region": "Floriana",
      "code": "MT-09",
      "type": "Local council"
    },
    {
      "region": "Fontana",
      "code": "MT-10",
      "type": "Local council"
    },
    {
      "region": "Gudja",
      "code": "MT-11",
      "type": "Local council"
    },
    {
      "region": "Għajnsielem",
      "code": "MT-13",
      "type": "Local council"
    },
    {
      "region": "Għarb",
      "code": "MT-14",
      "type": "Local council"
    },
    {
      "region": "Għargħur",
      "code": "MT-15",
      "type": "Local council"
    },
    {
      "region": "Għasri",
      "code": "MT-16",
      "type": "Local council"
    },
    {
      "region": "Għaxaq",
      "code": "MT-17",
      "type": "Local council"
    },
    {
      "region": "Gżira",
      "code": "MT-12",
      "type": "Local council"
    },
    {
      "region": "Iklin",
      "code": "MT-19",
      "type": "Local council"
    },
    {
      "region": "Isla",
      "code": "MT-20",
      "type": "Local council"
    },
    {
      "region": "Kalkara",
      "code": "MT-21",
      "type": "Local council"
    },
    {
      "region": "Kerċem",
      "code": "MT-22",
      "type": "Local council"
    },
    {
      "region": "Kirkop",
      "code": "MT-23",
      "type": "Local council"
    },
    {
      "region": "Lija",
      "code": "MT-24",
      "type": "Local council"
    },
    {
      "region": "Luqa",
      "code": "MT-25",
      "type": "Local council"
    },
    {
      "region": "Marsa",
      "code": "MT-26",
      "type": "Local council"
    },
    {
      "region": "Marsaskala",
      "code": "MT-27",
      "type": "Local council"
    },
    {
      "region": "Marsaxlokk",
      "code": "MT-28",
      "type": "Local council"
    },
    {
      "region": "Mdina",
      "code": "MT-29",
      "type": "Local council"
    },
    {
      "region": "Mellieħa",
      "code": "MT-30",
      "type": "Local council"
    },
    {
      "region": "Mosta",
      "code": "MT-32",
      "type": "Local council"
    },
    {
      "region": "Mqabba",
      "code": "MT-33",
      "type": "Local council"
    },
    {
      "region": "Msida",
      "code": "MT-34",
      "type": "Local council"
    },
    {
      "region": "Mtarfa",
      "code": "MT-35",
      "type": "Local council"
    },
    {
      "region": "Munxar",
      "code": "MT-36",
      "type": "Local council"
    },
    {
      "region": "Mġarr",
      "code": "MT-31",
      "type": "Local council"
    },
    {
      "region": "Nadur",
      "code": "MT-37",
      "type": "Local council"
    },
    {
      "region": "Naxxar",
      "code": "MT-38",
      "type": "Local council"
    },
    {
      "region": "Paola",
      "code": "MT-39",
      "type": "Local council"
    },
    {
      "region": "Pembroke",
      "code": "MT-40",
      "type": "Local council"
    },
    {
      "region": "Pietà",
      "code": "MT-41",
      "type": "Local council"
    },
    {
      "region": "Qala",
      "code": "MT-42",
      "type": "Local council"
    },
    {
      "region": "Qormi",
      "code": "MT-43",
      "type": "Local council"
    },
    {
      "region": "Qrendi",
      "code": "MT-44",
      "type": "Local council"
    },
    {
      "region": "Rabat Gozo",
      "code": "MT-45",
      "type": "Local council"
    },
    {
      "region": "Rabat Malta",
      "code": "MT-46",
      "type": "Local council"
    },
    {
      "region": "Safi",
      "code": "MT-47",
      "type": "Local council"
    },
    {
      "region": "Saint John",
      "code": "MT-49",
      "type": "Local council"
    },
    {
      "region": "Saint Julian's",
      "code": "MT-48",
      "type": "Local council"
    },
    {
      "region": "Saint Lawrence",
      "code": "MT-50",
      "type": "Local council"
    },
    {
      "region": "Saint Lucia's",
      "code": "MT-53",
      "type": "Local council"
    },
    {
      "region": "Saint Paul's Bay",
      "code": "MT-51",
      "type": "Local council"
    },
    {
      "region": "Sannat",
      "code": "MT-52",
      "type": "Local council"
    },
    {
      "region": "Santa Venera",
      "code": "MT-54",
      "type": "Local council"
    },
    {
      "region": "Siġġiewi",
      "code": "MT-55",
      "type": "Local council"
    },
    {
      "region": "Sliema",
      "code": "MT-56",
      "type": "Local council"
    },
    {
      "region": "Swieqi",
      "code": "MT-57",
      "type": "Local council"
    },
    {
      "region": "Ta' Xbiex",
      "code": "MT-58",
      "type": "Local council"
    },
    {
      "region": "Tarxien",
      "code": "MT-59",
      "type": "Local council"
    },
    {
      "region": "Valletta",
      "code": "MT-60",
      "type": "Local council"
    },
    {
      "region": "Xagħra",
      "code": "MT-61",
      "type": "Local council"
    },
    {
      "region": "Xewkija",
      "code": "MT-62",
      "type": "Local council"
    },
    {
      "region": "Xgħajra",
      "code": "MT-63",
      "type": "Local council"
    },
    {
      "region": "Ħamrun",
      "code": "MT-18",
      "type": "Local council"
    },
    {
      "region": "Żabbar",
      "code": "MT-64",
      "type": "Local council"
    },
    {
      "region": "Żebbuġ Gozo",
      "code": "MT-65",
      "type": "Local council"
    },
    {
      "region": "Żebbuġ Malta",
      "code": "MT-66",
      "type": "Local council"
    },
    {
      "region": "Żejtun",
      "code": "MT-67",
      "type": "Local council"
    },
    {
      "region": "Żurrieq",
      "code": "MT-68",
      "type": "Local council"
    }
  ],
  "MU": [
    {
      "region": "Agalega Islands",
      "code": "MU-AG",
      "type": "Dependency"
    },
    {
      "region": "Black River",
      "code": "MU-BL",
      "type": "District"
    },
    {
      "region": "Cargados Carajos Shoals",
      "code": "MU-CC",
      "type": "Dependency"
    },
    {
      "region": "Flacq",
      "code": "MU-FL",
      "type": "District"
    },
    {
      "region": "Grand Port",
      "code": "MU-GP",
      "type": "District"
    },
    {
      "region": "Moka",
      "code": "MU-MO",
      "type": "District"
    },
    {
      "region": "Pamplemousses",
      "code": "MU-PA",
      "type": "District"
    },
    {
      "region": "Plaines Wilhems",
      "code": "MU-PW",
      "type": "District"
    },
    {
      "region": "Port Louis",
      "code": "MU-PL",
      "type": "District"
    },
    {
      "region": "Rivière du Rempart",
      "code": "MU-RR",
      "type": "District"
    },
    {
      "region": "Rodrigues Island",
      "code": "MU-RO",
      "type": "Dependency"
    },
    {
      "region": "Savanne",
      "code": "MU-SA",
      "type": "District"
    }
  ],
  "MV": [
    {
      "region": "Addu City",
      "code": "MV-01",
      "type": "City"
    },
    {
      "region": "Faadhippolhu",
      "code": "MV-03",
      "type": "Administrative atoll"
    },
    {
      "region": "Felidhu Atoll",
      "code": "MV-04",
      "type": "Administrative atoll"
    },
    {
      "region": "Fuvammulah",
      "code": "MV-29",
      "type": "Administrative atoll"
    },
    {
      "region": "Hahdhunmathi",
      "code": "MV-05",
      "type": "Administrative atoll"
    },
    {
      "region": "Kolhumadulu",
      "code": "MV-08",
      "type": "Administrative atoll"
    },
    {
      "region": "Male",
      "code": "MV-MLE",
      "type": "City"
    },
    {
      "region": "Male Atoll",
      "code": "MV-26",
      "type": "Administrative atoll"
    },
    {
      "region": "Mulaku Atoll",
      "code": "MV-12",
      "type": "Administrative atoll"
    },
    {
      "region": "North Ari Atoll",
      "code": "MV-02",
      "type": "Administrative atoll"
    },
    {
      "region": "North Huvadhu Atoll",
      "code": "MV-27",
      "type": "Administrative atoll"
    },
    {
      "region": "North Maalhosmadulu",
      "code": "MV-13",
      "type": "Administrative atoll"
    },
    {
      "region": "North Miladhunmadulu",
      "code": "MV-24",
      "type": "Administrative atoll"
    },
    {
      "region": "North Nilandhe Atoll",
      "code": "MV-14",
      "type": "Administrative atoll"
    },
    {
      "region": "North Thiladhunmathi",
      "code": "MV-07",
      "type": "Administrative atoll"
    },
    {
      "region": "South Ari Atoll",
      "code": "MV-00",
      "type": "Administrative atoll"
    },
    {
      "region": "South Huvadhu Atoll",
      "code": "MV-28",
      "type": "Administrative atoll"
    },
    {
      "region": "South Maalhosmadulu",
      "code": "MV-20",
      "type": "Administrative atoll"
    },
    {
      "region": "South Miladhunmadulu",
      "code": "MV-25",
      "type": "Administrative atoll"
    },
    {
      "region": "South Nilandhe Atoll",
      "code": "MV-17",
      "type": "Administrative atoll"
    },
    {
      "region": "South Thiladhunmathi",
      "code": "MV-23",
      "type": "Administrative atoll"
    }
  ],
  "MW": [
    {
      "region": "Central Region",
      "code": "MW-C",
      "type": "Region"
    },
    {
      "region": "Northern Region",
      "code": "MW-N",
      "type": "Region"
    },
    {
      "region": "Southern Region",
      "code": "MW-S",
      "type": "Region"
    }
  ],
  "MX": [
    {
      "region": "Aguascalientes",
      "code": "MX-AGU",
      "type": "State"
    },
    {
      "region": "Baja California",
      "code": "MX-BCN",
      "type": "State"
    },
    {
      "region": "Baja California Sur",
      "code": "MX-BCS",
      "type": "State"
    },
    {
      "region": "Campeche",
      "code": "MX-CAM",
      "type": "State"
    },
    {
      "region": "Chiapas",
      "code": "MX-CHP",
      "type": "State"
    },
    {
      "region": "Chihuahua",
      "code": "MX-CHH",
      "type": "State"
    },
    {
      "region": "Ciudad de México",
      "code": "MX-CMX",
      "type": "Federal district"
    },
    {
      "region": "Coahuila de Zaragoza",
      "code": "MX-COA",
      "type": "State"
    },
    {
      "region": "Colima",
      "code": "MX-COL",
      "type": "State"
    },
    {
      "region": "Durango",
      "code": "MX-DUR",
      "type": "State"
    },
    {
      "region": "Guanajuato",
      "code": "MX-GUA",
      "type": "State"
    },
    {
      "region": "Guerrero",
      "code": "MX-GRO",
      "type": "State"
    },
    {
      "region": "Hidalgo",
      "code": "MX-HID",
      "type": "State"
    },
    {
      "region": "Jalisco",
      "code": "MX-JAL",
      "type": "State"
    },
    {
      "region": "Michoacán de Ocampo",
      "code": "MX-MIC",
      "type": "State"
    },
    {
      "region": "Morelos",
      "code": "MX-MOR",
      "type": "State"
    },
    {
      "region": "México",
      "code": "MX-MEX",
      "type": "State"
    },
    {
      "region": "Nayarit",
      "code": "MX-NAY",
      "type": "State"
    },
    {
      "region": "Nuevo León",
      "code": "MX-NLE",
      "type": "State"
    },
    {
      "region": "Oaxaca",
      "code": "MX-OAX",
      "type": "State"
    },
    {
      "region": "Puebla",
      "code": "MX-PUE",
      "type": "State"
    },
    {
      "region": "Querétaro",
      "code": "MX-QUE",
      "type": "State"
    },
    {
      "region": "Quintana Roo",
      "code": "MX-ROO",
      "type": "State"
    },
    {
      "region": "San Luis Potosí",
      "code": "MX-SLP",
      "type": "State"
    },
    {
      "region": "Sinaloa",
      "code": "MX-SIN",
      "type": "State"
    },
    {
      "region": "Sonora",
      "code": "MX-SON",
      "type": "State"
    },
    {
      "region": "Tabasco",
      "code": "MX-TAB",
      "type": "State"
    },
    {
      "region": "Tamaulipas",
      "code": "MX-TAM",
      "type": "State"
    },
    {
      "region": "Tlaxcala",
      "code": "MX-TLA",
      "type": "State"
    },
    {
      "region": "Veracruz de Ignacio de la Llave",
      "code": "MX-VER",
      "type": "State"
    },
    {
      "region": "Yucatán",
      "code": "MX-YUC",
      "type": "State"
    },
    {
      "region": "Zacatecas",
      "code": "MX-ZAC",
      "type": "State"
    }
  ],
  "MY": [
    {
      "region": "Johor",
      "code": "MY-01",
      "type": "State"
    },
    {
      "region": "Kedah",
      "code": "MY-02",
      "type": "State"
    },
    {
      "region": "Kelantan",
      "code": "MY-03",
      "type": "State"
    },
    {
      "region": "Melaka",
      "code": "MY-04",
      "type": "State"
    },
    {
      "region": "Negeri Sembilan",
      "code": "MY-05",
      "type": "State"
    },
    {
      "region": "Pahang",
      "code": "MY-06",
      "type": "State"
    },
    {
      "region": "Perak",
      "code": "MY-08",
      "type": "State"
    },
    {
      "region": "Perlis",
      "code": "MY-09",
      "type": "State"
    },
    {
      "region": "Pulau Pinang",
      "code": "MY-07",
      "type": "State"
    },
    {
      "region": "Sabah",
      "code": "MY-12",
      "type": "State"
    },
    {
      "region": "Sarawak",
      "code": "MY-13",
      "type": "State"
    },
    {
      "region": "Selangor",
      "code": "MY-10",
      "type": "State"
    },
    {
      "region": "Terengganu",
      "code": "MY-11",
      "type": "State"
    },
    {
      "region": "Wilayah Persekutuan Kuala Lumpur",
      "code": "MY-14",
      "type": "Federal territory"
    },
    {
      "region": "Wilayah Persekutuan Labuan",
      "code": "MY-15",
      "type": "Federal territory"
    },
    {
      "region": "Wilayah Persekutuan Putrajaya",
      "code": "MY-16",
      "type": "Federal territory"
    }
  ],
  "MZ": [
    {
      "region": "Cabo Delgado",
      "code": "MZ-P",
      "type": "Province"
    },
    {
      "region": "Gaza",
      "code": "MZ-G",
      "type": "Province"
    },
    {
      "region": "Inhambane",
      "code": "MZ-I",
      "type": "Province"
    },
    {
      "region": "Manica",
      "code": "MZ-B",
      "type": "Province"
    },
    {
      "region": "Maputo",
      "code": "MZ-L",
      "type": "Province"
    },
    {
      "region": "Maputo",
      "code": "MZ-MPM",
      "type": "City"
    },
    {
      "region": "Nampula",
      "code": "MZ-N",
      "type": "Province"
    },
    {
      "region": "Niassa",
      "code": "MZ-A",
      "type": "Province"
    },
    {
      "region": "Sofala",
      "code": "MZ-S",
      "type": "Province"
    },
    {
      "region": "Tete",
      "code": "MZ-T",
      "type": "Province"
    },
    {
      "region": "Zambézia",
      "code": "MZ-Q",
      "type": "Province"
    }
  ],
  "NA": [
    {
      "region": "//Karas",
      "code": "NA-KA",
      "type": "Region"
    },
    {
      "region": "Erongo",
      "code": "NA-ER",
      "type": "Region"
    },
    {
      "region": "Hardap",
      "code": "NA-HA",
      "type": "Region"
    },
    {
      "region": "Kavango East",
      "code": "NA-KE",
      "type": "Region"
    },
    {
      "region": "Kavango West",
      "code": "NA-KW",
      "type": "Region"
    },
    {
      "region": "Khomas",
      "code": "NA-KH",
      "type": "Region"
    },
    {
      "region": "Kunene",
      "code": "NA-KU",
      "type": "Region"
    },
    {
      "region": "Ohangwena",
      "code": "NA-OW",
      "type": "Region"
    },
    {
      "region": "Omaheke",
      "code": "NA-OH",
      "type": "Region"
    },
    {
      "region": "Omusati",
      "code": "NA-OS",
      "type": "Region"
    },
    {
      "region": "Oshana",
      "code": "NA-ON",
      "type": "Region"
    },
    {
      "region": "Oshikoto",
      "code": "NA-OT",
      "type": "Region"
    },
    {
      "region": "Otjozondjupa",
      "code": "NA-OD",
      "type": "Region"
    },
    {
      "region": "Zambezi",
      "code": "NA-CA",
      "type": "Region"
    }
  ],
  "NE": [
    {
      "region": "Agadez",
      "code": "NE-1",
      "type": "Region"
    },
    {
      "region": "Diffa",
      "code": "NE-2",
      "type": "Region"
    },
    {
      "region": "Dosso",
      "code": "NE-3",
      "type": "Region"
    },
    {
      "region": "Maradi",
      "code": "NE-4",
      "type": "Region"
    },
    {
      "region": "Niamey",
      "code": "NE-8",
      "type": "Urban community"
    },
    {
      "region": "Tahoua",
      "code": "NE-5",
      "type": "Region"
    },
    {
      "region": "Tillabéri",
      "code": "NE-6",
      "type": "Region"
    },
    {
      "region": "Zinder",
      "code": "NE-7",
      "type": "Region"
    }
  ],
  "NG": [
    {
      "region": "Abia",
      "code": "NG-AB",
      "type": "State"
    },
    {
      "region": "Abuja Federal Capital Territory",
      "code": "NG-FC",
      "type": "Capital territory"
    },
    {
      "region": "Adamawa",
      "code": "NG-AD",
      "type": "State"
    },
    {
      "region": "Akwa Ibom",
      "code": "NG-AK",
      "type": "State"
    },
    {
      "region": "Anambra",
      "code": "NG-AN",
      "type": "State"
    },
    {
      "region": "Bauchi",
      "code": "NG-BA",
      "type": "State"
    },
    {
      "region": "Bayelsa",
      "code": "NG-BY",
      "type": "State"
    },
    {
      "region": "Benue",
      "code": "NG-BE",
      "type": "State"
    },
    {
      "region": "Borno",
      "code": "NG-BO",
      "type": "State"
    },
    {
      "region": "Cross River",
      "code": "NG-CR",
      "type": "State"
    },
    {
      "region": "Delta",
      "code": "NG-DE",
      "type": "State"
    },
    {
      "region": "Ebonyi",
      "code": "NG-EB",
      "type": "State"
    },
    {
      "region": "Edo",
      "code": "NG-ED",
      "type": "State"
    },
    {
      "region": "Ekiti",
      "code": "NG-EK",
      "type": "State"
    },
    {
      "region": "Enugu",
      "code": "NG-EN",
      "type": "State"
    },
    {
      "region": "Gombe",
      "code": "NG-GO",
      "type": "State"
    },
    {
      "region": "Imo",
      "code": "NG-IM",
      "type": "State"
    },
    {
      "region": "Jigawa",
      "code": "NG-JI",
      "type": "State"
    },
    {
      "region": "Kaduna",
      "code": "NG-KD",
      "type": "State"
    },
    {
      "region": "Kano",
      "code": "NG-KN",
      "type": "State"
    },
    {
      "region": "Katsina",
      "code": "NG-KT",
      "type": "State"
    },
    {
      "region": "Kebbi",
      "code": "NG-KE",
      "type": "State"
    },
    {
      "region": "Kogi",
      "code": "NG-KO",
      "type": "State"
    },
    {
      "region": "Kwara",
      "code": "NG-KW",
      "type": "State"
    },
    {
      "region": "Lagos",
      "code": "NG-LA",
      "type": "State"
    },
    {
      "region": "Nasarawa",
      "code": "NG-NA",
      "type": "State"
    },
    {
      "region": "Niger",
      "code": "NG-NI",
      "type": "State"
    },
    {
      "region": "Ogun",
      "code": "NG-OG",
      "type": "State"
    },
    {
      "region": "Ondo",
      "code": "NG-ON",
      "type": "State"
    },
    {
      "region": "Osun",
      "code": "NG-OS",
      "type": "State"
    },
    {
      "region": "Oyo",
      "code": "NG-OY",
      "type": "State"
    },
    {
      "region": "Plateau",
      "code": "NG-PL",
      "type": "State"
    },
    {
      "region": "Rivers",
      "code": "NG-RI",
      "type": "State"
    },
    {
      "region": "Sokoto",
      "code": "NG-SO",
      "type": "State"
    },
    {
      "region": "Taraba",
      "code": "NG-TA",
      "type": "State"
    },
    {
      "region": "Yobe",
      "code": "NG-YO",
      "type": "State"
    },
    {
      "region": "Zamfara",
      "code": "NG-ZA",
      "type": "State"
    }
  ],
  "NI": [
    {
      "region": "Boaco",
      "code": "NI-BO",
      "type": "Department"
    },
    {
      "region": "Carazo",
      "code": "NI-CA",
      "type": "Department"
    },
    {
      "region": "Chinandega",
      "code": "NI-CI",
      "type": "Department"
    },
    {
      "region": "Chontales",
      "code": "NI-CO",
      "type": "Department"
    },
    {
      "region": "Costa Caribe Norte",
      "code": "NI-AN",
      "type": "Autonomous region"
    },
    {
      "region": "Costa Caribe Sur",
      "code": "NI-AS",
      "type": "Autonomous region"
    },
    {
      "region": "Estelí",
      "code": "NI-ES",
      "type": "Department"
    },
    {
      "region": "Granada",
      "code": "NI-GR",
      "type": "Department"
    },
    {
      "region": "Jinotega",
      "code": "NI-JI",
      "type": "Department"
    },
    {
      "region": "León",
      "code": "NI-LE",
      "type": "Department"
    },
    {
      "region": "Madriz",
      "code": "NI-MD",
      "type": "Department"
    },
    {
      "region": "Managua",
      "code": "NI-MN",
      "type": "Department"
    },
    {
      "region": "Masaya",
      "code": "NI-MS",
      "type": "Department"
    },
    {
      "region": "Matagalpa",
      "code": "NI-MT",
      "type": "Department"
    },
    {
      "region": "Nueva Segovia",
      "code": "NI-NS",
      "type": "Department"
    },
    {
      "region": "Rivas",
      "code": "NI-RI",
      "type": "Department"
    },
    {
      "region": "Río San Juan",
      "code": "NI-SJ",
      "type": "Department"
    }
  ],
  "NL": [
    {
      "region": "Aruba",
      "code": "NL-AW",
      "type": "Country"
    },
    {
      "region": "Bonaire",
      "code": "NL-BQ1",
      "type": "Special municipality"
    },
    {
      "region": "Curaçao",
      "code": "NL-CW",
      "type": "Country"
    },
    {
      "region": "Drenthe",
      "code": "NL-DR",
      "type": "Province"
    },
    {
      "region": "Flevoland",
      "code": "NL-FL",
      "type": "Province"
    },
    {
      "region": "Fryslân",
      "code": "NL-FR",
      "type": "Province"
    },
    {
      "region": "Gelderland",
      "code": "NL-GE",
      "type": "Province"
    },
    {
      "region": "Groningen",
      "code": "NL-GR",
      "type": "Province"
    },
    {
      "region": "Limburg",
      "code": "NL-LI",
      "type": "Province"
    },
    {
      "region": "Noord-Brabant",
      "code": "NL-NB",
      "type": "Province"
    },
    {
      "region": "Noord-Holland",
      "code": "NL-NH",
      "type": "Province"
    },
    {
      "region": "Overijssel",
      "code": "NL-OV",
      "type": "Province"
    },
    {
      "region": "Saba",
      "code": "NL-BQ2",
      "type": "Special municipality"
    },
    {
      "region": "Sint Eustatius",
      "code": "NL-BQ3",
      "type": "Special municipality"
    },
    {
      "region": "Sint Maarten",
      "code": "NL-SX",
      "type": "Country"
    },
    {
      "region": "Utrecht",
      "code": "NL-UT",
      "type": "Province"
    },
    {
      "region": "Zeeland",
      "code": "NL-ZE",
      "type": "Province"
    },
    {
      "region": "Zuid-Holland",
      "code": "NL-ZH",
      "type": "Province"
    }
  ],
  "NO": [
    {
      "region": "Agder",
      "code": "NO-42",
      "type": "County"
    },
    {
      "region": "Innlandet",
      "code": "NO-34",
      "type": "County"
    },
    {
      "region": "Jan Mayen (Arctic Region)",
      "code": "NO-22",
      "type": "Arctic region"
    },
    {
      "region": "Møre og Romsdal",
      "code": "NO-15",
      "type": "County"
    },
    {
      "region": "Nordland",
      "code": "NO-18",
      "type": "County"
    },
    {
      "region": "Oslo",
      "code": "NO-03",
      "type": "County"
    },
    {
      "region": "Rogaland",
      "code": "NO-11",
      "type": "County"
    },
    {
      "region": "Romssa ja Finnmárkku",
      "code": "NO-54",
      "type": "County"
    },
    {
      "region": "Svalbard (Arctic Region)",
      "code": "NO-21",
      "type": "Arctic region"
    },
    {
      "region": "Trööndelage",
      "code": "NO-50",
      "type": "County"
    },
    {
      "region": "Vestfold og Telemark",
      "code": "NO-38",
      "type": "County"
    },
    {
      "region": "Vestland",
      "code": "NO-46",
      "type": "County"
    },
    {
      "region": "Viken",
      "code": "NO-30",
      "type": "County"
    }
  ],
  "NP": [
    {
      "region": "Bāgmatī",
      "code": "NP-P3",
      "type": "Province"
    },
    {
      "region": "Central",
      "code": "NP-1",
      "type": "Development region"
    },
    {
      "region": "Eastern",
      "code": "NP-4",
      "type": "Development region"
    },
    {
      "region": "Far Western",
      "code": "NP-5",
      "type": "Development region"
    },
    {
      "region": "Gandaki",
      "code": "NP-P4",
      "type": "Province"
    },
    {
      "region": "Karnali",
      "code": "NP-P6",
      "type": "Province"
    },
    {
      "region": "Mid Western",
      "code": "NP-2",
      "type": "Development region"
    },
    {
      "region": "Province 1",
      "code": "NP-P1",
      "type": "Province"
    },
    {
      "region": "Province 2",
      "code": "NP-P2",
      "type": "Province"
    },
    {
      "region": "Province 5",
      "code": "NP-P5",
      "type": "Province"
    },
    {
      "region": "Sudūr Pashchim",
      "code": "NP-P7",
      "type": "Province"
    },
    {
      "region": "Western",
      "code": "NP-3",
      "type": "Development region"
    }
  ],
  "NR": [
    {
      "region": "Aiwo",
      "code": "NR-01",
      "type": "District"
    },
    {
      "region": "Anabar",
      "code": "NR-02",
      "type": "District"
    },
    {
      "region": "Anetan",
      "code": "NR-03",
      "type": "District"
    },
    {
      "region": "Anibare",
      "code": "NR-04",
      "type": "District"
    },
    {
      "region": "Baitsi",
      "code": "NR-05",
      "type": "District"
    },
    {
      "region": "Boe",
      "code": "NR-06",
      "type": "District"
    },
    {
      "region": "Buada",
      "code": "NR-07",
      "type": "District"
    },
    {
      "region": "Denigomodu",
      "code": "NR-08",
      "type": "District"
    },
    {
      "region": "Ewa",
      "code": "NR-09",
      "type": "District"
    },
    {
      "region": "Ijuw",
      "code": "NR-10",
      "type": "District"
    },
    {
      "region": "Meneng",
      "code": "NR-11",
      "type": "District"
    },
    {
      "region": "Nibok",
      "code": "NR-12",
      "type": "District"
    },
    {
      "region": "Uaboe",
      "code": "NR-13",
      "type": "District"
    },
    {
      "region": "Yaren",
      "code": "NR-14",
      "type": "District"
    }
  ],
  "NZ": [
    {
      "region": "Auckland",
      "code": "NZ-AUK",
      "type": "Region"
    },
    {
      "region": "Bay of Plenty",
      "code": "NZ-BOP",
      "type": "Region"
    },
    {
      "region": "Canterbury",
      "code": "NZ-CAN",
      "type": "Region"
    },
    {
      "region": "Chatham Islands Territory",
      "code": "NZ-CIT",
      "type": "Special island authority"
    },
    {
      "region": "Gisborne",
      "code": "NZ-GIS",
      "type": "Region"
    },
    {
      "region": "Hawke's Bay",
      "code": "NZ-HKB",
      "type": "Region"
    },
    {
      "region": "Manawatu-Wanganui",
      "code": "NZ-MWT",
      "type": "Region"
    },
    {
      "region": "Marlborough",
      "code": "NZ-MBH",
      "type": "Region"
    },
    {
      "region": "Nelson",
      "code": "NZ-NSN",
      "type": "Region"
    },
    {
      "region": "Northland",
      "code": "NZ-NTL",
      "type": "Region"
    },
    {
      "region": "Otago",
      "code": "NZ-OTA",
      "type": "Region"
    },
    {
      "region": "Southland",
      "code": "NZ-STL",
      "type": "Region"
    },
    {
      "region": "Taranaki",
      "code": "NZ-TKI",
      "type": "Region"
    },
    {
      "region": "Tasman",
      "code": "NZ-TAS",
      "type": "Region"
    },
    {
      "region": "Waikato",
      "code": "NZ-WKO",
      "type": "Region"
    },
    {
      "region": "Wellington",
      "code": "NZ-WGN",
      "type": "Region"
    },
    {
      "region": "West Coast",
      "code": "NZ-WTC",
      "type": "Region"
    }
  ],
  "OM": [
    {
      "region": "Ad Dākhilīyah",
      "code": "OM-DA",
      "type": "Governorate"
    },
    {
      "region": "Al Buraymī",
      "code": "OM-BU",
      "type": "Governorate"
    },
    {
      "region": "Al Wusţá",
      "code": "OM-WU",
      "type": "Governorate"
    },
    {
      "region": "Az̧ Z̧āhirah",
      "code": "OM-ZA",
      "type": "Governorate"
    },
    {
      "region": "Janūb al Bāţinah",
      "code": "OM-BJ",
      "type": "Governorate"
    },
    {
      "region": "Janūb ash Sharqīyah",
      "code": "OM-SJ",
      "type": "Governorate"
    },
    {
      "region": "Masqaţ",
      "code": "OM-MA",
      "type": "Governorate"
    },
    {
      "region": "Musandam",
      "code": "OM-MU",
      "type": "Governorate"
    },
    {
      "region": "Shamāl al Bāţinah",
      "code": "OM-BS",
      "type": "Governorate"
    },
    {
      "region": "Shamāl ash Sharqīyah",
      "code": "OM-SS",
      "type": "Governorate"
    },
    {
      "region": "Z̧ufār",
      "code": "OM-ZU",
      "type": "Governorate"
    }
  ],
  "PA": [
    {
      "region": "Bocas del Toro",
      "code": "PA-1",
      "type": "Province"
    },
    {
      "region": "Chiriquí",
      "code": "PA-4",
      "type": "Province"
    },
    {
      "region": "Coclé",
      "code": "PA-2",
      "type": "Province"
    },
    {
      "region": "Colón",
      "code": "PA-3",
      "type": "Province"
    },
    {
      "region": "Darién",
      "code": "PA-5",
      "type": "Province"
    },
    {
      "region": "Emberá",
      "code": "PA-EM",
      "type": "Indigenous region"
    },
    {
      "region": "Guna Yala",
      "code": "PA-KY",
      "type": "Indigenous region"
    },
    {
      "region": "Herrera",
      "code": "PA-6",
      "type": "Province"
    },
    {
      "region": "Los Santos",
      "code": "PA-7",
      "type": "Province"
    },
    {
      "region": "Ngöbe-Buglé",
      "code": "PA-NB",
      "type": "Indigenous region"
    },
    {
      "region": "Panamá",
      "code": "PA-8",
      "type": "Province"
    },
    {
      "region": "Panamá Oeste",
      "code": "PA-10",
      "type": "Province"
    },
    {
      "region": "Veraguas",
      "code": "PA-9",
      "type": "Province"
    }
  ],
  "PE": [
    {
      "region": "Amarumayu",
      "code": "PE-AMA",
      "type": "Region"
    },
    {
      "region": "Ancash",
      "code": "PE-ANC",
      "type": "Region"
    },
    {
      "region": "Apurimaq",
      "code": "PE-APU",
      "type": "Region"
    },
    {
      "region": "Arequipa",
      "code": "PE-ARE",
      "type": "Region"
    },
    {
      "region": "Ayacucho",
      "code": "PE-AYA",
      "type": "Region"
    },
    {
      "region": "Cajamarca",
      "code": "PE-CAJ",
      "type": "Region"
    },
    {
      "region": "Cusco",
      "code": "PE-CUS",
      "type": "Region"
    },
    {
      "region": "El Callao",
      "code": "PE-CAL",
      "type": "Region"
    },
    {
      "region": "Huancavelica",
      "code": "PE-HUV",
      "type": "Region"
    },
    {
      "region": "Hunin",
      "code": "PE-JUN",
      "type": "Region"
    },
    {
      "region": "Huánuco",
      "code": "PE-HUC",
      "type": "Region"
    },
    {
      "region": "Ica",
      "code": "PE-ICA",
      "type": "Region"
    },
    {
      "region": "La Libertad",
      "code": "PE-LAL",
      "type": "Region"
    },
    {
      "region": "Lambayeque",
      "code": "PE-LAM",
      "type": "Region"
    },
    {
      "region": "Lima",
      "code": "PE-LIM",
      "type": "Region"
    },
    {
      "region": "Lima hatun llaqta",
      "code": "PE-LMA",
      "type": "Municipality"
    },
    {
      "region": "Loreto",
      "code": "PE-LOR",
      "type": "Region"
    },
    {
      "region": "Madre de Dios",
      "code": "PE-MDD",
      "type": "Region"
    },
    {
      "region": "Moquegua",
      "code": "PE-MOQ",
      "type": "Region"
    },
    {
      "region": "Pasco",
      "code": "PE-PAS",
      "type": "Region"
    },
    {
      "region": "Piura",
      "code": "PE-PIU",
      "type": "Region"
    },
    {
      "region": "Puno",
      "code": "PE-PUN",
      "type": "Region"
    },
    {
      "region": "San Martin",
      "code": "PE-SAM",
      "type": "Region"
    },
    {
      "region": "Tacna",
      "code": "PE-TAC",
      "type": "Region"
    },
    {
      "region": "Tumbes",
      "code": "PE-TUM",
      "type": "Region"
    },
    {
      "region": "Ucayali",
      "code": "PE-UCA",
      "type": "Region"
    }
  ],
  "PG": [
    {
      "region": "Bougainville",
      "code": "PG-NSB",
      "type": "Autonomous region"
    },
    {
      "region": "Central",
      "code": "PG-CPM",
      "type": "Province"
    },
    {
      "region": "Chimbu",
      "code": "PG-CPK",
      "type": "Province"
    },
    {
      "region": "East New Britain",
      "code": "PG-EBR",
      "type": "Province"
    },
    {
      "region": "East Sepik",
      "code": "PG-ESW",
      "type": "Province"
    },
    {
      "region": "Eastern Highlands",
      "code": "PG-EHG",
      "type": "Province"
    },
    {
      "region": "Enga",
      "code": "PG-EPW",
      "type": "Province"
    },
    {
      "region": "Gulf",
      "code": "PG-GPK",
      "type": "Province"
    },
    {
      "region": "Hela",
      "code": "PG-HLA",
      "type": "Province"
    },
    {
      "region": "Jiwaka",
      "code": "PG-JWK",
      "type": "Province"
    },
    {
      "region": "Madang",
      "code": "PG-MPM",
      "type": "Province"
    },
    {
      "region": "Manus",
      "code": "PG-MRL",
      "type": "Province"
    },
    {
      "region": "Milne Bay",
      "code": "PG-MBA",
      "type": "Province"
    },
    {
      "region": "Morobe",
      "code": "PG-MPL",
      "type": "Province"
    },
    {
      "region": "National Capital District (Port Moresby)",
      "code": "PG-NCD",
      "type": "District"
    },
    {
      "region": "New Ireland",
      "code": "PG-NIK",
      "type": "Province"
    },
    {
      "region": "Northern",
      "code": "PG-NPP",
      "type": "Province"
    },
    {
      "region": "Southern Highlands",
      "code": "PG-SHM",
      "type": "Province"
    },
    {
      "region": "West New Britain",
      "code": "PG-WBK",
      "type": "Province"
    },
    {
      "region": "West Sepik",
      "code": "PG-SAN",
      "type": "Province"
    },
    {
      "region": "Western",
      "code": "PG-WPD",
      "type": "Province"
    },
    {
      "region": "Western Highlands",
      "code": "PG-WHM",
      "type": "Province"
    }
  ],
  "PH": [
    {
      "region": "Autonomous Region in Muslim Mindanao (ARMM)",
      "code": "PH-14",
      "type": "Region"
    },
    {
      "region": "Bicol (Region V)",
      "code": "PH-05",
      "type": "Region"
    },
    {
      "region": "Cagayan Valley (Region II)",
      "code": "PH-02",
      "type": "Region"
    },
    {
      "region": "Calabarzon (Region IV-A)",
      "code": "PH-40",
      "type": "Region"
    },
    {
      "region": "Caraga (Region XIII)",
      "code": "PH-13",
      "type": "Region"
    },
    {
      "region": "Central Luzon (Region III)",
      "code": "PH-03",
      "type": "Region"
    },
    {
      "region": "Central Visayas (Region VII)",
      "code": "PH-07",
      "type": "Region"
    },
    {
      "region": "Cordillera Administrative Region (CAR)",
      "code": "PH-15",
      "type": "Region"
    },
    {
      "region": "Davao (Region XI)",
      "code": "PH-11",
      "type": "Region"
    },
    {
      "region": "Eastern Visayas (Region VIII)",
      "code": "PH-08",
      "type": "Region"
    },
    {
      "region": "Ilocos (Region I)",
      "code": "PH-01",
      "type": "Region"
    },
    {
      "region": "Mimaropa (Region IV-B)",
      "code": "PH-41",
      "type": "Region"
    },
    {
      "region": "National Capital Region",
      "code": "PH-00",
      "type": "Region"
    },
    {
      "region": "Northern Mindanao (Region X)",
      "code": "PH-10",
      "type": "Region"
    },
    {
      "region": "Soccsksargen (Region XII)",
      "code": "PH-12",
      "type": "Region"
    },
    {
      "region": "Western Visayas (Region VI)",
      "code": "PH-06",
      "type": "Region"
    },
    {
      "region": "Zamboanga Peninsula (Region IX)",
      "code": "PH-09",
      "type": "Region"
    }
  ],
  "PK": [
    {
      "region": "Azad Jammu and Kashmir",
      "code": "PK-JK",
      "type": "Pakistan administered area"
    },
    {
      "region": "Balochistan",
      "code": "PK-BA",
      "type": "Province"
    },
    {
      "region": "Gilgit-Baltistan",
      "code": "PK-GB",
      "type": "Pakistan administered area"
    },
    {
      "region": "Islamabad",
      "code": "PK-IS",
      "type": "Federal capital territory"
    },
    {
      "region": "Khyber Pakhtunkhwa",
      "code": "PK-KP",
      "type": "Province"
    },
    {
      "region": "Punjab",
      "code": "PK-PB",
      "type": "Province"
    },
    {
      "region": "Sindh",
      "code": "PK-SD",
      "type": "Province"
    }
  ],
  "PL": [
    {
      "region": "Dolnośląskie",
      "code": "PL-02",
      "type": "Voivodship"
    },
    {
      "region": "Kujawsko-pomorskie",
      "code": "PL-04",
      "type": "Voivodship"
    },
    {
      "region": "Lubelskie",
      "code": "PL-06",
      "type": "Voivodship"
    },
    {
      "region": "Lubuskie",
      "code": "PL-08",
      "type": "Voivodship"
    },
    {
      "region": "Mazowieckie",
      "code": "PL-14",
      "type": "Voivodship"
    },
    {
      "region": "Małopolskie",
      "code": "PL-12",
      "type": "Voivodship"
    },
    {
      "region": "Opolskie",
      "code": "PL-16",
      "type": "Voivodship"
    },
    {
      "region": "Podkarpackie",
      "code": "PL-18",
      "type": "Voivodship"
    },
    {
      "region": "Podlaskie",
      "code": "PL-20",
      "type": "Voivodship"
    },
    {
      "region": "Pomorskie",
      "code": "PL-22",
      "type": "Voivodship"
    },
    {
      "region": "Warmińsko-mazurskie",
      "code": "PL-28",
      "type": "Voivodship"
    },
    {
      "region": "Wielkopolskie",
      "code": "PL-30",
      "type": "Voivodship"
    },
    {
      "region": "Zachodniopomorskie",
      "code": "PL-32",
      "type": "Voivodship"
    },
    {
      "region": "Łódzkie",
      "code": "PL-10",
      "type": "Voivodship"
    },
    {
      "region": "Śląskie",
      "code": "PL-24",
      "type": "Voivodship"
    },
    {
      "region": "Świętokrzyskie",
      "code": "PL-26",
      "type": "Voivodship"
    }
  ],
  "PS": [
    {
      "region": "Bethlehem",
      "code": "PS-BTH",
      "type": "Governorate"
    },
    {
      "region": "Deir El Balah",
      "code": "PS-DEB",
      "type": "Governorate"
    },
    {
      "region": "Gaza",
      "code": "PS-GZA",
      "type": "Governorate"
    },
    {
      "region": "Hebron",
      "code": "PS-HBN",
      "type": "Governorate"
    },
    {
      "region": "Jenin",
      "code": "PS-JEN",
      "type": "Governorate"
    },
    {
      "region": "Jericho and Al Aghwar",
      "code": "PS-JRH",
      "type": "Governorate"
    },
    {
      "region": "Jerusalem",
      "code": "PS-JEM",
      "type": "Governorate"
    },
    {
      "region": "Khan Yunis",
      "code": "PS-KYS",
      "type": "Governorate"
    },
    {
      "region": "Nablus",
      "code": "PS-NBS",
      "type": "Governorate"
    },
    {
      "region": "North Gaza",
      "code": "PS-NGZ",
      "type": "Governorate"
    },
    {
      "region": "Qalqilya",
      "code": "PS-QQA",
      "type": "Governorate"
    },
    {
      "region": "Rafah",
      "code": "PS-RFH",
      "type": "Governorate"
    },
    {
      "region": "Ramallah",
      "code": "PS-RBH",
      "type": "Governorate"
    },
    {
      "region": "Salfit",
      "code": "PS-SLT",
      "type": "Governorate"
    },
    {
      "region": "Tubas",
      "code": "PS-TBS",
      "type": "Governorate"
    },
    {
      "region": "Tulkarm",
      "code": "PS-TKM",
      "type": "Governorate"
    }
  ],
  "PT": [
    {
      "region": "Aveiro",
      "code": "PT-01",
      "type": "District"
    },
    {
      "region": "Beja",
      "code": "PT-02",
      "type": "District"
    },
    {
      "region": "Braga",
      "code": "PT-03",
      "type": "District"
    },
    {
      "region": "Bragança",
      "code": "PT-04",
      "type": "District"
    },
    {
      "region": "Castelo Branco",
      "code": "PT-05",
      "type": "District"
    },
    {
      "region": "Coimbra",
      "code": "PT-06",
      "type": "District"
    },
    {
      "region": "Faro",
      "code": "PT-08",
      "type": "District"
    },
    {
      "region": "Guarda",
      "code": "PT-09",
      "type": "District"
    },
    {
      "region": "Leiria",
      "code": "PT-10",
      "type": "District"
    },
    {
      "region": "Lisboa",
      "code": "PT-11",
      "type": "District"
    },
    {
      "region": "Portalegre",
      "code": "PT-12",
      "type": "District"
    },
    {
      "region": "Porto",
      "code": "PT-13",
      "type": "District"
    },
    {
      "region": "Região Autónoma da Madeira",
      "code": "PT-30",
      "type": "Autonomous region"
    },
    {
      "region": "Região Autónoma dos Açores",
      "code": "PT-20",
      "type": "Autonomous region"
    },
    {
      "region": "Santarém",
      "code": "PT-14",
      "type": "District"
    },
    {
      "region": "Setúbal",
      "code": "PT-15",
      "type": "District"
    },
    {
      "region": "Viana do Castelo",
      "code": "PT-16",
      "type": "District"
    },
    {
      "region": "Vila Real",
      "code": "PT-17",
      "type": "District"
    },
    {
      "region": "Viseu",
      "code": "PT-18",
      "type": "District"
    },
    {
      "region": "Évora",
      "code": "PT-07",
      "type": "District"
    }
  ],
  "PW": [
    {
      "region": "Aimeliik",
      "code": "PW-002",
      "type": "State"
    },
    {
      "region": "Airai",
      "code": "PW-004",
      "type": "State"
    },
    {
      "region": "Angaur",
      "code": "PW-010",
      "type": "State"
    },
    {
      "region": "Hatohobei",
      "code": "PW-050",
      "type": "State"
    },
    {
      "region": "Kayangel",
      "code": "PW-100",
      "type": "State"
    },
    {
      "region": "Koror",
      "code": "PW-150",
      "type": "State"
    },
    {
      "region": "Melekeok",
      "code": "PW-212",
      "type": "State"
    },
    {
      "region": "Ngaraard",
      "code": "PW-214",
      "type": "State"
    },
    {
      "region": "Ngarchelong",
      "code": "PW-218",
      "type": "State"
    },
    {
      "region": "Ngardmau",
      "code": "PW-222",
      "type": "State"
    },
    {
      "region": "Ngatpang",
      "code": "PW-224",
      "type": "State"
    },
    {
      "region": "Ngchesar",
      "code": "PW-226",
      "type": "State"
    },
    {
      "region": "Ngeremlengui",
      "code": "PW-227",
      "type": "State"
    },
    {
      "region": "Ngiwal",
      "code": "PW-228",
      "type": "State"
    },
    {
      "region": "Peleliu",
      "code": "PW-350",
      "type": "State"
    },
    {
      "region": "Sonsorol",
      "code": "PW-370",
      "type": "State"
    }
  ],
  "PY": [
    {
      "region": "Alto Paraguay",
      "code": "PY-16",
      "type": "Department"
    },
    {
      "region": "Alto Paraná",
      "code": "PY-10",
      "type": "Department"
    },
    {
      "region": "Amambay",
      "code": "PY-13",
      "type": "Department"
    },
    {
      "region": "Asunción",
      "code": "PY-ASU",
      "type": "Capital"
    },
    {
      "region": "Boquerón",
      "code": "PY-19",
      "type": "Department"
    },
    {
      "region": "Caaguazú",
      "code": "PY-5",
      "type": "Department"
    },
    {
      "region": "Caazapá",
      "code": "PY-6",
      "type": "Department"
    },
    {
      "region": "Canindeyú",
      "code": "PY-14",
      "type": "Department"
    },
    {
      "region": "Central",
      "code": "PY-11",
      "type": "Department"
    },
    {
      "region": "Concepción",
      "code": "PY-1",
      "type": "Department"
    },
    {
      "region": "Cordillera",
      "code": "PY-3",
      "type": "Department"
    },
    {
      "region": "Guairá",
      "code": "PY-4",
      "type": "Department"
    },
    {
      "region": "Itapúa",
      "code": "PY-7",
      "type": "Department"
    },
    {
      "region": "Misiones",
      "code": "PY-8",
      "type": "Department"
    },
    {
      "region": "Paraguarí",
      "code": "PY-9",
      "type": "Department"
    },
    {
      "region": "Presidente Hayes",
      "code": "PY-15",
      "type": "Department"
    },
    {
      "region": "San Pedro",
      "code": "PY-2",
      "type": "Department"
    },
    {
      "region": "Ñeembucú",
      "code": "PY-12",
      "type": "Department"
    }
  ],
  "QA": [
    {
      "region": "Ad Dawḩah",
      "code": "QA-DA",
      "type": "Municipality"
    },
    {
      "region": "Al Khawr wa adh Dhakhīrah",
      "code": "QA-KH",
      "type": "Municipality"
    },
    {
      "region": "Al Wakrah",
      "code": "QA-WA",
      "type": "Municipality"
    },
    {
      "region": "Ar Rayyān",
      "code": "QA-RA",
      "type": "Municipality"
    },
    {
      "region": "Ash Shamāl",
      "code": "QA-MS",
      "type": "Municipality"
    },
    {
      "region": "Ash Shīḩānīyah",
      "code": "QA-SH",
      "type": "Municipality"
    },
    {
      "region": "Az̧ Z̧a‘āyin",
      "code": "QA-ZA",
      "type": "Municipality"
    },
    {
      "region": "Umm Şalāl",
      "code": "QA-US",
      "type": "Municipality"
    }
  ],
  "RO": [
    {
      "region": "Alba",
      "code": "RO-AB",
      "type": "Department"
    },
    {
      "region": "Arad",
      "code": "RO-AR",
      "type": "Department"
    },
    {
      "region": "Argeș",
      "code": "RO-AG",
      "type": "Department"
    },
    {
      "region": "Bacău",
      "code": "RO-BC",
      "type": "Department"
    },
    {
      "region": "Bihor",
      "code": "RO-BH",
      "type": "Department"
    },
    {
      "region": "Bistrița-Năsăud",
      "code": "RO-BN",
      "type": "Department"
    },
    {
      "region": "Botoșani",
      "code": "RO-BT",
      "type": "Department"
    },
    {
      "region": "Brașov",
      "code": "RO-BV",
      "type": "Department"
    },
    {
      "region": "Brăila",
      "code": "RO-BR",
      "type": "Department"
    },
    {
      "region": "București",
      "code": "RO-B",
      "type": "Municipality"
    },
    {
      "region": "Buzău",
      "code": "RO-BZ",
      "type": "Department"
    },
    {
      "region": "Caraș-Severin",
      "code": "RO-CS",
      "type": "Department"
    },
    {
      "region": "Cluj",
      "code": "RO-CJ",
      "type": "Department"
    },
    {
      "region": "Constanța",
      "code": "RO-CT",
      "type": "Department"
    },
    {
      "region": "Covasna",
      "code": "RO-CV",
      "type": "Department"
    },
    {
      "region": "Călărași",
      "code": "RO-CL",
      "type": "Department"
    },
    {
      "region": "Dolj",
      "code": "RO-DJ",
      "type": "Department"
    },
    {
      "region": "Dâmbovița",
      "code": "RO-DB",
      "type": "Department"
    },
    {
      "region": "Galați",
      "code": "RO-GL",
      "type": "Department"
    },
    {
      "region": "Giurgiu",
      "code": "RO-GR",
      "type": "Department"
    },
    {
      "region": "Gorj",
      "code": "RO-GJ",
      "type": "Department"
    },
    {
      "region": "Harghita",
      "code": "RO-HR",
      "type": "Department"
    },
    {
      "region": "Hunedoara",
      "code": "RO-HD",
      "type": "Department"
    },
    {
      "region": "Ialomița",
      "code": "RO-IL",
      "type": "Department"
    },
    {
      "region": "Iași",
      "code": "RO-IS",
      "type": "Department"
    },
    {
      "region": "Ilfov",
      "code": "RO-IF",
      "type": "Department"
    },
    {
      "region": "Maramureș",
      "code": "RO-MM",
      "type": "Department"
    },
    {
      "region": "Mehedinți",
      "code": "RO-MH",
      "type": "Department"
    },
    {
      "region": "Mureș",
      "code": "RO-MS",
      "type": "Department"
    },
    {
      "region": "Neamț",
      "code": "RO-NT",
      "type": "Department"
    },
    {
      "region": "Olt",
      "code": "RO-OT",
      "type": "Department"
    },
    {
      "region": "Prahova",
      "code": "RO-PH",
      "type": "Department"
    },
    {
      "region": "Satu Mare",
      "code": "RO-SM",
      "type": "Department"
    },
    {
      "region": "Sibiu",
      "code": "RO-SB",
      "type": "Department"
    },
    {
      "region": "Suceava",
      "code": "RO-SV",
      "type": "Department"
    },
    {
      "region": "Sălaj",
      "code": "RO-SJ",
      "type": "Department"
    },
    {
      "region": "Teleorman",
      "code": "RO-TR",
      "type": "Department"
    },
    {
      "region": "Timiș",
      "code": "RO-TM",
      "type": "Department"
    },
    {
      "region": "Tulcea",
      "code": "RO-TL",
      "type": "Department"
    },
    {
      "region": "Vaslui",
      "code": "RO-VS",
      "type": "Department"
    },
    {
      "region": "Vrancea",
      "code": "RO-VN",
      "type": "Department"
    },
    {
      "region": "Vâlcea",
      "code": "RO-VL",
      "type": "Department"
    }
  ],
  "RS": [
    {
      "region": "Beograd",
      "code": "RS-00",
      "type": "City"
    },
    {
      "region": "Borski okrug",
      "code": "RS-14",
      "type": "District"
    },
    {
      "region": "Braničevski okrug",
      "code": "RS-11",
      "type": "District"
    },
    {
      "region": "Jablanički okrug",
      "code": "RS-23",
      "type": "District"
    },
    {
      "region": "Kolubarski okrug",
      "code": "RS-09",
      "type": "District"
    },
    {
      "region": "Kosovo-Metohija",
      "code": "RS-KM",
      "type": "Autonomous province"
    },
    {
      "region": "Mačvanski okrug",
      "code": "RS-08",
      "type": "District"
    },
    {
      "region": "Moravički okrug",
      "code": "RS-17",
      "type": "District"
    },
    {
      "region": "Nišavski okrug",
      "code": "RS-20",
      "type": "District"
    },
    {
      "region": "Pirotski okrug",
      "code": "RS-22",
      "type": "District"
    },
    {
      "region": "Podunavski okrug",
      "code": "RS-10",
      "type": "District"
    },
    {
      "region": "Pomoravski okrug",
      "code": "RS-13",
      "type": "District"
    },
    {
      "region": "Pčinjski okrug",
      "code": "RS-24",
      "type": "District"
    },
    {
      "region": "Rasinski okrug",
      "code": "RS-19",
      "type": "District"
    },
    {
      "region": "Raški okrug",
      "code": "RS-18",
      "type": "District"
    },
    {
      "region": "Toplički okrug",
      "code": "RS-21",
      "type": "District"
    },
    {
      "region": "Vojvodina",
      "code": "RS-VO",
      "type": "Autonomous province"
    },
    {
      "region": "Zaječarski okrug",
      "code": "RS-15",
      "type": "District"
    },
    {
      "region": "Zlatiborski okrug",
      "code": "RS-16",
      "type": "District"
    },
    {
      "region": "Šumadijski okrug",
      "code": "RS-12",
      "type": "District"
    }
  ],
  "RU": [
    {
      "region": "Adygeja, Respublika",
      "code": "RU-AD",
      "type": "Republic"
    },
    {
      "region": "Altaj, Respublika",
      "code": "RU-AL",
      "type": "Republic"
    },
    {
      "region": "Altajskij kraj",
      "code": "RU-ALT",
      "type": "Administrative territory"
    },
    {
      "region": "Amurskaja oblast'",
      "code": "RU-AMU",
      "type": "Administrative region"
    },
    {
      "region": "Arhangel'skaja oblast'",
      "code": "RU-ARK",
      "type": "Administrative region"
    },
    {
      "region": "Astrahanskaja oblast'",
      "code": "RU-AST",
      "type": "Administrative region"
    },
    {
      "region": "Bashkortostan, Respublika",
      "code": "RU-BA",
      "type": "Republic"
    },
    {
      "region": "Belgorodskaja oblast'",
      "code": "RU-BEL",
      "type": "Administrative region"
    },
    {
      "region": "Brjanskaja oblast'",
      "code": "RU-BRY",
      "type": "Administrative region"
    },
    {
      "region": "Burjatija, Respublika",
      "code": "RU-BU",
      "type": "Republic"
    },
    {
      "region": "Chechenskaya Respublika",
      "code": "RU-CE",
      "type": "Republic"
    },
    {
      "region": "Chelyabinskaya oblast'",
      "code": "RU-CHE",
      "type": "Administrative region"
    },
    {
      "region": "Chukotskiy avtonomnyy okrug",
      "code": "RU-CHU",
      "type": "Autonomous district"
    },
    {
      "region": "Chuvashskaya Respublika",
      "code": "RU-CU",
      "type": "Republic"
    },
    {
      "region": "Dagestan, Respublika",
      "code": "RU-DA",
      "type": "Republic"
    },
    {
      "region": "Evrejskaja avtonomnaja oblast'",
      "code": "RU-YEV",
      "type": "Autonomous region"
    },
    {
      "region": "Habarovskij kraj",
      "code": "RU-KHA",
      "type": "Administrative territory"
    },
    {
      "region": "Hakasija, Respublika",
      "code": "RU-KK",
      "type": "Republic"
    },
    {
      "region": "Hanty-Mansijskij avtonomnyj okrug",
      "code": "RU-KHM",
      "type": "Autonomous district"
    },
    {
      "region": "Ingushetiya, Respublika",
      "code": "RU-IN",
      "type": "Republic"
    },
    {
      "region": "Irkutskaja oblast'",
      "code": "RU-IRK",
      "type": "Administrative region"
    },
    {
      "region": "Ivanovskaja oblast'",
      "code": "RU-IVA",
      "type": "Administrative region"
    },
    {
      "region": "Jamalo-Neneckij avtonomnyj okrug",
      "code": "RU-YAN",
      "type": "Autonomous district"
    },
    {
      "region": "Jaroslavskaja oblast'",
      "code": "RU-YAR",
      "type": "Administrative region"
    },
    {
      "region": "Kabardino-Balkarskaja Respublika",
      "code": "RU-KB",
      "type": "Republic"
    },
    {
      "region": "Kaliningradskaja oblast'",
      "code": "RU-KGD",
      "type": "Administrative region"
    },
    {
      "region": "Kalmykija, Respublika",
      "code": "RU-KL",
      "type": "Republic"
    },
    {
      "region": "Kaluzhskaya oblast'",
      "code": "RU-KLU",
      "type": "Administrative region"
    },
    {
      "region": "Kamchatskiy kray",
      "code": "RU-KAM",
      "type": "Administrative territory"
    },
    {
      "region": "Karachayevo-Cherkesskaya Respublika",
      "code": "RU-KC",
      "type": "Republic"
    },
    {
      "region": "Karelija, Respublika",
      "code": "RU-KR",
      "type": "Republic"
    },
    {
      "region": "Kemerovskaja oblast'",
      "code": "RU-KEM",
      "type": "Administrative region"
    },
    {
      "region": "Kirovskaja oblast'",
      "code": "RU-KIR",
      "type": "Administrative region"
    },
    {
      "region": "Komi, Respublika",
      "code": "RU-KO",
      "type": "Republic"
    },
    {
      "region": "Kostromskaja oblast'",
      "code": "RU-KOS",
      "type": "Administrative region"
    },
    {
      "region": "Krasnodarskij kraj",
      "code": "RU-KDA",
      "type": "Administrative territory"
    },
    {
      "region": "Krasnojarskij kraj",
      "code": "RU-KYA",
      "type": "Administrative territory"
    },
    {
      "region": "Kurganskaja oblast'",
      "code": "RU-KGN",
      "type": "Administrative region"
    },
    {
      "region": "Kurskaja oblast'",
      "code": "RU-KRS",
      "type": "Administrative region"
    },
    {
      "region": "Leningradskaja oblast'",
      "code": "RU-LEN",
      "type": "Administrative region"
    },
    {
      "region": "Lipeckaja oblast'",
      "code": "RU-LIP",
      "type": "Administrative region"
    },
    {
      "region": "Magadanskaja oblast'",
      "code": "RU-MAG",
      "type": "Administrative region"
    },
    {
      "region": "Marij Èl, Respublika",
      "code": "RU-ME",
      "type": "Republic"
    },
    {
      "region": "Mordovija, Respublika",
      "code": "RU-MO",
      "type": "Republic"
    },
    {
      "region": "Moskovskaja oblast'",
      "code": "RU-MOS",
      "type": "Administrative region"
    },
    {
      "region": "Moskva",
      "code": "RU-MOW",
      "type": "Autonomous city"
    },
    {
      "region": "Murmanskaja oblast'",
      "code": "RU-MUR",
      "type": "Administrative region"
    },
    {
      "region": "Neneckij avtonomnyj okrug",
      "code": "RU-NEN",
      "type": "Autonomous district"
    },
    {
      "region": "Nizhegorodskaya oblast'",
      "code": "RU-NIZ",
      "type": "Administrative region"
    },
    {
      "region": "Novgorodskaja oblast'",
      "code": "RU-NGR",
      "type": "Administrative region"
    },
    {
      "region": "Novosibirskaja oblast'",
      "code": "RU-NVS",
      "type": "Administrative region"
    },
    {
      "region": "Omskaja oblast'",
      "code": "RU-OMS",
      "type": "Administrative region"
    },
    {
      "region": "Orenburgskaja oblast'",
      "code": "RU-ORE",
      "type": "Administrative region"
    },
    {
      "region": "Orlovskaja oblast'",
      "code": "RU-ORL",
      "type": "Administrative region"
    },
    {
      "region": "Penzenskaja oblast'",
      "code": "RU-PNZ",
      "type": "Administrative region"
    },
    {
      "region": "Permskij kraj",
      "code": "RU-PER",
      "type": "Administrative territory"
    },
    {
      "region": "Primorskij kraj",
      "code": "RU-PRI",
      "type": "Administrative territory"
    },
    {
      "region": "Pskovskaja oblast'",
      "code": "RU-PSK",
      "type": "Administrative region"
    },
    {
      "region": "Rjazanskaja oblast'",
      "code": "RU-RYA",
      "type": "Administrative region"
    },
    {
      "region": "Rostovskaja oblast'",
      "code": "RU-ROS",
      "type": "Administrative region"
    },
    {
      "region": "Saha, Respublika",
      "code": "RU-SA",
      "type": "Republic"
    },
    {
      "region": "Sahalinskaja oblast'",
      "code": "RU-SAK",
      "type": "Administrative region"
    },
    {
      "region": "Samarskaja oblast'",
      "code": "RU-SAM",
      "type": "Administrative region"
    },
    {
      "region": "Sankt-Peterburg",
      "code": "RU-SPE",
      "type": "Autonomous city"
    },
    {
      "region": "Saratovskaja oblast'",
      "code": "RU-SAR",
      "type": "Administrative region"
    },
    {
      "region": "Severnaja Osetija, Respublika",
      "code": "RU-SE",
      "type": "Republic"
    },
    {
      "region": "Smolenskaja oblast'",
      "code": "RU-SMO",
      "type": "Administrative region"
    },
    {
      "region": "Stavropol'skij kraj",
      "code": "RU-STA",
      "type": "Administrative territory"
    },
    {
      "region": "Sverdlovskaja oblast'",
      "code": "RU-SVE",
      "type": "Administrative region"
    },
    {
      "region": "Tambovskaja oblast'",
      "code": "RU-TAM",
      "type": "Administrative region"
    },
    {
      "region": "Tatarstan, Respublika",
      "code": "RU-TA",
      "type": "Republic"
    },
    {
      "region": "Tjumenskaja oblast'",
      "code": "RU-TYU",
      "type": "Administrative region"
    },
    {
      "region": "Tomskaja oblast'",
      "code": "RU-TOM",
      "type": "Administrative region"
    },
    {
      "region": "Tul'skaja oblast'",
      "code": "RU-TUL",
      "type": "Administrative region"
    },
    {
      "region": "Tverskaja oblast'",
      "code": "RU-TVE",
      "type": "Administrative region"
    },
    {
      "region": "Tyva, Respublika",
      "code": "RU-TY",
      "type": "Republic"
    },
    {
      "region": "Udmurtskaja Respublika",
      "code": "RU-UD",
      "type": "Republic"
    },
    {
      "region": "Ul'janovskaja oblast'",
      "code": "RU-ULY",
      "type": "Administrative region"
    },
    {
      "region": "Vladimirskaja oblast'",
      "code": "RU-VLA",
      "type": "Administrative region"
    },
    {
      "region": "Volgogradskaja oblast'",
      "code": "RU-VGG",
      "type": "Administrative region"
    },
    {
      "region": "Vologodskaja oblast'",
      "code": "RU-VLG",
      "type": "Administrative region"
    },
    {
      "region": "Voronezhskaya oblast'",
      "code": "RU-VOR",
      "type": "Administrative region"
    },
    {
      "region": "Zabajkal'skij kraj",
      "code": "RU-ZAB",
      "type": "Administrative territory"
    }
  ],
  "RW": [
    {
      "region": "City of Kigali",
      "code": "RW-01",
      "type": "City"
    },
    {
      "region": "Eastern",
      "code": "RW-02",
      "type": "Province"
    },
    {
      "region": "Northern",
      "code": "RW-03",
      "type": "Province"
    },
    {
      "region": "Southern",
      "code": "RW-05",
      "type": "Province"
    },
    {
      "region": "Western",
      "code": "RW-04",
      "type": "Province"
    }
  ],
  "SA": [
    {
      "region": "'Asīr",
      "code": "SA-14",
      "type": "Region"
    },
    {
      "region": "Al Bāḩah",
      "code": "SA-11",
      "type": "Region"
    },
    {
      "region": "Al Jawf",
      "code": "SA-12",
      "type": "Region"
    },
    {
      "region": "Al Madīnah al Munawwarah",
      "code": "SA-03",
      "type": "Region"
    },
    {
      "region": "Al Qaşīm",
      "code": "SA-05",
      "type": "Region"
    },
    {
      "region": "Al Ḩudūd ash Shamālīyah",
      "code": "SA-08",
      "type": "Region"
    },
    {
      "region": "Ar Riyāḑ",
      "code": "SA-01",
      "type": "Region"
    },
    {
      "region": "Ash Sharqīyah",
      "code": "SA-04",
      "type": "Region"
    },
    {
      "region": "Jāzān",
      "code": "SA-09",
      "type": "Region"
    },
    {
      "region": "Makkah al Mukarramah",
      "code": "SA-02",
      "type": "Region"
    },
    {
      "region": "Najrān",
      "code": "SA-10",
      "type": "Region"
    },
    {
      "region": "Tabūk",
      "code": "SA-07",
      "type": "Region"
    },
    {
      "region": "Ḩā'il",
      "code": "SA-06",
      "type": "Region"
    }
  ],
  "SB": [
    {
      "region": "Capital Territory (Honiara)",
      "code": "SB-CT",
      "type": "Capital territory"
    },
    {
      "region": "Central",
      "code": "SB-CE",
      "type": "Province"
    },
    {
      "region": "Choiseul",
      "code": "SB-CH",
      "type": "Province"
    },
    {
      "region": "Guadalcanal",
      "code": "SB-GU",
      "type": "Province"
    },
    {
      "region": "Isabel",
      "code": "SB-IS",
      "type": "Province"
    },
    {
      "region": "Makira-Ulawa",
      "code": "SB-MK",
      "type": "Province"
    },
    {
      "region": "Malaita",
      "code": "SB-ML",
      "type": "Province"
    },
    {
      "region": "Rennell and Bellona",
      "code": "SB-RB",
      "type": "Province"
    },
    {
      "region": "Temotu",
      "code": "SB-TE",
      "type": "Province"
    },
    {
      "region": "Western",
      "code": "SB-WE",
      "type": "Province"
    }
  ],
  "SC": [
    {
      "region": "Anse Boileau",
      "code": "SC-02",
      "type": "District"
    },
    {
      "region": "Anse Etoile",
      "code": "SC-03",
      "type": "District"
    },
    {
      "region": "Anse Royale",
      "code": "SC-05",
      "type": "District"
    },
    {
      "region": "Anse aux Pins",
      "code": "SC-01",
      "type": "District"
    },
    {
      "region": "Au Cap",
      "code": "SC-04",
      "type": "District"
    },
    {
      "region": "Baie Lazare",
      "code": "SC-06",
      "type": "District"
    },
    {
      "region": "Baie Sainte Anne",
      "code": "SC-07",
      "type": "District"
    },
    {
      "region": "Beau Vallon",
      "code": "SC-08",
      "type": "District"
    },
    {
      "region": "Bel Air",
      "code": "SC-09",
      "type": "District"
    },
    {
      "region": "Bel Ombre",
      "code": "SC-10",
      "type": "District"
    },
    {
      "region": "Cascade",
      "code": "SC-11",
      "type": "District"
    },
    {
      "region": "English River",
      "code": "SC-16",
      "type": "District"
    },
    {
      "region": "Glacis",
      "code": "SC-12",
      "type": "District"
    },
    {
      "region": "Grand Anse Mahe",
      "code": "SC-13",
      "type": "District"
    },
    {
      "region": "Grand Anse Praslin",
      "code": "SC-14",
      "type": "District"
    },
    {
      "region": "Ile Perseverance I",
      "code": "SC-26",
      "type": "District"
    },
    {
      "region": "Ile Perseverance II",
      "code": "SC-27",
      "type": "District"
    },
    {
      "region": "La Digue",
      "code": "SC-15",
      "type": "District"
    },
    {
      "region": "Les Mamelles",
      "code": "SC-24",
      "type": "District"
    },
    {
      "region": "Mont Buxton",
      "code": "SC-17",
      "type": "District"
    },
    {
      "region": "Mont Fleuri",
      "code": "SC-18",
      "type": "District"
    },
    {
      "region": "Plaisance",
      "code": "SC-19",
      "type": "District"
    },
    {
      "region": "Pointe Larue",
      "code": "SC-20",
      "type": "District"
    },
    {
      "region": "Port Glaud",
      "code": "SC-21",
      "type": "District"
    },
    {
      "region": "Roche Caiman",
      "code": "SC-25",
      "type": "District"
    },
    {
      "region": "Saint Louis",
      "code": "SC-22",
      "type": "District"
    },
    {
      "region": "Takamaka",
      "code": "SC-23",
      "type": "District"
    }
  ],
  "SD": [
    {
      "region": "Blue Nile",
      "code": "SD-NB",
      "type": "State"
    },
    {
      "region": "Central Darfur",
      "code": "SD-DC",
      "type": "State"
    },
    {
      "region": "East Darfur",
      "code": "SD-DE",
      "type": "State"
    },
    {
      "region": "Gedaref",
      "code": "SD-GD",
      "type": "State"
    },
    {
      "region": "Gezira",
      "code": "SD-GZ",
      "type": "State"
    },
    {
      "region": "Kassala",
      "code": "SD-KA",
      "type": "State"
    },
    {
      "region": "Khartoum",
      "code": "SD-KH",
      "type": "State"
    },
    {
      "region": "North Darfur",
      "code": "SD-DN",
      "type": "State"
    },
    {
      "region": "North Kordofan",
      "code": "SD-KN",
      "type": "State"
    },
    {
      "region": "Northern",
      "code": "SD-NO",
      "type": "State"
    },
    {
      "region": "Red Sea",
      "code": "SD-RS",
      "type": "State"
    },
    {
      "region": "River Nile",
      "code": "SD-NR",
      "type": "State"
    },
    {
      "region": "Sennar",
      "code": "SD-SI",
      "type": "State"
    },
    {
      "region": "South Darfur",
      "code": "SD-DS",
      "type": "State"
    },
    {
      "region": "South Kordofan",
      "code": "SD-KS",
      "type": "State"
    },
    {
      "region": "West Darfur",
      "code": "SD-DW",
      "type": "State"
    },
    {
      "region": "West Kordofan",
      "code": "SD-GK",
      "type": "State"
    },
    {
      "region": "White Nile",
      "code": "SD-NW",
      "type": "State"
    }
  ],
  "SE": [
    {
      "region": "Blekinge län [SE-10]",
      "code": "SE-K",
      "type": "County"
    },
    {
      "region": "Dalarnas län [SE-20]",
      "code": "SE-W",
      "type": "County"
    },
    {
      "region": "Gotlands län [SE-09]",
      "code": "SE-I",
      "type": "County"
    },
    {
      "region": "Gävleborgs län [SE-21]",
      "code": "SE-X",
      "type": "County"
    },
    {
      "region": "Hallands län [SE-13]",
      "code": "SE-N",
      "type": "County"
    },
    {
      "region": "Jämtlands län [SE-23]",
      "code": "SE-Z",
      "type": "County"
    },
    {
      "region": "Jönköpings län [SE-06]",
      "code": "SE-F",
      "type": "County"
    },
    {
      "region": "Kalmar län [SE-08]",
      "code": "SE-H",
      "type": "County"
    },
    {
      "region": "Kronobergs län [SE-07]",
      "code": "SE-G",
      "type": "County"
    },
    {
      "region": "Norrbottens län [SE-25]",
      "code": "SE-BD",
      "type": "County"
    },
    {
      "region": "Skåne län [SE-12]",
      "code": "SE-M",
      "type": "County"
    },
    {
      "region": "Stockholms län [SE-01]",
      "code": "SE-AB",
      "type": "County"
    },
    {
      "region": "Södermanlands län [SE-04]",
      "code": "SE-D",
      "type": "County"
    },
    {
      "region": "Uppsala län [SE-03]",
      "code": "SE-C",
      "type": "County"
    },
    {
      "region": "Värmlands län [SE-17]",
      "code": "SE-S",
      "type": "County"
    },
    {
      "region": "Västerbottens län [SE-24]",
      "code": "SE-AC",
      "type": "County"
    },
    {
      "region": "Västernorrlands län [SE-22]",
      "code": "SE-Y",
      "type": "County"
    },
    {
      "region": "Västmanlands län [SE-19]",
      "code": "SE-U",
      "type": "County"
    },
    {
      "region": "Västra Götalands län [SE-14]",
      "code": "SE-O",
      "type": "County"
    },
    {
      "region": "Örebro län [SE-18]",
      "code": "SE-T",
      "type": "County"
    },
    {
      "region": "Östergötlands län [SE-05]",
      "code": "SE-E",
      "type": "County"
    }
  ],
  "SG": [
    {
      "region": "Central Singapore",
      "code": "SG-01",
      "type": "District"
    },
    {
      "region": "North East",
      "code": "SG-02",
      "type": "District"
    },
    {
      "region": "North West",
      "code": "SG-03",
      "type": "District"
    },
    {
      "region": "South East",
      "code": "SG-04",
      "type": "District"
    },
    {
      "region": "South West",
      "code": "SG-05",
      "type": "District"
    }
  ],
  "SH": [
    {
      "region": "Ascension",
      "code": "SH-AC",
      "type": "Geographical entity"
    },
    {
      "region": "Saint Helena",
      "code": "SH-HL",
      "type": "Geographical entity"
    },
    {
      "region": "Tristan da Cunha",
      "code": "SH-TA",
      "type": "Geographical entity"
    }
  ],
  "SI": [
    {
      "region": "Ajdovščina",
      "code": "SI-001",
      "type": "Municipality"
    },
    {
      "region": "Ankaran",
      "code": "SI-213",
      "type": "Municipality"
    },
    {
      "region": "Apače",
      "code": "SI-195",
      "type": "Municipality"
    },
    {
      "region": "Beltinci",
      "code": "SI-002",
      "type": "Municipality"
    },
    {
      "region": "Benedikt",
      "code": "SI-148",
      "type": "Municipality"
    },
    {
      "region": "Bistrica ob Sotli",
      "code": "SI-149",
      "type": "Municipality"
    },
    {
      "region": "Bled",
      "code": "SI-003",
      "type": "Municipality"
    },
    {
      "region": "Bloke",
      "code": "SI-150",
      "type": "Municipality"
    },
    {
      "region": "Bohinj",
      "code": "SI-004",
      "type": "Municipality"
    },
    {
      "region": "Borovnica",
      "code": "SI-005",
      "type": "Municipality"
    },
    {
      "region": "Bovec",
      "code": "SI-006",
      "type": "Municipality"
    },
    {
      "region": "Braslovče",
      "code": "SI-151",
      "type": "Municipality"
    },
    {
      "region": "Brda",
      "code": "SI-007",
      "type": "Municipality"
    },
    {
      "region": "Brezovica",
      "code": "SI-008",
      "type": "Municipality"
    },
    {
      "region": "Brežice",
      "code": "SI-009",
      "type": "Municipality"
    },
    {
      "region": "Cankova",
      "code": "SI-152",
      "type": "Municipality"
    },
    {
      "region": "Celje",
      "code": "SI-011",
      "type": "Municipality"
    },
    {
      "region": "Cerklje na Gorenjskem",
      "code": "SI-012",
      "type": "Municipality"
    },
    {
      "region": "Cerknica",
      "code": "SI-013",
      "type": "Municipality"
    },
    {
      "region": "Cerkno",
      "code": "SI-014",
      "type": "Municipality"
    },
    {
      "region": "Cerkvenjak",
      "code": "SI-153",
      "type": "Municipality"
    },
    {
      "region": "Cirkulane",
      "code": "SI-196",
      "type": "Municipality"
    },
    {
      "region": "Destrnik",
      "code": "SI-018",
      "type": "Municipality"
    },
    {
      "region": "Divača",
      "code": "SI-019",
      "type": "Municipality"
    },
    {
      "region": "Dobje",
      "code": "SI-154",
      "type": "Municipality"
    },
    {
      "region": "Dobrepolje",
      "code": "SI-020",
      "type": "Municipality"
    },
    {
      "region": "Dobrna",
      "code": "SI-155",
      "type": "Municipality"
    },
    {
      "region": "Dobrova-Polhov Gradec",
      "code": "SI-021",
      "type": "Municipality"
    },
    {
      "region": "Dobrovnik",
      "code": "SI-156",
      "type": "Municipality"
    },
    {
      "region": "Dol pri Ljubljani",
      "code": "SI-022",
      "type": "Municipality"
    },
    {
      "region": "Dolenjske Toplice",
      "code": "SI-157",
      "type": "Municipality"
    },
    {
      "region": "Domžale",
      "code": "SI-023",
      "type": "Municipality"
    },
    {
      "region": "Dornava",
      "code": "SI-024",
      "type": "Municipality"
    },
    {
      "region": "Dravograd",
      "code": "SI-025",
      "type": "Municipality"
    },
    {
      "region": "Duplek",
      "code": "SI-026",
      "type": "Municipality"
    },
    {
      "region": "Gorenja vas-Poljane",
      "code": "SI-027",
      "type": "Municipality"
    },
    {
      "region": "Gorišnica",
      "code": "SI-028",
      "type": "Municipality"
    },
    {
      "region": "Gorje",
      "code": "SI-207",
      "type": "Municipality"
    },
    {
      "region": "Gornja Radgona",
      "code": "SI-029",
      "type": "Municipality"
    },
    {
      "region": "Gornji Grad",
      "code": "SI-030",
      "type": "Municipality"
    },
    {
      "region": "Gornji Petrovci",
      "code": "SI-031",
      "type": "Municipality"
    },
    {
      "region": "Grad",
      "code": "SI-158",
      "type": "Municipality"
    },
    {
      "region": "Grosuplje",
      "code": "SI-032",
      "type": "Municipality"
    },
    {
      "region": "Hajdina",
      "code": "SI-159",
      "type": "Municipality"
    },
    {
      "region": "Hodoš",
      "code": "SI-161",
      "type": "Municipality"
    },
    {
      "region": "Horjul",
      "code": "SI-162",
      "type": "Municipality"
    },
    {
      "region": "Hoče-Slivnica",
      "code": "SI-160",
      "type": "Municipality"
    },
    {
      "region": "Hrastnik",
      "code": "SI-034",
      "type": "Municipality"
    },
    {
      "region": "Hrpelje-Kozina",
      "code": "SI-035",
      "type": "Municipality"
    },
    {
      "region": "Idrija",
      "code": "SI-036",
      "type": "Municipality"
    },
    {
      "region": "Ig",
      "code": "SI-037",
      "type": "Municipality"
    },
    {
      "region": "Ilirska Bistrica",
      "code": "SI-038",
      "type": "Municipality"
    },
    {
      "region": "Ivančna Gorica",
      "code": "SI-039",
      "type": "Municipality"
    },
    {
      "region": "Izola",
      "code": "SI-040",
      "type": "Municipality"
    },
    {
      "region": "Jesenice",
      "code": "SI-041",
      "type": "Municipality"
    },
    {
      "region": "Jezersko",
      "code": "SI-163",
      "type": "Municipality"
    },
    {
      "region": "Juršinci",
      "code": "SI-042",
      "type": "Municipality"
    },
    {
      "region": "Kamnik",
      "code": "SI-043",
      "type": "Municipality"
    },
    {
      "region": "Kanal",
      "code": "SI-044",
      "type": "Municipality"
    },
    {
      "region": "Kidričevo",
      "code": "SI-045",
      "type": "Municipality"
    },
    {
      "region": "Kobarid",
      "code": "SI-046",
      "type": "Municipality"
    },
    {
      "region": "Kobilje",
      "code": "SI-047",
      "type": "Municipality"
    },
    {
      "region": "Komen",
      "code": "SI-049",
      "type": "Municipality"
    },
    {
      "region": "Komenda",
      "code": "SI-164",
      "type": "Municipality"
    },
    {
      "region": "Koper",
      "code": "SI-050",
      "type": "Municipality"
    },
    {
      "region": "Kosanjevica na Krki",
      "code": "SI-197",
      "type": "Municipality"
    },
    {
      "region": "Kostel",
      "code": "SI-165",
      "type": "Municipality"
    },
    {
      "region": "Kozje",
      "code": "SI-051",
      "type": "Municipality"
    },
    {
      "region": "Kočevje",
      "code": "SI-048",
      "type": "Municipality"
    },
    {
      "region": "Kranj",
      "code": "SI-052",
      "type": "Municipality"
    },
    {
      "region": "Kranjska Gora",
      "code": "SI-053",
      "type": "Municipality"
    },
    {
      "region": "Križevci",
      "code": "SI-166",
      "type": "Municipality"
    },
    {
      "region": "Krško",
      "code": "SI-054",
      "type": "Municipality"
    },
    {
      "region": "Kungota",
      "code": "SI-055",
      "type": "Municipality"
    },
    {
      "region": "Kuzma",
      "code": "SI-056",
      "type": "Municipality"
    },
    {
      "region": "Laško",
      "code": "SI-057",
      "type": "Municipality"
    },
    {
      "region": "Lenart",
      "code": "SI-058",
      "type": "Municipality"
    },
    {
      "region": "Lendava",
      "code": "SI-059",
      "type": "Municipality"
    },
    {
      "region": "Litija",
      "code": "SI-060",
      "type": "Municipality"
    },
    {
      "region": "Ljubljana",
      "code": "SI-061",
      "type": "Municipality"
    },
    {
      "region": "Ljubno",
      "code": "SI-062",
      "type": "Municipality"
    },
    {
      "region": "Ljutomer",
      "code": "SI-063",
      "type": "Municipality"
    },
    {
      "region": "Log-Dragomer",
      "code": "SI-208",
      "type": "Municipality"
    },
    {
      "region": "Logatec",
      "code": "SI-064",
      "type": "Municipality"
    },
    {
      "region": "Lovrenc na Pohorju",
      "code": "SI-167",
      "type": "Municipality"
    },
    {
      "region": "Loška dolina",
      "code": "SI-065",
      "type": "Municipality"
    },
    {
      "region": "Loški Potok",
      "code": "SI-066",
      "type": "Municipality"
    },
    {
      "region": "Lukovica",
      "code": "SI-068",
      "type": "Municipality"
    },
    {
      "region": "Luče",
      "code": "SI-067",
      "type": "Municipality"
    },
    {
      "region": "Majšperk",
      "code": "SI-069",
      "type": "Municipality"
    },
    {
      "region": "Makole",
      "code": "SI-198",
      "type": "Municipality"
    },
    {
      "region": "Maribor",
      "code": "SI-070",
      "type": "Municipality"
    },
    {
      "region": "Markovci",
      "code": "SI-168",
      "type": "Municipality"
    },
    {
      "region": "Medvode",
      "code": "SI-071",
      "type": "Municipality"
    },
    {
      "region": "Mengeš",
      "code": "SI-072",
      "type": "Municipality"
    },
    {
      "region": "Metlika",
      "code": "SI-073",
      "type": "Municipality"
    },
    {
      "region": "Mežica",
      "code": "SI-074",
      "type": "Municipality"
    },
    {
      "region": "Miklavž na Dravskem polju",
      "code": "SI-169",
      "type": "Municipality"
    },
    {
      "region": "Miren-Kostanjevica",
      "code": "SI-075",
      "type": "Municipality"
    },
    {
      "region": "Mirna",
      "code": "SI-212",
      "type": "Municipality"
    },
    {
      "region": "Mirna Peč",
      "code": "SI-170",
      "type": "Municipality"
    },
    {
      "region": "Mislinja",
      "code": "SI-076",
      "type": "Municipality"
    },
    {
      "region": "Mokronog-Trebelno",
      "code": "SI-199",
      "type": "Municipality"
    },
    {
      "region": "Moravske Toplice",
      "code": "SI-078",
      "type": "Municipality"
    },
    {
      "region": "Moravče",
      "code": "SI-077",
      "type": "Municipality"
    },
    {
      "region": "Mozirje",
      "code": "SI-079",
      "type": "Municipality"
    },
    {
      "region": "Murska Sobota",
      "code": "SI-080",
      "type": "Municipality"
    },
    {
      "region": "Muta",
      "code": "SI-081",
      "type": "Municipality"
    },
    {
      "region": "Naklo",
      "code": "SI-082",
      "type": "Municipality"
    },
    {
      "region": "Nazarje",
      "code": "SI-083",
      "type": "Municipality"
    },
    {
      "region": "Nova Gorica",
      "code": "SI-084",
      "type": "Municipality"
    },
    {
      "region": "Novo Mesto",
      "code": "SI-085",
      "type": "Municipality"
    },
    {
      "region": "Odranci",
      "code": "SI-086",
      "type": "Municipality"
    },
    {
      "region": "Oplotnica",
      "code": "SI-171",
      "type": "Municipality"
    },
    {
      "region": "Ormož",
      "code": "SI-087",
      "type": "Municipality"
    },
    {
      "region": "Osilnica",
      "code": "SI-088",
      "type": "Municipality"
    },
    {
      "region": "Pesnica",
      "code": "SI-089",
      "type": "Municipality"
    },
    {
      "region": "Piran",
      "code": "SI-090",
      "type": "Municipality"
    },
    {
      "region": "Pivka",
      "code": "SI-091",
      "type": "Municipality"
    },
    {
      "region": "Podlehnik",
      "code": "SI-172",
      "type": "Municipality"
    },
    {
      "region": "Podvelka",
      "code": "SI-093",
      "type": "Municipality"
    },
    {
      "region": "Podčetrtek",
      "code": "SI-092",
      "type": "Municipality"
    },
    {
      "region": "Poljčane",
      "code": "SI-200",
      "type": "Municipality"
    },
    {
      "region": "Polzela",
      "code": "SI-173",
      "type": "Municipality"
    },
    {
      "region": "Postojna",
      "code": "SI-094",
      "type": "Municipality"
    },
    {
      "region": "Prebold",
      "code": "SI-174",
      "type": "Municipality"
    },
    {
      "region": "Preddvor",
      "code": "SI-095",
      "type": "Municipality"
    },
    {
      "region": "Prevalje",
      "code": "SI-175",
      "type": "Municipality"
    },
    {
      "region": "Ptuj",
      "code": "SI-096",
      "type": "Municipality"
    },
    {
      "region": "Puconci",
      "code": "SI-097",
      "type": "Municipality"
    },
    {
      "region": "Radenci",
      "code": "SI-100",
      "type": "Municipality"
    },
    {
      "region": "Radeče",
      "code": "SI-099",
      "type": "Municipality"
    },
    {
      "region": "Radlje ob Dravi",
      "code": "SI-101",
      "type": "Municipality"
    },
    {
      "region": "Radovljica",
      "code": "SI-102",
      "type": "Municipality"
    },
    {
      "region": "Ravne na Koroškem",
      "code": "SI-103",
      "type": "Municipality"
    },
    {
      "region": "Razkrižje",
      "code": "SI-176",
      "type": "Municipality"
    },
    {
      "region": "Rače-Fram",
      "code": "SI-098",
      "type": "Municipality"
    },
    {
      "region": "Renče-Vogrsko",
      "code": "SI-201",
      "type": "Municipality"
    },
    {
      "region": "Rečica ob Savinji",
      "code": "SI-209",
      "type": "Municipality"
    },
    {
      "region": "Ribnica",
      "code": "SI-104",
      "type": "Municipality"
    },
    {
      "region": "Ribnica na Pohorju",
      "code": "SI-177",
      "type": "Municipality"
    },
    {
      "region": "Rogatec",
      "code": "SI-107",
      "type": "Municipality"
    },
    {
      "region": "Rogaška Slatina",
      "code": "SI-106",
      "type": "Municipality"
    },
    {
      "region": "Rogašovci",
      "code": "SI-105",
      "type": "Municipality"
    },
    {
      "region": "Ruše",
      "code": "SI-108",
      "type": "Municipality"
    },
    {
      "region": "Selnica ob Dravi",
      "code": "SI-178",
      "type": "Municipality"
    },
    {
      "region": "Semič",
      "code": "SI-109",
      "type": "Municipality"
    },
    {
      "region": "Sevnica",
      "code": "SI-110",
      "type": "Municipality"
    },
    {
      "region": "Sežana",
      "code": "SI-111",
      "type": "Municipality"
    },
    {
      "region": "Slovenj Gradec",
      "code": "SI-112",
      "type": "Municipality"
    },
    {
      "region": "Slovenska Bistrica",
      "code": "SI-113",
      "type": "Municipality"
    },
    {
      "region": "Slovenske Konjice",
      "code": "SI-114",
      "type": "Municipality"
    },
    {
      "region": "Sodražica",
      "code": "SI-179",
      "type": "Municipality"
    },
    {
      "region": "Solčava",
      "code": "SI-180",
      "type": "Municipality"
    },
    {
      "region": "Središče ob Dravi",
      "code": "SI-202",
      "type": "Municipality"
    },
    {
      "region": "Starše",
      "code": "SI-115",
      "type": "Municipality"
    },
    {
      "region": "Straža",
      "code": "SI-203",
      "type": "Municipality"
    },
    {
      "region": "Sveta Ana",
      "code": "SI-181",
      "type": "Municipality"
    },
    {
      "region": "Sveta Trojica v Slovenskih goricah",
      "code": "SI-204",
      "type": "Municipality"
    },
    {
      "region": "Sveti Andraž v Slovenskih goricah",
      "code": "SI-182",
      "type": "Municipality"
    },
    {
      "region": "Sveti Jurij ob Ščavnici",
      "code": "SI-116",
      "type": "Municipality"
    },
    {
      "region": "Sveti Jurij v Slovenskih goricah",
      "code": "SI-210",
      "type": "Municipality"
    },
    {
      "region": "Sveti Tomaž",
      "code": "SI-205",
      "type": "Municipality"
    },
    {
      "region": "Tabor",
      "code": "SI-184",
      "type": "Municipality"
    },
    {
      "region": "Tišina",
      "code": "SI-010",
      "type": "Municipality"
    },
    {
      "region": "Tolmin",
      "code": "SI-128",
      "type": "Municipality"
    },
    {
      "region": "Trbovlje",
      "code": "SI-129",
      "type": "Municipality"
    },
    {
      "region": "Trebnje",
      "code": "SI-130",
      "type": "Municipality"
    },
    {
      "region": "Trnovska Vas",
      "code": "SI-185",
      "type": "Municipality"
    },
    {
      "region": "Trzin",
      "code": "SI-186",
      "type": "Municipality"
    },
    {
      "region": "Tržič",
      "code": "SI-131",
      "type": "Municipality"
    },
    {
      "region": "Turnišče",
      "code": "SI-132",
      "type": "Municipality"
    },
    {
      "region": "Velenje",
      "code": "SI-133",
      "type": "Municipality"
    },
    {
      "region": "Velika Polana",
      "code": "SI-187",
      "type": "Municipality"
    },
    {
      "region": "Velike Lašče",
      "code": "SI-134",
      "type": "Municipality"
    },
    {
      "region": "Veržej",
      "code": "SI-188",
      "type": "Municipality"
    },
    {
      "region": "Videm",
      "code": "SI-135",
      "type": "Municipality"
    },
    {
      "region": "Vipava",
      "code": "SI-136",
      "type": "Municipality"
    },
    {
      "region": "Vitanje",
      "code": "SI-137",
      "type": "Municipality"
    },
    {
      "region": "Vodice",
      "code": "SI-138",
      "type": "Municipality"
    },
    {
      "region": "Vojnik",
      "code": "SI-139",
      "type": "Municipality"
    },
    {
      "region": "Vransko",
      "code": "SI-189",
      "type": "Municipality"
    },
    {
      "region": "Vrhnika",
      "code": "SI-140",
      "type": "Municipality"
    },
    {
      "region": "Vuzenica",
      "code": "SI-141",
      "type": "Municipality"
    },
    {
      "region": "Zagorje ob Savi",
      "code": "SI-142",
      "type": "Municipality"
    },
    {
      "region": "Zavrč",
      "code": "SI-143",
      "type": "Municipality"
    },
    {
      "region": "Zreče",
      "code": "SI-144",
      "type": "Municipality"
    },
    {
      "region": "Črenšovci",
      "code": "SI-015",
      "type": "Municipality"
    },
    {
      "region": "Črna na Koroškem",
      "code": "SI-016",
      "type": "Municipality"
    },
    {
      "region": "Črnomelj",
      "code": "SI-017",
      "type": "Municipality"
    },
    {
      "region": "Šalovci",
      "code": "SI-033",
      "type": "Municipality"
    },
    {
      "region": "Šempeter-Vrtojba",
      "code": "SI-183",
      "type": "Municipality"
    },
    {
      "region": "Šentilj",
      "code": "SI-118",
      "type": "Municipality"
    },
    {
      "region": "Šentjernej",
      "code": "SI-119",
      "type": "Municipality"
    },
    {
      "region": "Šentjur",
      "code": "SI-120",
      "type": "Municipality"
    },
    {
      "region": "Šentrupert",
      "code": "SI-211",
      "type": "Municipality"
    },
    {
      "region": "Šenčur",
      "code": "SI-117",
      "type": "Municipality"
    },
    {
      "region": "Škocjan",
      "code": "SI-121",
      "type": "Municipality"
    },
    {
      "region": "Škofja Loka",
      "code": "SI-122",
      "type": "Municipality"
    },
    {
      "region": "Škofljica",
      "code": "SI-123",
      "type": "Municipality"
    },
    {
      "region": "Šmarje pri Jelšah",
      "code": "SI-124",
      "type": "Municipality"
    },
    {
      "region": "Šmarješke Toplice",
      "code": "SI-206",
      "type": "Municipality"
    },
    {
      "region": "Šmartno ob Paki",
      "code": "SI-125",
      "type": "Municipality"
    },
    {
      "region": "Šmartno pri Litiji",
      "code": "SI-194",
      "type": "Municipality"
    },
    {
      "region": "Šoštanj",
      "code": "SI-126",
      "type": "Municipality"
    },
    {
      "region": "Štore",
      "code": "SI-127",
      "type": "Municipality"
    },
    {
      "region": "Žalec",
      "code": "SI-190",
      "type": "Municipality"
    },
    {
      "region": "Železniki",
      "code": "SI-146",
      "type": "Municipality"
    },
    {
      "region": "Žetale",
      "code": "SI-191",
      "type": "Municipality"
    },
    {
      "region": "Žiri",
      "code": "SI-147",
      "type": "Municipality"
    },
    {
      "region": "Žirovnica",
      "code": "SI-192",
      "type": "Municipality"
    },
    {
      "region": "Žužemberk",
      "code": "SI-193",
      "type": "Municipality"
    }
  ],
  "SK": [
    {
      "region": "Banskobystrický kraj",
      "code": "SK-BC",
      "type": "Region"
    },
    {
      "region": "Bratislavský kraj",
      "code": "SK-BL",
      "type": "Region"
    },
    {
      "region": "Košický kraj",
      "code": "SK-KI",
      "type": "Region"
    },
    {
      "region": "Nitriansky kraj",
      "code": "SK-NI",
      "type": "Region"
    },
    {
      "region": "Prešovský kraj",
      "code": "SK-PV",
      "type": "Region"
    },
    {
      "region": "Trenčiansky kraj",
      "code": "SK-TC",
      "type": "Region"
    },
    {
      "region": "Trnavský kraj",
      "code": "SK-TA",
      "type": "Region"
    },
    {
      "region": "Žilinský kraj",
      "code": "SK-ZI",
      "type": "Region"
    }
  ],
  "SL": [
    {
      "region": "Eastern",
      "code": "SL-E",
      "type": "Province"
    },
    {
      "region": "North Western",
      "code": "SL-NW",
      "type": "Province"
    },
    {
      "region": "Northern",
      "code": "SL-N",
      "type": "Province"
    },
    {
      "region": "Southern",
      "code": "SL-S",
      "type": "Province"
    },
    {
      "region": "Western Area (Freetown)",
      "code": "SL-W",
      "type": "Area"
    }
  ],
  "SM": [
    {
      "region": "Acquaviva",
      "code": "SM-01",
      "type": "Municipality"
    },
    {
      "region": "Borgo Maggiore",
      "code": "SM-06",
      "type": "Municipality"
    },
    {
      "region": "Chiesanuova",
      "code": "SM-02",
      "type": "Municipality"
    },
    {
      "region": "Città di San Marino",
      "code": "SM-07",
      "type": "Municipality"
    },
    {
      "region": "Domagnano",
      "code": "SM-03",
      "type": "Municipality"
    },
    {
      "region": "Faetano",
      "code": "SM-04",
      "type": "Municipality"
    },
    {
      "region": "Fiorentino",
      "code": "SM-05",
      "type": "Municipality"
    },
    {
      "region": "Montegiardino",
      "code": "SM-08",
      "type": "Municipality"
    },
    {
      "region": "Serravalle",
      "code": "SM-09",
      "type": "Municipality"
    }
  ],
  "SN": [
    {
      "region": "Dakar",
      "code": "SN-DK",
      "type": "Region"
    },
    {
      "region": "Diourbel",
      "code": "SN-DB",
      "type": "Region"
    },
    {
      "region": "Fatick",
      "code": "SN-FK",
      "type": "Region"
    },
    {
      "region": "Kaffrine",
      "code": "SN-KA",
      "type": "Region"
    },
    {
      "region": "Kaolack",
      "code": "SN-KL",
      "type": "Region"
    },
    {
      "region": "Kolda",
      "code": "SN-KD",
      "type": "Region"
    },
    {
      "region": "Kédougou",
      "code": "SN-KE",
      "type": "Region"
    },
    {
      "region": "Louga",
      "code": "SN-LG",
      "type": "Region"
    },
    {
      "region": "Matam",
      "code": "SN-MT",
      "type": "Region"
    },
    {
      "region": "Saint-Louis",
      "code": "SN-SL",
      "type": "Region"
    },
    {
      "region": "Sédhiou",
      "code": "SN-SE",
      "type": "Region"
    },
    {
      "region": "Tambacounda",
      "code": "SN-TC",
      "type": "Region"
    },
    {
      "region": "Thiès",
      "code": "SN-TH",
      "type": "Region"
    },
    {
      "region": "Ziguinchor",
      "code": "SN-ZG",
      "type": "Region"
    }
  ],
  "SO": [
    {
      "region": "Awdal",
      "code": "SO-AW",
      "type": "Region"
    },
    {
      "region": "Bakool",
      "code": "SO-BK",
      "type": "Region"
    },
    {
      "region": "Banaadir",
      "code": "SO-BN",
      "type": "Region"
    },
    {
      "region": "Bari",
      "code": "SO-BR",
      "type": "Region"
    },
    {
      "region": "Bay",
      "code": "SO-BY",
      "type": "Region"
    },
    {
      "region": "Galguduud",
      "code": "SO-GA",
      "type": "Region"
    },
    {
      "region": "Gedo",
      "code": "SO-GE",
      "type": "Region"
    },
    {
      "region": "Hiiraan",
      "code": "SO-HI",
      "type": "Region"
    },
    {
      "region": "Jubbada Dhexe",
      "code": "SO-JD",
      "type": "Region"
    },
    {
      "region": "Jubbada Hoose",
      "code": "SO-JH",
      "type": "Region"
    },
    {
      "region": "Mudug",
      "code": "SO-MU",
      "type": "Region"
    },
    {
      "region": "Nugaal",
      "code": "SO-NU",
      "type": "Region"
    },
    {
      "region": "Sanaag",
      "code": "SO-SA",
      "type": "Region"
    },
    {
      "region": "Shabeellaha Dhexe",
      "code": "SO-SD",
      "type": "Region"
    },
    {
      "region": "Shabeellaha Hoose",
      "code": "SO-SH",
      "type": "Region"
    },
    {
      "region": "Sool",
      "code": "SO-SO",
      "type": "Region"
    },
    {
      "region": "Togdheer",
      "code": "SO-TO",
      "type": "Region"
    },
    {
      "region": "Woqooyi Galbeed",
      "code": "SO-WO",
      "type": "Region"
    }
  ],
  "SR": [
    {
      "region": "Brokopondo",
      "code": "SR-BR",
      "type": "District"
    },
    {
      "region": "Commewijne",
      "code": "SR-CM",
      "type": "District"
    },
    {
      "region": "Coronie",
      "code": "SR-CR",
      "type": "District"
    },
    {
      "region": "Marowijne",
      "code": "SR-MA",
      "type": "District"
    },
    {
      "region": "Nickerie",
      "code": "SR-NI",
      "type": "District"
    },
    {
      "region": "Para",
      "code": "SR-PR",
      "type": "District"
    },
    {
      "region": "Paramaribo",
      "code": "SR-PM",
      "type": "District"
    },
    {
      "region": "Saramacca",
      "code": "SR-SA",
      "type": "District"
    },
    {
      "region": "Sipaliwini",
      "code": "SR-SI",
      "type": "District"
    },
    {
      "region": "Wanica",
      "code": "SR-WA",
      "type": "District"
    }
  ],
  "SS": [
    {
      "region": "Central Equatoria",
      "code": "SS-EC",
      "type": "State"
    },
    {
      "region": "Eastern Equatoria",
      "code": "SS-EE",
      "type": "State"
    },
    {
      "region": "Jonglei",
      "code": "SS-JG",
      "type": "State"
    },
    {
      "region": "Lakes",
      "code": "SS-LK",
      "type": "State"
    },
    {
      "region": "Northern Bahr el Ghazal",
      "code": "SS-BN",
      "type": "State"
    },
    {
      "region": "Unity",
      "code": "SS-UY",
      "type": "State"
    },
    {
      "region": "Upper Nile",
      "code": "SS-NU",
      "type": "State"
    },
    {
      "region": "Warrap",
      "code": "SS-WR",
      "type": "State"
    },
    {
      "region": "Western Bahr el Ghazal",
      "code": "SS-BW",
      "type": "State"
    },
    {
      "region": "Western Equatoria",
      "code": "SS-EW",
      "type": "State"
    }
  ],
  "ST": [
    {
      "region": "Cantagalo",
      "code": "ST-02",
      "type": "District"
    },
    {
      "region": "Caué",
      "code": "ST-03",
      "type": "District"
    },
    {
      "region": "Lembá",
      "code": "ST-04",
      "type": "District"
    },
    {
      "region": "Lobata",
      "code": "ST-05",
      "type": "District"
    },
    {
      "region": "Mé-Zóchi",
      "code": "ST-06",
      "type": "District"
    },
    {
      "region": "Príncipe",
      "code": "ST-P",
      "type": "Autonomous region"
    },
    {
      "region": "Água Grande",
      "code": "ST-01",
      "type": "District"
    }
  ],
  "SV": [
    {
      "region": "Ahuachapán",
      "code": "SV-AH",
      "type": "Department"
    },
    {
      "region": "Cabañas",
      "code": "SV-CA",
      "type": "Department"
    },
    {
      "region": "Chalatenango",
      "code": "SV-CH",
      "type": "Department"
    },
    {
      "region": "Cuscatlán",
      "code": "SV-CU",
      "type": "Department"
    },
    {
      "region": "La Libertad",
      "code": "SV-LI",
      "type": "Department"
    },
    {
      "region": "La Paz",
      "code": "SV-PA",
      "type": "Department"
    },
    {
      "region": "La Unión",
      "code": "SV-UN",
      "type": "Department"
    },
    {
      "region": "Morazán",
      "code": "SV-MO",
      "type": "Department"
    },
    {
      "region": "San Miguel",
      "code": "SV-SM",
      "type": "Department"
    },
    {
      "region": "San Salvador",
      "code": "SV-SS",
      "type": "Department"
    },
    {
      "region": "San Vicente",
      "code": "SV-SV",
      "type": "Department"
    },
    {
      "region": "Santa Ana",
      "code": "SV-SA",
      "type": "Department"
    },
    {
      "region": "Sonsonate",
      "code": "SV-SO",
      "type": "Department"
    },
    {
      "region": "Usulután",
      "code": "SV-US",
      "type": "Department"
    }
  ],
  "SY": [
    {
      "region": "Al Lādhiqīyah",
      "code": "SY-LA",
      "type": "Province"
    },
    {
      "region": "Al Qunayţirah",
      "code": "SY-QU",
      "type": "Province"
    },
    {
      "region": "Al Ḩasakah",
      "code": "SY-HA",
      "type": "Province"
    },
    {
      "region": "Ar Raqqah",
      "code": "SY-RA",
      "type": "Province"
    },
    {
      "region": "As Suwaydā'",
      "code": "SY-SU",
      "type": "Province"
    },
    {
      "region": "Dar'ā",
      "code": "SY-DR",
      "type": "Province"
    },
    {
      "region": "Dayr az Zawr",
      "code": "SY-DY",
      "type": "Province"
    },
    {
      "region": "Dimashq",
      "code": "SY-DI",
      "type": "Province"
    },
    {
      "region": "Idlib",
      "code": "SY-ID",
      "type": "Province"
    },
    {
      "region": "Rīf Dimashq",
      "code": "SY-RD",
      "type": "Province"
    },
    {
      "region": "Ţarţūs",
      "code": "SY-TA",
      "type": "Province"
    },
    {
      "region": "Ḩalab",
      "code": "SY-HL",
      "type": "Province"
    },
    {
      "region": "Ḩamāh",
      "code": "SY-HM",
      "type": "Province"
    },
    {
      "region": "Ḩimş",
      "code": "SY-HI",
      "type": "Province"
    }
  ],
  "SZ": [
    {
      "region": "Hhohho",
      "code": "SZ-HH",
      "type": "Region"
    },
    {
      "region": "Lubombo",
      "code": "SZ-LU",
      "type": "Region"
    },
    {
      "region": "Manzini",
      "code": "SZ-MA",
      "type": "Region"
    },
    {
      "region": "Shiselweni",
      "code": "SZ-SH",
      "type": "Region"
    }
  ],
  "TD": [
    {
      "region": "Al Baţḩā’",
      "code": "TD-BA",
      "type": "Province"
    },
    {
      "region": "Al Buḩayrah",
      "code": "TD-LC",
      "type": "Province"
    },
    {
      "region": "Bahr el Ghazal",
      "code": "TD-BG",
      "type": "Province"
    },
    {
      "region": "Borkou",
      "code": "TD-BO",
      "type": "Province"
    },
    {
      "region": "Chari-Baguirmi",
      "code": "TD-CB",
      "type": "Province"
    },
    {
      "region": "Ennedi-Est",
      "code": "TD-EE",
      "type": "Province"
    },
    {
      "region": "Ennedi-Ouest",
      "code": "TD-EO",
      "type": "Province"
    },
    {
      "region": "Guéra",
      "code": "TD-GR",
      "type": "Province"
    },
    {
      "region": "Hadjer Lamis",
      "code": "TD-HL",
      "type": "Province"
    },
    {
      "region": "Kanem",
      "code": "TD-KA",
      "type": "Province"
    },
    {
      "region": "Logone-Occidental",
      "code": "TD-LO",
      "type": "Province"
    },
    {
      "region": "Logone-Oriental",
      "code": "TD-LR",
      "type": "Province"
    },
    {
      "region": "Madīnat Injamīnā",
      "code": "TD-ND",
      "type": "Province"
    },
    {
      "region": "Mandoul",
      "code": "TD-MA",
      "type": "Province"
    },
    {
      "region": "Mayo-Kebbi-Est",
      "code": "TD-ME",
      "type": "Province"
    },
    {
      "region": "Mayo-Kebbi-Ouest",
      "code": "TD-MO",
      "type": "Province"
    },
    {
      "region": "Moyen-Chari",
      "code": "TD-MC",
      "type": "Province"
    },
    {
      "region": "Ouaddaï",
      "code": "TD-OD",
      "type": "Province"
    },
    {
      "region": "Salamat",
      "code": "TD-SA",
      "type": "Province"
    },
    {
      "region": "Sila",
      "code": "TD-SI",
      "type": "Province"
    },
    {
      "region": "Tandjilé",
      "code": "TD-TA",
      "type": "Province"
    },
    {
      "region": "Tibastī",
      "code": "TD-TI",
      "type": "Province"
    },
    {
      "region": "Wadi Fira",
      "code": "TD-WF",
      "type": "Province"
    }
  ],
  "TG": [
    {
      "region": "Centrale",
      "code": "TG-C",
      "type": "Region"
    },
    {
      "region": "Kara",
      "code": "TG-K",
      "type": "Region"
    },
    {
      "region": "Maritime (Région)",
      "code": "TG-M",
      "type": "Region"
    },
    {
      "region": "Plateaux",
      "code": "TG-P",
      "type": "Region"
    },
    {
      "region": "Savanes",
      "code": "TG-S",
      "type": "Region"
    }
  ],
  "TH": [
    {
      "region": "Amnat Charoen",
      "code": "TH-37",
      "type": "Province"
    },
    {
      "region": "Ang Thong",
      "code": "TH-15",
      "type": "Province"
    },
    {
      "region": "Bueng Kan",
      "code": "TH-38",
      "type": "Province"
    },
    {
      "region": "Buri Ram",
      "code": "TH-31",
      "type": "Province"
    },
    {
      "region": "Chachoengsao",
      "code": "TH-24",
      "type": "Province"
    },
    {
      "region": "Chai Nat",
      "code": "TH-18",
      "type": "Province"
    },
    {
      "region": "Chaiyaphum",
      "code": "TH-36",
      "type": "Province"
    },
    {
      "region": "Chanthaburi",
      "code": "TH-22",
      "type": "Province"
    },
    {
      "region": "Chiang Mai",
      "code": "TH-50",
      "type": "Province"
    },
    {
      "region": "Chiang Rai",
      "code": "TH-57",
      "type": "Province"
    },
    {
      "region": "Chon Buri",
      "code": "TH-20",
      "type": "Province"
    },
    {
      "region": "Chumphon",
      "code": "TH-86",
      "type": "Province"
    },
    {
      "region": "Kalasin",
      "code": "TH-46",
      "type": "Province"
    },
    {
      "region": "Kamphaeng Phet",
      "code": "TH-62",
      "type": "Province"
    },
    {
      "region": "Kanchanaburi",
      "code": "TH-71",
      "type": "Province"
    },
    {
      "region": "Khon Kaen",
      "code": "TH-40",
      "type": "Province"
    },
    {
      "region": "Krabi",
      "code": "TH-81",
      "type": "Province"
    },
    {
      "region": "Krung Thep Maha Nakhon",
      "code": "TH-10",
      "type": "Metropolitan administration"
    },
    {
      "region": "Lampang",
      "code": "TH-52",
      "type": "Province"
    },
    {
      "region": "Lamphun",
      "code": "TH-51",
      "type": "Province"
    },
    {
      "region": "Loei",
      "code": "TH-42",
      "type": "Province"
    },
    {
      "region": "Lop Buri",
      "code": "TH-16",
      "type": "Province"
    },
    {
      "region": "Mae Hong Son",
      "code": "TH-58",
      "type": "Province"
    },
    {
      "region": "Maha Sarakham",
      "code": "TH-44",
      "type": "Province"
    },
    {
      "region": "Mukdahan",
      "code": "TH-49",
      "type": "Province"
    },
    {
      "region": "Nakhon Nayok",
      "code": "TH-26",
      "type": "Province"
    },
    {
      "region": "Nakhon Pathom",
      "code": "TH-73",
      "type": "Province"
    },
    {
      "region": "Nakhon Phanom",
      "code": "TH-48",
      "type": "Province"
    },
    {
      "region": "Nakhon Ratchasima",
      "code": "TH-30",
      "type": "Province"
    },
    {
      "region": "Nakhon Sawan",
      "code": "TH-60",
      "type": "Province"
    },
    {
      "region": "Nakhon Si Thammarat",
      "code": "TH-80",
      "type": "Province"
    },
    {
      "region": "Nan",
      "code": "TH-55",
      "type": "Province"
    },
    {
      "region": "Narathiwat",
      "code": "TH-96",
      "type": "Province"
    },
    {
      "region": "Nong Bua Lam Phu",
      "code": "TH-39",
      "type": "Province"
    },
    {
      "region": "Nong Khai",
      "code": "TH-43",
      "type": "Province"
    },
    {
      "region": "Nonthaburi",
      "code": "TH-12",
      "type": "Province"
    },
    {
      "region": "Pathum Thani",
      "code": "TH-13",
      "type": "Province"
    },
    {
      "region": "Pattani",
      "code": "TH-94",
      "type": "Province"
    },
    {
      "region": "Phangnga",
      "code": "TH-82",
      "type": "Province"
    },
    {
      "region": "Phatthalung",
      "code": "TH-93",
      "type": "Province"
    },
    {
      "region": "Phatthaya",
      "code": "TH-S",
      "type": "Special administrative city"
    },
    {
      "region": "Phayao",
      "code": "TH-56",
      "type": "Province"
    },
    {
      "region": "Phetchabun",
      "code": "TH-67",
      "type": "Province"
    },
    {
      "region": "Phetchaburi",
      "code": "TH-76",
      "type": "Province"
    },
    {
      "region": "Phichit",
      "code": "TH-66",
      "type": "Province"
    },
    {
      "region": "Phitsanulok",
      "code": "TH-65",
      "type": "Province"
    },
    {
      "region": "Phra Nakhon Si Ayutthaya",
      "code": "TH-14",
      "type": "Province"
    },
    {
      "region": "Phrae",
      "code": "TH-54",
      "type": "Province"
    },
    {
      "region": "Phuket",
      "code": "TH-83",
      "type": "Province"
    },
    {
      "region": "Prachin Buri",
      "code": "TH-25",
      "type": "Province"
    },
    {
      "region": "Prachuap Khiri Khan",
      "code": "TH-77",
      "type": "Province"
    },
    {
      "region": "Ranong",
      "code": "TH-85",
      "type": "Province"
    },
    {
      "region": "Ratchaburi",
      "code": "TH-70",
      "type": "Province"
    },
    {
      "region": "Rayong",
      "code": "TH-21",
      "type": "Province"
    },
    {
      "region": "Roi Et",
      "code": "TH-45",
      "type": "Province"
    },
    {
      "region": "Sa Kaeo",
      "code": "TH-27",
      "type": "Province"
    },
    {
      "region": "Sakon Nakhon",
      "code": "TH-47",
      "type": "Province"
    },
    {
      "region": "Samut Prakan",
      "code": "TH-11",
      "type": "Province"
    },
    {
      "region": "Samut Sakhon",
      "code": "TH-74",
      "type": "Province"
    },
    {
      "region": "Samut Songkhram",
      "code": "TH-75",
      "type": "Province"
    },
    {
      "region": "Saraburi",
      "code": "TH-19",
      "type": "Province"
    },
    {
      "region": "Satun",
      "code": "TH-91",
      "type": "Province"
    },
    {
      "region": "Si Sa Ket",
      "code": "TH-33",
      "type": "Province"
    },
    {
      "region": "Sing Buri",
      "code": "TH-17",
      "type": "Province"
    },
    {
      "region": "Songkhla",
      "code": "TH-90",
      "type": "Province"
    },
    {
      "region": "Sukhothai",
      "code": "TH-64",
      "type": "Province"
    },
    {
      "region": "Suphan Buri",
      "code": "TH-72",
      "type": "Province"
    },
    {
      "region": "Surat Thani",
      "code": "TH-84",
      "type": "Province"
    },
    {
      "region": "Surin",
      "code": "TH-32",
      "type": "Province"
    },
    {
      "region": "Tak",
      "code": "TH-63",
      "type": "Province"
    },
    {
      "region": "Trang",
      "code": "TH-92",
      "type": "Province"
    },
    {
      "region": "Trat",
      "code": "TH-23",
      "type": "Province"
    },
    {
      "region": "Ubon Ratchathani",
      "code": "TH-34",
      "type": "Province"
    },
    {
      "region": "Udon Thani",
      "code": "TH-41",
      "type": "Province"
    },
    {
      "region": "Uthai Thani",
      "code": "TH-61",
      "type": "Province"
    },
    {
      "region": "Uttaradit",
      "code": "TH-53",
      "type": "Province"
    },
    {
      "region": "Yala",
      "code": "TH-95",
      "type": "Province"
    },
    {
      "region": "Yasothon",
      "code": "TH-35",
      "type": "Province"
    }
  ],
  "TJ": [
    {
      "region": "Dushanbe",
      "code": "TJ-DU",
      "type": "Capital territory"
    },
    {
      "region": "Khatlon",
      "code": "TJ-KT",
      "type": "Region"
    },
    {
      "region": "Kŭhistoni Badakhshon",
      "code": "TJ-GB",
      "type": "Autonomous region"
    },
    {
      "region": "Sughd",
      "code": "TJ-SU",
      "type": "Region"
    },
    {
      "region": "nohiyahoi tobei jumhurí",
      "code": "TJ-RA",
      "type": "Districts under republic administration"
    }
  ],
  "TL": [
    {
      "region": "Aileu",
      "code": "TL-AL",
      "type": "Municipality"
    },
    {
      "region": "Ainaro",
      "code": "TL-AN",
      "type": "Municipality"
    },
    {
      "region": "Baucau",
      "code": "TL-BA",
      "type": "Municipality"
    },
    {
      "region": "Bobonaro",
      "code": "TL-BO",
      "type": "Municipality"
    },
    {
      "region": "Cova Lima",
      "code": "TL-CO",
      "type": "Municipality"
    },
    {
      "region": "Díli",
      "code": "TL-DI",
      "type": "Municipality"
    },
    {
      "region": "Ermera",
      "code": "TL-ER",
      "type": "Municipality"
    },
    {
      "region": "Lautein",
      "code": "TL-LA",
      "type": "Municipality"
    },
    {
      "region": "Likisá",
      "code": "TL-LI",
      "type": "Municipality"
    },
    {
      "region": "Manatuto",
      "code": "TL-MT",
      "type": "Municipality"
    },
    {
      "region": "Manufahi",
      "code": "TL-MF",
      "type": "Municipality"
    },
    {
      "region": "Oekusi-Ambenu",
      "code": "TL-OE",
      "type": "Special administrative region"
    },
    {
      "region": "Vikeke",
      "code": "TL-VI",
      "type": "Municipality"
    }
  ],
  "TM": [
    {
      "region": "Ahal",
      "code": "TM-A",
      "type": "Region"
    },
    {
      "region": "Aşgabat",
      "code": "TM-S",
      "type": "City"
    },
    {
      "region": "Balkan",
      "code": "TM-B",
      "type": "Region"
    },
    {
      "region": "Daşoguz",
      "code": "TM-D",
      "type": "Region"
    },
    {
      "region": "Lebap",
      "code": "TM-L",
      "type": "Region"
    },
    {
      "region": "Mary",
      "code": "TM-M",
      "type": "Region"
    }
  ],
  "TN": [
    {
      "region": "Ben Arous",
      "code": "TN-13",
      "type": "Governorate"
    },
    {
      "region": "Bizerte",
      "code": "TN-23",
      "type": "Governorate"
    },
    {
      "region": "Béja",
      "code": "TN-31",
      "type": "Governorate"
    },
    {
      "region": "Gabès",
      "code": "TN-81",
      "type": "Governorate"
    },
    {
      "region": "Gafsa",
      "code": "TN-71",
      "type": "Governorate"
    },
    {
      "region": "Jendouba",
      "code": "TN-32",
      "type": "Governorate"
    },
    {
      "region": "Kairouan",
      "code": "TN-41",
      "type": "Governorate"
    },
    {
      "region": "Kasserine",
      "code": "TN-42",
      "type": "Governorate"
    },
    {
      "region": "Kébili",
      "code": "TN-73",
      "type": "Governorate"
    },
    {
      "region": "L'Ariana",
      "code": "TN-12",
      "type": "Governorate"
    },
    {
      "region": "La Manouba",
      "code": "TN-14",
      "type": "Governorate"
    },
    {
      "region": "Le Kef",
      "code": "TN-33",
      "type": "Governorate"
    },
    {
      "region": "Mahdia",
      "code": "TN-53",
      "type": "Governorate"
    },
    {
      "region": "Monastir",
      "code": "TN-52",
      "type": "Governorate"
    },
    {
      "region": "Médenine",
      "code": "TN-82",
      "type": "Governorate"
    },
    {
      "region": "Nabeul",
      "code": "TN-21",
      "type": "Governorate"
    },
    {
      "region": "Sfax",
      "code": "TN-61",
      "type": "Governorate"
    },
    {
      "region": "Sidi Bouzid",
      "code": "TN-43",
      "type": "Governorate"
    },
    {
      "region": "Siliana",
      "code": "TN-34",
      "type": "Governorate"
    },
    {
      "region": "Sousse",
      "code": "TN-51",
      "type": "Governorate"
    },
    {
      "region": "Tataouine",
      "code": "TN-83",
      "type": "Governorate"
    },
    {
      "region": "Tozeur",
      "code": "TN-72",
      "type": "Governorate"
    },
    {
      "region": "Tunis",
      "code": "TN-11",
      "type": "Governorate"
    },
    {
      "region": "Zaghouan",
      "code": "TN-22",
      "type": "Governorate"
    }
  ],
  "TO": [
    {
      "region": "'Eua",
      "code": "TO-01",
      "type": "Division"
    },
    {
      "region": "Ha'apai",
      "code": "TO-02",
      "type": "Division"
    },
    {
      "region": "Niuas",
      "code": "TO-03",
      "type": "Division"
    },
    {
      "region": "Tongatapu",
      "code": "TO-04",
      "type": "Division"
    },
    {
      "region": "Vava'u",
      "code": "TO-05",
      "type": "Division"
    }
  ],
  "TR": [
    {
      "region": "Adana",
      "code": "TR-01",
      "type": "Province"
    },
    {
      "region": "Adıyaman",
      "code": "TR-02",
      "type": "Province"
    },
    {
      "region": "Afyonkarahisar",
      "code": "TR-03",
      "type": "Province"
    },
    {
      "region": "Aksaray",
      "code": "TR-68",
      "type": "Province"
    },
    {
      "region": "Amasya",
      "code": "TR-05",
      "type": "Province"
    },
    {
      "region": "Ankara",
      "code": "TR-06",
      "type": "Province"
    },
    {
      "region": "Antalya",
      "code": "TR-07",
      "type": "Province"
    },
    {
      "region": "Ardahan",
      "code": "TR-75",
      "type": "Province"
    },
    {
      "region": "Artvin",
      "code": "TR-08",
      "type": "Province"
    },
    {
      "region": "Aydın",
      "code": "TR-09",
      "type": "Province"
    },
    {
      "region": "Ağrı",
      "code": "TR-04",
      "type": "Province"
    },
    {
      "region": "Balıkesir",
      "code": "TR-10",
      "type": "Province"
    },
    {
      "region": "Bartın",
      "code": "TR-74",
      "type": "Province"
    },
    {
      "region": "Batman",
      "code": "TR-72",
      "type": "Province"
    },
    {
      "region": "Bayburt",
      "code": "TR-69",
      "type": "Province"
    },
    {
      "region": "Bilecik",
      "code": "TR-11",
      "type": "Province"
    },
    {
      "region": "Bingöl",
      "code": "TR-12",
      "type": "Province"
    },
    {
      "region": "Bitlis",
      "code": "TR-13",
      "type": "Province"
    },
    {
      "region": "Bolu",
      "code": "TR-14",
      "type": "Province"
    },
    {
      "region": "Burdur",
      "code": "TR-15",
      "type": "Province"
    },
    {
      "region": "Bursa",
      "code": "TR-16",
      "type": "Province"
    },
    {
      "region": "Denizli",
      "code": "TR-20",
      "type": "Province"
    },
    {
      "region": "Diyarbakır",
      "code": "TR-21",
      "type": "Province"
    },
    {
      "region": "Düzce",
      "code": "TR-81",
      "type": "Province"
    },
    {
      "region": "Edirne",
      "code": "TR-22",
      "type": "Province"
    },
    {
      "region": "Elazığ",
      "code": "TR-23",
      "type": "Province"
    },
    {
      "region": "Erzincan",
      "code": "TR-24",
      "type": "Province"
    },
    {
      "region": "Erzurum",
      "code": "TR-25",
      "type": "Province"
    },
    {
      "region": "Eskişehir",
      "code": "TR-26",
      "type": "Province"
    },
    {
      "region": "Gaziantep",
      "code": "TR-27",
      "type": "Province"
    },
    {
      "region": "Giresun",
      "code": "TR-28",
      "type": "Province"
    },
    {
      "region": "Gümüşhane",
      "code": "TR-29",
      "type": "Province"
    },
    {
      "region": "Hakkâri",
      "code": "TR-30",
      "type": "Province"
    },
    {
      "region": "Hatay",
      "code": "TR-31",
      "type": "Province"
    },
    {
      "region": "Isparta",
      "code": "TR-32",
      "type": "Province"
    },
    {
      "region": "Iğdır",
      "code": "TR-76",
      "type": "Province"
    },
    {
      "region": "Kahramanmaraş",
      "code": "TR-46",
      "type": "Province"
    },
    {
      "region": "Karabük",
      "code": "TR-78",
      "type": "Province"
    },
    {
      "region": "Karaman",
      "code": "TR-70",
      "type": "Province"
    },
    {
      "region": "Kars",
      "code": "TR-36",
      "type": "Province"
    },
    {
      "region": "Kastamonu",
      "code": "TR-37",
      "type": "Province"
    },
    {
      "region": "Kayseri",
      "code": "TR-38",
      "type": "Province"
    },
    {
      "region": "Kilis",
      "code": "TR-79",
      "type": "Province"
    },
    {
      "region": "Kocaeli",
      "code": "TR-41",
      "type": "Province"
    },
    {
      "region": "Konya",
      "code": "TR-42",
      "type": "Province"
    },
    {
      "region": "Kütahya",
      "code": "TR-43",
      "type": "Province"
    },
    {
      "region": "Kırklareli",
      "code": "TR-39",
      "type": "Province"
    },
    {
      "region": "Kırıkkale",
      "code": "TR-71",
      "type": "Province"
    },
    {
      "region": "Kırşehir",
      "code": "TR-40",
      "type": "Province"
    },
    {
      "region": "Malatya",
      "code": "TR-44",
      "type": "Province"
    },
    {
      "region": "Manisa",
      "code": "TR-45",
      "type": "Province"
    },
    {
      "region": "Mardin",
      "code": "TR-47",
      "type": "Province"
    },
    {
      "region": "Mersin",
      "code": "TR-33",
      "type": "Province"
    },
    {
      "region": "Muğla",
      "code": "TR-48",
      "type": "Province"
    },
    {
      "region": "Muş",
      "code": "TR-49",
      "type": "Province"
    },
    {
      "region": "Nevşehir",
      "code": "TR-50",
      "type": "Province"
    },
    {
      "region": "Niğde",
      "code": "TR-51",
      "type": "Province"
    },
    {
      "region": "Ordu",
      "code": "TR-52",
      "type": "Province"
    },
    {
      "region": "Osmaniye",
      "code": "TR-80",
      "type": "Province"
    },
    {
      "region": "Rize",
      "code": "TR-53",
      "type": "Province"
    },
    {
      "region": "Sakarya",
      "code": "TR-54",
      "type": "Province"
    },
    {
      "region": "Samsun",
      "code": "TR-55",
      "type": "Province"
    },
    {
      "region": "Siirt",
      "code": "TR-56",
      "type": "Province"
    },
    {
      "region": "Sinop",
      "code": "TR-57",
      "type": "Province"
    },
    {
      "region": "Sivas",
      "code": "TR-58",
      "type": "Province"
    },
    {
      "region": "Tekirdağ",
      "code": "TR-59",
      "type": "Province"
    },
    {
      "region": "Tokat",
      "code": "TR-60",
      "type": "Province"
    },
    {
      "region": "Trabzon",
      "code": "TR-61",
      "type": "Province"
    },
    {
      "region": "Tunceli",
      "code": "TR-62",
      "type": "Province"
    },
    {
      "region": "Uşak",
      "code": "TR-64",
      "type": "Province"
    },
    {
      "region": "Van",
      "code": "TR-65",
      "type": "Province"
    },
    {
      "region": "Yalova",
      "code": "TR-77",
      "type": "Province"
    },
    {
      "region": "Yozgat",
      "code": "TR-66",
      "type": "Province"
    },
    {
      "region": "Zonguldak",
      "code": "TR-67",
      "type": "Province"
    },
    {
      "region": "Çanakkale",
      "code": "TR-17",
      "type": "Province"
    },
    {
      "region": "Çankırı",
      "code": "TR-18",
      "type": "Province"
    },
    {
      "region": "Çorum",
      "code": "TR-19",
      "type": "Province"
    },
    {
      "region": "İstanbul",
      "code": "TR-34",
      "type": "Province"
    },
    {
      "region": "İzmir",
      "code": "TR-35",
      "type": "Province"
    },
    {
      "region": "Şanlıurfa",
      "code": "TR-63",
      "type": "Province"
    },
    {
      "region": "Şırnak",
      "code": "TR-73",
      "type": "Province"
    }
  ],
  "TT": [
    {
      "region": "Arima",
      "code": "TT-ARI",
      "type": "Borough"
    },
    {
      "region": "Chaguanas",
      "code": "TT-CHA",
      "type": "Borough"
    },
    {
      "region": "Couva-Tabaquite-Talparo",
      "code": "TT-CTT",
      "type": "Region"
    },
    {
      "region": "Diego Martin",
      "code": "TT-DMN",
      "type": "Region"
    },
    {
      "region": "Mayaro-Rio Claro",
      "code": "TT-MRC",
      "type": "Region"
    },
    {
      "region": "Penal-Debe",
      "code": "TT-PED",
      "type": "Region"
    },
    {
      "region": "Point Fortin",
      "code": "TT-PTF",
      "type": "Borough"
    },
    {
      "region": "Port of Spain",
      "code": "TT-POS",
      "type": "City"
    },
    {
      "region": "Princes Town",
      "code": "TT-PRT",
      "type": "Region"
    },
    {
      "region": "San Fernando",
      "code": "TT-SFO",
      "type": "City"
    },
    {
      "region": "San Juan-Laventille",
      "code": "TT-SJL",
      "type": "Region"
    },
    {
      "region": "Sangre Grande",
      "code": "TT-SGE",
      "type": "Region"
    },
    {
      "region": "Siparia",
      "code": "TT-SIP",
      "type": "Region"
    },
    {
      "region": "Tobago",
      "code": "TT-TOB",
      "type": "Ward"
    },
    {
      "region": "Tunapuna-Piarco",
      "code": "TT-TUP",
      "type": "Region"
    }
  ],
  "TV": [
    {
      "region": "Funafuti",
      "code": "TV-FUN",
      "type": "Town council"
    },
    {
      "region": "Nanumaga",
      "code": "TV-NMG",
      "type": "Island council"
    },
    {
      "region": "Nanumea",
      "code": "TV-NMA",
      "type": "Island council"
    },
    {
      "region": "Niutao",
      "code": "TV-NIT",
      "type": "Island council"
    },
    {
      "region": "Nui",
      "code": "TV-NUI",
      "type": "Island council"
    },
    {
      "region": "Nukufetau",
      "code": "TV-NKF",
      "type": "Island council"
    },
    {
      "region": "Nukulaelae",
      "code": "TV-NKL",
      "type": "Island council"
    },
    {
      "region": "Vaitupu",
      "code": "TV-VAI",
      "type": "Island council"
    }
  ],
  "TW": [
    {
      "region": "Changhua",
      "code": "TW-CHA",
      "type": "County"
    },
    {
      "region": "Chiayi",
      "code": "TW-CYI",
      "type": "City"
    },
    {
      "region": "Chiayi",
      "code": "TW-CYQ",
      "type": "County"
    },
    {
      "region": "Hsinchu",
      "code": "TW-HSQ",
      "type": "County"
    },
    {
      "region": "Hsinchu",
      "code": "TW-HSZ",
      "type": "City"
    },
    {
      "region": "Hualien",
      "code": "TW-HUA",
      "type": "County"
    },
    {
      "region": "Kaohsiung",
      "code": "TW-KHH",
      "type": "Special municipality"
    },
    {
      "region": "Keelung",
      "code": "TW-KEE",
      "type": "City"
    },
    {
      "region": "Kinmen",
      "code": "TW-KIN",
      "type": "County"
    },
    {
      "region": "Lienchiang",
      "code": "TW-LIE",
      "type": "County"
    },
    {
      "region": "Miaoli",
      "code": "TW-MIA",
      "type": "County"
    },
    {
      "region": "Nantou",
      "code": "TW-NAN",
      "type": "County"
    },
    {
      "region": "New Taipei",
      "code": "TW-NWT",
      "type": "Special municipality"
    },
    {
      "region": "Penghu",
      "code": "TW-PEN",
      "type": "County"
    },
    {
      "region": "Pingtung",
      "code": "TW-PIF",
      "type": "County"
    },
    {
      "region": "Taichung",
      "code": "TW-TXG",
      "type": "Special municipality"
    },
    {
      "region": "Tainan",
      "code": "TW-TNN",
      "type": "Special municipality"
    },
    {
      "region": "Taipei",
      "code": "TW-TPE",
      "type": "Special municipality"
    },
    {
      "region": "Taitung",
      "code": "TW-TTT",
      "type": "County"
    },
    {
      "region": "Taoyuan",
      "code": "TW-TAO",
      "type": "Special municipality"
    },
    {
      "region": "Yilan",
      "code": "TW-ILA",
      "type": "County"
    },
    {
      "region": "Yunlin",
      "code": "TW-YUN",
      "type": "County"
    }
  ],
  "TZ": [
    {
      "region": "Arusha",
      "code": "TZ-01",
      "type": "Region"
    },
    {
      "region": "Coast",
      "code": "TZ-19",
      "type": "Region"
    },
    {
      "region": "Dar es Salaam",
      "code": "TZ-02",
      "type": "Region"
    },
    {
      "region": "Dodoma",
      "code": "TZ-03",
      "type": "Region"
    },
    {
      "region": "Geita",
      "code": "TZ-27",
      "type": "Region"
    },
    {
      "region": "Iringa",
      "code": "TZ-04",
      "type": "Region"
    },
    {
      "region": "Kagera",
      "code": "TZ-05",
      "type": "Region"
    },
    {
      "region": "Katavi",
      "code": "TZ-28",
      "type": "Region"
    },
    {
      "region": "Kigoma",
      "code": "TZ-08",
      "type": "Region"
    },
    {
      "region": "Kilimanjaro",
      "code": "TZ-09",
      "type": "Region"
    },
    {
      "region": "Lindi",
      "code": "TZ-12",
      "type": "Region"
    },
    {
      "region": "Manyara",
      "code": "TZ-26",
      "type": "Region"
    },
    {
      "region": "Mara",
      "code": "TZ-13",
      "type": "Region"
    },
    {
      "region": "Mbeya",
      "code": "TZ-14",
      "type": "Region"
    },
    {
      "region": "Morogoro",
      "code": "TZ-16",
      "type": "Region"
    },
    {
      "region": "Mtwara",
      "code": "TZ-17",
      "type": "Region"
    },
    {
      "region": "Mwanza",
      "code": "TZ-18",
      "type": "Region"
    },
    {
      "region": "Njombe",
      "code": "TZ-29",
      "type": "Region"
    },
    {
      "region": "Pemba North",
      "code": "TZ-06",
      "type": "Region"
    },
    {
      "region": "Pemba South",
      "code": "TZ-10",
      "type": "Region"
    },
    {
      "region": "Rukwa",
      "code": "TZ-20",
      "type": "Region"
    },
    {
      "region": "Ruvuma",
      "code": "TZ-21",
      "type": "Region"
    },
    {
      "region": "Shinyanga",
      "code": "TZ-22",
      "type": "Region"
    },
    {
      "region": "Simiyu",
      "code": "TZ-30",
      "type": "Region"
    },
    {
      "region": "Singida",
      "code": "TZ-23",
      "type": "Region"
    },
    {
      "region": "Songwe",
      "code": "TZ-31",
      "type": "Region"
    },
    {
      "region": "Tabora",
      "code": "TZ-24",
      "type": "Region"
    },
    {
      "region": "Tanga",
      "code": "TZ-25",
      "type": "Region"
    },
    {
      "region": "Zanzibar North",
      "code": "TZ-07",
      "type": "Region"
    },
    {
      "region": "Zanzibar South",
      "code": "TZ-11",
      "type": "Region"
    },
    {
      "region": "Zanzibar West",
      "code": "TZ-15",
      "type": "Region"
    }
  ],
  "UA": [
    {
      "region": "Avtonomna Respublika Krym",
      "code": "UA-43",
      "type": "Republic"
    },
    {
      "region": "Cherkaska oblast",
      "code": "UA-71",
      "type": "Region"
    },
    {
      "region": "Chernihivska oblast",
      "code": "UA-74",
      "type": "Region"
    },
    {
      "region": "Chernivetska oblast",
      "code": "UA-77",
      "type": "Region"
    },
    {
      "region": "Dnipropetrovska oblast",
      "code": "UA-12",
      "type": "Region"
    },
    {
      "region": "Donetska oblast",
      "code": "UA-14",
      "type": "Region"
    },
    {
      "region": "Ivano-Frankivska oblast",
      "code": "UA-26",
      "type": "Region"
    },
    {
      "region": "Kharkivska oblast",
      "code": "UA-63",
      "type": "Region"
    },
    {
      "region": "Khersonska oblast",
      "code": "UA-65",
      "type": "Region"
    },
    {
      "region": "Khmelnytska oblast",
      "code": "UA-68",
      "type": "Region"
    },
    {
      "region": "Kirovohradska oblast",
      "code": "UA-35",
      "type": "Region"
    },
    {
      "region": "Kyiv",
      "code": "UA-30",
      "type": "City"
    },
    {
      "region": "Kyivska oblast",
      "code": "UA-32",
      "type": "Region"
    },
    {
      "region": "Luhanska oblast",
      "code": "UA-09",
      "type": "Region"
    },
    {
      "region": "Lvivska oblast",
      "code": "UA-46",
      "type": "Region"
    },
    {
      "region": "Mykolaivska oblast",
      "code": "UA-48",
      "type": "Region"
    },
    {
      "region": "Odeska oblast",
      "code": "UA-51",
      "type": "Region"
    },
    {
      "region": "Poltavska oblast",
      "code": "UA-53",
      "type": "Region"
    },
    {
      "region": "Rivnenska oblast",
      "code": "UA-56",
      "type": "Region"
    },
    {
      "region": "Sevastopol",
      "code": "UA-40",
      "type": "City"
    },
    {
      "region": "Sumska oblast",
      "code": "UA-59",
      "type": "Region"
    },
    {
      "region": "Ternopilska oblast",
      "code": "UA-61",
      "type": "Region"
    },
    {
      "region": "Vinnytska oblast",
      "code": "UA-05",
      "type": "Region"
    },
    {
      "region": "Volynska oblast",
      "code": "UA-07",
      "type": "Region"
    },
    {
      "region": "Zakarpatska oblast",
      "code": "UA-21",
      "type": "Region"
    },
    {
      "region": "Zaporizka oblast",
      "code": "UA-23",
      "type": "Region"
    },
    {
      "region": "Zhytomyrska oblast",
      "code": "UA-18",
      "type": "Region"
    }
  ],
  "UG": [
    {
      "region": "Central",
      "code": "UG-C",
      "type": "Geographical region"
    },
    {
      "region": "Eastern",
      "code": "UG-E",
      "type": "Geographical region"
    },
    {
      "region": "Northern",
      "code": "UG-N",
      "type": "Geographical region"
    },
    {
      "region": "Western",
      "code": "UG-W",
      "type": "Geographical region"
    }
  ],
  "UM": [
    {
      "region": "Baker Island",
      "code": "UM-81",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Howland Island",
      "code": "UM-84",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Jarvis Island",
      "code": "UM-86",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Johnston Atoll",
      "code": "UM-67",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Kingman Reef",
      "code": "UM-89",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Midway Islands",
      "code": "UM-71",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Navassa Island",
      "code": "UM-76",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Palmyra Atoll",
      "code": "UM-95",
      "type": "Islands, groups of islands"
    },
    {
      "region": "Wake Island",
      "code": "UM-79",
      "type": "Islands, groups of islands"
    }
  ],
  "US": [
    {
      "region": "Alabama",
      "code": "US-AL",
      "type": "State"
    },
    {
      "region": "Alaska",
      "code": "US-AK",
      "type": "State"
    },
    {
      "region": "American Samoa",
      "code": "US-AS",
      "type": "Outlying area"
    },
    {
      "region": "Arizona",
      "code": "US-AZ",
      "type": "State"
    },
    {
      "region": "Arkansas",
      "code": "US-AR",
      "type": "State"
    },
    {
      "region": "California",
      "code": "US-CA",
      "type": "State"
    },
    {
      "region": "Colorado",
      "code": "US-CO",
      "type": "State"
    },
    {
      "region": "Connecticut",
      "code": "US-CT",
      "type": "State"
    },
    {
      "region": "Delaware",
      "code": "US-DE",
      "type": "State"
    },
    {
      "region": "District of Columbia",
      "code": "US-DC",
      "type": "District"
    },
    {
      "region": "Florida",
      "code": "US-FL",
      "type": "State"
    },
    {
      "region": "Georgia",
      "code": "US-GA",
      "type": "State"
    },
    {
      "region": "Guam",
      "code": "US-GU",
      "type": "Outlying area"
    },
    {
      "region": "Hawaii",
      "code": "US-HI",
      "type": "State"
    },
    {
      "region": "Idaho",
      "code": "US-ID",
      "type": "State"
    },
    {
      "region": "Illinois",
      "code": "US-IL",
      "type": "State"
    },
    {
      "region": "Indiana",
      "code": "US-IN",
      "type": "State"
    },
    {
      "region": "Iowa",
      "code": "US-IA",
      "type": "State"
    },
    {
      "region": "Kansas",
      "code": "US-KS",
      "type": "State"
    },
    {
      "region": "Kentucky",
      "code": "US-KY",
      "type": "State"
    },
    {
      "region": "Louisiana",
      "code": "US-LA",
      "type": "State"
    },
    {
      "region": "Maine",
      "code": "US-ME",
      "type": "State"
    },
    {
      "region": "Maryland",
      "code": "US-MD",
      "type": "State"
    },
    {
      "region": "Massachusetts",
      "code": "US-MA",
      "type": "State"
    },
    {
      "region": "Michigan",
      "code": "US-MI",
      "type": "State"
    },
    {
      "region": "Minnesota",
      "code": "US-MN",
      "type": "State"
    },
    {
      "region": "Mississippi",
      "code": "US-MS",
      "type": "State"
    },
    {
      "region": "Missouri",
      "code": "US-MO",
      "type": "State"
    },
    {
      "region": "Montana",
      "code": "US-MT",
      "type": "State"
    },
    {
      "region": "Nebraska",
      "code": "US-NE",
      "type": "State"
    },
    {
      "region": "Nevada",
      "code": "US-NV",
      "type": "State"
    },
    {
      "region": "New Hampshire",
      "code": "US-NH",
      "type": "State"
    },
    {
      "region": "New Jersey",
      "code": "US-NJ",
      "type": "State"
    },
    {
      "region": "New Mexico",
      "code": "US-NM",
      "type": "State"
    },
    {
      "region": "New York",
      "code": "US-NY",
      "type": "State"
    },
    {
      "region": "North Carolina",
      "code": "US-NC",
      "type": "State"
    },
    {
      "region": "North Dakota",
      "code": "US-ND",
      "type": "State"
    },
    {
      "region": "Northern Mariana Islands",
      "code": "US-MP",
      "type": "Outlying area"
    },
    {
      "region": "Ohio",
      "code": "US-OH",
      "type": "State"
    },
    {
      "region": "Oklahoma",
      "code": "US-OK",
      "type": "State"
    },
    {
      "region": "Oregon",
      "code": "US-OR",
      "type": "State"
    },
    {
      "region": "Pennsylvania",
      "code": "US-PA",
      "type": "State"
    },
    {
      "region": "Puerto Rico",
      "code": "US-PR",
      "type": "Outlying area"
    },
    {
      "region": "Rhode Island",
      "code": "US-RI",
      "type": "State"
    },
    {
      "region": "South Carolina",
      "code": "US-SC",
      "type": "State"
    },
    {
      "region": "South Dakota",
      "code": "US-SD",
      "type": "State"
    },
    {
      "region": "Tennessee",
      "code": "US-TN",
      "type": "State"
    },
    {
      "region": "Texas",
      "code": "US-TX",
      "type": "State"
    },
    {
      "region": "United States Minor Outlying Islands",
      "code": "US-UM",
      "type": "Outlying area"
    },
    {
      "region": "Utah",
      "code": "US-UT",
      "type": "State"
    },
    {
      "region": "Vermont",
      "code": "US-VT",
      "type": "State"
    },
    {
      "region": "Virgin Islands, U.S.",
      "code": "US-VI",
      "type": "Outlying area"
    },
    {
      "region": "Virginia",
      "code": "US-VA",
      "type": "State"
    },
    {
      "region": "Washington",
      "code": "US-WA",
      "type": "State"
    },
    {
      "region": "West Virginia",
      "code": "US-WV",
      "type": "State"
    },
    {
      "region": "Wisconsin",
      "code": "US-WI",
      "type": "State"
    },
    {
      "region": "Wyoming",
      "code": "US-WY",
      "type": "State"
    }
  ],
  "UY": [
    {
      "region": "Artigas",
      "code": "UY-AR",
      "type": "Department"
    },
    {
      "region": "Canelones",
      "code": "UY-CA",
      "type": "Department"
    },
    {
      "region": "Cerro Largo",
      "code": "UY-CL",
      "type": "Department"
    },
    {
      "region": "Colonia",
      "code": "UY-CO",
      "type": "Department"
    },
    {
      "region": "Durazno",
      "code": "UY-DU",
      "type": "Department"
    },
    {
      "region": "Flores",
      "code": "UY-FS",
      "type": "Department"
    },
    {
      "region": "Florida",
      "code": "UY-FD",
      "type": "Department"
    },
    {
      "region": "Lavalleja",
      "code": "UY-LA",
      "type": "Department"
    },
    {
      "region": "Maldonado",
      "code": "UY-MA",
      "type": "Department"
    },
    {
      "region": "Montevideo",
      "code": "UY-MO",
      "type": "Department"
    },
    {
      "region": "Paysandú",
      "code": "UY-PA",
      "type": "Department"
    },
    {
      "region": "Rivera",
      "code": "UY-RV",
      "type": "Department"
    },
    {
      "region": "Rocha",
      "code": "UY-RO",
      "type": "Department"
    },
    {
      "region": "Río Negro",
      "code": "UY-RN",
      "type": "Department"
    },
    {
      "region": "Salto",
      "code": "UY-SA",
      "type": "Department"
    },
    {
      "region": "San José",
      "code": "UY-SJ",
      "type": "Department"
    },
    {
      "region": "Soriano",
      "code": "UY-SO",
      "type": "Department"
    },
    {
      "region": "Tacuarembó",
      "code": "UY-TA",
      "type": "Department"
    },
    {
      "region": "Treinta y Tres",
      "code": "UY-TT",
      "type": "Department"
    }
  ],
  "UZ": [
    {
      "region": "Andijon",
      "code": "UZ-AN",
      "type": "Region"
    },
    {
      "region": "Buxoro",
      "code": "UZ-BU",
      "type": "Region"
    },
    {
      "region": "Farg‘ona",
      "code": "UZ-FA",
      "type": "Region"
    },
    {
      "region": "Jizzax",
      "code": "UZ-JI",
      "type": "Region"
    },
    {
      "region": "Namangan",
      "code": "UZ-NG",
      "type": "Region"
    },
    {
      "region": "Navoiy",
      "code": "UZ-NW",
      "type": "Region"
    },
    {
      "region": "Qashqadaryo",
      "code": "UZ-QA",
      "type": "Region"
    },
    {
      "region": "Qoraqalpog‘iston Respublikasi",
      "code": "UZ-QR",
      "type": "Republic"
    },
    {
      "region": "Samarqand",
      "code": "UZ-SA",
      "type": "Region"
    },
    {
      "region": "Sirdaryo",
      "code": "UZ-SI",
      "type": "Region"
    },
    {
      "region": "Surxondaryo",
      "code": "UZ-SU",
      "type": "Region"
    },
    {
      "region": "Toshkent",
      "code": "UZ-TK",
      "type": "City"
    },
    {
      "region": "Toshkent",
      "code": "UZ-TO",
      "type": "Region"
    },
    {
      "region": "Xorazm",
      "code": "UZ-XO",
      "type": "Region"
    }
  ],
  "VC": [
    {
      "region": "Charlotte",
      "code": "VC-01",
      "type": "Parish"
    },
    {
      "region": "Grenadines",
      "code": "VC-06",
      "type": "Parish"
    },
    {
      "region": "Saint Andrew",
      "code": "VC-02",
      "type": "Parish"
    },
    {
      "region": "Saint David",
      "code": "VC-03",
      "type": "Parish"
    },
    {
      "region": "Saint George",
      "code": "VC-04",
      "type": "Parish"
    },
    {
      "region": "Saint Patrick",
      "code": "VC-05",
      "type": "Parish"
    }
  ],
  "VE": [
    {
      "region": "Amazonas",
      "code": "VE-Z",
      "type": "State"
    },
    {
      "region": "Anzoátegui",
      "code": "VE-B",
      "type": "State"
    },
    {
      "region": "Apure",
      "code": "VE-C",
      "type": "State"
    },
    {
      "region": "Aragua",
      "code": "VE-D",
      "type": "State"
    },
    {
      "region": "Barinas",
      "code": "VE-E",
      "type": "State"
    },
    {
      "region": "Bolívar",
      "code": "VE-F",
      "type": "State"
    },
    {
      "region": "Carabobo",
      "code": "VE-G",
      "type": "State"
    },
    {
      "region": "Cojedes",
      "code": "VE-H",
      "type": "State"
    },
    {
      "region": "Delta Amacuro",
      "code": "VE-Y",
      "type": "State"
    },
    {
      "region": "Dependencias Federales",
      "code": "VE-W",
      "type": "Federal dependency"
    },
    {
      "region": "Distrito Capital",
      "code": "VE-A",
      "type": "Capital district"
    },
    {
      "region": "Falcón",
      "code": "VE-I",
      "type": "State"
    },
    {
      "region": "Guárico",
      "code": "VE-J",
      "type": "State"
    },
    {
      "region": "La Guaira",
      "code": "VE-X",
      "type": "State"
    },
    {
      "region": "Lara",
      "code": "VE-K",
      "type": "State"
    },
    {
      "region": "Miranda",
      "code": "VE-M",
      "type": "State"
    },
    {
      "region": "Monagas",
      "code": "VE-N",
      "type": "State"
    },
    {
      "region": "Mérida",
      "code": "VE-L",
      "type": "State"
    },
    {
      "region": "Nueva Esparta",
      "code": "VE-O",
      "type": "State"
    },
    {
      "region": "Portuguesa",
      "code": "VE-P",
      "type": "State"
    },
    {
      "region": "Sucre",
      "code": "VE-R",
      "type": "State"
    },
    {
      "region": "Trujillo",
      "code": "VE-T",
      "type": "State"
    },
    {
      "region": "Táchira",
      "code": "VE-S",
      "type": "State"
    },
    {
      "region": "Yaracuy",
      "code": "VE-U",
      "type": "State"
    },
    {
      "region": "Zulia",
      "code": "VE-V",
      "type": "State"
    }
  ],
  "VN": [
    {
      "region": "An Giang",
      "code": "VN-44",
      "type": "Province"
    },
    {
      "region": "Bà Rịa - Vũng Tàu",
      "code": "VN-43",
      "type": "Province"
    },
    {
      "region": "Bình Dương",
      "code": "VN-57",
      "type": "Province"
    },
    {
      "region": "Bình Phước",
      "code": "VN-58",
      "type": "Province"
    },
    {
      "region": "Bình Thuận",
      "code": "VN-40",
      "type": "Province"
    },
    {
      "region": "Bình Định",
      "code": "VN-31",
      "type": "Province"
    },
    {
      "region": "Bạc Liêu",
      "code": "VN-55",
      "type": "Province"
    },
    {
      "region": "Bắc Giang",
      "code": "VN-54",
      "type": "Province"
    },
    {
      "region": "Bắc Kạn",
      "code": "VN-53",
      "type": "Province"
    },
    {
      "region": "Bắc Ninh",
      "code": "VN-56",
      "type": "Province"
    },
    {
      "region": "Bến Tre",
      "code": "VN-50",
      "type": "Province"
    },
    {
      "region": "Cao Bằng",
      "code": "VN-04",
      "type": "Province"
    },
    {
      "region": "Cà Mau",
      "code": "VN-59",
      "type": "Province"
    },
    {
      "region": "Cần Thơ",
      "code": "VN-CT",
      "type": "Municipality"
    },
    {
      "region": "Gia Lai",
      "code": "VN-30",
      "type": "Province"
    },
    {
      "region": "Hà Giang",
      "code": "VN-03",
      "type": "Province"
    },
    {
      "region": "Hà Nam",
      "code": "VN-63",
      "type": "Province"
    },
    {
      "region": "Hà Nội",
      "code": "VN-HN",
      "type": "Municipality"
    },
    {
      "region": "Hà Tĩnh",
      "code": "VN-23",
      "type": "Province"
    },
    {
      "region": "Hòa Bình",
      "code": "VN-14",
      "type": "Province"
    },
    {
      "region": "Hưng Yên",
      "code": "VN-66",
      "type": "Province"
    },
    {
      "region": "Hải Dương",
      "code": "VN-61",
      "type": "Province"
    },
    {
      "region": "Hải Phòng",
      "code": "VN-HP",
      "type": "Municipality"
    },
    {
      "region": "Hậu Giang",
      "code": "VN-73",
      "type": "Province"
    },
    {
      "region": "Hồ Chí Minh",
      "code": "VN-SG",
      "type": "Municipality"
    },
    {
      "region": "Khánh Hòa",
      "code": "VN-34",
      "type": "Province"
    },
    {
      "region": "Kiến Giang",
      "code": "VN-47",
      "type": "Province"
    },
    {
      "region": "Kon Tum",
      "code": "VN-28",
      "type": "Province"
    },
    {
      "region": "Lai Châu",
      "code": "VN-01",
      "type": "Province"
    },
    {
      "region": "Long An",
      "code": "VN-41",
      "type": "Province"
    },
    {
      "region": "Lào Cai",
      "code": "VN-02",
      "type": "Province"
    },
    {
      "region": "Lâm Đồng",
      "code": "VN-35",
      "type": "Province"
    },
    {
      "region": "Lạng Sơn",
      "code": "VN-09",
      "type": "Province"
    },
    {
      "region": "Nam Định",
      "code": "VN-67",
      "type": "Province"
    },
    {
      "region": "Nghệ An",
      "code": "VN-22",
      "type": "Province"
    },
    {
      "region": "Ninh Bình",
      "code": "VN-18",
      "type": "Province"
    },
    {
      "region": "Ninh Thuận",
      "code": "VN-36",
      "type": "Province"
    },
    {
      "region": "Phú Thọ",
      "code": "VN-68",
      "type": "Province"
    },
    {
      "region": "Phú Yên",
      "code": "VN-32",
      "type": "Province"
    },
    {
      "region": "Quảng Bình",
      "code": "VN-24",
      "type": "Province"
    },
    {
      "region": "Quảng Nam",
      "code": "VN-27",
      "type": "Province"
    },
    {
      "region": "Quảng Ngãi",
      "code": "VN-29",
      "type": "Province"
    },
    {
      "region": "Quảng Ninh",
      "code": "VN-13",
      "type": "Province"
    },
    {
      "region": "Quảng Trị",
      "code": "VN-25",
      "type": "Province"
    },
    {
      "region": "Sóc Trăng",
      "code": "VN-52",
      "type": "Province"
    },
    {
      "region": "Sơn La",
      "code": "VN-05",
      "type": "Province"
    },
    {
      "region": "Thanh Hóa",
      "code": "VN-21",
      "type": "Province"
    },
    {
      "region": "Thái Bình",
      "code": "VN-20",
      "type": "Province"
    },
    {
      "region": "Thái Nguyên",
      "code": "VN-69",
      "type": "Province"
    },
    {
      "region": "Thừa Thiên-Huế",
      "code": "VN-26",
      "type": "Province"
    },
    {
      "region": "Tiền Giang",
      "code": "VN-46",
      "type": "Province"
    },
    {
      "region": "Trà Vinh",
      "code": "VN-51",
      "type": "Province"
    },
    {
      "region": "Tuyên Quang",
      "code": "VN-07",
      "type": "Province"
    },
    {
      "region": "Tây Ninh",
      "code": "VN-37",
      "type": "Province"
    },
    {
      "region": "Vĩnh Long",
      "code": "VN-49",
      "type": "Province"
    },
    {
      "region": "Vĩnh Phúc",
      "code": "VN-70",
      "type": "Province"
    },
    {
      "region": "Yên Bái",
      "code": "VN-06",
      "type": "Province"
    },
    {
      "region": "Điện Biên",
      "code": "VN-71",
      "type": "Province"
    },
    {
      "region": "Đà Nẵng",
      "code": "VN-DN",
      "type": "Municipality"
    },
    {
      "region": "Đắk Lắk",
      "code": "VN-33",
      "type": "Province"
    },
    {
      "region": "Đắk Nông",
      "code": "VN-72",
      "type": "Province"
    },
    {
      "region": "Đồng Nai",
      "code": "VN-39",
      "type": "Province"
    },
    {
      "region": "Đồng Tháp",
      "code": "VN-45",
      "type": "Province"
    }
  ],
  "VU": [
    {
      "region": "Malampa",
      "code": "VU-MAP",
      "type": "Province"
    },
    {
      "region": "Pénama",
      "code": "VU-PAM",
      "type": "Province"
    },
    {
      "region": "Sanma",
      "code": "VU-SAM",
      "type": "Province"
    },
    {
      "region": "Shéfa",
      "code": "VU-SEE",
      "type": "Province"
    },
    {
      "region": "Taféa",
      "code": "VU-TAE",
      "type": "Province"
    },
    {
      "region": "Torba",
      "code": "VU-TOB",
      "type": "Province"
    }
  ],
  "WF": [
    {
      "region": "Alo",
      "code": "WF-AL",
      "type": "Administrative precinct"
    },
    {
      "region": "Sigave",
      "code": "WF-SG",
      "type": "Administrative precinct"
    },
    {
      "region": "Uvea",
      "code": "WF-UV",
      "type": "Administrative precinct"
    }
  ],
  "WS": [
    {
      "region": "A'ana",
      "code": "WS-AA",
      "type": "District"
    },
    {
      "region": "Aiga-i-le-Tai",
      "code": "WS-AL",
      "type": "District"
    },
    {
      "region": "Atua",
      "code": "WS-AT",
      "type": "District"
    },
    {
      "region": "Fa'asaleleaga",
      "code": "WS-FA",
      "type": "District"
    },
    {
      "region": "Gaga'emauga",
      "code": "WS-GE",
      "type": "District"
    },
    {
      "region": "Gagaifomauga",
      "code": "WS-GI",
      "type": "District"
    },
    {
      "region": "Palauli",
      "code": "WS-PA",
      "type": "District"
    },
    {
      "region": "Satupa'itea",
      "code": "WS-SA",
      "type": "District"
    },
    {
      "region": "Tuamasaga",
      "code": "WS-TU",
      "type": "District"
    },
    {
      "region": "Va'a-o-Fonoti",
      "code": "WS-VF",
      "type": "District"
    },
    {
      "region": "Vaisigano",
      "code": "WS-VS",
      "type": "District"
    }
  ],
  "YE": [
    {
      "region": "Abyan",
      "code": "YE-AB",
      "type": "Governorate"
    },
    {
      "region": "Al Bayḑā’",
      "code": "YE-BA",
      "type": "Governorate"
    },
    {
      "region": "Al Jawf",
      "code": "YE-JA",
      "type": "Governorate"
    },
    {
      "region": "Al Mahrah",
      "code": "YE-MR",
      "type": "Governorate"
    },
    {
      "region": "Al Maḩwīt",
      "code": "YE-MW",
      "type": "Governorate"
    },
    {
      "region": "Al Ḩudaydah",
      "code": "YE-HU",
      "type": "Governorate"
    },
    {
      "region": "Amānat al ‘Āşimah [city]",
      "code": "YE-SA",
      "type": "Municipality"
    },
    {
      "region": "Arkhabīl Suquţrá",
      "code": "YE-SU",
      "type": "Governorate"
    },
    {
      "region": "Aḑ Ḑāli‘",
      "code": "YE-DA",
      "type": "Governorate"
    },
    {
      "region": "Dhamār",
      "code": "YE-DH",
      "type": "Governorate"
    },
    {
      "region": "Ibb",
      "code": "YE-IB",
      "type": "Governorate"
    },
    {
      "region": "Laḩij",
      "code": "YE-LA",
      "type": "Governorate"
    },
    {
      "region": "Ma’rib",
      "code": "YE-MA",
      "type": "Governorate"
    },
    {
      "region": "Raymah",
      "code": "YE-RA",
      "type": "Governorate"
    },
    {
      "region": "Shabwah",
      "code": "YE-SH",
      "type": "Governorate"
    },
    {
      "region": "Tāʻizz",
      "code": "YE-TA",
      "type": "Governorate"
    },
    {
      "region": "Şanʻā’",
      "code": "YE-SN",
      "type": "Governorate"
    },
    {
      "region": "Şāʻdah",
      "code": "YE-SD",
      "type": "Governorate"
    },
    {
      "region": "Ḩajjah",
      "code": "YE-HJ",
      "type": "Governorate"
    },
    {
      "region": "Ḩaḑramawt",
      "code": "YE-HD",
      "type": "Governorate"
    },
    {
      "region": "‘Adan",
      "code": "YE-AD",
      "type": "Governorate"
    },
    {
      "region": "‘Amrān",
      "code": "YE-AM",
      "type": "Governorate"
    }
  ],
  "ZA": [
    {
      "region": "Eastern Cape",
      "code": "ZA-EC",
      "type": "Province"
    },
    {
      "region": "Free State",
      "code": "ZA-FS",
      "type": "Province"
    },
    {
      "region": "Gauteng",
      "code": "ZA-GP",
      "type": "Province"
    },
    {
      "region": "Kwazulu-Natal",
      "code": "ZA-KZN",
      "type": "Province"
    },
    {
      "region": "Limpopo",
      "code": "ZA-LP",
      "type": "Province"
    },
    {
      "region": "Mpumalanga",
      "code": "ZA-MP",
      "type": "Province"
    },
    {
      "region": "North-West",
      "code": "ZA-NW",
      "type": "Province"
    },
    {
      "region": "Northern Cape",
      "code": "ZA-NC",
      "type": "Province"
    },
    {
      "region": "Western Cape",
      "code": "ZA-WC",
      "type": "Province"
    }
  ],
  "ZM": [
    {
      "region": "Central",
      "code": "ZM-02",
      "type": "Province"
    },
    {
      "region": "Copperbelt",
      "code": "ZM-08",
      "type": "Province"
    },
    {
      "region": "Eastern",
      "code": "ZM-03",
      "type": "Province"
    },
    {
      "region": "Luapula",
      "code": "ZM-04",
      "type": "Province"
    },
    {
      "region": "Lusaka",
      "code": "ZM-09",
      "type": "Province"
    },
    {
      "region": "Muchinga",
      "code": "ZM-10",
      "type": "Province"
    },
    {
      "region": "North-Western",
      "code": "ZM-06",
      "type": "Province"
    },
    {
      "region": "Northern",
      "code": "ZM-05",
      "type": "Province"
    },
    {
      "region": "Southern",
      "code": "ZM-07",
      "type": "Province"
    },
    {
      "region": "Western",
      "code": "ZM-01",
      "type": "Province"
    }
  ],
  "ZW": [
    {
      "region": "Bulawayo",
      "code": "ZW-BU",
      "type": "Province"
    },
    {
      "region": "Harare",
      "code": "ZW-HA",
      "type": "Province"
    },
    {
      "region": "Manicaland",
      "code": "ZW-MA",
      "type": "Province"
    },
    {
      "region": "Mashonaland Central",
      "code": "ZW-MC",
      "type": "Province"
    },
    {
      "region": "Mashonaland East",
      "code": "ZW-ME",
      "type": "Province"
    },
    {
      "region": "Mashonaland West",
      "code": "ZW-MW",
      "type": "Province"
    },
    {
      "region": "Masvingo",
      "code": "ZW-MV",
      "type": "Province"
    },
    {
      "region": "Matabeleland North",
      "code": "ZW-MN",
      "type": "Province"
    },
    {
      "region": "Matabeleland South",
      "code": "ZW-MS",
      "type": "Province"
    },
    {
      "region": "Midlands",
      "code": "ZW-MI",
      "type": "Province"
    }
  ]
};

