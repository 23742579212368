import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
    setIsSerial, 
    setSerialFirstProfileHash, 
    setSerialPartNumber, 
    setTitle,
    setDescription,
    setCurrentStage,
    setGenre,
    setStyle,
    setPreviousSerialHash
  } from '../../store/publishingSlice';
import { apiInstance } from '../../utils/axiosInstance';
import { Auth } from 'aws-amplify';
import Header from '../../components/Header/Header';
import './WriterPublishType.css';

function WriterPublishType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNewSerial, setIsNewSerial] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [writerPosts, setWriterPosts] = useState([]);

  const userId = useSelector((state) => state.user.userId);
  const userName = useSelector((state) => state.user.userName);

  useEffect(() => {
    fetchWriterPosts();
  }, [userId, userName]);

  const fetchWriterPosts = async () => {
    try {
      const session = await Auth.currentSession();
      const token = session.idToken.jwtToken;

      const response = await apiInstance.get(`/pieces/serial_pieces?user_id=${userId}&author_name=${encodeURIComponent(userName)}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setWriterPosts(response.data.posts);

      console.log('Writer posts:', response.data.posts);
    } catch (error) {
      console.error('Error fetching writer posts:', error);
    }
  };

  const handleNewPost = () => {
    dispatch(setIsSerial(false));
    dispatch(setSerialFirstProfileHash(null));
    dispatch(setSerialPartNumber(null));
    if (isNewSerial) {
      // Logic for starting a new serial
    } else {
      // Logic for starting a regular post
    }
    dispatch(setCurrentStage('coverart'));
    navigate('../coverart');
  };

  const handleContinueSeries = () => {
    if (selectedSeries) {
      dispatch(setIsSerial(true));
      dispatch(setTitle(selectedSeries.title));
      dispatch(setDescription(selectedSeries.description));
      dispatch(setGenre(selectedSeries.genre));
      dispatch(setStyle(selectedSeries.style));

      if (selectedSeries.is_serial) {
        // Continuing an existing series
        dispatch(setSerialFirstProfileHash(selectedSeries.serial_first_profile_hash)); // Use the current piece as the most recent
        dispatch(setSerialPartNumber(selectedSeries.serial_total_parts + 1));
        dispatch(setPreviousSerialHash(selectedSeries.serial_most_recent_profile_hash));
      } else {
        // Starting a new series from a standalone post
        dispatch(setSerialFirstProfileHash(selectedSeries.piece_hash));
        dispatch(setSerialPartNumber(2)); // The selected post becomes part 1, new post is part 2
        dispatch(setPreviousSerialHash(selectedSeries.piece_hash));
      }


      // Additional logic for continuing a series
      dispatch(setCurrentStage('coverart'));
      navigate('../coverart');
    }
  };

  const handleSelectSeries = (post) => {
    setSelectedSeries(post);
    console.log('Selected series:', post);
  };

  

  return (
    <div className="writer-publish-type">
      <Header/>
      <h2 className="writer-publish-type__title">Choose one of the following</h2>
      
      <div className="writer-publish-type__section">
        <h3 className="writer-publish-type__section-title">1. Start a new</h3>
        <button className="writer-publish-type__button" onClick={handleNewPost}>
          {isNewSerial ? 'Start New Serial' : 'New Post'}
        </button>
      </div>

      <div className="writer-publish-type__section">
        <h3 className="writer-publish-type__section-title">2. Build on previous post/series</h3>
        <div className="writer-publish-type__serial-feed">
            {writerPosts.map((post, index) => (
                <div
                key={index}
                className={`writer-publish-type__serial-item ${selectedSeries && selectedSeries.piece_id === post.piece_id ? 'selected' : ''}`}
                onClick={() => handleSelectSeries(post)}
                >
                <img
                    src={post.image_jpeg || post.image_webp||post.image_url}
                    alt={post.title}
                    className="writer-publish-type__serial-image"
                />
                <div className="writer-publish-type__serial-details">
                    <div className="writer-publish-type__serial-title">{post.title}</div>
                    {post.is_serial && (
                    <div className="writer-publish-type__serial-info">Part {post.serial_part_number} of {post.serial_total_parts}</div>
                    )}
                </div>
                </div>
            ))}
        </div>
        <button
          className="writer-publish-type__button"
          onClick={handleContinueSeries}
          disabled={!selectedSeries}
        >
          Continue Series
        </button>
      </div>
    </div>
  );
}

export default WriterPublishType;