import React from 'react';
import Header from '../../components/Header/Header';
import CreateAccountWords from '../../components/CreateAccountWords/CreateAccountWords';
import Footer from '../../components/FooterCreateAccountInfo/FooterCreateAccountInfo';
import './GettingIntoCreateAccount.css';


function GettingIntoCreateAccount() {
  return (
    <div className="whatup-create-page">
      <Header />
      <div className="whatup-create-content">
        <CreateAccountWords />
      </div>
      <Footer />
    </div>
  );
}

export default GettingIntoCreateAccount;