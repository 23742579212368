import React from 'react';
import './FinerDetailsWords.css';

function FinerDetailsWords() {
    return (
        <div className="finer-details-words">
            <h3>Why ShorterStories anyway? What's the deal? </h3>
            <p>The writers life has always been a difficult one, a life of complex challenges that come with making a living from art.</p>
            <p>Unfortunately, the industry lacks incentive to support writers. Publishers publish books, not the writers themselves. And publishers that have invested in writers have ended up paying for it as writers tended to switch publishers for a better deal with their subsequent books. Its common wisdom that publishers that invested in writers lost out, and so the publishers don't invest in writers (or at least as much as they should).</p>
            <p>The long term job of marketing yourself and building your career falls to you.</p>
            <p>One of the realities of being a writer is that you don't just produce words. By daring to call yourself a writer, you have unwittingly agreed to be a marketer, an accountant, a CEO, a salesman, a PR firm and more.  </p>
            <p>Another bullshit reality of being a writer is that it rarely pays a comfortable wage. Even writers we would consider successful have day jobs to get by. </p>
            <p>While not being a pancea to all the problems within the writing industry, ShorterStories enhances the current system by addressing key challenges it faces. </p>
            <p>ShorterStories is a platform designed for writers to publish, to grow and develop an audience and to make money; a platform for building and strengthening a writing career.</p>

            <h3>But how does ShorterStories do all this? </h3>
            <p>As a writer on ShorterStories, you are be able to create a profile, publish written pieces and market books. In so doing, ShorterStories opens up opportunities designed to enhance your visibility and monetize your work effectively. Here's what you can expect:</p>
            <p><strong>Monetization through Affiliate Links:</strong> Earn from the affiliate links (tagged books) included in your pieces and on your profile page. We split affiliate revenue 75/25 in your favor, and reinvest the majority of what we take to build out the platform.</p>
            <p><strong>Insightful Statistics:</strong> Gain a deep understanding of your audience with detailed analytics, providing insights into how readers engage with your work.</p>
            <p><strong>Targeted Exposure:</strong> Our algorithms and search methods connect your stories with the readers most likely to enjoy and engage with them, expanding your reach and fostering a loyal following.</p>
            <p><strong>Platform Growth:</strong> We're dedicated to evolving our product for the industry, ensuring you have access to the best tools and opportunities for success. As time goes by, we'll continue to innovate and support your journey as a writer.</p>


            <h3>What's this you said about making money? Explain that to me. </h3>
            <p>You make money on the platform by using affiliate links. These are the books that you promote with every piece you publish on the platform.</p>
            <p>Affiliate Marketing, often called performance marketing, pays publishers for the sales they drive. When a consumer clicks on a link, if they check out on the retailers page, i.e., if they buy the book, then the retailer pays out a commission based on that retailer's specific commission rate. (You can check out the commission section for specific details.)</p>
            <p>Affiliate marketing forms the back bone of much of the internet. It's the technology that enables many businesses to operate, incluidng Expedia and Google flights (travel), LTK (influencer marketing), Nerd Wallet or Wire Cutter (consumer advice giants). Yet it is generally hidden from the consumer.</p>
            <p>In the world of affiliate marketing, ShorterStories operates a sub-network. This means we work one layer below the affiliate network, and don't track sales on the retailers' sites ourselves. Instead, we partner with affiliate networks that handle all tracking. When a consumer clicks on a link, we send that link to the affiliate network, which adds a tracking parameter to the user's journey. If they make a purchase, the network rewards us with a commission, we take our cut and pass the rest on to you.</p>
            
            <h3>Is there anything you can't do on the platform? </h3>
            <p>In joining the affiliate networks, we promised to uphold the terms and conditions of both the affiliate networks we partner with and the specific terms of the booksellers we work with.</p>
            <p>And so, by proxy, you too have agreed to these terms and conditions. So what are these responsibilities? </p>
            <p>Respect our affiliate partners and use the platform as intended. Don’t try to trick people with deceptive practices like cookie stuffing (an industry term for something nefarious that if you were doing you would know what it is). </p>
            <p>We promised our affiliates we wouldn't promote pornography or hate speech, so if we find you doing any of that, we'll have to 86 you and cancel your commissions. Moreover, if you do anything that's damaging to our reputation and the platform in general, chances are we'll probably remove you for that too. </p>
            <p>Make sure you don't publish copyrighted material and don't steal from others.</p>
            <p>The idea is to keep to the spirit of the platform. I.e., be cool. </p>

            <h3>But how does ShorterStories make money? What's the skinny on that?  </h3>
            <p>We take a cut. We split the commissions revenue 75/25 in your favor. Most of the money we take is reinvested back into the platform in order to grow. This serves everyones interests as the growth of the platform enhances the opportunities for writers on the platform.  </p>
            <p>The platform also makes commissions when readers search for books directly, without a writer acting as middleman. We don't want to be greedy so we share these revenue with you too. We owe the writers our existence, so it is our intention to split any money earned on the platform with writers in the same deal 75/25. These commissions will go to those writers who drive the most traffic to the platform and therefore ultimately the ones most helping to grow it.</p>
            <p> This is the contract of the platform, all incentives are to grow the platform, to the good of all. </p>
            <p>And that's the skinny on that. </p>

            <h3>What commissions can I expect? When do I get paid? </h3>
            <p>Let’s dive into the nitty-gritty. We pay out twice a month, on the 1st and 15th. But there’s a bit of a process before that sweet commission hits your account.</p>
            <p>When a customer buys something through your link, it starts as an open commission in the network. Think of it as a pending approval. We have to wait for the retailer to confirm and close the sale on their end. This can take a little time, each retailer acts on its own schedule.</p>
            <p>Once the sale is confirmed, we don’t pay out immediately. Instead, we hold the money for 30 days. Why? Well, this gives the platform some necessary cash flow to keep things running smoothly and continue growing. It’s like a buffer that helps us all in the long run.</p>
            <p>Currently, we have one bookseller partner, Books-A-Million, with a flat commission rate of 3% on all books. After our split, you receive 2.25% of the book sales. We are actively working to partner with more booksellers to increase your earning potential.</p>
            <p>So, in short: customer buys, retailer confirms, we hold the commission for 30 days, and then you get paid. Simple, transparent, and designed to keep the platform healthy and thriving.</p>
        </div>
    );
}

export default FinerDetailsWords;