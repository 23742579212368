import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthenticationStatus } from '../../store/userSlice';
import WriterLoginScreen from '../WriterLoginScreen/WriterLoginScreen';
import ApplyWriterScreen from '../ApplyWriterScreen/ApplyWriterScreen';
import PendingWriterScreen from '../PendingWriterScreen/PendingWriterScreen';
import InsideWritersPortal from '../InsideWritersPortal/InsideWritersPortal';

function WriterPortal() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userRole = useSelector((state) => state.user.userRole);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await dispatch(checkAuthenticationStatus());
      setIsLoading(false);
    })();
  }, [dispatch]);

  let content;
  let pageTitle = "Writer's Portal | ShorterStories";
  let pageDescription = "Join our community of writers. Publish your work and connect with readers passionate about books.";

  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (!isAuthenticated) {
    content = <WriterLoginScreen />;
    pageTitle = "Writer Login | ShorterStories";
    pageDescription = "Access your writer's account on Shorter Stories. Login to share your stories and engage with readers.";
  } else {
    switch(userRole) {
      case 'writer':
        content = <InsideWritersPortal />;
        pageTitle = "Writer's corner | ShorterStories";
        pageDescription = "Manage your profile, build your portfolio and explore your performance on your personalized ShorterStories dashboard.";
        break;
      case 'applicant':
        content = <PendingWriterScreen />;
        pageTitle = "Application status | ShorterStories";
        pageDescription = "Check the status of your writer application for ShorterStories. We're reviewing your submission and will be in touch soon.";
        break;
      default:
        content = <ApplyWriterScreen />;
        pageTitle = "Become a writer | ShorterStories";
        pageDescription = "Apply to become a writer on ShorterStories.";
    }
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      {content}
    </>
  );
}

export default WriterPortal;