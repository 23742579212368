import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import 'chartjs-adapter-date-fns'; 

// import 'chartjs-adapter-date-fns'; // Ensure date handling works properly

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

const timeframeMapping = {
  '7days': 'seven_days_stats',
  '6weeks': 'six_weeks_stats',
  '12months': 'twelve_months_stats'
};


const TwoAxisLineGraph = ({ timeframe = 'data7Days' }) => {
  const { stats, isLoading, error } = useSelector((state) => state.home);
  const selectedTime = useSelector(state => state.home.selectedTime);
  console.log(selectedTime);

  const getDataForTimeframe = () => {
    if (!stats) return [];
    const timeframeKey = timeframeMapping[selectedTime] || 'seven_days_stats'; // Default to seven days if no match
    return stats[timeframeKey] || [];
  };
  const data = getDataForTimeframe(selectedTime);

  const chartData = {
    labels: data.map(d => d.date_of_action),
    datasets: [
      {
        label: 'Pieces Published',
        data: data.map(d => d.pieces_published),
        borderColor: 'black',
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
        yAxisID: 'y-axis-1',
      },
      {
        label: 'Pieces Visited',
        data: data.map(d => d.piece_views),
        borderColor: 'black',
        borderDash: [10, 5],
        borderWidth: 2,
        pointRadius: 0,
        fill: false,
        yAxisID: 'y-axis-2',
      }
    ],
  };

  const options = {
    animation: { duration: 0 }, // Disables animations
    scales: {
      x: {
        type: 'time',
        time: {
          unit: selectedTime === '12months' ? 'month' : selectedTime === '6weeks' ? 'week' : 'day',
          tooltipFormat: selectedTime === '12months' ? 'MMMM yyyy' : 'MMM dd',
          isoWeekday: true,  
          displayFormats: {
            day: 'MMM dd',
            week: 'MMM dd',
            month: 'MMM yyyy'
          }
        },
        grid: {
          display: false
        }
      },
      'y-axis-1': {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        suggestedMax: 10,
        grid: {
          drawOnChartArea: false
        },
        title: {
            display: true,
            text: 'Pieces Published',
            font: {
              size: 14
            },
          }
      },
      'y-axis-2': {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
        suggestedMax: 10,
        title: {
            display: true,
            text: 'Pieces Visited',
            font: {
              size: 14
            },
          }
      }
    },
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart) => {
            return chart.data.datasets.map((dataset, index) => {
              const style = dataset.label === 'Pieces Published' ? 'solid' : 'dotted';
              return {
                datasetIndex: index,
                text: dataset.label,
                lineDash: style === 'solid' ? [] : [10,5],
                strokeStyle: dataset.borderColor,
                lineWidth: dataset.label === 'Pieces Published' ? 1.5 : 1,
                fillStyle: 'transparent',
              };
            });
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div style={{ maxWidth: '436px', width: '100%', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default TwoAxisLineGraph;
