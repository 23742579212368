import React from 'react';
import './HomePrivacyWords.css';

function HomePrivacyWords() {
    return (
        <div className="privacy-policy-words">
            <h3>1. Introduction </h3>
            <p>Welcome to ShorterStories, a unique and innovative platform dedicated to revolutionizing the world of writing and book marketing. ShorterStories provides a space for authors to harness their content to generate money and shine a spotlight on their work. We aim to enhance the traditional dynamics of the publishing industry, creating incentives that reward authors to grow the community. Simultaneously, we offer readers a diverse and engaging space where they can discover new authors and literary works, and where they enjoy, in essence, shopping for books.</p>
            <p>Our service is designed as an organic bookstore and a content publishing hub, where writers can share pieces, market books, build followings and connect with readers. Our commitment extends beyond just providing a platform for sharing and discovery. We understand the importance of privacy and data protection in the digital age. This Privacy Policy outlines how we collect, use, and safeguard the information of our visitors and users. We are dedicated to maintaining transparency in our practices and ensuring that your data is handled with the utmost care and respect.</p>

            <h3>2. Data Collection and Use</h3>
            <p>At ShorterStories, we are committed to maintaining the trust and confidence of our visitors and users. Part of this commitment involves being transparent about the types of data we collect and the purposes for which we use it.</p>
            
            <h4>Types of Data Collected</h4>
            <ul>
                <li>Device Information: We gather information about the device you use to access our platform, such as the device type and operating system.</li>
                <li>Operating System: Understanding the operating systems our visitors use helps us to optimize our platform for better compatibility and user experience.</li>
                <li>Browser Information: We collect data regarding the browser you use, which aids us in resolving any browser-specific issues and improving platform functionality.</li>
                <li>Regional Data: Information about your state and country is collected to understand our user demographic better and tailor our services accordingly.</li>
                <li>User Agent: We record the user agent string from your device, which provides us with technical details about the browser and operating system used.</li>
            </ul>
            
            <h3>3. Purpose of Data Collection</h3>
            <p>The primary goal of collecting this data is to:</p>
            <ul>
                <li>Improve Platform Performance: Understanding the devices, operating systems, and browsers our users employ allows us to optimize our platform for a wide range of technologies.</li>
                <li>Debugging and Troubleshooting: Device and browser information are crucial for diagnosing and resolving any technical issues that may arise, ensuring a seamless user experience.</li>
                <li>Analytics and User Behavior Understanding: By analyzing how users interact with our platform, we can continually enhance our features and content to better meet the needs of our audience.</li>
            </ul>

            <h3>4. Personal Information</h3>
            <p>It is important to note that our data collection focuses on non-personally identifiable information. We do not collect personally identifiable information (such as names, email addresses, or phone numbers) unless it is voluntarily provided by our users, for instance, through a contact form or registration process. When we do collect such information, it is used solely for the purpose for which it was provided, and we ensure its confidentiality and security in line with this privacy policy and applicable laws.</p>

            <h3>5. Third-Party Data Sharing and Affiliate Marketing</h3>
            <h4>Third-Party Data Sharing</h4>
            <p>Currently, ShorterStories does not share user data with third parties for marketing or data analysis purposes. However, we may use third-party services for operational functions, such as website hosting, analytics, or customer service interactions. These services are bound by confidentiality agreements and are not permitted to use the data for any other purposes.</p>

            <h4>Affiliate Marketing</h4>
            <p>ShorterStories has introduced affiliate marketing partnerships to provide users with direct links to purchase books or related products. This enables us to generate revenue and improve the platform, while also offering users convenient access to purchase related items. We ensure that all affiliate activity adheres to our privacy standards and any data shared with affiliates is handled securely and in line with this policy.</p>

            <h3>6. User Consent</h3>
            <h4>General Data Collection (All Users)</h4>
            <p>ShorterStories collects non-personally identifiable information for all users, such as device type, operating system, browser details, and regional data. This data is essential for improving our platform's performance and understanding user interactions. As this information does not identify individuals and is used solely to enhance the functionality of the platform, we do not request explicit user consent for this general data collection.</p>
            
            <h4>Additional Data Tracking (Logged-in Users)</h4>
            <p>For users who create an account and log in, ShorterStories conducts additional tracking of user interactions for algorithmic purposes, aimed at improving user experience and personalizing the platform. This includes analyzing how logged-in users navigate the site, which sections they frequent, and their content preferences. For this level of data collection, we obtain explicit user consent, ensuring users are fully aware of what data is being collected and how it is used. This helps us to not only enhance the user experience but also ensure transparency and respect for user privacy.</p>

            <h3>7. Compliance with Laws and Regulations</h3>
            <p>At ShorterStories, while our platform is in its early stages, we strive to operate in good faith with respect to data protection and privacy laws, committed to complying with data protection and privacy laws such as the GDPR and CCPA. For users who create an account and log in, we collect and track data on user interactions to personalize the platform and enhance the user experience. We ensure that all data collection practices are transparent and secure, with explicit user consent obtained for tracking.</p>
            <p>As we continue to grow, we are dedicated to continually updating our policies and practices to ensure full compliance with relevant laws and regulations.</p>
            


            <h3>8. User Rights</h3>
            <p>Your data privacy is important to us. When you create an account, we collect your email address and track your usage patterns to improve our services. You have the right to access, correct, or delete your data at any time. If you have any concerns or requests related to your data privacy, please contact Turbo at turbo@shorterstories.xyz.</p>

            <h3>9. Changes to the Privacy Policy</h3>
            <p>Our Privacy Policy will undergo changes as ShorterStories evolves. We pledge to communicate any significant changes to our users in a timely and clear manner. Updates to our policy will be posted on our website, ensuring our users are always informed about how their data is handled.</p>

            <h3>10. Contact Information</h3>
            <p>For any questions, concerns, or inquiries regarding privacy at ShorterStories, please feel free to contact Turbo at turbo@shorterstories.xyz. We welcome your input and are ready to assist with any privacy-related matters.</p>
        </div>
    );
}

export default HomePrivacyWords;
