import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HeaderPublish.css';
import  CameraIcon from '../../assets/images/photo.png';
import BookIcon from '../../assets/images/book.png';
import TypewriterIcon from '../../assets/images/piece.png';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStage } from '../../store/publishingSlice';


function HeaderPublish() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentStage = useSelector(state => state.publishing.currentStage);

  const stages = ['coverart', 'writing', 'details', 'preview']; 

  const goToStage = (stage) => {
    dispatch(setCurrentStage(stage));
    navigate(`/writers/publish/${stage}`);
  };

  const isStageActive = (stage) => {
    const stageIndex = stages.indexOf(stage);
    const currentStageIndex = stages.indexOf(currentStage);

    return stageIndex <= currentStageIndex;
  }



  return (
    <div className="header-publish-container">
      <div className="publish-step" onClick={() => goToStage('coverart')}>
        <img src={CameraIcon} className={isStageActive('coverart') ? 'active-icon' : 'inactive-icon'} />
        <span className={isStageActive('writing') ? 'active-arrow' : 'inactive-arrow'}>&nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;</span>
      </div>
      <div className="publish-step" onClick={() => goToStage('writing')}>
        <img src={TypewriterIcon} className={isStageActive('writing') ? 'active-icon' : 'inactive-icon'} />
        <span className={isStageActive('details') ? 'active-arrow' : 'inactive-arrow'}>&nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;</span>
      </div>
      <div className="publish-step" onClick={() => goToStage('details')}>
        <img src={BookIcon} className={isStageActive('details') ? 'active-icon' : 'inactive-icon'} />
      </div>
    </div>
  );
  
}

export default HeaderPublish;

