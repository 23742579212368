import React, { useEffect, useRef, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WriterTopCard from '../../components/WriterTopCard/WriterTopCard';
import Header from '../../components/HeaderEditProfile/HeaderEditProfile';
import ProfileDetailsEdit from '../../components/ProfileDetailsEdit/ProfileDetailsEdit'
import { useDispatch } from 'react-redux';
import { showGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData, setPreEditableAuthorsData } from '../../store/editWriterSlice';

// Correct the import paths according to your web project structure
import printingPressProfile from '../../assets/images/printingpressprofile.png';
import './EditProfileScreen.css'; // Your CSS file for the web styles
import { apiInstance } from '../../utils/axiosInstance';


const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bioImageRef = useRef(null); // Create a ref for the image
  const { pathname } = useLocation();
  const userId = useSelector((state) => state.user.userId);
  const userName = useSelector((state) => state.user.userName);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
  // Create a deep copy of authorsData for editing
  const editableAuthorsData = useSelector((state) => state.editWriter.editableAuthorsData);


  useEffect(() => {
    if (editableAuthorsData === null) { // Check if editableAuthorsData is null and then fetch data

      setLoading(true);

      const fetchAuthorProfile = async () => {
        try {
          const url = `/platformAuthors/summary/fetch?user_id=${userId}&author_name=${encodeURIComponent(userName)}`;
          const response = await apiInstance.get(url);
          // Assume a Redux action named setEditableAuthorsData that updates the author's data
          dispatch(setEditableAuthorsData(response.data));
          dispatch(setPreEditableAuthorsData(response.data));
          setLoading(false);
        } catch (error) {
          console.error('Error fetching the author profile:', error);
          setLoading(false);

        }
      };

      fetchAuthorProfile();
    } else {
      // Data does not exist, proceed to fetch it and setLoading true is handled within fetchAuthorProfile
      setLoading(false);
    }
    
  }, [dispatch, navigate, editableAuthorsData, userId, userName]);


  const bioImageSrcs = editableAuthorsData?.profile_image || printingPressProfile;


useEffect(() => {
  const adjustImage = () => {
    const img = bioImageRef.current;
    if (img && img.complete) {
      if (img.naturalWidth > img.naturalHeight) {
        img.style.height = `${img.offsetWidth}px`;
        img.style.objectFit = 'cover';
      } else {
        img.style.height = 'auto';
        img.style.objectFit = 'contain';
      }
    }
  };

  adjustImage();
}, [bioImageSrcs]); // Depend on bioImageSrc to re-run this effect if the image source changes



if (loading) {
  return <div>Loading...</div>; // or any other loading indicator
}


  

  // Convert the touchable opacity to a button for the we

  const handlePress = (modalType) => {
    dispatch(showGenericModal({
      modalType: modalType, // This could be 'EDIT_BIO' or any other modal type you have.
      modalProps: { // Assuming you want to pass some props based on the modal type
      }
    }));
  };

  const bioImageSrc = editableAuthorsData.profile_image_jpeg || printingPressProfile;


  return (
    <div className="editProfileContainer">
      <Header />
      <WriterTopCard summary={editableAuthorsData} />
      <div className="infoBox">
        <button className="infoButton" onClick={() => handlePress('EDIT_BIO')}>Swap out portrait in bio</button>
        {editableAuthorsData.short_bio && (
          <div className="bioContainer">
            <p className="authorBio">{editableAuthorsData.short_bio}</p>
          </div>
        )}
        <div className="editProfilePage-divider"/>
        <button className="infosButtoneasy" onClick={() => handlePress('EDIT_LINKS_AND_SOCIALS')}>Edit links and social media</button>
        
        <div className="centerWrapper">
          <div className="headshotLink" onClick={() => handlePress('EDIT_HEADSHOT')}>
            <picture>
              {editableAuthorsData.profile_image_webp && (
                <source srcSet={editableAuthorsData.profile_image_webp} type="image/webp" />
              )}
              {editableAuthorsData.profile_image_jpeg && (
                <source srcSet={editableAuthorsData.profile_image_jpeg} type="image/jpeg" />
              )}
              <img
                src={editableAuthorsData.profile_image_webp || editableAuthorsData.profile_image_jpeg || editableAuthorsData.profile_image || printingPressProfile}
                alt="Headshot"
                className="profileImage"
              />
            </picture>
            <span className="linkText">Swap out headshot</span>
          </div>
        </div>
        
        
        <ProfileDetailsEdit originalData={editableAuthorsData} handlePress={handlePress} />
      </div>
    </div>
  );
};


export default EditProfile;