import React, { useState } from 'react';
import './SocialMediaFormModal.css';
import { useDispatch } from 'react-redux';
import {addSocialMediaEntry} from '../../store/applyWriterSlice';
import { hideGenericModal } from '../../store/modalSlice';

const SocialMediaFormModal = () => {
  const [platform, setPlatform] = useState('');
  const [handle, setHandle] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    // Submit logic here, e.g., call onSubmit with platform and handle

    if (platform.trim() === '' || handle.trim() === '') {
        window.alert("Hold on! Neither the platform or the handle can be empty to add it to the pile.");
        return;
      }
    dispatch(addSocialMediaEntry({platform, handle}));
    setPlatform('');
    setHandle('');
    dispatch(hideGenericModal());

  };

  return (
    <div className="socialMediaFormModal">
      <div className="formGroup">
        <label>Social media platform (if website just enter 'web'):</label>
        <input
          type="text"
          value={platform}
          onChange={(e) => setPlatform(e.target.value)}
          placeholder="e.g. Instagram, Twitter"
        />
      </div>
      <div className="formGroup">
        <label>Handle or web address:</label>
        <input
          type="text"
          value={handle}
          onChange={(e) => setHandle(e.target.value)}
          placeholder="e.g. @shrtrstrz"
        />
      </div>
      <button onClick={handleSubmit}>Add</button>
    </div>
  );
};

export default SocialMediaFormModal;
