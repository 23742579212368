import React from 'react';
import './JobWords.css';

function JobWords() {
    const skills = ['- Computer hacking skillz', '- Book industry skillz', '- Design skillz', '- Operational skillz (Accounting and Finance, Legal, HR)', '- Science skillz', '- Selling and developing the biz skillz'];  
  return (
    <div className="about-words">
      <h3>Jobs ay?</h3>
      <p>
      Welcome. This isn't a conventional jobs page because we're anything but a conventional 
      company.
      </p>
      <p>
      The division of labor within companies was once seen as a necessity, promising 
      efficiency and the ability to scale operations. But, it came with heavy shackles. It 
      birthed bloated bureaucracies, dehumanized workers, led to endless meetings, 
      and spawned transaction costs at every turn. It removed genuine competition, fostering
       a self-serving executive class focused more on currying favor than adding real value. 
      </p>
      <p>
      The art of business, as it emerged, was no art at all. It was a pseudo-science that gave
       rise to restrictive cultures. Job titles became boxes, trapping people within walls, 
       stifling their true potential. The Agile movement, with its initial promise of a
        better way of working together, was corrupted into a tool used for surveillance.
      </p>
      <p>
      Today, we champion an approach that may seem iconoclastic. We're rejecting accepted 
      norms, driven by observations of the flaws in the workplace and the extraordinary opportunities 
      offered by new technologies. We note that the ideal isn't new, it's the emerging technologies
      that bring fresh opporutnities for both worker and business to fulfil them.
      </p>
      <p>
      We see each individual as a constellation of skills, not a single-function cog in a 
      machine. A designer can master front-end, a front-end coder can contribute to the back-end 
      architecture. A data scientist can understand the business, just as a marketer comprehends 
      how the user interacts with an algorithm. Everyone has empathy for the user. The team complements
      each other. 
        </p>
        <p>
        The barriers to acquiring new skills are gone. We share, we learn, and we grow 
        together. We're not just more productive; we're also liberated from the shackles of 
        the old, flawed notions. We acknowledge that a lack of traditional job titles might 
        seem daunting for your career trajectory. Here, you'll define your own title. And your 
        achievements will speak for themselves.
      </p>
      <h3>Nunchuck skillz?</h3>
      <p>
        We are just starting up the shop and thus looking for people who can help with 
        the following specific tasks: 
      </p>
      <div>
        {skills.map((skill, index) => (
            <p key={index}>{skill}</p>
        ))}
      </div>
      <p>
      Generally we're looking for people who are open, non-traditional, creative, have plenty 
      of ideas, are tolerant and who listen and truly love people. That is to say we're 
      looking for some rad dudes and dudettes. If this is you, email turbo at 'turbo@shorterstories.xyz'. 
      </p>
    </div>
  );
}

export default JobWords;