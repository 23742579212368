import React from 'react';
import './CreateAccountBox.css'; // Import CSS for styling
import { useNavigate } from 'react-router-dom';



const CreateAccountBox = () => {

  const navigate = useNavigate();

  const onCreateAccount = () => {
    navigate('/writers/create-account');
  };


  return (
    <div className="createAccountBox">
      <span className="createText">Don't have a ShorterStories account:&nbsp;</span>
      <button onClick={onCreateAccount} className="createLink"> create one</button>
    </div>
  );
}; 

export default CreateAccountBox;
