import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function usePreviousRoute() {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState();

  useEffect(() => {
    setPrevLocation(location.pathname);
  }, [location]);

  return prevLocation;
}

export default usePreviousRoute;
