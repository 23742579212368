
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmployeeStatus } from '../../store/employeeSlice'; // Update the path
import EmployeeLoginForm from '../../components/EmployeeLoginForm/EmployeeLoginForm'; // Update path as necessary
import NonEmployeePortal from '../../components/NonEmployeePortal/NonEmployeePortal';
import ReviewApplications from '../../components/ReviewApplications/ReviewApplications';
import './EmployeeDashboard.css';

function EmployeeDashboard() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const employeeRole = useSelector((state) => state.employee.employeeRole);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(checkEmployeeStatus());
      setIsLoading(false);  // Update isLoading when the data is fetched
    };

    fetchData();
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;  // Show a loading indicator or something similar
  } else if (!isAuthenticated) {
    return <EmployeeLoginForm />;
  } else if (employeeRole === 'cool') {
    return <ReviewApplications/>;
  } else {
    return <NonEmployeePortal/>;
  }
}

export default EmployeeDashboard;
