import React from 'react';
import './TagBookModalTwoBN.css';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedBookOnSaleBN } from '../../store/taggingSlice';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import failedbooks from '../../assets/images/book.png';

const TagBookModalTwoBN = () => {
  const dispatch = useDispatch();
  const chosenBook = useSelector((state) => state.tagging.chosenBook);
  const bnBooksOnSale = useSelector((state) => state.tagging.bnBooksOnSale);
  const selectedBookOnSaleBN = useSelector((state) => state.tagging.selectedBookOnSaleBN);
  console.log(bnBooksOnSale, 'books on sale');



  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    }));
  };

  const prevModal = () => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK_ONE_AND_HALF',
    }));
  };

  const handleSelectBookOnSale = (book) => {
    dispatch(setSelectedBookOnSaleBN(book));
  };

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        
        <h2 className="modalsTitles">Tagging a book:</h2>
        <div className="tags-dividers"/>
        <label className="sectionTitle">3. Choose the edition/publisher/price:</label>
        <div className="horizontal-feed-background">
          <div className="horizontal-feed">
            {bnBooksOnSale.items && bnBooksOnSale.items.map((book, index) => (
                <div
                    key={index}
                    className={`book-item ${selectedBookOnSaleBN && selectedBookOnSaleBN.external_product_id === book.external_product_id ? 'selected-book-item' : ''}`}
                    onClick={() => handleSelectBookOnSale(book)}
                >
                    <img
                    src={book.image_link || failedbooks}
                    alt="Cover"
                    className="books-images"
                    onError={(e) => { e.target.onerror = null; e.target.src = failedbooks; }}
                    />
                    <div className="book-details">
                    <div>{book.publisher}</div>
                    <div>${book.price_amount}</div>
                    <div>
                      <a 
                        href={book.link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        style={{ color: 'black', textDecoration: 'underline' }}
                      >
                        check link
                      </a>
                    </div>
                    </div>
                </div>
            ))}
          </div>
        </div>

        <button 
          className="infosButton-details" 
          onClick={() => nextModal('TAG_BOOK_THREE_BN', { /* next modal props */ })}
          disabled={!selectedBookOnSaleBN}
        >
          Next step &nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;
        </button>
        <button 
          className="infosButton-details-prev" 
          onClick={prevModal}
        >
          &lt;--- Previous step
        </button>
      </div>
    </div>
  );
};

export default TagBookModalTwoBN;