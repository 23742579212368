import React from 'react';
import { useSlate } from 'slate-react';
import { Editor } from 'slate';

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

export const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  const toggleMark = (editor, format) => {
    const isActive = Editor.marks(editor)?.[format];
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  return (
    <button
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      className={isMarkActive(editor, format) ? 'active' : ''}
    >
      <span className="material-icons">{icon}</span>
    </button>
  );
};
