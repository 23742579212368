import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData } from '../../store/editWriterSlice';

import './ModalAddLink.css';

const AddLinkModal = ({  }) => {
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);
  const [publisher, setPublisher] = useState('');
  const [link, setLink] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const [links, setLinks] = useState(authorData.magazine_links || []);

  
  const onAdd = (link) => {
    const newLinks = [...links, link];
    setLinks(newLinks);
    dispatch(setEditableAuthorsData({
      ...authorData,
      magazine_links: newLinks,
    }));
  };


  const validateLink = (url) => {
    // Simple regex for URL validation, consider using a more comprehensive solution in production
    const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name and extension
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!urlPattern.test(url);
  };

  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    })); // Immediately show the next modal
  };


  const handleAdd = () => {
    if (!publisher || !link || !title) {
      setError('Publisher, link and title are required.');
      return;
    }
    if (!validateLink(link)) {
      setError('Please enter a valid URL.');
      return;
    }
    // Here you could add additional checks for malicious URLs if needed

    onAdd({ publisher, link, title, description });
    nextModal('EDIT_LINKS_AND_SOCIALS', {
      // Pass any needed props for the "EDIT_LINKS_AND_SOCIALS" modal
    });
  };

  
  const handleDiscard = () => {

    nextModal('EDIT_LINKS_AND_SOCIALS', {
      // Pass any needed props for the "EDIT_LINKS_AND_SOCIALS" modal
    });
  };

  return (
    <div className="addLinkModalBackground" onClick={() => dispatch(hideGenericModal())}>
      <div className="addLinkModalContainer" onClick={(e) => e.stopPropagation()}>
        <h3 className='addslinkswriting'>Add Link</h3>
        {error && <p className="addLinkModalError">{error}</p>}
        <input
          type="text"
          value={publisher}
          onChange={(e) => setPublisher(e.target.value)}
          placeholder="Publisher, e.g. The New York Times"
          className="addLinkModalInput"
        />
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Link, e.g., www.example.com/real-article"
          className="addLinkModalInput"
        />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title, e.g., The curse of Ted Cunningham"
          className="addLinkModalInput"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description of the writing (optional)"
          className="addLinkModalTextarea"
        />
        <button onClick={handleAdd} className="addLinkModalButton">Add</button>
        <div className="separatorestest" />
        <button onClick={handleDiscard} className="discardLinkModalButton">Discard</button>
      </div>
    </div>
  );
};

export default AddLinkModal;
