import React, { useState, useEffect } from 'react';
import './DashHorizontalFeedBooks.css';
import failedBookImage from '../../assets/images/book.png'; 

const RetryableImage = ({ src, alt, className, maxRetries = 3, interval = 1000 }) => {
  const [retryCount, setRetryCount] = useState(0);
  const [imageSrc, setImageSrc] = useState(failedBookImage); // Start with the default image

  useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSrc(src); // Set actual image on successful load
    img.onerror = () => {
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          img.src = `${src}?retry=${retryCount + 1}`; // Retry with query string to bypass cache
        }, interval);
      } else {
        setImageSrc(failedBookImage); // Use fallback image if retries exceeded
      }
    };
    img.src = src; // Initial attempt to load the image
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount, maxRetries, interval]);

  return <img src={imageSrc} alt={alt} className={className} />;
};

const HorizontalFeedBooks = ({ feedName, feedItems }) => {

  
  if (feedItems.length === 0) {
    return (
      <div className="dash-horizontal-feed-books">
        <h2 className="feed-name">{feedName}</h2>
        <p>No items to display</p>
      </div>
    );
  }

  return (
    <div className="dash-horizontal-feed-books">
      <h2 className="feed-name">{feedName}</h2>
      <div className="feed-items">
        {feedItems.map((item, index) => (
          <div className="feed-item" key={index}>
            <RetryableImage
              className="book-image"
              src={item.example_image_link} // Use the correct property for image URL
              alt={item.book_raw_title}
              maxRetries={20} // You can adjust max retries and interval as needed
              interval={1000}
            />
            <p className="item-title">{item.book_raw_title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalFeedBooks;
