import React from 'react';
import { Link } from 'react-router-dom';
import './FooterFinerDetails.css';

function Footer() {

  return (
    <div className="footer-create">
      <Link to="/writers" className="finer-footer-link">Return to writer's portal</Link>
    </div>
  );
}

export default Footer;