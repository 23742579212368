import React from 'react';
import './AboutReadersWords.css';
import turbo from '../../assets/images/me-profile.png';
import birdie from '../../assets/images/piece-summary.png';
import bookswide from '../../assets/images/books-wide.png';

function AboutReadersWords() {
    return (
        <div className="about-readers-words">
            <h3>ShorterStories: A new kind of bookstore</h3>
            <p>Most book discovery apps focus on you connecting with friends, but what do your friends know about books? With ShorterStories you connect directly with writers and the books that inspire them.</p>
            <p>Imagine a bookstore where you don't browse shelves, but discover writers. Where each book recommendation comes with a story, and each story leads you to a new book. That's ShorterStories. </p>
            <p><strong>1. Quality reading:</strong></p>
            
            
            <ul>
                <li><strong>Diverse content:</strong> Explore everything from 50-word erotic poems to 5,000-word fantastical short stories.</li>
                <li><strong>Serial publishing:</strong> Follow stories week-to-week, enjoying the anticipation of serialized content.</li>
            </ul>
            
            <div className="profile-section">
                <p><strong>2. Writer connections:</strong></p>
                <img src={turbo} alt="Profile-photo" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Follow along:</strong> Keep up with your favorite writers.</li>
                <li><strong>Behind the veil:</strong> Get insights into the writing process and inspirations of writers.</li>
            </ul>
            <p><strong>3. Discovering books:</strong></p>
            <img src={bookswide} alt="book-wide" className="uninlinable-image" />
            <ul>
                <li><strong>Inspiration:</strong> Discover what books inspired your favorite authors.</li>
                <li><strong>Rabbit trails:</strong> Take the paths that lead from one writer to another and discover new voices and books.</li>
            </ul>
            

            <p><strong>4. Support the industry:</strong> When you buy books through ShorterStories, writers earn money: they receive a percentage of the sale. This creates a direct financial link between you and who you follow on the platform.</p>
            {/* <p><strong>5. Evolving Platform:</strong> We're constantly improving, ensuring you always have access to ever cooler stuff.</p> */}
        </div>
    );
}

export default AboutReadersWords;