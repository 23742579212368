import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import PublishImage from '../WritersPublishImage/WritersPublishImage'; 
import PublishWriting from '../WritersPublishWriting/WritersPublishWriting';
import PublishDetails from '../WritersPublishDetails/WritersPublishDetails'; 
import PublishPreview from '../WritersPublishPreview/WritersPublishPreview';  
import WriterPublishType from '../WriterPublishType/WriterPublishType';
import { useSelector } from 'react-redux';

function WritersPublishScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentStage = useSelector(state => state.publishing.currentStage); // Retrieve the current stage from Redux store or similar state management.

  useEffect(() => {
    // If the path is just 'publish', redirect to the last known step or the first step.
    if (location.pathname.endsWith('publish')) {
      navigate(currentStage || 'image', { replace: true });
    }
  }, [currentStage, location.pathname, navigate]);

  return (
    <Routes>
      <Route path="selection" element={<WriterPublishType />} />
      <Route path="coverart" element={<PublishImage />} />
      <Route path="writing" element={<PublishWriting />} />
      <Route path="details" element={<PublishDetails />} />
      <Route path="preview" element={<PublishPreview />} />
      {/* Add a redirect for any other paths that are not expected */}
      <Route path="*" element={<navigate to="selection" replace />} />
    </Routes>
  );
}

export default WritersPublishScreen;
