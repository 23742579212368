import React from 'react';
import './BooksInPiecePreview.css';
import { useDispatch } from 'react-redux';
import { showGenericModal } from '../../store/modalSlice';



const BooksInPiecePreview = ({ feedItems }) => {
  const dispatch = useDispatch();
  if (!feedItems || feedItems.length === 0) {
    return null;
  }

  console.log(feedItems);

  const handleBookClick = (book, index) => {

    // dispatch(showGenericModal({
    //   modalType: 'BOOK_MODAL', // String identifier
    //   modalProps: { book , prevRoute, position: index, pieceId, pieceHash } // Serializable props
    // }));

  };

  let repeatingBooks = [];
  while (repeatingBooks.length < 4) {
    repeatingBooks = repeatingBooks.concat(feedItems);
  }

  return (
    <div className="bookContainer">
      <div className="bookItemsContainer">
        {repeatingBooks.slice(0, 4).map((item, index) => (
          <div 
            className="bookItem" 
            key={index} 
            onClick={() => handleBookClick(item, index)}
          >
            <img 
              className="bookImage" 
              src={item.image_link} 
              alt={item.raw_title} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BooksInPiecePreview;
