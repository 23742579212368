import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  writersName: null,
  published: 'no',
  authorID: null,
  magPublished: [],
  socialMedia: [],
  lastWords: '',
};

export const applyWriterSlice = createSlice({
  name: 'applyWriter',
  initialState,
  reducers: {
    // Set the current step in the application flow
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    // Set the writer's name
    setWritersName: (state, action) => {
      state.writersName = action.payload;
    },
    // Set the published status
    setPublished: (state, action) => {
      state.published = action.payload;
    },
    // Set the author's ID
    setAuthorID: (state, action) => {
      state.authorID = action.payload;
    },
    // Set the magazines where the writer has been published
    setMagPublished: (state, action) => {
      state.magPublished = action.payload;
    },
    // Set the writer's social media accounts
    setSocialMedia: (state, action) => {
      state.socialMedia = action.payload;
    },
    // Set the last words or comments from the writer
    setLastWords: (state, action) => {
      state.lastWords = action.payload;
    },
    addSocialMediaEntry: (state, action) => {
      state.socialMedia.push(action.payload);
    },
    addMagPublishedEntry: (state, action) => {
      state.magPublished.push(action.payload);
    },
    // Reset the application state to its initial values
    resetApplication: (state) => {
      Object.assign(state, initialState);
    },
    removeSocialMediaEntry: (state, action) => {
      state.socialMedia = state.socialMedia.filter((_, index) => index !== action.payload);
    },
    removeMagPublishedEntry: (state, action) => {
      state.magPublished = state.magPublished.filter((_, index) => index !== action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentStep, 
  setWritersName,
  setPublished,
  setAuthorID,
  setMagPublished,
  setSocialMedia,
  setLastWords,
  resetApplication,
  addSocialMediaEntry,
  addMagPublishedEntry,
  removeSocialMediaEntry,
  removeMagPublishedEntry,
} = applyWriterSlice.actions;

export default applyWriterSlice.reducer;
