// AppFormSocials.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReusableBoxishContainer from '../ReusableBoxContainer/ReusableBoxContainer';
import {
  setCurrentStep,
  resetApplication,
  removeSocialMediaEntry,
  removeMagPublishedEntry,
  // other necessary actions from your slice
} from '../../store/applyWriterSlice';
import { showGenericModal } from '../../store/modalSlice';
import './AppFormSocials.css'; // Your CSS file for styling




const AppFormSocials = () => {
  const dispatch = useDispatch();
  const { socialMedia, magPublished } = useSelector(state => state.applyWriter);

  const handleMoveToNextStage = () => {

      dispatch(setCurrentStep(3));

  };

    // Inside AppFormSocials.js
  const handleShowSocialMediaModal = () => {

    if (socialMedia.length + magPublished.length >= 2) {
        window.alert("Whoa, two's plenty (links or socials) - no need to bother with doing extra");
        return;
    }

    dispatch(showGenericModal({
      modalType: 'SOCIAL_MEDIA_FORM',
      modalProps: {} // Add any props you might need for the form
    }));
  };

  const handleShowPublicationModal = () => {

    if (socialMedia.length + magPublished.length >= 2) {
      window.alert("Whoa, two's plenty (links or socials) - no need to bother with doing extra");
        return;
    }

    dispatch(showGenericModal({
      modalType: 'PUBLICATION_FORM',
      modalProps: {} // Add any props you might need for the form
    }));
  };

  return (
    <div className="applyFormSocialsContainer">
      <div className="titleContainer-social">
        <h2 className="bold-background">Some background</h2>
      </div>

      <p className="description">
        Do you have examples of your writing on social media, personal websites,
        or have your writings been published in magazines or online?
      </p>

      <button 
        className="socialsButtons" 
        onClick={handleShowSocialMediaModal}>
        Social media or personal website
      </button>
      

      <button 
         className="magazineButton" 
         onClick={handleShowPublicationModal}>
         Published in a magazine or online publisher

         
      </button>

      {socialMedia.length + magPublished.length > 0 && (
        <div className="contentishList">
          {socialMedia.map((social, index) => (
            <ReusableBoxishContainer
              key={index}
              title={social.platform}
              description={social.handle}
              showButton={true}
              buttonText="Remove"
              onButtonPress={() => dispatch(removeSocialMediaEntry(index))}
            />
          ))}
          {magPublished.map((publication, index) => (
            <ReusableBoxishContainer
              key={index}
              title={publication.magazine}
              description={publication.link}
              showButton={true}
              buttonText="Remove"
              onButtonPress={() => dispatch(removeMagPublishedEntry(index))}
            />
          ))}
        </div>
      )}
      <div className="dashed-separator-social" />

      {/* Social Media and Published inputs/modals here */}
      {/* Iterate over socialMedia and magPublished to display the added items */}

      <button className="applyButton" onClick={handleMoveToNextStage}>
        <span className="applyButtonText">Move to next stage of application ----&gt;</span>
      </button>
      <div className="separator" />
      <button className="discardButton" onClick={() => dispatch(resetApplication())}>
        Discard application
      </button>
    </div>
  );
};

export default AppFormSocials;
