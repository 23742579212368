import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setWritersName, setPublished, setCurrentStep, resetApplication } from '../../store/applyWriterSlice';
import './AppFormNamePublished.css';

const AppFormNamePublished = () => {
  const dispatch = useDispatch();
  const { writersName, published, currentStep } = useSelector(state => state.applyWriter);

  const handleNameChange = (event) => {
    dispatch(setWritersName(event.target.value));
  };

  const handlePublishedChange = (event) => {
    dispatch(setPublished(event.target.value));
  };

  const handleMoveToNextStage = () => {
    if (!writersName || writersName.trim() === '') {
      alert('Whoa, not so quick. You left the name empty. But everybody gotta have a name, Jack');
    } else {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const handleDiscardApplication = () => {
    dispatch(resetApplication());
  };

  return (
    <div className="applyFormOneContainer">
      <div className="titleContainer"> 
        <h2 className="bold-prelim">A few preliminaries</h2> 
      </div>
      <label htmlFor="writersName">1. What name do you write under?</label>
      <input
        id="writersName"
        type="text"
        value={writersName|| ''}
        onChange={handleNameChange}
        placeholder="Your name"
      />
      <label htmlFor="writersName">(Like the name you would publish a book under)</label>

      <div className="dashed-separator" />


      <div className="publishedSection">
        <label>2. Have you written any books that have been published?</label>
        <div className="radioWrapper">
          <div className="radioOptions">
            <input
              type="radio"
              id="publishedYes"
              name="published"
              value="yes"
              checked={published === 'yes'}
              onChange={handlePublishedChange}
            />
            <label htmlFor="publishedYes">Yes</label>

            <input
              type="radio"
              id="publishedNo"
              name="published"
              value="no"
              checked={published === 'no'}
              onChange={handlePublishedChange}
            />
            <label htmlFor="publishedNo">No</label>
          </div>
        </div>
      </div>
      <div className="dashed-separator" />


      <button className="applyButton" onClick={handleMoveToNextStage}>
        <span className="applyButtonText">Move to next stage of application ----&gt;</span>
      </button>
      <div className="separator" />
      <button className="discardButton" onClick={handleDiscardApplication}>
        Discard application
      </button>
    </div>
  );
};

export default AppFormNamePublished;
