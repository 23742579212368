import React from 'react';
import { useSelector } from 'react-redux';
import ForgotPasswordBox from '../../components/ForgotPasswordBox/ForgotPasswordBox'; // Adjust the path as necessary
import Header from '../../components/Header/Header'; // Adjust the path as necessary
import './ForgotPassword.css'; // Make sure to create this CSS file
import ResetPasswordBox from '../../components/ResetPasswordBox/ResetPasswordBox'; // Adjust the path as necessary

const ForgotPassword = () => {
    const currentForgotPasswordPage = useSelector((state) => state.user.currentForgotPasswordPage);


  return (
    <div className="container">
      <Header />
      <div className="contentContainer">
        {currentForgotPasswordPage === 'forgotPassword' && (
          <div className="boxContainer">
            <ForgotPasswordBox />
          </div>
        )}
        {currentForgotPasswordPage === 'resetPassword' && (
          <div className="boxContainer">
            <ResetPasswordBox />
          </div>
        )}
        {/* Add more conditions if there are other pages in the forgot password flow */}
      </div>
    </div>
  );
};

export default ForgotPassword;