import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutWords.css';
import birdie from '../../assets/images/birdie.jpeg';

function AboutWords() {
  const navigate = useNavigate();

  const handleInfo = (route) => {
    console.log(`Navigating to ${route}`);
    navigate(route);
  };

  return (
    <div className="about-words">
      <h3>You got questions? Who's asking?</h3>
      <p>
        ShorterStories means different things to different people. Click on the link below that feels like you might get some answers to what it means to you: 
      </p>
      <ul className="about-list">
        <li><span className="clickable-link" onClick={() => handleInfo("/about/writers")}>Writer</span></li>
        <li><span className="clickable-link" onClick={() => handleInfo("/about/readers")}>Reader or bookshopper</span></li>
        <li><span className="clickable-link" onClick={() => handleInfo("/about/industry")}>Bookseller or anyone in the biz</span></li>
      </ul>
      <div className="image-birdie-container">
        <img src={birdie} alt="Birdie" className="centered-birdie-image" />
      </div>
    </div>
  );
}

export default AboutWords;