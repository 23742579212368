import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { resetFilters, setShouldApplyFilters } from '../../store/theFeedSlice';
import { Auth } from 'aws-amplify';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { resetAuthorProfile } from '../../store/authorProfileSlice';
import { clearSelections } from '../../store/taggingSlice';
import { resetPublishingProcess } from '../../store/publishingSlice';
import { resetHomeStats } from '../../store/homeSlice';

import './ModalTheFeedOptions.css';

const FeedFilterModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filters = useSelector(state => state.theFeed);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);

  const showFilterModal = (filterType) => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: filterType,
    }));
  };

  const handleClearFilters = () => {
    dispatch(resetFilters());
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };

  const handleLoginOrSignup = () => {
    dispatch(hideGenericModal());
    navigate('/login');
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      dispatch(checkAuthenticationStatus());
      navigate("/");
      dispatch(resetAuthorProfile());
      dispatch(clearSelections());
      dispatch(resetPublishingProcess());
      dispatch(hideGenericModal());
      dispatch(resetHomeStats());
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleInfo = () => {
    console.log("Display Info");
    dispatch(hideGenericModal());
    navigate("/writers/finer-details");
  };

  const filterOptions = [
    { label: "genre", type: "FILTER_GENRE" },
    { label: "style", type: "FILTER_STYLE" },
    { label: "length", type: "FILTER_LENGTH" },
    { label: "when the writer grew up", type: "FILTER_DECADE" },
    { label: "where the writer grew up", type: "FILTER_LOCATION_GREW_UP" },
    { label: "where the writer lives", type: "FILTER_LOCATION_LIVE_NOW" }
  ];

  const isAnyFilterApplied = 
    filters.genre.length > 0 ||
    filters.style.length > 0 ||
    filters.length.length > 0 ||
    filters.decade.length > 0 ||
    filters.locationGrewUp.country !== '' ||
    filters.locationGrewUp.region !== '' ||
    filters.locationLiveNow.country !== '' ||
    filters.locationLiveNow.region !== '';

  return (
    <div className="homeSettingsModalContainer">
      <div className="homeSettingsModalOptions">
        <p className="homeSettingsModalTitle">Filter by:</p>
        {filterOptions.map(option => (
          <button
            key={option.type}
            onClick={() => showFilterModal(option.type)}
            className="homeSettingsModalButton"
          >
            - {option.label}
          </button>
        ))}
        {isAnyFilterApplied && (
          <p 
            className="homeSettingsModalTitle" 
            onClick={handleClearFilters} 
            style={{ cursor: 'pointer' }}
          >
            Clear filters
          </p>
        )}
        {isAuthenticated ? (
          <h3 
            className="homeSettingsModalsTitle" 
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            Logout
          </h3>
        ) : (
          <h3 
            className="homeSettingsModalsTitle" 
            onClick={handleLoginOrSignup}
            style={{ cursor: 'pointer' }}
          >
            Create account/login
          </h3>
        )}
      </div>
    </div>
  );
};

export default FeedFilterModal;