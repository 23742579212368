import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData } from '../../store/editWriterSlice';   
import './ModalAddSocial.css';

const AddSocialModal = ({  }) => {
  const [platform, setPlatform] = useState('');
  const [handle, setHandle] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);
  const [socials, setSocials] = useState(authorData.social_links || []);

  
  const onAdd = (social) => {
    const newSocials = [...socials, social];
    setSocials( newSocials);
    dispatch(setEditableAuthorsData({
      ...authorData,
      social_links: newSocials,
    }));
  };
  

  const socialPlatforms = [
    { name: 'Website', url: '' },
    { name: 'Instagram', url: 'https://instagram.com/' },
    { name: 'YouTube', url: 'https://youtube.com/@' },
    { name: 'Spotify', url: 'https://open.spotify.com/artist/' },
    { name: 'X', url: 'https://x.com/' },
    { name: 'Facebook', url: 'https://facebook.com/' },
    { name: 'LinkedIn', url: 'https://linkedin.com/in/' },

  ];

  const getPlaceholder = () => {
    switch (platform) {
      case 'Website':
        return 'Enter full URL, e.g., https://example.com';
      case 'LinkedIn':
        return 'Enter the bit of the profile url that identifies you';
      case 'Spotify':
        return 'Enter your Spotify artist ID, e.g., 2YZyLoL8N0Wb9xBt1NhZWg';
      case 'YouTube':
        return 'Handle, e.g., @mrbeast';
      default:
        return 'Handle, e.g., @username';
    }
  };

  

  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    }));
  };

  const handleAdd = () => {
    if (!platform || !handle) {
      setError('Platform and handle are required.');
      return;
    }

    let cleanHandle = handle;
    if (cleanHandle.startsWith('@')) {
      cleanHandle = cleanHandle.substring(1);
    }

    const selectedPlatform = socialPlatforms.find(p => p.name === platform);
    const url = `${selectedPlatform.url}${cleanHandle}`;

    onAdd({ platform, handle: cleanHandle, url });
    nextModal('EDIT_LINKS_AND_SOCIALS', {});
  };

  const handleDiscard = () => {
    nextModal('EDIT_LINKS_AND_SOCIALS', {});
  };

  return (
    <div className="addSocialModalBackground" onClick={() => dispatch(hideGenericModal())}>
      <div className="addSocialModalContainer" onClick={(e) => e.stopPropagation()}>
        <h3 className='addsSocialswriting'>Add Website or Social Platform</h3>
        {error && <p className="addSocialModalError">{error}</p>}
        <select
          value={platform}
          onChange={(e) => setPlatform(e.target.value)}
          className="addSocialModalInput"
        >
          <option value="" disabled>Select platform</option>
          {socialPlatforms.map((platform) => (
            <option key={platform.name} value={platform.name}>{platform.name}</option>
          ))}
        </select>
        <input
          type="text"
          value={handle}
          onChange={(e) => setHandle(e.target.value)}
          placeholder={getPlaceholder()}
          className="addSocialModalInput"
        />
        <button onClick={handleAdd} className="addSocialModalButton">Add</button>
        <div className="separatorestest" />
        <button onClick={handleDiscard} className="discardSocialModalButton">Discard</button>
      </div>
    </div>
  );
};

export default AddSocialModal;
