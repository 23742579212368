import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './WritersFooter.css'; // Assuming you have a CSS file for styling
// Import your icons
import homeIcon from '../../assets/images/home.png';
import publishIcon from '../../assets/images/presses.png';
import profileIcon from '../../assets/images/turbo-copy-brights.png';

const WritersFooter = forwardRef((props, ref) => {
  const location = useLocation();

  const isFocused = (path) => {

    if (path === '/writers') {
      return location.pathname === path;
    }
    // Use location from the top level of the component
    return location.pathname === path || location.pathname.startsWith(`${path}/`);
  };

  const renderLabel = (label, path) => {
    const focusedClass = isFocused(path) ? 'visible' : 'hidden';
    return (
      <div className="label-container"> {/* Add this div */}
        <span className={`arrow left ${focusedClass}`}>&nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;</span>
        <span className="label">{label}</span> {/* Ensure this has the 'label' class */}
        <span className={`arrow right ${focusedClass}`}>&nbsp;&lt;&#8209;&#8209;&#8209;&nbsp;&nbsp;</span>
      </div>
    );
  };



  return (
    <footer ref={ref} className="writers-footer"> 
      <div className="icons-container">
        <Link to="/writers/profile" className="footer-link">
        <div className={isFocused('/writers/profile') ? 'footer-item focused' : 'footer-item'}>     
            <img src={profileIcon} alt="Profile" className="footer-icon" />
            <span>{renderLabel('Profile', '/writers/profile')}</span>  
        </div>
        </Link>
        <Link to="/writers" className="footer-link">
        <div className={isFocused('/writers') ? 'footer-item focused' : 'footer-item'}>          
            <img src={homeIcon} alt="Home" className="footer-icon" />
            <span>{renderLabel('Home', '/writers')}</span>        
        </div>
        </Link>
        <Link to="/writers/publish" className="footer-link">
        <div className={isFocused('/writers/publish') ? 'footer-item focused' : 'footer-item'}>            
            <img src={publishIcon} alt="Publish" className="footer-icon" />
            <span>{renderLabel('Publish', '/writers/publish')}</span>            
        </div>
        </Link>
      </div>

    </footer>
  );
});

export default WritersFooter;
