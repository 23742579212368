import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HeaderEditProfile.css';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useMutation } from 'react-query';
import { Auth } from 'aws-amplify'; 
import { apiInstance } from '../../utils/axiosInstance';

import { useQueryClient } from 'react-query';
import { resetAuthorProfile } from '../../store/authorProfileSlice';






function HeaderEditProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();
  
  const [hasChanges, setHasChanges] = useState(false);
  const userId = useSelector(state => state.user.userId);
  const userName = useSelector(state => state.user.userName);
  const profileImageFile = useSelector(state => state.editWriter.profileImageFile);
  const verticalImageFile = useSelector(state => state.editWriter.verticalImageFile);


  const editedAuthorData = useSelector(state => state.editWriter.editableAuthorsData);
  const preEditedAuthorData = useSelector(state => state.editWriter.preEditableAuthorsData);

  const navigateToProfile = () => {
    navigate('/writers/profile'); // Assuming '/profile' is the route for the profile page
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  const mutation = useMutation(async (formData) => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();

    console.log('FormData contents:');
    for (let [key, value] of formData.entries()) {
      if (value instanceof File) {
          console.log(key, value.name, value.type, value.size);
      } else {
          console.log(key, value);
      }
  }

    const response = await apiInstance.patch('/platformAuthors/update', formData, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
        },
      });

      return response.data;
    }, {

        onSuccess: (data) => {
            dispatch(resetAuthorProfile());
            // Assuming the response data structure is suitable for directly updating the Redux store
            queryClient.invalidateQueries(['authorProfile', userId, userName]);
            navigate('/writers/profile');
            // Navigate back to the profile page or wherever appropriate
          },
          onError: (error) => {
            console.error('Error updating profile:', error);
            // Handle the error appropriately
          },
        });

        const saveChanges = () => {
          let formData = new FormData();
      
          formData.append('user', userId);
          formData.append('name', userName);
      
          Object.keys(editedAuthorData).forEach(key => {
              if (!isEqual(editedAuthorData[key], preEditedAuthorData[key])) {
                  if (key === 'profile_image') {
                      if (profileImageFile) {
                          if (typeof profileImageFile === 'string' && profileImageFile.startsWith('data:')) {
                              const file = dataURLtoFile(profileImageFile, 'profile_image.png');
                              formData.append('profile_image', file);
                          } else {
                              formData.append('profile_image', profileImageFile);
                          }
                      } else {
                          formData.append('profile_image_reset', 'true');
                      }
                  } else if (key === 'vertical_image') {
                      if (verticalImageFile) {
                          if (typeof verticalImageFile === 'string' && verticalImageFile.startsWith('data:')) {
                              const file = dataURLtoFile(verticalImageFile, 'vertical_image.png');
                              formData.append('vertical_image', file);
                          } else {
                              formData.append('vertical_image', verticalImageFile);
                          }
                      } else {
                          formData.append('vertical_image_reset', 'true');
                      }
                  } else if (['in_bio_visible', 'home_country_name', 'home_region_name', 'region_name', 'country_name', 'magazine_links', 'social_links'].includes(key)) {
                      formData.append(key, JSON.stringify(editedAuthorData[key]));
                  } else {
                      formData.append(key, editedAuthorData[key]);
                  }
              }
          });
      
          console.log('FormData contents before mutation:');
          for (let [key, value] of formData.entries()) {
              console.log(key, value);
          }
      
          mutation.mutate(formData);
      };

  useEffect(() => {
    // Compare the editedAuthorData and preEditedAuthorData for changes
    const dataHasChanged = !isEqual(editedAuthorData, preEditedAuthorData);
    setHasChanges(dataHasChanged);
  }, [editedAuthorData, preEditedAuthorData]); 

  return (
    <div className="header-edit-profile-container"> {/* New container div */}
      <div className="header-edit-profile">
        <span className="link discard" onClick={navigateToProfile}>Discard</span>
        <span className={`link save ${hasChanges ? 'active' : ''}`} onClick={hasChanges ? saveChanges : undefined}>
          Save Changes
        </span>
      </div>
    </div>
  );

}

export default HeaderEditProfile;