import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import { Helmet } from 'react-helmet';
import LoginWrapper from '../../components/LoginWrapper/LoginWrapper';
import { checkAuthenticationStatus } from '../../store/userSlice';
import './HomePage.css';
import Footer from '../../components/Footer/Footer';

function HomePage() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    const checkAuth = async () => {
      dispatch(checkAuthenticationStatus());
      setIsLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>ShorterStories</title>
        <meta name="description" content="Imagine a bookstore where you don't browse shelves, but discover writers. Where each book recommendation comes with a story, and each story leads you to a new book. That's ShorterStories." />
      </Helmet>
      <Header />
      <div className="homepage-content">
        <LoginWrapper />
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;