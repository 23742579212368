import React from 'react';
import CreateAccountPageBox from '../../components/CreateAccountPageBoxHome/CreateAccountPageBoxHome'; // Adjust the path as necessary
import Header from '../../components/Header/Header'; // Adjust the path as necessary
import './CreateAccount.css'; // Make sure to create this CSS file

const CreateAccount = () => {
  return (
    <div className="container">
      <Header />
      <div className="contentContainer">
        <div className="boxContainer">
          <CreateAccountPageBox />
        </div>
      </div>
      {/* Optionally add FooterLogin if needed */}
    </div>
  );
};

export default CreateAccount;