import React from 'react';
import './AppStore.css';
import appStoreImage from '../../assets/images/iosappstore.png'; // replace with your image filename
import playStoreImage from '../../assets/images/googleplaystore.png'; // replace with your image filename

function AppStore() {
  return (
    <div className="app-store">
      <p>Web app is live, the rest is coming soon to an app store near you.</p>
      <div className="store-images">
        <img src={appStoreImage} alt="App Store"/>
        <img src={playStoreImage} alt="Play Store"/>
      </div>
    </div>
  );
}

export default AppStore;