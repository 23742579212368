import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setFilter, setShouldApplyFilters } from '../../store/theFeedSlice';
import './ModalFilterDecadeTheFeed.css';

const ModalFilterDecadeTheFeed = () => {
  const dispatch = useDispatch();
  const currentDecades = useSelector(state => state.theFeed.decade);
  const [selectedDecades, setSelectedDecades] = useState(currentDecades);

  const decadeCategories = [
    { label: "1920s", value: "20s" },
    { label: "1930s", value: "30s" },
    { label: "1940s", value: "40s" },
    { label: "1950s", value: "50s" },
    { label: "1960s", value: "60s" },
    { label: "1970s", value: "70s" },
    { label: "1980s", value: "80s" },
    { label: "1990s", value: "90s" },
    { label: "2000s", value: "00s" },
    { label: "2010s", value: "10s" },
  ];

  const handleDecadeSelect = (decade) => {
    setSelectedDecades(prevDecades => {
      if (prevDecades.includes(decade)) {
        return prevDecades.filter(d => d !== decade);
      } else {
        return [...prevDecades, decade];
      }
    });
  };

  const handleSave = () => {
    dispatch(setFilter({ filterType: 'decade', value: selectedDecades }));
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };

  const handleClearAll = () => {
    setSelectedDecades([]);
  };

  const renderDecadeItem = ({ label, value }) => {
    const isSelected = selectedDecades.includes(value);
    return (
      <div 
        key={value} 
        className={`genreItem ${isSelected ? 'selected' : ''}`} 
        onClick={() => handleDecadeSelect(value)}
      >
        <div className="label-container">
          {isSelected ? (
            <span className="genreText">&#8209;&#8209;&#8209;&gt;&#160;&#160;{label}&#160;&#160;&lt;&#8209;&#8209;&#8209;</span>
          ) : (
            <span className="genreText">{label}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="modal-filter-decade">
      <div className="modalBackground">
        <div className="modalContainer">
          <h2 className="modalTitle">Filter by decade the writer grew up</h2>
          <div className="genreList">
            {decadeCategories.map(renderDecadeItem)}
          </div>
          <div 
            className={`genreItem ${selectedDecades.length === 0 ? 'selected' : ''}`} 
            onClick={handleClearAll}
          >
            <span className="genreText">Clear All</span>
          </div>
          <button className="infosButton" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterDecadeTheFeed;