import React from 'react';
import { Link } from 'react-router-dom';
import './FooterAboutWriter.css';

function Footer() {

  return (
    <div className="footer-create">
      <Link to="/about" className="writer-footer-link">Return to about page</Link>
    </div>
  );
}

export default Footer;