// ProfileSectionContainer.js
import React from 'react';
import './ProfileDetailsEdit.css'; // Your CSS file for the web styles

const ProfileDetailsEdit = ({ originalData, handlePress }) => {
  const truncateBio = (bio, maxLength = 15) => {
    return bio && bio.length > maxLength ? bio.substring(0, maxLength) + '...' : bio;
  };

  const displayValue = (value) => {
    if (!value) return '??????'; // If value is null or undefined, return '??????'
    if (typeof value === 'object' && value !== null) {
      // If value is an object and not null, attempt to use its label
      return value.label ? value.label : value;
    }
    return value; // For all other cases, return the value directly
  };

  const shouldDisplayWhatsOn = () => {
    const fieldsToCheck = [ 'major_genre', 'country_name', 'home_country_name', 'home_region_name', 'region_name', 'year_of_birth'];

  
    return fieldsToCheck.some(field => originalData[field] !== null && originalData[field] !== '');
  };

  const visibility = originalData.in_bio_visible || {
    year_of_birth: true,
    major_genre: true,
    country: true,
    region: true,
    home_country: true,
    home_region: true,
};
  const countVisible = Object.values(visibility).filter(Boolean).length;

  const visibilityMessage = countVisible > 0 ? 'One or two things' : '??????';

  return (
    <div className="profileSections">
      <div className="sectionContainer">
        <h2 className="sectionTitle">General info:</h2>
        <div className="fieldRow" onClick={() => handlePress('EDIT_GENERAL_INFO')}>
          <span className="fieldLabel">Bio:</span>
          <span className="fieldValue">
            {truncateBio(displayValue(originalData.short_bio), 15)} {'>>'}
          </span>
        </div>
        <div className="fieldRow" onClick={() => handlePress('EDIT_GENERAL_INFO')}>
          <span className="fieldLabel">Year of birth:</span>
          <span className="fieldValue">
            {displayValue(originalData.year_of_birth)} {'>>'}
          </span>
        </div>
      </div>

      <div className="sectionContainer">
        <h2 className="sectionTitle">Literary Styling:</h2>
        <div className="fieldRow" onClick={() => handlePress('EDIT_MAJOR_GENRE')}>
          <span className="fieldLabel">Major genre:</span>
          <span className="fieldValue">
            {displayValue(originalData.major_genre)} {'>>'}
          </span>
        </div>
      </div>

      <div className="sectionContainer">
        <h2 className="sectionTitle">Where you live:</h2>
        <div className="fieldRow" onClick={() => handlePress('EDIT_LIVES_REGION_COUNTRY')}>
          <span className="fieldLabel">Country:</span>
          <span className="fieldValue">
            {displayValue(originalData.country_name )} {'>>'}
          </span>
        </div>
        <div className="fieldRow" onClick={() => handlePress('EDIT_LIVES_REGION_COUNTRY')}>
          <span className="fieldLabel">State/Region:</span>
          <span className="fieldValue">
            {displayValue(originalData.region_name)} {'>>'}
          </span>
        </div>
      </div>

      <div className="sectionContainer">
        <h2 className="sectionTitle">Where you are from:</h2>
        <div className="fieldRow" onClick={() => handlePress('EDIT_FROM_REGION_COUNTRY')}>
          <span className="fieldLabel">Country:</span>
          <span className="fieldValue">
            {displayValue(originalData.home_country_name)} {'>>'}
          </span>
        </div>
        <div className="fieldRow" onClick={() => handlePress('EDIT_FROM_REGION_COUNTRY')}>
          <span className="fieldLabel">State/Region:</span>
          <span className="fieldValue">
            {displayValue(originalData.home_region_name)} {'>>'}
          </span>
        </div>
      </div>

      <div className="sectionContainer">
        <h2 className="sectionTitle">Name you write under:</h2>
        <div className="fieldRow" onClick={() => handlePress('EDIT_MAJOR_GENRE')}>
          <span className="fieldLabel">Major genre:</span>
          <span className="fieldValue">
            {displayValue(originalData.major_genre)} {'>>'}
          </span>
        </div>
      </div>

      {/* Assuming shouldDisplayWhatsOn is a function passed via props or defined within this component */}
      {shouldDisplayWhatsOn() && (
        <div className="sectionContainer">
          <h2 className="sectionTitle">What's on the card:</h2>
          <div className="fieldRow" onClick={() => handlePress('EDIT_CARD')}>
            <span className="fieldLabel">Info displayed:</span>
            <span className="fieldValue">
              {visibilityMessage} {'>>'} {/* Assuming visibilityMessage is a prop or state */}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDetailsEdit;
