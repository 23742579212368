import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    applicationReviewModalVisible: false,
    genericModalVisible: false,
    genericModalType: '',  // Added for identifying the type of modal to display
    genericModalProps: {}, // Props to pass to the generic modal
    // other modal states
  },
  reducers: {
    showApplicationReviewModal: (state, action) => {
      state.applicationReviewModalVisible = true;
      state.modalProps = action.payload.modalProps;
    },
    hideApplicationReviewModal: (state) => {
      state.applicationReviewModalVisible = false;
    },
    showGenericModal: (state, action) => {
      state.genericModalVisible = true;
      state.genericModalType = action.payload.modalType; // Store modal type identifier
      state.genericModalProps = action.payload.modalProps || {};
    },
    hideGenericModal: (state) => {
      state.genericModalVisible = false;
      state.genericModalType = ''; // Reset modal type
      state.genericModalProps = {}; // Reset modal props
    }
    // other modal actions
  },
});

export const { 
  showApplicationReviewModal, 
  hideApplicationReviewModal,
  showGenericModal, 
  hideGenericModal,
} = modalSlice.actions;

export default modalSlice.reducer;
