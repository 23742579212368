import React from 'react';
import Header from '../../components/Header/Header';
import WriterApplicationWords from '../../components/WriterApplicationWords/WriterApplicationWords';
import Footer from '../../components/FooterApplicationInfo/FooterApplicationInfo';
import './GettingIntoApplication.css';


function GettingIntoCreateAccount() {
  return (
    <div className="whatup-create-page">
      <Header />
      <div className="whatup-create-content">
        <WriterApplicationWords />
      </div>
      <Footer />
    </div>
  );
}

export default GettingIntoCreateAccount;