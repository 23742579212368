import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import './ResetPasswordBox.css'; // Import the CSS file for styling
import { setCurrentForgotPasswordPage, setUserEmail } from '../../store/userSlice';

const ResetPasswordBox = () => {
  const [code, setCode] = useState('');
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useSelector((state) => state.user.userEmail);

  const handlePasswordReset = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      dispatch(setUserEmail(''));
      alert('Success, Your password has been reset. Please log in with your new password.');
      const loginPath = location.pathname.startsWith('/writers') ? '/writers' : '/login';
      navigate(loginPath);
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };

  const handleBackToLogin = () => {
    dispatch(setUserEmail(''));
    dispatch(setCurrentForgotPasswordPage('forgotPassword'));
    const loginPath = location.pathname.startsWith('/writers') ? '/writers' : '/login';
    navigate(loginPath);
  };

  

  

  return (
    <div className="resetPasswordBox">
      <p className="emailText">{email}</p>
      <input
        className="input"
        placeholder="Verification Code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <input
        className="input"
        type="password"
        placeholder="New Password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <button className="submitButton" onClick={handlePasswordReset}>
        Reset Password
      </button>
      <div className="separator" />
      <button className="forgotPassword" onClick={handleBackToLogin}>
        Return to login
      </button>
    </div>
  );
};

export default ResetPasswordBox;
