import React from 'react';
import './RecentBooksProfile.css'; // You'll need to create this CSS file
import failedBookImage from '../../assets/images/book.png'; // Adjust the path as needed
import { useDispatch } from 'react-redux';
import { showGenericModal } from '../../store/modalSlice';

const RetryableImage = ({ src, alt, className, maxRetries = 3, interval = 1000 }) => {
  const [retryCount, setRetryCount] = React.useState(0);
  const [imageSrc, setImageSrc] = React.useState(failedBookImage);

  React.useEffect(() => {
    const img = new Image();
    img.onload = () => setImageSrc(src);
    img.onerror = () => {
      if (retryCount < maxRetries) {
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          img.src = `${src}?retry=${retryCount + 1}`;
        }, interval);
      } else {
        setImageSrc(failedBookImage);
      }
    };
    img.src = src;
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount, maxRetries, interval]);

  return <img src={imageSrc} alt={alt} className={className} />;
};



const RecentBooksProfile = ({ summary, topBooks }) => {
  const dispatch = useDispatch();
  if (!topBooks || topBooks.length === 0) {
    return null; // Return nothing if there are no books
  }
  

  const handleBookClick = (book, index) => {

    dispatch(showGenericModal({
      modalType: 'BOOK_MODAL_PROFILE', // String identifier
      modalProps: { book , position: index, summary } // Serializable props
    }));

  };


  return (
    <div className="recent-books-profile">
      <div className="feed-items">
        {topBooks.map((book, index) => (
          <div className="feed-item" key={index} onClick={() => handleBookClick(book, index)}>
            <RetryableImage
              className="book-image"
              src={book.book_image_url}
              alt={book.book_title}
              maxRetries={3}
              interval={1000}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentBooksProfile;