// ForgotPasswordBox.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; 
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCurrentForgotPasswordPage, setUserEmail } from '../../store/userSlice';
import './ForgotPasswordBox.css'; // Make sure to create this CSS file


const ForgotPasswordBox = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSendResetLink = async () => {
    try {
      await Auth.forgotPassword(email);
      dispatch(setUserEmail(email));
      alert('Success: A password reset link has been sent to your email.');
      dispatch(setCurrentForgotPasswordPage('resetPassword'));

      // You can navigate to a different page or show a success message here
    } catch (error) {
      if (error.code === 'UserNotFoundException') {
        setErrorMessage('No account found with the provided email.');
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const handleBackToLogin = () => {
    const isWritersPage = location.pathname.startsWith('/writers');
    const loginPath = isWritersPage ? '/writers' : '/login';
    navigate(loginPath);
  };


  return (
    <div className="forgotPasswordBox">
      <input
        className="input"
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder="Email"
      />
      <button className="submitButton" onClick={handleSendResetLink}>
        Send Reset Password Code
      </button>
      <div className="separator" />
      <button className="returnLogin" onClick={handleBackToLogin}>
        Return to log in page
      </button>
      {errorMessage && <div className="error">{errorMessage}</div>}
      {/* Optionally add a link/button to return to the login page */}
    </div>
  );
};

export default ForgotPasswordBox;
