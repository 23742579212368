import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLastWords, setCurrentStep, resetApplication } from '../../store/applyWriterSlice';
import './AppFormLastWords.css'; // Ensure you have this CSS for styling

const AppFormLastWords = () => {
  const dispatch = useDispatch();
  const { writersName,lastWords } = useSelector(state => state.applyWriter);
    const { currentStep } = useSelector(state => state.applyWriter);

  const handleLastWordsChange = (event) => {
    dispatch(setLastWords(event.target.value));
  };

  const handlePreviewApplication = () => {
    // Assuming the next step after this is the preview
    if (!writersName || writersName.trim() === '') {
      alert('Whoa, not so quick. You left the name empty. But everybody gotta have a name, Jack');
    } else {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };



  const handleDiscardApplication = () => {
    dispatch(resetApplication());
  };

  return (
    <div className="appFormLastWordsContainer">

      <div className="titleContainer-lastwords">
        <h2 className="bold-words">Any last words</h2>
      </div>
      <p className="instructionText">
        Before you submit your application, do you have any last words? This could be an example of your writing or it could be a description of what you like to write about. You could just insult us or write nothing. That is, you've got a lot of leeway.
      </p>
      <textarea className='lastWordsTextArea'
        id="lastWords"
        value={lastWords}
        onChange={handleLastWordsChange}
        placeholder=" Your last words here..."
        
      />

      <button className="previewButton" onClick={handlePreviewApplication}>
        Preview Application
      </button>
      <div className="separator" />
      <button className="discardButton" onClick={handleDiscardApplication}>
        Discard application
      </button>
    </div>
  );
};

export default AppFormLastWords;
