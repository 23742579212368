import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  editableAuthorsData: null,
  preEditableAuthorsData: null,
  profileImageFile: null, // To store the actual file object for the profile image
  verticalImageFile: null, // To store the actual file object for the vertical image
};

export const editWriterSlice = createSlice({
  name: 'editWriter',
  initialState,
  reducers: {
    setEditableAuthorsData: (state, action) => {
      state.editableAuthorsData = action.payload;
    },
    setPreEditableAuthorsData: (state, action) => {
      state.preEditableAuthorsData = action.payload;
    },
    // Add reducers for setting the image file objects
    setProfileImageFile: (state, action) => {
      state.profileImageFile = action.payload;
    },
    setVerticalImageFile: (state, action) => {
      state.verticalImageFile = action.payload;
    },
    // You might need actions to reset these file objects after successful upload or form reset
    resetImageFiles: (state) => {
      state.profileImageFile = null;
      state.verticalImageFile = null;
    },
    // Any other actions...
  },
});

export const { 
  setEditableAuthorsData, 
  setPreEditableAuthorsData, 
  setProfileImageFile, 
  setVerticalImageFile, 
  resetImageFiles 
} = editWriterSlice.actions;

export default editWriterSlice.reducer;
