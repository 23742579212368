import React from 'react';
import './CreateAccountWords.css';

function CreateAccountWords() {
    return (
        <div className="create-account-words">
            <h3>What are you getting yourself into? </h3>
            <p>If you've navigated to this section, you're likely interested in joining ShorterStories as a writer. </p>
            <p>Creating an account is the first step. It doesn't yet allow you to post content but initiates your presence on the platform. It is essentially creating a "readers" account.</p>
            <p>Once you have created an account, that's your log in to the ShorterStories platform, but you'll still need to apply to join the writing class. This application process is crucial for two reasons: it helps keep the barbarians at the gate, ensuring that the platform is free of bots and spammers. Secondly, it helps maintain a dress code of sorts—it's about upholding a standard that enriches our collective reading and writing experience. This simple application helps us curate content from those who authentically want to write, allowing us to build a platform that celebrates and supports writers.</p>
            <p>You should know that once you set up your "readers" account what pieces you visit by other writers and the books you click out to will be recorded to your profile. This data strategy enables you to find content algoritmically and add books and pieces to your "pile". The aim is to help readers find and connect with authors they enjoy reading. Not only should this help you to find and grow your audience, but the platform and industry as a whole.</p>
            <p>If you don't want your analytics recorded while on the readers side of the platform, you can log out and use the platform anonymously. When you’re not logged in we don't track your interactions, treating you like just like everyone else.</p>
            
        </div>
    );
}

export default CreateAccountWords;
