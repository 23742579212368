import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TheFeed from '../TheFeed/TheFeed';
import ReadersFooter from '../../components/ReaderFooter/ReaderFooter';

function InsideReaderPortal() {
  const location = useLocation();

  return (
    <div>
      <div style={{ flex: 1, paddingBottom: '60px' }}> 
        {location.pathname === '/readers' ? <TheFeed /> : <Outlet />}
      </div>
      <ReadersFooter />
    </div>
  );
}

export default InsideReaderPortal;