import React from 'react';
import Header from '../../components/Header/Header';
import FinerDetailsWords from '../../components/FinerDetailsWords/FinerDetailsWords';
import Footer from '../../components/FooterFinerDetails/FooterFinerDetails';
import './FinerDetails.css';


function FinerDetails() {
  return (
    <div className="finer-details-page">
      <Header />
      <div className="finer-details-content">
        <FinerDetailsWords />
      </div>
      <Footer />
    </div>
  );
}

export default FinerDetails;