import React, { useState } from 'react';
import './EditMajorGenreModal.css'; // Make sure to create and import corresponding CSS
import { useSelector, useDispatch } from 'react-redux'; // Adjust import paths as needed
import { setEditableAuthorsData } from '../../store/editWriterSlice'; // Adjust import paths as needed
import { hideGenericModal } from '../../store/modalSlice';
import _ from 'lodash';

const EditMajorGenreModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);
  const currentGenre = authorData.major_genre;

  const [selectedGenre, setSelectedGenre] = useState(currentGenre);

  const [visibility, setVisibility] = useState(_.cloneDeep(authorData.in_bio_visible || {
    year_of_birth: true,
    major_genre: true,
    country: true,
    region: true,
    home_country: true,
    home_region: true,
  }));

  const genres = [
    "Fictional Autobiography", 
    "Creative Non-fiction",
    "Memoir", 
    "Essay", 
    "Science-Fiction", 
    "Crime", 
    "Noir",
    "Erotica", 
    "Fantasy", 
    "Queer",
    "Horror", 
    "Romance", 
    "Thriller", 
    "Humor",
    "Creative Fiction",
    "Reportage",
    "Activism and Social Change",
  ];

  const handleGenreSelect = (genre) => {
    setSelectedGenre(genre);
  };

  const handleSave = () => {

    const updatedVisibility = { ...visibility };

    // Update visibility based on the existence of selectedCountry and selectedRegion
    if (selectedGenre) {
      updatedVisibility.major_genre = true; // Set true if there's a selection
    } else {

      updatedVisibility.major_genre = false; // Set false if selection is cleared
    }

    console.log('slut',updatedVisibility);

    const updatedAuthorData = {
        ...authorData,
        major_genre: selectedGenre,
        in_bio_visible: updatedVisibility,
    };

    dispatch(setEditableAuthorsData(updatedAuthorData));
    dispatch(hideGenericModal());
    // Close modal or navigate back as needed
  };


  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <h2 className="modalTitle">Major Genre</h2>
        <div className="genreList">
          {genres.map((genre, index) => (
            <div 
              key={index} 
              className={`genreItem ${selectedGenre === genre ? 'selected' : ''}`} 
              onClick={() => handleGenreSelect(genre)}
            >
              <span className="genreText">{genre}</span>
            </div>
          ))}
          <div className="genreItem" onClick={() => handleGenreSelect('')}>
            <span className="genreText">Clear current choice</span>
          </div>
        </div>

        <button className="infosButton" onClick={handleSave}>Update</button>
      </div>
    </div>
  );
};

export default EditMajorGenreModal;