import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthenticationStatus, setCurrentLoginPage, setPassEmail, setPassPassword } from '../../store/userSlice';
import './ConfirmSignUp.css'; // Make sure to create this CSS file
import {apiInstance} from '../../utils/axiosInstance'; // Import the API instance

const ConfirmSignUp = () => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const dispatch = useDispatch();
  const email = useSelector((state) => state.user.passEmail);
  const passterassword = useSelector((state) => state.user.passPassword);

  const saveUserToRds = async (email, cognitoUserId) => {
    try {
      const session = await Auth.currentSession();
      const jwtToken = session.getIdToken().getJwtToken();
  
      // Create FormData object and append data
      let formData = new FormData();
      formData.append('user_id', cognitoUserId);
      formData.append('email', email);
  
      // Use axios instance to make the call, ensuring to send FormData
      const response = await apiInstance.post('/users/create', formData, {
        headers: {
          'Authorization': `Bearer ${jwtToken}`,
          // Content-Type may not be needed as axios and browsers set it correctly for FormData
          // 'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 201) {
        console.log('User saved successfully:', response.data);
      } else {
        console.error('Error saving user:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while saving the user to RDS:', error);
    }
  };
  

  const handleConfirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signOut({ global: true });
      const user = await Auth.signIn(email, passterassword);
      dispatch(checkAuthenticationStatus());
      saveUserToRds(email, user.attributes.sub);
      dispatch(setPassEmail(''));
      dispatch(setPassPassword(''));
      dispatch(setCurrentLoginPage('login'));
      alert('Account confirmed, Your account has been confirmed!');
    } catch (error) {
      console.error('Error confirming sign up', error);
      alert('Error confirming sign up: ' + error.message);
    }
  };

  const handleResendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      alert('Code Sent, A new confirmation code has been sent to your email.');
    } catch (error) {
      console.error('Error resending code', error);
      alert('Error resending code: ' + error.message);
    }
  };

  const handleLoginPageReturn = async () => {
    try {
      await Auth.signOut();
      dispatch(setPassEmail(''));
      dispatch(setPassPassword(''));
      dispatch(setCurrentLoginPage('login'));
      
    } catch (error) {
      console.error('Error signing out', error);
      dispatch(setPassEmail(''));
      dispatch(setPassPassword(''));
      dispatch(setCurrentLoginPage('login'));
    }
  };

  return (
    <div className="confirmationBox">
      <div className="inputContainer">
        <input
          className="input"
          type="text"
          onChange={(e) => setConfirmationCode(e.target.value)}
          value={confirmationCode}
          placeholder="Confirmation code - check your email"
        />
      </div>
      <button className="confirmButton" onClick={handleConfirmSignUp}>
        Confirm
      </button>
      <div className="separator" />

      <div className="linkContainer">
        <button className="linkTouch" onClick={handleResendCode}>
          Email code again
        </button>

        <button className="linkTouch" onClick={handleLoginPageReturn}>
          Return to Login
        </button>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
