import React from 'react';


import PendingWriterComponent from '../../components/PendingWriterComponent/PendingWriterComponent';
import Header from '../../components/Header/Header';
import './PendingWriterScreen.css'; // Make sure to create this CSS file

const PendingWriterScreen = () => {

  return (
    <div className="pendingWriterContainer">
      <Header /> 

      <div className="pendingWriterContent">
        <PendingWriterComponent />
      </div>    
    </div>
  );
};

export default PendingWriterScreen;

