import React, { useState, useEffect } from 'react';
import './ModalFilterLivesTheFeed.css';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter, setShouldApplyFilters } from '../../store/theFeedSlice';
import { hideGenericModal } from '../../store/modalSlice';
import { countries, regionsByCountry } from '../../data/countriesAndRegions';
import Select from 'react-select';

const ModalFilterLivesTheFeed = () => {
  const dispatch = useDispatch();
  const currentLiveNow = useSelector(state => state.theFeed.locationLiveNow);
  const [selectedCountry, setSelectedCountry] = useState(currentLiveNow?.country || null);
  const [selectedRegion, setSelectedRegion] = useState(currentLiveNow?.region || null);
  const [regionOptions, setRegionOptions] = useState([]);

  const countryOptions = countries.map((country) => ({
    value: country.code,
    label: `${country.country} ${country.flag}`,
  }));

  useEffect(() => {
    if (selectedCountry) {
      const regions = regionsByCountry[selectedCountry.value] || [];
      const formattedRegions = regions.map(region => ({
        value: region.code,
        label: region.region,
      }));
      setRegionOptions(formattedRegions);
      setSelectedRegion(null); // Reset selected region when country changes
    } else {
      setRegionOptions([]);
    }
  }, [selectedCountry]);

  const handleSave = () => {
    const liveNowFilter = {
      country: selectedCountry,
      region: selectedRegion,
    };
    dispatch(setFilter({ filterType: 'locationLiveNow', value: liveNowFilter }));
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };
  
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  };

  return (
    <div className="modal-filter-lives">
      <div className="modalBackground">
        <div className="modalContainer">
          <h2 className="modalTitle">Filter by where the writer lives</h2>
          
          <label className="sectionTitle">Country:</label>
          <Select
            className="dropdown"
            value={selectedCountry}
            onChange={handleCountryChange}
            options={countryOptions}
            placeholder="Select a country"
            styles={customStyles}
            menuPortalTarget={document.body}
            isClearable={true}
          />

          <label className="sectionTitle">Region:</label>
          <Select
            className="dropdown"
            value={selectedRegion}
            onChange={handleRegionChange}
            options={regionOptions}
            placeholder="Select a region"
            styles={customStyles}
            menuPortalTarget={document.body}
            isClearable={true}
            isDisabled={!selectedCountry}
          />

          <button className="infosButton" onClick={handleSave}>Apply Filter</button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterLivesTheFeed;