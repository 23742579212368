// HeaderAppFlow.js
import React from 'react';
import './HeaderAppFlow.css'; // Make sure to create this CSS file
import cameraIcon from '../../assets/images/photo.png'; // Adjust the path as necessary
import bookIcon from '../../assets/images/book.png'; // Adjust the path as necessary
import typewriterIcon from '../../assets/images/piece.png'; // Adjust the path as necessary
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from '../../store/applyWriterSlice';

const HeaderAppFlow = () => {
    const { currentStep } = useSelector(state => state.applyWriter);
    const dispatch = useDispatch();
    const adjustedStage = currentStep - 1;  // Adjust currentStage for the flow


    return (
        <div className="headersContainers">
            <div className="iconsContainers"> 
                <span className="arrows">----{'\u003E'}</span>
                <button className="stepsies" onClick={() => dispatch(setCurrentStep(1))}>
                    <img src={bookIcon} alt="Book Icon" className={adjustedStage >= 0 ? "activeIcon" : "inactiveIconsies"} />
                </button>
                <span className={adjustedStage >= 1 ? "visible" : "hidden"}>----{'\u003E'}</span>
                <button className="stepsies" onClick={() => dispatch(setCurrentStep(2))}>
                    <img src={cameraIcon} alt="Camera Icon" className={adjustedStage >= 1 ? "activeIcon" : "inactiveIconsies"} />
                </button>
                <span className={adjustedStage >= 2 ? "visible" : "hidden"}>----{'\u003E'}</span>
                <button className="stepsies" onClick={() => dispatch(setCurrentStep(3))}>
                    <img src={typewriterIcon} alt="Typewriter Icon" className={adjustedStage >= 2 ? "activeIcon" : "inactiveIconsies"} />
                </button>
            </div>
        </div>
    );
};

export default HeaderAppFlow;
