import React, { useState, useEffect } from 'react';
import './EditWhatsOnTheCardModal.css'; // Ensure you have corresponding CSS
import { useSelector, useDispatch } from 'react-redux';
import { setEditableAuthorsData } from '../../store/editWriterSlice';
import { hideGenericModal } from '../../store/modalSlice';
import _ from 'lodash';

const EditProfileVisibilityModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);

  const [visibility, setVisibility] = useState(_.cloneDeep(authorData.in_bio_visible || {
    year_of_birth: true,
    major_genre: true,
    country: true,
    region: true,
    home_country: true,
    home_region: true,
  }));



  const handleCheckboxChange = (field) => {
    setVisibility(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleSave = () => {

    const updatedAuthorData = {
      ...authorData,
      in_bio_visible: visibility,
    };

    dispatch(setEditableAuthorsData(updatedAuthorData));
    dispatch(hideGenericModal());
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <h2 className="modalTitle">Profile Visibility Settings</h2>
        {authorData.year_of_birth && (
          <div className="sectionContainer">
            <p className="sectionTitle">General info:</p>
            <div className="fieldRow">
              <label className="fieldLabel">Generation:</label>
              <input
                type="checkbox"
                checked={visibility.year_of_birth}
                onChange={() => handleCheckboxChange('year_of_birth')}
              />
            </div>
          </div>
        )}
        {authorData.major_genre && (

          <div className="sectionContainer">
            <p className="sectionTitle">Literary styling:</p>
            <div className="fieldRow">
              <label className="fieldLabel">Major Genre:</label>
              <input
                type="checkbox"
                checked={visibility.major_genre}
                onChange={() => handleCheckboxChange('major_genre')}
              />
            </div>
          </div>
        )}
        {( authorData.country_name || authorData.region_name) && (
          <div className="sectionContainer">
            <p className="sectionTitle">Where you live:</p>
            {authorData.country_name && (
              <div className="fieldRow">
                <label className="fieldLabel">Country:</label>
                <input
                  type="checkbox"
                  checked={visibility.country}
                  onChange={() => handleCheckboxChange('country')}
                />
              </div>
            )}  
            {authorData.region_name && (
              <div className="fieldRow">
                <label className="fieldLabel">Region:</label>
                <input
                  type="checkbox"
                  checked={visibility.region}
                  onChange={() => handleCheckboxChange('region')}
                />
              </div>
            )}
          </div>
        )}
        {( authorData.home_country_name || authorData.home_region_name) && (

          <div className="sectionContainer">
            <p className="sectionTitle">Where you are from:</p>
            {authorData.home_country_name && (
              <div className="fieldRow">
                <label className="fieldLabel">Country:</label>
                <input
                  type="checkbox"
                  checked={visibility.home_country}
                  onChange={() => handleCheckboxChange('home_country')}
                />
              </div>
            )}
            {authorData.home_region_name && (
              <div className="fieldRow">
                <label className="fieldLabel">Region:</label>
                <input
                  type="checkbox"
                  checked={visibility.home_region}
                  onChange={() => handleCheckboxChange('home_region')}
                />
              </div>
            )}
          </div>
        )}  

        <button className="infosButton" onClick={handleSave}>Update</button>
      </div>
    </div>
  );
};

export default EditProfileVisibilityModal;
