import React, { useState } from 'react';
import './UpdateGeneralInfoModal.css'; // Make sure to create and import corresponding CSS
import { useDispatch, useSelector } from 'react-redux';
import { setEditableAuthorsData } from '../../store/editWriterSlice'; // Adjust import paths as needed
import { hideGenericModal } from '../../store/modalSlice';
import ReusableBoxContainer from '../ReusableBoxContainer/ReusableBoxContainer';
import _ from 'lodash';

const EditGeneralInfoModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);

  const [newBio, setNewBio] = useState(authorData.short_bio);
  const [newYearOfBirth, setNewYearOfBirth] = useState(authorData.year_of_birth ? authorData.year_of_birth.toString() : '');
  const currentYear = new Date().getFullYear();

  const [visibility, setVisibility] = useState(_.cloneDeep(authorData.in_bio_visible || {
    year_of_birth: true,
    major_genre: true,
    country: true,
    region: true,
    home_country: true,
    home_region: true,
  }));

  const handleSave = () => {
    const yearValidation = !newYearOfBirth || (newYearOfBirth >= 1900 && newYearOfBirth <= currentYear - 5);
    
    if (!yearValidation) {
      alert(`Year of birth must be between 1900 and ${currentYear - 5}.`);
      return;
    }

    if (newBio && newBio.length > 200) {
      alert("This is only the short bio, 200 characters max.");
      return;
    }


    const updatedVisibility = { ...visibility };

    // Update visibility based on the existence of selectedCountry and selectedRegion
    if (newYearOfBirth) {
      updatedVisibility.year_of_birth = true; // Set true if there's a selection
    } else {

      updatedVisibility.year_of_birth = false; // Set false if selection is cleared
    }

    



    const updatedAuthorData = {
        ...authorData,
        short_bio: newBio,
        year_of_birth: newYearOfBirth,
        in_bio_visible: updatedVisibility,
    };

    dispatch(setEditableAuthorsData(updatedAuthorData));
    dispatch(hideGenericModal());

    // Close modal or navigate back as needed
  };

  const handleBioChange = (e) => {
    const text = e.target.value.replace(/(\r\n|\n|\r)/gm, "");
    if (text.length <= 200) {
      setNewBio(text);
    }
  };

  // For web, you might not need to dismiss the keyboard explicitly like in React Native.

  return (
    <div className="editGeneralInfoModal">
      {/* Use ReusableBoxContainer or equivalent web components */}
      <ReusableBoxContainer
        title="Bio:"
        inputValue={newBio}
        onInputChange={handleBioChange}
        showInput={true}
        inputType="text" // Assuming ReusableBoxContainer supports this prop for web
        placeholder="Write something to describe yourself..."
        description="Write something to describe who you are. Maybe sell yourself or tell a joke. Just remember to be brief, you've only got 200 characters."
        inputSize = 'large'
      />
      <ReusableBoxContainer
        title="Year of Birth:"
        inputValue={newYearOfBirth}
        onInputChange={(e) => setNewYearOfBirth(e.target.value)}
        inputType="number"
        showInput={true}
        placeholder="Enter your year of birth..."
        description="We will convert this into a generation to help readers find and search for new writers. As an example, if you were born in 1985 you 'grew up in the 90s'."
      />

      <button onClick={handleSave} className="infosButton">
        Update
      </button>
    </div>
  );
};

export default EditGeneralInfoModal;

// Adapt CSS styles as needed for your web app's design
