import React, { useState } from 'react';
import './ModalAddLinksAndSocials.css';
import { useSelector, useDispatch } from 'react-redux';
import { setEditableAuthorsData } from '../../store/editWriterSlice';
import { showGenericModal,hideGenericModal } from '../../store/modalSlice';
import LinkDisplayBox from '../WriterLinkDisplayBox/WriterLinkDisplayBox';
import SocialDisplayBox from '../WriterSocialDisplayBox/WriterSocialDisplayBox';

const ModalAddLinksAndSocials = () => {
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);

  const [activeTab, setActiveTab] = useState('links'); // State to manage the active tab

  // State for form data
  const [links, setLinks] = useState(authorData.magazine_links || []);
  const [socials, setSocials] = useState(authorData.social_links || []);
  console.log('links',links);
  console.log('socials', socials);

  const handleSave = () => {
    // Update the author data with the new links and socials
    const updatedAuthorData = {
      ...authorData, 
      magazine_links: links,
      social_links:socials,
    };

    dispatch(setEditableAuthorsData(updatedAuthorData));
    dispatch(hideGenericModal());
  };

  const nextModal = (nextModalType, nextModalProps = {}) => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: nextModalType,
      modalProps: nextModalProps
    })); // Immediately show the next modal
  };

  const handleAddLink = () => {
    nextModal('ADD_LINK_MODAL');
  };

  const handleRemoveLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    dispatch(setEditableAuthorsData({ ...authorData, magazine_links: newLinks }));
  };

  const handleAddSocial = () => {
    nextModal('ADD_SOCIAL_MODAL');
  };

  const handleRemoveSocial = (index) => {
    const newSocials = socials.filter((_, i) => i !== index);
    setSocials(newSocials);
    dispatch(setEditableAuthorsData({ ...authorData, social_links: newSocials }));
  };

  return (
    <div className="linkmodalBackground" onClick={() => dispatch(hideGenericModal())}>
      <div className="linkmodalContainer" onClick={(e) => e.stopPropagation()}>
        <div className="modalHeader">
          <div className={`tabTitleContainer ${activeTab === 'links' ? 'active' : ''}`} onClick={() => setActiveTab('links')}>
            <h2 className="tabTitle">Links</h2>
          </div>
          <div className={`tabTitleContainer ${activeTab === 'socials' ? 'active' : ''}`} onClick={() => setActiveTab('socials')}>
            <h2 className="tabTitle">Platforms</h2>
          </div>
        </div>
        <div className="modalContent">
          {activeTab === 'links' && (
            <div className="linksSection">
              {links.length === 0 ? (
                <p>No links added (yet)</p>
              ) : (
                authorData.magazine_links.map((link, index) => (
                  <LinkDisplayBox
                    key={index}
                    title={link.title}
                    publisher={link.publisher}
                    description={link.description}
                    onRemove={() => handleRemoveLink(index)}
                  />
                ))
              )}
              <button className="infosButtonjonks" onClick={handleAddLink}>Add link</button>
              <div className="separatorest" />
            </div>
          )}
          {activeTab === 'socials' && (
            <div className="socialsSection">
              {socials.length === 0 ? (
                <p>No social media or website added (yet)</p>
              ) : (
                authorData.social_links.map((social, index) => (
                  <SocialDisplayBox
                    key={index}
                    platform={social.platform}
                    handle={social.handle}
                    onRemove={() => handleRemoveSocial(index)}
                  />
                ))
              )}

              <button className="infosButtonjonks" onClick={handleAddSocial}>Add platform</button>
              <div className="separatorest" />
            </div>
          )}
        </div>
        <div className="modalFooter">
          <button className="infosButtonjonks" onClick={handleSave}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default ModalAddLinksAndSocials;
