import React, { useState, useEffect } from 'react';
import './EditFromRegionCountryModal.css'; // Ensure CSS is structured similarly to EditMajorGenreModal.css
import { useSelector, useDispatch } from 'react-redux';
import { setEditableAuthorsData } from '../../store/editWriterSlice';
import { hideGenericModal } from '../../store/modalSlice';
import { countries, regionsByCountry } from '../../data/countriesAndRegions';
import Select from 'react-select';
import _ from 'lodash';

const EditCountryRegionModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector(state => state.editWriter.editableAuthorsData);
  const [selectedCountry, setSelectedCountry] = useState(authorData.home_country_name || '');

  const [selectedRegion, setSelectedRegion] = useState(authorData.home_region_name || '');
  const [regionOptions, setRegionOptions] = useState([]);

  const [visibility, setVisibility] = useState(_.cloneDeep(authorData.in_bio_visible || {
    year_of_birth: true,
    major_genre: true,
    country: true,
    region: true,
    home_country: true,
    home_region: true,
  }));

  const countryOptions = countries.map((country) => ({
    value: country.code,
    label: `${country.country} ${country.flag}`,
  }));

  useEffect(() => {
        if (selectedCountry) {
        // Assuming regionsByCountry is an object with country codes as keys and arrays of regions as values
        const regions = regionsByCountry[selectedCountry.value] || [];
        const formattedRegions = regions.map(region => ({
            value: region.code,
            label: region.region,
        }));
        setRegionOptions(formattedRegions);
        setSelectedRegion(null); // Reset selected region when country changes
        } else {
        setRegionOptions([]);
        }
    }, [selectedCountry]);

  const handleSave = () => {

    const updatedVisibility = { ...visibility };

    // Update visibility based on the existence of selectedCountry and selectedRegion
    if (selectedCountry) {
      updatedVisibility.home_country = true; // Set true if there's a selection
    } else {

      updatedVisibility.home_country = false; // Set false if selection is cleared
    }
  
    if (selectedRegion) {
      updatedVisibility.home_region = true; // Set true if there's a selection
    } else {
      updatedVisibility.home_region = false; // Set false if selection is cleared
    }



  
  // Concatenate the country name and flag if a country is selected

    const updatedAuthorData = {
      ...authorData,
      home_country_name: selectedCountry,
      home_region_name: selectedRegion,
      in_bio_visible: updatedVisibility,
    };

    dispatch(setEditableAuthorsData(updatedAuthorData));
    dispatch(hideGenericModal());

  };

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }), // Adjust zIndex as needed to ensure it's above your modal
  };

  const handleChangeRegion = (option) => {
    setSelectedRegion(option);
  };


  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <h2 className="modalTitle">Where are you from?</h2>
        <label className="sectionTitle">Country:</label>
        <Select
            className="dropdown"
            value={selectedCountry}
            onChange={handleChange}
            options={countryOptions}
            placeholder="Select a country"
            styles={customStyles}
            menuPortalTarget={document.body}
            isClearable={true}
        />

        <label className="sectionTitle">Region:</label>
        <Select
            className="dropdown"
            value={selectedRegion}
            onChange={handleChangeRegion}
            options={regionOptions}
            placeholder="Select a region"
            styles={customStyles}
            menuPortalTarget={document.body}
            isClearable={true}
            isDisabled={!selectedCountry} // Disable if no country is selected
        />

        <button className="infosButton" onClick={handleSave}>Update</button>
      </div>
    </div>
  );
};

export default EditCountryRegionModal;
