import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import AboutWritersWords from '../../components/AboutWritersWords/AboutWritersWords';
import Footer from '../../components/FooterAboutWriter/FooterAboutWriter';
import './AboutWriters.css';

function AboutWriters() {
  return (
    <div className="about-writers-page">
      <Helmet>
        <title>ShorterStories for Writers: Publish, Grow Your Audience, and Monetize Your Work</title>
        <meta name="description" content="Join ShorterStories to publish your short-form content, build your writer profile, grow your audience, and earn through affiliate marketing. A new way to publish and monetize your writing." />
      </Helmet>
      <Header />
      <div className="about-writers-content">
        <AboutWritersWords />
      </div>
      <Footer />
    </div>
  );
}

export default AboutWriters;