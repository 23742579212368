import React from 'react';
import './WriterLinkDisplayBox.css';
import closeIcon from '../../assets/images/circle-xmark-regular.svg';

const LinkDisplayBox = ({ title, publisher, description, onRemove  }) => {
  const getPublicationText = (publisher) => {
    if (publisher.toLowerCase().startsWith('the')) {
      return `published in ${publisher}`;
    }
    return `published on ${publisher}`;
  };

  return (
    <div className="reusableBoxContainered">
      <div className="contentViewsomuchInBox">
        <img src={closeIcon} alt="Remove" className="removeIcon" onClick={onRemove} />
        <p className="boldsterInBox">{title}</p>
        <p className="normailerInBox">{getPublicationText(publisher)}</p>
        {description && <p className="textInBoxcar">{description}</p>}
      </div>
    </div>
  );
};

export default LinkDisplayBox;
