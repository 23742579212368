import React, { useState, useEffect } from 'react';
import './TagBookModalThree.css'; // Ensure CSS is structured similarly to EditMajorGenreModal.css
import { useSelector, useDispatch } from 'react-redux';

import { hideGenericModal } from '../../store/modalSlice';
import { setChosenBook, setChosenFormat, setBooksOnSale, setFormatOptions, setSelectedBookOnSale} from '../../store/taggingSlice';
import EnhancedBookCard from '../EnhancedBookCard/EnhancedBookCard';
import {setTaggedBooks} from '../../store/publishingSlice';
import {  showGenericModal } from '../../store/modalSlice';

const TagBookModalThree = () => {
    const dispatch = useDispatch();
    // Assuming selectedBookOnSale is the book selected for tagging
    const selectedBookOnSale = useSelector((state) => state.tagging.selectedBookOnSale);
    const chosenRetailer = useSelector((state) => state.tagging.chosenRetailer);
    console.log(selectedBookOnSale,'selected books');
    const taggedBooks = useSelector((state) => state.publishing.taggedBooks);
    const [associationText, setAssociationText] = useState("");
  
    const handleAssociationChange = (event) => {
      setAssociationText(event.target.value);
    };
  
    const handleSubmit = () => {
        if (associationText.length > 200) {
            alert("Please keep the summary under 200 characters.");
            return;
          }
          
      const newTaggedBook = {
        ...selectedBookOnSale,
        reason: associationText
      };

      dispatch(setTaggedBooks([...taggedBooks, newTaggedBook]));
      dispatch(hideGenericModal()); // Close the modal after submission

    };

    const prevModal = () => {
      dispatch(hideGenericModal());
      dispatch(showGenericModal({
          modalType: chosenRetailer === 'BN' ? 'TAG_BOOK_TWO_BN' : 'TAG_BOOK_TWO',
      }));
  };

  
  
    return (
      <div className="modalBackground">
        <div className="modalesContaineres">
          <EnhancedBookCard book={selectedBookOnSale} isBarnesAndNoble={false} />
          <div className="tags-dividers"/>
          <label className="sectionTitle">4. How does this book relate to your piece?</label>
          <textarea
            className="association-input"
            value={associationText}
            onChange={handleAssociationChange}
            placeholder="Explain how the book is associated to your piece here..."
          />
          <button className="in-Bolds-Button-details" onClick={handleSubmit}>
            Tag Book
          </button>
          <button 
              className="infosButton-details-prev" 
              onClick={prevModal}
          >
              &lt;--- Previous step
          </button>
        </div>
      </div>
    );
  };
  
  export default TagBookModalThree;