import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './ReaderFooter.css'; // You'll need to create this CSS file
import { useDispatch } from 'react-redux';
// Import your icons
import feedIcon from '../../assets/images/feeding.png';
import libraryIcon from '../../assets/images/library.png';
import { resetFilters } from '../../store/theFeedSlice';

const ReadersFooter = forwardRef((props, ref) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isFocused = (path) => {
    if (path === '/readers') {
      return location.pathname === path || location.pathname === '/readers/';
    }
    return location.pathname.startsWith(path);
  };

  const handleFeedNavigation = () => {
    
    if (location.pathname !== '/readers') {
      dispatch(resetFilters());
      window.scrollTo(0, 0);
      console.log('this working');
    }
  };

  const renderLabel = (label, path) => {
    const focusedClass = isFocused(path) ? 'visible' : 'hidden';
    return (
      <div className="label-container">
        <span className={`arrow left ${focusedClass}`}>&nbsp;&nbsp;&#8209;&#8209;&#8209;&gt;&nbsp;</span>
        <span className="label">{label}</span>
        <span className={`arrow right ${focusedClass}`}>&nbsp;&lt;&#8209;&#8209;&#8209;&nbsp;&nbsp;</span>
      </div>
    );
  };

  return (
    <footer ref={ref} className="readers-footer">
      <div className="icons-container">
        <Link to="/readers" className="footer-link" onClick={handleFeedNavigation}>
          <div className={isFocused('/readers') ? 'footer-item focused' : 'footer-item'}>
            <img src={feedIcon} alt="Feed" className="footer-icon" />
            <span>{renderLabel('Feed', '/readers')}</span>
          </div>
        </Link>
        <Link to="/readers/library" className="footer-link">
          <div className={isFocused('/readers/library') ? 'footer-item focused' : 'footer-item'}>
            <img src={libraryIcon} alt="Library" className="footer-icon" />
            <span>{renderLabel('Library', '/readers/library')}</span>
          </div>
        </Link>
      </div>
    </footer>
  );
});

export default ReadersFooter;