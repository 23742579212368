import React from 'react';
import BookCard from '../BookCard/BookCard'; // Adjust the path as necessary
import ReusableBox from '../ReusableBoxContainer/ReusableBoxContainer'; // Adjust the path as necessary
import './BookModalContent.css'; // Import the CSS file here
import { apiInstance } from '../../utils/axiosInstance'; 
import { useSelector } from 'react-redux';

const BookModalContent = ({ book, prevRoute, position,piece, author_info, pieceViewHash }) => {

  const userId = useSelector((state) => state.user.userId);

  console.log('Book:', book);

  function generateCustomTimestamp() {
    const now = new Date();
    
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month (01-12)
    const day = now.getDate().toString().padStart(2, '0'); // Day of the month (01-31)
    const hour = now.getHours().toString().padStart(2, '0'); // Hour (00-23)
  
    return `${year}${month}${day}${hour}`;
  }

  const onButtonPress = () => {
    // Open the book link directly in response to the user's click
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')  // Base 36 encoding
        .slice(0, length);  // Ensure the hash is exactly 'length' characters
    };
    
    const timestamp = generateCustomTimestamp();// Current time as a simple tracker
    
    const uniqueHash = generateHash(12);     // Generate a 12-character unique hash

    const clickType = 'P'; 

    const paddedPosition = position.toString().padStart(2, '0'); 
    
    const sid = `${uniqueHash}-${clickType}-${paddedPosition}-${piece.piece_hash}-${author_info.profile_hash}-${timestamp}`;
    
    // URL encode the SID to handle special characters
    const encodedSid = encodeURIComponent(sid);
    
    console.log('Generated SID:', sid);
    console.log('Encoded SID:', encodedSid);

    
    // Append the SID to the product link URL
    const url = new URL(book.affiliate_url);
    url.searchParams.append('sid', sid);  // Assuming 'sid' is the query parameter required by your affiliate program
    
    // Open the book link with the SID directly in response to the user's click
    window.open(url.toString(), '_blank');

    console.log(url.toString());

    console.log(sid.length);

    const affiliateClickData = {
      piece_view_hash: pieceViewHash,
      unique_hash: uniqueHash,
      piece_id: piece.piece_id,
      piece_hash: piece.piece_hash,
      link_id: book.link_id,
      referrer_page: prevRoute,
      position: position,
      affiliate_id: book.affiliate_id,
      affiliate_url: book.affiliate_url,
      book_author_name: book.book_author_name,
      book_image_url: book.book_image_url,
      book_raw_title: book.book_raw_title,
      book_sub_title: book.book_sub_title,
      book_title: book.book_title,
      format: book.format,
      product_link_url: book.product_link_url,
      publishing_user: book.publishing_user,
      consuming_user: userId,
      reason_why: book.reason_why,
      retailer_id: book.retailer_id,
      piece_writer_name: author_info.name,
      profile_hash: author_info.profile_hash,
      referrer_external: document.referrer
    };
  
    // Make the API call without waiting for its result
    apiInstance.post('/clicks/affiliate/create', affiliateClickData)
    .then(response => console.log('API Call Successful:', response.data))
    .catch(error => console.error('Error in API Call:', error));
  };
  

  return (
    <div className="bookModalContent"v>
      <BookCard 
        book={book}
      />
      <button 
        className="buttoninbox boldButton"
        onClick={onButtonPress}
      >
        Check out the Book
      </button>
      <ReusableBox 
        title="Association to Piece"
        description={book.reason_why}
        // showButton = {true}
        // boldButton = {true}
        // buttonText="Check out the Book"
        // onButtonPress={onButtonPress}
      />

    </div>
    
  );
};

export default BookModalContent;


