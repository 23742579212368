import React, { useState } from 'react';
import './EditBioPicModal.css';
import ReusableBoxContainer from '../ReusableBoxContainer/ReusableBoxContainer';

import defaultVerticalImage from '../../assets/images/defaultVerticalmage.png';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData, setVerticalImageFile } from '../../store/editWriterSlice';
import EXIF from 'exif-js';


const EditBioPicModal = () => {
  const dispatch = useDispatch();
  const authorData = useSelector((state) => state.editWriter.editableAuthorsData);
  const [isImageTouched, setIsImageTouched] = useState(false);

  const { vertical_image_jpeg, vertical_image_webp, ...restOfAuthorData } = authorData;

  const [selectedImage, setSelectedImage] = useState(authorData.vertical_image || authorData.vertical_image_webp || defaultVerticalImage);


  const originalVerticalImageFile = useSelector((state) => state.editWriter.verticalImageFile);

  

  const fileInputRef = React.createRef();

  const [previousImage, setPreviousImage] = useState(authorData.vertical_image_webp || defaultVerticalImage);

  const correctImageOrientation = (base64, orientation) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // Adjust the canvas size based on the orientation
        if ([4, 5, 6, 7].includes(orientation)) {
          canvas.width = img.height;
          canvas.height = img.width;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }
  
        // Adjust the drawing context based on the orientation
        switch (orientation) {
          case 2: ctx.transform(-1, 0, 0, 1, canvas.width, 0); break;
          case 3: ctx.transform(-1, 0, 0, -1, canvas.width, canvas.height); break;
          case 4: ctx.transform(1, 0, 0, -1, 0, canvas.height); break;
          case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
          case 6: ctx.transform(0, 1, -1, 0, canvas.height, 0); break;
          case 7: ctx.transform(0, -1, -1, 0, canvas.height, canvas.width); break;
          case 8: ctx.transform(0, -1, 1, 0, 0, canvas.width); break;
          default: ctx.transform(1, 0, 0, 1, 0, 0);
        }
  
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL());
      };
      img.src = base64;
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Read EXIF data and adjust orientation
      EXIF.getData(file, function () {
        const orientation = EXIF.getTag(this, 'Orientation');
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result;
          correctImageOrientation(base64, orientation).then((correctedBase64) => {
            setSelectedImage(correctedBase64);
            dispatch(setVerticalImageFile(correctedBase64));
            setIsImageTouched(true);
          });
        };
        reader.readAsDataURL(file);
      });
    }
  };
  

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const revertToPreviousImage = () => {
    setSelectedImage(previousImage);
    dispatch(setVerticalImageFile(originalVerticalImageFile));
    setIsImageTouched(false); 
  };


  const revertToDefaultImage = () => {
    // Logic to revert to the default image
    setSelectedImage(defaultVerticalImage);
    dispatch(setVerticalImageFile(null));
    setIsImageTouched(true); 
  };

  const handleUpdateAuthorData = () => {
    if (isImageTouched) {
      const updatedAuthorData = {
        ...restOfAuthorData,
        vertical_image: selectedImage,
      };
      dispatch(setEditableAuthorsData(updatedAuthorData));
    }
    dispatch(hideGenericModal());
    // Here you could also close the modal or show a success message
  };

  return (
    <div className="modal">
      <div className="contentBox">

        <div className="titleContainer">
          <p className="modalTitle">Upload portrait:</p>
        </div>
        {selectedImage && <img src={selectedImage} alt="Selected" className="image-preview" />}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          className="fileInput"
          accept="image/*"
          style={{ display: 'none' }} // Hide the actual file input
        />

        <button onClick={handleUploadClick} className='infosButton'>Upload image</button>
        <div className="textButtonContainer">
          <button onClick={revertToPreviousImage} className="textButton">Revert to previous image</button>
          <button onClick={revertToDefaultImage} className="textButton">Revert to default image</button>
        </div>
      </div>
      <ReusableBoxContainer
        title="You gotta know:"
        description="This pic has got to be at least as long as it is wide. In the biz they call this a portrait. You can upload any pic but if it's wider than it is long it'll cut some of it off."
        // Add any other props as needed
      />
      <button onClick={handleUpdateAuthorData} className='infosButton'>Update</button>

    </div>
    

  );
};

export default EditBioPicModal;
