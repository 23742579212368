import React from 'react';
import { useDispatch } from 'react-redux';
import './WriterTopCard.css'; // Ensure you've imported the CSS file correctly
import defaultVerticalImage from '../../assets/images/defaultVerticalmage.png';
import { showGenericModal } from '../../store/modalSlice';

const WriterAuthorSummary = ({ summary }) => {
    const dispatch = useDispatch();
    const determineGeneration = (yearOfBirth) => {
        if (yearOfBirth === null) {
            return null;
        }
        const lastDigit = yearOfBirth % 10;
        const decade = Math.floor(yearOfBirth / 10) * 10;
        
        if (lastDigit === 9) {
            return `${decade + 10}s and ${decade + 20}s`;
        } else if (lastDigit === 0 || lastDigit === 1) {
            return `${decade}s and ${decade + 10}s`;
        } else {
            return `${decade + 10}s`;
        }
    };

    // Check if in_bio_visible exists or use a default object with all false values
    const visibility = summary.in_bio_visible || {
        year_of_birth: true,
        major_genre: true,
        country: true,
        region: true,
        home_country: true,
        home_region: true,
    };
    
    const formatLocation = (isCountryVisible, isRegionVisible, regionName, countryName) => {
        // Use optional chaining to safely access .label
        const formattedRegionName = regionName?.label;
        const formattedCountryName = countryName?.label;
        
        // Conditions based on visibility and null checks
        if (isCountryVisible && !isRegionVisible && formattedCountryName) {
            return formattedCountryName; // Only country is visible and not null
        } else if (!isCountryVisible && isRegionVisible && formattedRegionName) {
            return formattedRegionName; // Only region is visible and not null
        } else if (isCountryVisible && isRegionVisible && formattedRegionName && formattedCountryName) {
            return `${formattedRegionName}, ${formattedCountryName}`; // Both are visible and not null
        }
        return ''; // Fallback for other cases
    };

    // Simplify the displayInfo check
    
    
    const generation = determineGeneration(summary.year_of_birth);

    const currentLocation = formatLocation(visibility.country, visibility.region, summary.region_name, summary.country_name);
    const homeLocation = formatLocation(visibility.home_country, visibility.home_region, summary.home_region_name, summary.home_country_name);

    const authorImage = summary.vertical_image_jpeg || defaultVerticalImage;

const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth > img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth}px`; // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

const hasInfo = generation || summary.major_genre || currentLocation || homeLocation;

const handlePress = (modalType) => {
    dispatch(showGenericModal({
      modalType: modalType, // This could be 'EDIT_BIO' or any other modal type you have.
      modalProps: { // Assuming you want to pass some props based on the modal type
      }
    }));
  };

    return (
      <div className="authorSummary-wrapper">
        <div className="authorSummary">
            <div className="topCard">
                <div className='authorSummary-imagesContainer'>
                    <picture>
                        {summary.vertical_image_webp && (
                            <source srcSet={summary.vertical_image_webp} type="image/webp"/>
                        )}
                        {summary.vertical_image_jpeg && (
                            <source srcSet={summary.vertical_image_jpeg} type="image/jpeg" />
                        )}
                        {/* Ensure the img tag is always rendered for fallback */}
                        <img src={summary.vertical_image_webp || summary.vertical_image_jpeg || summary.vertical_image || defaultVerticalImage} 
                            alt="Author" 
                            className="authorSummary-imagess"
                            onLoad={handleImageLoad}
                            onClick={() => handlePress('EDIT_BIO')}
                        />
                    </picture>
                </div>
                <div className="authorSummary-info">
                    <div className="authorName-container">
                      <h3 className="authorSummary-name">{summary.name}</h3>
                    </div>
                    {hasInfo ? (
                      <>
                        {generation && visibility.year_of_birth && (
                            <p className="summary-info"><span className="info-label">Grew up in the:</span> {generation}</p>
                        )}
                        {summary.major_genre && visibility.major_genre && (
                            <p className="summary-info"><span className="info-label">Major Genre:</span> {summary.major_genre}</p>
                        )}
                        {currentLocation && (
                            <p className="summary-info"><span className="info-label">Lives:</span> {currentLocation}</p>
                        )}
                        {homeLocation && (
                            <p className="summary-info"><span className="info-label">From:</span> {homeLocation}</p>
                        )}
                      </>
                    ) : (
                      <div className="authorName-container">
                        <p className="summary-info-center">of whom little is known.</p>
                      </div>
                    )}
                </div>
            </div>
        </div>
      </div>
    );
};

export default WriterAuthorSummary;
