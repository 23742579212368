import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideGenericModal, showGenericModal } from '../../store/modalSlice';
import { setRestrictedRetailer } from '../../store/taggingSlice';
import './RestrictRetailerModal.css';

const RestrictToRetailerModal = () => {
  const dispatch = useDispatch();
  const [selectedRetailer, setSelectedRetailer] = useState(null);

  const handleRetailerChange = (event) => {
    setSelectedRetailer(event.target.value);
  };

  const handleCancel = () => {
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK',
    }));
  };

  const handleUpdate = () => {
    if (selectedRetailer) {
      dispatch(setRestrictedRetailer(selectedRetailer));
    }
    dispatch(hideGenericModal());
    dispatch(showGenericModal({
      modalType: 'TAG_BOOK',
    }));
  };

  return (
    <div className="modalBackground">
      <div className="modalesContaineres">
        <h2 className="modalsTitles">Restrict search to retailer</h2>
        <div className="forbreaks-hopes">
          <label className="sectionTitler">Choose Retailer:</label>
        </div>
        <div className="retailer-options">
          <label>
            <input
              type="radio"
              value="BN"
              checked={selectedRetailer === 'BN'}
              onChange={handleRetailerChange}
            />
            Barnes & Noble
          </label>
          <label>
            <input
              type="radio"
              value="BAM"
              checked={selectedRetailer === 'BAM'}
              onChange={handleRetailerChange}
            />
            Books-A-Million
          </label>
        </div>
        <div className="button-container">
          <button 
            className="infosButton-details-next" 
            onClick={handleUpdate}
            disabled={!selectedRetailer}
          >
            Update
          </button>            
          <button 
            className="infosButton-cancel" 
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RestrictToRetailerModal;