import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { checkAuthenticationStatus } from '../../store/userSlice';
import TheFeed from '../TheFeed/TheFeed';
import InsideReaderPortal from '../InsideReaderPortal/InsideReaderPortal';
import Library from '../Library/Library';

function ReaderPortal() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(checkAuthenticationStatus()).then(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <TheFeed />;
  }

  return (
    <Routes>
      <Route path="/" element={<InsideReaderPortal />}>
        <Route index element={<TheFeed />} />
        <Route path="library" element={<Library />} />
      </Route>
    </Routes>
  );
}

export default ReaderPortal;