import { createSlice } from '@reduxjs/toolkit';

// Define the initial state
const initialState = {
  applications: [],
};

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    // Action to set the array of applications
    setApplications: (state, action) => {
      state.applications = action.payload;
    },
    // Add other reducer actions as needed
  },
});

// Export the action creators
export const { setApplications } = applicationsSlice.actions;

// Export the selector (good practice to abstract this)
export const selectApplications = (state) => state.applications.applications;

// Export the reducer
export default applicationsSlice.reducer;
