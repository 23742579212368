
import React, { useState } from 'react';
import './ModalViewLinksAndSocials.css';
import { useDispatch } from 'react-redux';
import LinkDisplayBox from '../LinkDisplayBox/LinkDisplayBox';
import SocialDisplayBox from '../SocialDisplayBox/SocialDisplayBox';
import { hideGenericModal } from '../../store/modalSlice';


const ModalViewLinksAndSocials = ({ links, socials }) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(links.length > 0 ? 'links' : 'socials');

    const onClose = () => {
        dispatch(hideGenericModal());
      };

    return (
        <div className="unique-modal-view-background" >
            <div className="unique-modal-view-container" onClick={(e) => e.stopPropagation()}>
                <div className="unique-modal-view-header">
                    {(links.length > 0 || socials.length > 0) ? (
                        <>
                            {links.length > 0 && (
                                <div
                                    className={`unique-tab-title-container ${activeTab === 'links' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('links')}
                                >
                                    <h2 className="unique-tab-title">Links</h2>
                                </div>
                            )}
                            {socials.length > 0 && (
                                <div
                                    className={`unique-tab-title-container ${activeTab === 'socials' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('socials')}
                                >
                                    <h2 className="unique-tab-title">Platforms</h2>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="unique-single-tab-title">
                            <h2 className="unique-tab-title">{links.length ? 'Links' : 'Platforms'}</h2>
                        </div>
                    )}
                </div>
                <div className="unique-modal-view-content">
                    {activeTab === 'links' && (
                        <div className="unique-links-section">
                            {links.length === 0 ? (
                                <p>No links added (yet)</p>
                            ) : (
                                links.map((link, index) => (
                                    <LinkDisplayBox
                                        key={index}
                                        title={link.title}
                                        link={link.link}
                                        publisher={link.publisher}
                                        description={link.description}
                                    />
                                ))
                            )}

                        </div>
                    )}
                    {activeTab === 'socials' && (
                        <div className="unique-socials-section">
                            {socials.length === 0 ? (
                                <p>No social media or website added (yet)</p>
                            ) : (
                                socials.map((social, index) => (
                                    <SocialDisplayBox
                                        key={index}
                                        platform={social.platform}
                                        handle={social.handle}
                                        url={social.url}
                                    />
                                ))
                            )}
                            
                        </div>
                    )}
                </div>
                <div className="unique-modal-view-separator" />
                <div className="modalest-foot">
                    <button className="unique-modal-close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default ModalViewLinksAndSocials;
