import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  genre: [],
  style: [],
  length: [],
  decade: [],
  locationGrewUp: {
    country: '',
    region: ''
  },
  locationLiveNow: {
    country: '',
    region: ''
  },
  shouldApplyFilters: false,
  isFiltering: false
};

const updateIsFiltering = (state) => {
  state.isFiltering = 
    state.genre.length > 0 ||
    state.style.length > 0 ||
    state.length.length > 0 ||
    state.decade.length > 0 ||
    state.locationGrewUp.country !== '' ||
    state.locationGrewUp.region !== '' ||
    state.locationLiveNow.country !== '' ||
    state.locationLiveNow.region !== '';
};

export const theFeedSlice = createSlice({
  name: 'theFeed',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;
      if (filterType === 'locationGrewUp' || filterType === 'locationLiveNow') {
        state[filterType] = { ...state[filterType], ...value };
      } else {
        state[filterType] = value;
      }
      updateIsFiltering(state);
    },
    resetFilters: () => {
      return initialState;
    },
    clearFilter: (state, action) => {
      const filterType = action.payload;
      if (filterType === 'locationGrewUp' || filterType === 'locationLiveNow') {
        state[filterType] = { country: '', region: '' };
      } else {
        state[filterType] = [];
      }
      updateIsFiltering(state);
    },
    setShouldApplyFilters: (state, action) => {
      state.shouldApplyFilters = action.payload;
    },
  },
});

export const { setFilter, resetFilters, clearFilter, setShouldApplyFilters } = theFeedSlice.actions;

export default theFeedSlice.reducer;