import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import AboutWords from '../../components/AboutWords/AboutWords';
import Footer from '../../components/Footer/Footer';
import './About.css';

function About() {
  window.scrollTo(0, 0);
  
  return (
    <div className="aboutpage">
      <Helmet>
        <title>About ShorterStories | For writers, readers, and industry professionals</title>
        <meta name="description" content="Discover how ShorterStories serves writers, readers and the book industry. " />
      </Helmet>
      <Header />
      <div className="aboutpage-content">
        <AboutWords />
      </div>
      <Footer />
    </div>
  );
}

export default About;