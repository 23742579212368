  import { createSlice } from '@reduxjs/toolkit';

  // Initial state of the publishing process
  const initialState = {
    currentStage: 'selection', // The starting stage of the publishing process
    currentPhoto: null,       // The initial state for the cover art photo
    title: '',                // The initial state for the title of the piece
    subtitle: '', 
    description: '',          // The initial state for the description of the piece
    piece: '',                // The initial state for the content of the piece
    taggedBooks: [],          // The initial state for tagged books
    genre: 'blank',                // The initial state for genre
    style: 'blank',               // The initial state for style
    isSerial: false,
    serialFirstProfileHash: null,
    serialPartNumber: null,
    previousSerialHash: null,
  };

  export const publishingSlice = createSlice({
    name: 'publishing',
    initialState,
    reducers: {
      // Set the current stage of the publishing process
      setCurrentStage: (state, action) => {
        state.currentStage = action.payload;
      },
      // Set the current photo
      setCurrentPhoto: (state, action) => {
        state.currentPhoto = action.payload;
      },
      // Set the title
      setTitle: (state, action) => {
        state.title = action.payload;
      },
      setSubtitle: (state, action) => {  // New reducer for subtitle
        state.subtitle = action.payload;
      },
      // Set the description
      setDescription: (state, action) => {
        state.description = action.payload;
      },
      // Set the piece content
      setPiece: (state, action) => {
        state.piece = action.payload;
      },
      setTaggedBooks: (state, action) => {
        state.taggedBooks = action.payload;
      },
      setGenre: (state, action) => {
        state.genre = action.payload;
      },
      setStyle: (state, action) => {
        state.style = action.payload;
      },
      setIsSerial: (state, action) => {
        state.isSerial = action.payload;
      },
      setSerialFirstProfileHash: (state, action) => {
        state.serialFirstProfileHash = action.payload;
      },
      setSerialPartNumber: (state, action) => {
        state.serialPartNumber = action.payload;
      },
      setPreviousSerialHash: (state, action) => {
        state.previousSerialHash = action.payload;
      },
      // Reset the publishing process to its initial state
      resetPublishingProcess: () => initialState,
    },
    
  });

  // Export actions
  export const {
    setCurrentPhoto,
    setCurrentStage,
    setTitle,
    setSubtitle, 
    setDescription,
    setPiece,
    resetPublishingProcess,
    setTaggedBooks,
    setGenre,
    setStyle,
    setIsSerial,
    setSerialFirstProfileHash,
    setSerialPartNumber,
    setPreviousSerialHash,
  } = publishingSlice.actions;

  // Export the reducer
  export default publishingSlice.reducer;
    