import React, { useState, useEffect } from "react";
import './UnsubscribeBox.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function UnsubscribeBox() {
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenParam = params.get('token');
    if (tokenParam) {
      setToken(tokenParam); 
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = 'https://shorterstoriesxyz.vercel.app/api/sendEmailServer';  


    try {
        await axios.delete(url, { 
            data: { token: token },
            headers: { "Content-Type": "application/json" } 
          });

      setMessage('Unsubscribe successful. Good riddance. I was too good looking for you anyway. [The machine chuckled to itself]');
    } catch (error) {
      setMessage('Oh shit oh shit oh shit, error in the unsubscribe process.');
      console.error('Error details:', error);
    }
};



  return (
    <div className="unsubscribe-box">
      <p className="unsubscribe-text">What?! You think you're better than me?</p>
      <p className="unsubscribe-text">Please confirm below:</p>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
            <input
            className="email-inputs"
            type="hidden"
            name="token"
            value={token}
            />
            <input className="submit-buttons" type="submit" value="Unsubscribe" />
        </form>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default UnsubscribeBox;