// Import necessary components and hooks from react-router-dom
import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import WritersPublishScreen from '../WritersPublishScreen/WritersPublishScreen';
import WritersProfileScreen from '../WritersProfileScreen/WritersProfileScreen';
import Header from '../../components/HeaderPublishPreview/HeaderPublishPreview';
import BooksInPiece from '../../components/BooksInPiecePreview/BooksInPiecePreview';
import { fetchAuthorProfile } from '../../store/authorProfileSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import './WritersPublishPreview.css';
import printingPressProfile from '../../assets/images/printingpressprofile.png';

function PublishPreview() {

  const dispatch = useDispatch();

  const {
    title,
    piece,
    taggedBooks,
    genre,
    style,
  } = useSelector(state => state.publishing);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }
  

  const getLengthCategory = (text) => {
    const wordCount = countWords(stripHtml(text));
    if (wordCount <= 250) return 'Bite Sized';
    if (wordCount <= 1500) return 'Flash';
    if (wordCount <= 5000) return 'Shorter Story';
    return 'Short Story';
  };


  const { userId, userName } = useSelector(state => state.user);


  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const { data: authorProfile, isLoading: isLoadingProfile, error: errorProfile } = useSelector((state) => state.authorProfile);


  // Fetch author profile data
  useEffect(() => {
      if (!authorProfile && !isLoadingProfile) {
        // Trigger the fetch only if authorProfile hasn't been loaded and not currently loading
        dispatch(fetchAuthorProfile({ userId, userName }));
      }
      // It's essential to include isLoading in the dependency array if its value is used in the condition
    }, [dispatch, userId, userName, authorProfile, isLoadingProfile]);
  // Use useNavigate for navigation actions
  const navigate = useNavigate();

  
  if (isLoadingProfile || !authorProfile) {
    return <div>Loading author information...</div>;
  }

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1.0< img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

  return (
    <div className="preview-piece-page">
      <Header />
      <div className="preview-contents-container">
        <div className="previews-author-info">
          <div className='preview-author-only'>
            <img src={authorProfile.profile_image_jpeg||printingPressProfile} alt="Author" className="preview-author-photo" />
            <span className="preview-author-name">{authorProfile.name}</span>
          </div>
          <span className="preview-length-indicator">{getLengthCategory(piece)}</span>
        </div>

        {currentPhoto && (
          <div className="preview-piece-image-container">
            <img src={currentPhoto} alt="Cover Art" className="preview-piece-image" onLoad={handleImageLoad}/>
          </div>
        )}
        <div className="preview-genre-style-container">
          <span>
            Genre: <span className="preview-italic-text">{genre !== 'blank' ? genre : 'Not specified'}</span>
          </span>
          <span>
            Style: <span className="preview-italic-text">{style !== 'blank' ? style : 'Not specified'}</span>
          </span>
        </div>
        <div className="preview-divider"></div>
        {taggedBooks && taggedBooks.length > 0 && (
          <>
            <div className="preview-promoted-books-text">Promoted books</div>
            <BooksInPiece feedItems={taggedBooks} />
            <div className="preview-divider"></div>
          </>
        )}
        <h3 className="preview-pieces-title">
          {title} <span className="preview-authors-name">by {authorProfile.name}</span>
        </h3>
        <div className="preview-piece-content" dangerouslySetInnerHTML={{ __html: piece }}></div>
      </div>
    </div>
  );
}

export default PublishPreview;
