import React from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { checkEmployeeStatus } from '../../store/employeeSlice';
import ReusableBoxContainer from '../ReusableBoxContainer/ReusableBoxContainer';

const NonEmployeePortal = () => {
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      dispatch(checkEmployeeStatus());
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="center-screen"> {/* Your CSS class to center the component */}
      <ReusableBoxContainer
        title="Hold up!"
        description="We aint got you down as employee, try logging in and then out again. If that don't work, wo nelly, tell your boss to tell me that I better put my firing pants on, cause someone done gone and screwed the hell up."
        showButton={true}
        buttonText="Log Out"
        onButtonPress={handleSignOut}
      />
    </div>
  );
};

export default NonEmployeePortal;
