import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import WritersFooter from '../../components/WritersFooter/WritersFooter';

function InsideWritersPortal() {
  const navigate = useNavigate();
  const footerRef = useRef(null); // Create a ref for the footer
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    // Function to update the footer height using the ref
    const updateFooterHeight = () => {
      if (footerRef.current) {
        setFooterHeight(footerRef.current.offsetHeight);
      }
    };

    // Call it on mount to ensure the footer height is set
    updateFooterHeight();

    // Optional: If your footer might change height based on window resize, add this
    window.addEventListener('resize', updateFooterHeight);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', updateFooterHeight);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div style={{ paddingBottom: `${footerHeight}px` }}> {/* Dynamically set padding based on footer height */}
      <Outlet />
      <WritersFooter ref={footerRef} /> {/* Attach the ref to WritersFooter */}
    </div>
  );
}

export default InsideWritersPortal;
