import React from 'react';
import Header from '../../components/Header/Header';
import UnsubscribeBox from '../../components/UnsubscribeBox/UnsubscribeBox';
import './Unsubscribe.css';
import Footer from '../../components/Footer/Footer';

function Unsubscribe() {
    return (
      <div className="unsubscribe">
        <Header />
        <div className="main-content">
          <div className="unsubscribe-content">
            <UnsubscribeBox />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
export default Unsubscribe;