import React from 'react';
import './DashHorizontalFeedPieces.css';

const HorizontalFeedPieces = ({ feedName, feedItems }) => {
  if (feedItems.length === 0) {
    return (
      <div className="dash-horizontal-feed-pieces">
        <h2 className="feed-name">{feedName}</h2>
        <p>No items to display</p>
      </div>
    );
  }

  return (
    <div className="dash-horizontal-feed-pieces">
      <h2 className="feed-name">{feedName}</h2>
      <div className="feed-items">
        {feedItems.map((item, index) => (
          <div className="feed-item" key={index}>
            <img src={item.imageSrc} alt={item.title} />
            <p className="item-title">{item.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalFeedPieces;
