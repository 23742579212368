import React, { useState } from 'react';
import { useDispatch, useSelector} from 'react-redux'; 
import { useNavigate, useLocation } from 'react-router-dom';
import { setCurrentLoginPage, setPassEmail, setPassPassword, checkAuthenticationStatus } from '../../store/userSlice'; 
import './LoginBox.css'; // Import the CSS file for styling
import { Auth } from 'aws-amplify';

const LoginBox = ( ) => {
  const [emailLocal, setEmailLocal] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch(); 
  const navigate = useNavigate();
  const location = useLocation();
  const { stats, isLoading, error } = useSelector((state) => state.home);

  const handleLogin = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    console.log('here', stats);
    console.log('error',error);

    if (!emailLocal) {
      alert('Not so fast, You got to enter an email address.');
      return;
    }

    if (!emailPattern.test(emailLocal)) {
      alert('Not so fast, That is not a valid email address.');
      return;
    }

    if (!password) {
      alert('Not so fast, You got to enter a password.');
      return;
    }

    if (password.length < 6) {
      alert('Not so fast, Your password got to be at least 6 characters long.');
      return;
    }

    try {
      await Auth.signIn(emailLocal, password);
      dispatch(checkAuthenticationStatus());
      if (!location.pathname.startsWith('/writers')) {
        navigate('/');
      }
    } catch (error) {
      
      if (error.code === 'UserNotConfirmedException') {
        dispatch(setPassEmail(emailLocal));
        dispatch(setPassPassword(password));
        dispatch(setCurrentLoginPage('confirmSignUp'));
      } else {
        console.error('Error signing in', error);
        alert('Error signing in: ' + error.message);
      }
    }
  };

  const handleForgotPassword = () => {
    const isWritersPage = location.pathname.startsWith('/writers');
    const forgotPasswordPath = isWritersPage ? '/writers/forgot-password' : '/forgot-password';
    navigate(forgotPasswordPath);
  };

  return (
    <div className="loginBox">
      <div className="inputContainer">
        <input
          className="input"
          type="email"
          onChange={(e) => setEmailLocal(e.target.value)}
          value={emailLocal}
          placeholder="Email"
        />
        <input
          className="input"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder="Password"
        />
      </div>
      <button className="submitButton" onClick={handleLogin}>
        Log in
      </button>
      <div className="separator" />
      <button className="forgotPassword" onClick={handleForgotPassword}>
        Forgot password?
      </button>
    </div>
  );
};

export default LoginBox;
