import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../utils/axiosInstance';
import { Auth } from 'aws-amplify';

// Async thunk for fetching home stats
export const fetchHomeStats = createAsyncThunk(
    'home/fetchHomeStats',
    async ({ userId, userName, timeFrame }, { rejectWithValue }) => {
      try {
        console.log('here');
        const session = await Auth.currentSession();
        const token = session.idToken.jwtToken;
  
        const response = await apiInstance.get(`/dashboard/stats?user_id=${userId}&author_name=${encodeURIComponent(userName)}&time_frame=${timeFrame}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log('please', response.data); 
        return response.data;
      } catch (error) {
        console.error('Failed to fetch home stats why:', error.response.data);
        return rejectWithValue(error.response.data);
      }
    }
);

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    
    stats: null,
    isLoading: false,
    error: null,
    selectedTime: '7days', // Default time frame
  },
  reducers: {
    resetHomeStats: (state) => {
      console.log("Resetting home stats");
      state.stats = null;
      state.isLoading = false;
      state.error = null;
      state.selectedTime = '7days'
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHomeStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stats = action.payload;
      })
      .addCase(fetchHomeStats.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to fetch home stats';
      });
  },
});

export const { resetHomeStats, setSelectedTime } = homeSlice.actions;

export default homeSlice.reducer;
