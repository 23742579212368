import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import WriterPortal from './pages/WriterPortal/WriterPortal';
import Screenshots from './pages/ScreenshotsPage/ScreenshotsPage';
import HomePrivacyPolicy from './pages/HomePrivacyPolicy/HomePrivacyPolicy';
import Unsubscribe from './pages/Unsubscribe/Unsubscribe';
import CreateAccountWriter from './pages/CreateAccountWriter/CreateAccountWriter';
import EmployeeDashboard from './pages/EmployeeDashboard/EmployeeDashboard';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import WritersHomeView from './pages/WritersHomeView/WritersHomeView';
import WritersProfileScreen from './pages/WritersProfileScreen/WritersProfileScreen';
import WritersPublishScreen from './pages/WritersPublishScreen/WritersPublishScreen';
import EditProfileScreen from './pages/EditProfileScreen/EditProfileScreen';
import ApplicationReviewModal from './components/ApplicationReviewModal/ApplicationReviewModal'; 
import EditBioPicModal from './components/EditBioPicModal/EditBioPicModal';
import HomeSettingsModal from './components/HomeSettingsModal/HomeSettingsModal';
import EditHeadshotModalContent from './components/EditHeadshotModalContent/EditHeadshotModalContent';
import EditGeneralInfoModal from './components/UpdateGeneralInfoModal/UpdateGeneralInfoModal';
import EditMajorGenreModal from './components/EditMajorGenreModal/EditMajorGenreModal';
import EditLivesRegionCountryModal from './components/EditLivesRegionCountryModal/EditLivesRegionCountryModal';
import EditFromRegionCountryModal from './components/EditFromRegionCountryModal/EditFromRegionCountryModal';
import EditWritingNameModal from './components/EditWritingNameModal/EditWritingNameModal';
import EditDetailsModal from './components/EditDetailsModal/EditDetailsModal';
import EditWhatsOnTheCardModal from './components/EditWhatsOnTheCardModal/EditWhatsOnTheCardModal';
import ModalAddLinksAndSocials from './components/ModalAddLinksAndSocials/ModalAddLinksAndSocials';
import ModalViewLinksAndSocials from './components/ModalViewLinksAndSocials/ModalViewLinksAndSocials';
import ModalAddLink from './components/ModalAddLink/ModalAddLink';
import ModalAddSocial from './components/ModalAddSocial/ModalAddSocial';
import GettingIntoCreateAccount from './pages/GettingIntoCreateAccount/GettingIntoCreateAccount';
import GettingIntoCreateAccountHome from './pages/GettingIntoCreateAccountHome/GettingIntoCreateAccountHome';
import GettingIntoApplication from './pages/GettingIntoApplication/GettingIntoApplication';
import FinerDetails from './pages/FinerDetails/FinerDetails';
import AboutWriters from './pages/AboutWriters/AboutWriters';
import AboutReaders from './pages/AboutReaders/AboutReaders';
import BooksellerFAQs from './pages/BooksellerFAQs/BooksellerFAQs';
import TagBookModalOne from './components/TagBookModalOne/TagBookModalOne';
import TagBookModalOneAndHalf from './components/TagBookModalOneAndHalf/TagBookModalOneAndHalf';
import TagBookModalTwo from './components/TagBookModalTwo/TagBookModalTwo';
import TagBookModalTwoBN from './components/TagBookModalTwoBN/TagBookModalTwoBN';
import TagBookModalThree from './components/TagBookModalThree/TagBookModalThree';
import TagBookModalThreeBN from './components/TagBookModalThreeBN/TagBookModalThreeBN';
import GeneralModal from './components/modal/modal';
import BookModalContent from './components/BookModalContent/BookModalContent';
import BookModalProfile from './components/BookModalProfile/BookModalProfile';
import SocialMediaFormModal from './components/SocialMediaFormModal/SocialMediaFormModal'; // Adjust the path as necessary
import PublicationFormModal from './components/PublicationFormModal/PublicationFormModal';
import ModalTheFeedOptions from './components/ModalTheFeedOptions/ModalTheFeedOptions';
import ModalFilterGenreTheFeed from './components/ModalFilterGenreTheFeed/ModalFilterGenreTheFeed';
import ModalFilterStyleTheFeed from './components/ModalFilterStyleTheFeed/ModalFilterStyleTheFeed';
import ModalFilterLengthTheFeed from './components/ModalFilterLengthTheFeed/ModalFilterLengthTheFeed';
import ModalFilterDecadeTheFeed from './components/ModalFilterDecadeTheFeed/ModalFilterDecadeTheFeed';
import ModalFilterLivesTheFeed from './components/ModalFilterLivesTheFeed/ModalFilterLivesTheFeed';
import ModalFilterFromTheFeed from './components/ModalFilterFromTheFeed/ModalFilterFromTheFeed';
import HomeLogin from './pages/HomeLogin/HomeLogin';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import { hideGenericModal } from './store/modalSlice';
import Piece from './pages/Piece/Piece';
import TheFeed from './pages/TheFeed/TheFeed';
import WritersPublishPreview from './pages/WritersPublishPreview/WritersPublishPreview';
import AuthorRefinationModal from './components/AuthorRefinationModal/AuthorRefinationModal';
import RestrictRetailerModal from './components/RestrictRetailerModal/RestrictRetailerModal';
import ReaderPortal from './pages/ReaderPortal/ReaderPortal';



import './App.css';
import About from './pages/About/About';
import Jobs from './pages/Jobs/Jobs';

function App() {
  const dispatch = useDispatch();
  const applicationReviewModalVisible = useSelector(state => state.modal.applicationReviewModalVisible); // Adjust this selector to match your Redux store structure
  const { genericModalVisible, genericModalType, genericModalProps } = useSelector(state => state.modal);


  let ModalContent = null;
  if (genericModalVisible) {
    switch (genericModalType) {
      case 'BOOK_MODAL':
        ModalContent = <BookModalContent {...genericModalProps} />;
        break;
      case 'SOCIAL_MEDIA_FORM':
        ModalContent = <SocialMediaFormModal {...genericModalProps} />;
        break;
      case 'PUBLICATION_FORM':
        ModalContent = <PublicationFormModal {...genericModalProps} />;
        break;
      case 'EDIT_BIO':
        ModalContent = <EditBioPicModal {...genericModalProps} />;
        break;
      case 'EDIT_HEADSHOT':
        ModalContent = <EditHeadshotModalContent {...genericModalProps} />;
        break;
      case 'EDIT_GENERAL_INFO':
        ModalContent = <EditGeneralInfoModal {...genericModalProps} />;
        break;
      case 'EDIT_MAJOR_GENRE':
        ModalContent = <EditMajorGenreModal {...genericModalProps} />;
        break;
      case 'EDIT_LIVES_REGION_COUNTRY':
        ModalContent = <EditLivesRegionCountryModal {...genericModalProps} />;
        break;
      case 'EDIT_FROM_REGION_COUNTRY':
        ModalContent = <EditFromRegionCountryModal {...genericModalProps} />;
        break;
      case 'TAG_BOOK':
        ModalContent = <TagBookModalOne {...genericModalProps} />;
        break;
      case 'TAG_BOOK_ONE_AND_HALF':
        ModalContent = <TagBookModalOneAndHalf {...genericModalProps} />;
        break;
      case 'TAG_BOOK_TWO':
        ModalContent = <TagBookModalTwo {...genericModalProps} />;
        break;
      case 'TAG_BOOK_TWO_BN':
        ModalContent = <TagBookModalTwoBN {...genericModalProps} />;
        break;
      case 'TAG_BOOK_THREE':
        ModalContent = <TagBookModalThree {...genericModalProps} />;
        break;
      case 'TAG_BOOK_THREE_BN':
        ModalContent = <TagBookModalThreeBN {...genericModalProps} />;
        break;
      case 'EDIT_CARD':
        ModalContent = <EditWhatsOnTheCardModal {...genericModalProps} />;
        break;
      case 'EDIT_DETAILS':
        ModalContent = <EditDetailsModal {...genericModalProps} />;
        break;
      case 'HOME_SETTINGS':
        ModalContent = <HomeSettingsModal {...genericModalProps} />;
        break;
        case 'EDIT_LINKS_AND_SOCIALS':
          ModalContent = <ModalAddLinksAndSocials {...genericModalProps} />;
          break;
        case 'VIEW_LINKS_AND_SOCIALS':
          ModalContent = <ModalViewLinksAndSocials {...genericModalProps} />;
          break;
        case 'ADD_LINK_MODAL':
          ModalContent = <ModalAddLink {...genericModalProps} />;
          break;
        case 'ADD_SOCIAL_MODAL':
          ModalContent = <ModalAddSocial {...genericModalProps} />;
          break;
        case 'EDIT_WRITING_NAME':
          ModalContent = <EditWritingNameModal {...genericModalProps} />;
          break;
        case 'REFINE_BY_AUTHOR_MODAL':
          ModalContent = <AuthorRefinationModal {...genericModalProps} />;
          break;
        case 'RESTRICT_TO_RETAILER_MODAL':
          ModalContent = <RestrictRetailerModal {...genericModalProps} />;
          break;
        case 'BOOK_MODAL_PROFILE':
          ModalContent = <BookModalProfile {...genericModalProps} />;
          break;
        case 'THE_FEED_OPTIONS':
          ModalContent = <ModalTheFeedOptions {...genericModalProps} />;
          break;
        case 'FILTER_GENRE':
          ModalContent = <ModalFilterGenreTheFeed {...genericModalProps} />;
          break;
        case 'FILTER_STYLE':
          ModalContent = <ModalFilterStyleTheFeed {...genericModalProps} />;
          break;
        case 'FILTER_LENGTH':
          ModalContent = <ModalFilterLengthTheFeed {...genericModalProps} />;
          break;
        case 'FILTER_DECADE':
          ModalContent = <ModalFilterDecadeTheFeed {...genericModalProps} />;
          break;
        case 'FILTER_LOCATION_LIVE_NOW':
          ModalContent = <ModalFilterLivesTheFeed {...genericModalProps} />;
          break;
        case 'FILTER_LOCATION_GREW_UP':
          ModalContent = <ModalFilterFromTheFeed {...genericModalProps} />;
          break;
        
        
        
    
        
        default:
          // Default case when no modalType matches
          break;
      // Other cases for different modals
    }
  }

  return (  
    <div style={{ backgroundColor: '#FFF9F2', minHeight: '100vh' }}>
      <Router>
        {applicationReviewModalVisible && <ApplicationReviewModal />}
        {genericModalVisible && (
          <GeneralModal onClose={() => dispatch(hideGenericModal())}>
            {ModalContent}
          </GeneralModal>
        )}
        <Routes>  
          <Route path="/" element={<HomePage />} />
          <Route path="/writers/*" element={<WriterPortal />}>
            <Route index element={<WritersHomeView />} />
            <Route path="publish/*" element={<WritersPublishScreen />} />
            <Route path="profile" element={<WritersProfileScreen />} />
            <Route path="edit-profile" element={<EditProfileScreen />} />
            <Route path="preview-piece" element={<WritersPublishPreview />} />
          </Route>
          <Route path="/readers/*" element={<ReaderPortal />} />
          <Route path="/login" element={<HomeLogin />} />
          <Route path="/create-account" element={<CreateAccount />} /> 
          <Route path="/create-account/whats-up" element={<GettingIntoCreateAccountHome />} />
          <Route path="/booksellers/faqs" element={<BooksellerFAQs />} />
          {/* <Route path="/writers/login-info" element={<InfoScreen />} />  */}
          <Route path="/writers/create-account" element={<CreateAccountWriter />} /> 
          <Route path="/writers/application-whats-up" element={<GettingIntoApplication />} /> 
          <Route path="/writers/finer-details" element={<FinerDetails />} /> 
          <Route path="/writers/create-account/whats-up" element={<GettingIntoCreateAccount />} />
          <Route path="/writers/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/writers" element={<AboutWriters />} />
          <Route path="/about/readers" element={<AboutReaders />} />
          <Route path="/about/industry" element={<BooksellerFAQs />} />
          <Route path="/thefeed" element={<TheFeed />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/piece/:pieceHash" element={<Piece />} />
          <Route path="/writer/:profileHash" element={<ProfilePage />} />
          <Route path="/Screenshots" element={<Screenshots />} />
          <Route path="/home-privacy-policy" element={<HomePrivacyPolicy />} />
          <Route path="/Unsubscribe" element={<Unsubscribe />} />
          <Route path="/employee-dashboard" element={<EmployeeDashboard />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;