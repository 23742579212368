// slices/authorProfileSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiInstance } from '../utils/axiosInstance';


// Async thunk for fetching author profile
export const fetchAuthorProfile = createAsyncThunk(
  'authorProfile/fetchAuthorProfile',
  async ({ userId, userName }) => {
    const response = await apiInstance.get(`/platformAuthors/summary/fetch?user_id=${userId}&author_name=${encodeURIComponent(userName)}`);
    return response.data;
  }
);

const authorProfileSlice = createSlice({
  name: 'authorProfile',
  initialState: {
    data: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetAuthorProfile: state => {
    state.data = null;
    state.isLoading = false;
    state.error = null;
  }
},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthorProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAuthorProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAuthorProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetAuthorProfile } = authorProfileSlice.actions;

export default authorProfileSlice.reducer;
