// WritersPublishImage.js
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCurrentPhoto, setCurrentStage, resetPublishingProcess } from '../../store/publishingSlice';
import defaultImage from '../../assets/images/birdie.jpeg';
import HeaderPublish from '../../components/HeaderPublish/HeaderPublish';

import EXIF from 'exif-js';
import './WritersPublishImage.css';

function WritersPublishImage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Ensure to handle the case where `currentPhoto` is null or undefined initially
  const currentPhoto = useSelector(state => state.publishing.currentPhoto) || defaultImage;
  const fileInputRef = useRef(null);
  const [isDefault, setIsDefault] = useState(currentPhoto === defaultImage);
  const buttonText = "Use image and continue to next step \u2192"; 
  const otherButtonText = "Discard and start a new"; 
  const storedHtml = useSelector((state) => state.publishing.piece);



  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };


const correctImageOrientation = (base64, orientation) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set dimensions and rotate context based on orientation
      if (orientation === 6 || orientation === 5) {
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((90 * Math.PI) / 180);
        ctx.translate(0, -canvas.width);
      } else if (orientation === 3 || orientation === 4) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.rotate((180 * Math.PI) / 180);
        ctx.translate(-canvas.width, -canvas.height);
      } else if (orientation === 8 || orientation === 7) {
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.translate(-canvas.height, 0);
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      // Mirror image context if needed (for orientations 2, 4, 5, 7)
      if ([2, 4, 5, 7].indexOf(orientation) > -1) {
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
      }

      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.src = base64;
  });
};

const handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Read EXIF data and adjust orientation
    EXIF.getData(file, function () {
      const orientation = EXIF.getTag(this, 'Orientation');
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        correctImageOrientation(base64, orientation).then((correctedBase64) => {
          dispatch(setCurrentPhoto(correctedBase64));
          setIsDefault(false);
        });
      };
      reader.readAsDataURL(file);
    });
  }
};

  const discardImage = () => {
    dispatch(setCurrentPhoto(defaultImage));
    setIsDefault(true);
    console.log('Discard image', storedHtml);
  };

  const navigateToNextStage = () => {
    dispatch(setCurrentStage('writing'));
    navigate(`/writers/publish/writing`);

    // Placeholder for navigation, ensure you have your routing set up to navigate
  };
  

  const handleImageLoad = (e) => {


    const img = e.target;
    if (img.naturalWidth * 1 < img.naturalHeight) {

        // Adjust the image height to match its width for landscape images
        img.style.width = '100%'; // Set the width to 40% of its container
        img.style.height = `${img.offsetWidth * 1}px`;  // Match the height to the current width
        img.style.objectFit = 'cover'; // Ensure the image covers the area, cropping as necessary
    } else {

        // Apply or reset styles for portrait/square images as necessary
        img.style.width = '100%'; // Maintain the 40% width
        img.style.height = 'auto'; // Allow the height to adjust naturally
    }
};

const handleDiscardAndRestart = () => {
  const confirmRestart = window.confirm("Are you sure you want to discard your progress and start again?");
  if (confirmRestart) {
    dispatch(resetPublishingProcess());
    navigate('/writers/publish/selection');
  }
};


  
  return (
    <div className="modalesque">
      <HeaderPublish />
      <div className="contentBoxes">
        <div className="titleContainerey">
          <p className="modalTitley">Upload some cover art:</p>
        </div>
        <img src={currentPhoto} alt="Cover Art" className="image-previews" onLoad={handleImageLoad}/>
        <div className="fileUploadContainer">
          <input type="file" ref={fileInputRef} onChange={handleImageUpload} className="fileInput" accept="image/*" style={{ display: 'none' }} />
          {!isDefault && <div className="buttonRow">
            <button className="infosButton" onClick={discardImage}>Discard image</button>
            <button className="infosButton" onClick={handleImageUploadClick}>Upload new cover image</button>
            
          </div>}
          {isDefault && <button className="infosButton" onClick={handleImageUploadClick}>Upload cover image</button>}
        </div>
        <button onClick={navigateToNextStage} className="infosButton" style={{ fontWeight: 'bold' }}>{buttonText}</button>
        <div className="button-separatores"></div>
        <button onClick={handleDiscardAndRestart} className="infosButton">{otherButtonText}</button>
      </div>
    </div>
  );
}

export default WritersPublishImage;
