import React from 'react';
import './LinkDisplayBox.css';


const LinkDisplayBox = ({ link, title, publisher, description }) => {


    const getPublicationText = (publisher) => {
        if (publisher.toLowerCase().startsWith('the')) {
          return `published in ${publisher}`;
        }
        return `published on ${publisher}`;
      };


  return (
    <a href={link} 
       className="ttreusableBoxContainered" 
       target="_blank" 
       rel="noopener noreferrer"
    >
      <div className="tcontentedViewsomuchInBox">
        <p className="tboldsterInBox">{title}</p>
        <p className="tnormailerInBox">{getPublicationText(publisher)}</p>
        {description && <p className="ttextInBoxcar">{description}</p>}
      </div>
    </a>
  );
};

export default LinkDisplayBox;





