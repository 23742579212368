import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setFilter, setShouldApplyFilters } from '../../store/theFeedSlice';
import './ModalFilterLengthTheFeed.css';

const ModalFilterLengthTheFeed = () => {
  const dispatch = useDispatch();
  const currentLengths = useSelector(state => state.theFeed.length);
  const [selectedLengths, setSelectedLengths] = useState(currentLengths);

  const lengthCategories = [
    { label: "Bite sized (250 words or less)", value: "Bite Sized" },
    { label: "Flash (251 to 1500 words)", value: "Flash" },
    { label: "Shorter story (1501 to 5000 words)", value: "Shorter Story" },
    { label: "Short story (5001 to 10,000 words)", value: "Short Story" },
  ];

  const handleLengthSelect = (length) => {
    setSelectedLengths(prevLengths => {
      if (prevLengths.includes(length)) {
        return prevLengths.filter(l => l !== length);
      } else {
        return [...prevLengths, length];
      }
    });
  };

  const handleSave = () => {
    dispatch(setFilter({ filterType: 'length', value: selectedLengths }));
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };

  const handleClearAll = () => {
    setSelectedLengths([]);
  };

  const renderLengthItem = ({ label, value }) => {
    const isSelected = selectedLengths.includes(value);
    return (
      <div 
        key={value} 
        className={`genreItem ${isSelected ? 'selected' : ''}`} 
        onClick={() => handleLengthSelect(value)}
      >
        <div className="label-container">
          {isSelected ? (
            <span className="genreText">&#8209;&#8209;&#8209;&gt;&#160;&#160;{label}&#160;&#160;&lt;&#8209;&#8209;&#8209;</span>
          ) : (
            <span className="genreText">{label}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="modal-filter-length">
      <div className="modalBackground">
        <div className="modalContainer">
          <h2 className="modalTitle">Filter by Length</h2>
          <div className="genreList">
            {lengthCategories.map(renderLengthItem)}
          </div>
          <div 
            className={`genreItem ${selectedLengths.length === 0 ? 'selected' : ''}`} 
            onClick={handleClearAll}
          >
            <span className="genreText">Clear All</span>
          </div>
          <button className="infosButton" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterLengthTheFeed;