import React from 'react';
import Header from '../../components/Header/Header';
import HomePrivacyWords from '../../components/HomePrivacyWords/HomePrivacyWords';
import Footer from '../../components/Footer/Footer';
import './HomePrivacyPolicy.css';


function HomePrivacyPolicy() {
  window.scrollTo(0, 0);
  return (
    <div className="homeprivacypage">
      <Header />
      <div className="homeprivacypage-content">
        <HomePrivacyWords />
      </div>
      <Footer />
    </div>
  );
}

export default HomePrivacyPolicy;