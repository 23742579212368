import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './FooterCreateAccountInfo.css';

function Footer() {
  const location = useLocation();

  const createAccountPath = location.pathname.startsWith('/writers')
    ? '/writers/create-account'
    : '/create-account';

  return (
    <div className="footer-create">
      <Link to={createAccountPath} className="what-up-footer-link">
        Return to creating an account
      </Link>
    </div>
  );
}

export default Footer;