import React, { useState, useEffect } from 'react';
import './BooksInPiece.css';
import { useDispatch } from 'react-redux';
import { showGenericModal } from '../../store/modalSlice';
import failedbooks from '../../assets/images/book.png';



const BooksInPiece = ({ feedItems, prevRoute, piece, author_info, pieceViewHash }) => {
  const dispatch = useDispatch();
  if (!feedItems || feedItems.length === 0) {
    return null;
  }



  const handleBookClick = (book, index) => {

    dispatch(showGenericModal({
      modalType: 'BOOK_MODAL', // String identifier
      modalProps: { book , prevRoute, position: index, piece, author_info, pieceViewHash } // Serializable props
    }));

  };

  let repeatingBooks = [];
  while (repeatingBooks.length < 4) {
    repeatingBooks = repeatingBooks.concat(feedItems);
  }




  const RetryableImage = ({ src, alt, className, maxRetries = 15, interval = 1000 }) => {
    const [retryCount, setRetryCount] = useState(0);
    const [imageSrc, setImageSrc] = useState(failedbooks); // Initially set to fallback image
  
    useEffect(() => {
      const img = new Image();
      img.onload = () => {

        setImageSrc(src); // Only set to the original source on successful load
      };
      img.onerror = () => {
        if (retryCount < maxRetries) {
          setTimeout(() => {
            console.log(`Retrying image load: Attempt ${retryCount + 1}`);
            setRetryCount(retryCount + 1);
            img.src = `${src}?retry=${retryCount + 1}`; // Attempt to reload with retry query
          }, interval);
        } else {
          setImageSrc(failedbooks); // Keep the fallback image if max retries are exceeded
        }
      };
      img.src = `${src}?retry=${retryCount}`; // Start loading with retry count to bypass cache
      // Set the source to the fallback image every time the component attempts to load the original image
      setImageSrc(failedbooks);
  
      return () => {
        img.onload = null;
        img.onerror = null;
      };
    }, [src, retryCount, maxRetries, interval]);
  
    // Always render the img element with the current source
    return <img src={imageSrc} alt={alt} className={className} />;
  };

  return (
    <div className="bookContainer">
      <div className="bookItemsContainer">
        {repeatingBooks.slice(0, 4).map((item, index) => (
          <div 
            className="bookItem" 
            key={index} 
            onClick={() => handleBookClick(item, index)}
          >
            <RetryableImage
              className="bookImage"
              src={item.book_image_url}
              alt={item.book_title}
              maxRetries={15} // Customize as needed
              interval={1000} // 1000 milliseconds
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BooksInPiece;
