import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideApplicationReviewModal } from '../../store/modalSlice';
import { apiInstance } from "../../utils/axiosInstance";
import { Auth } from 'aws-amplify';
import './ApplicationReviewModal.css';
import { setApplications } from "../../store/applicationsSlice";

const ApplicationReviewModal = () => {
    const dispatch = useDispatch();
    const { modalProps } = useSelector(state => state.modal);
    const { application} = modalProps;
    const applications = useSelector((state) => state.applications.applications);


    if (!application) {
        return null;
    }

    const handleCloseClick = () => {
        dispatch(hideApplicationReviewModal());
    };

    const handleApprove = async () => {
        try {
          // Assume you have an API utility setup to handle authenticated requests
          const jwtToken = await Auth.currentSession();
          const response = await apiInstance.post("/applications/acception", 
            {
              applicationId: application.applicationId,
              email: application.email,
              userId: application.userId,
              name: application.writersName
            },

            {
              headers: { Authorization: `Bearer ${jwtToken.getIdToken().getJwtToken()}` }
            }
          );
      
          // Assuming your lambda returns a success status when the rejection is processed
          if (response.status === 200) {
            // Dispatch action to update local state, removing the rejected application
            dispatch(setApplications(applications.filter(app => app.applicationId !== application.applicationId)));
            console.log('Application accepted successfully');
            dispatch(hideApplicationReviewModal());
          } else {
            // Handle the case where the lambda function does not return a success status
            console.error('Failed to accept application');
          }
        } catch (error) {
          console.error('Error accepting application:', error);
          // Handle the error state in your UI
        }
        
      };

    
    

    const handleReject = async () => {
        try {
          // Assume you have an API utility setup to handle authenticated requests
          const jwtToken = await Auth.currentSession();
          const response = await apiInstance.put("/applications/rejection", 
            {
              applicationId: application.applicationId,
              email: application.email,
              userId: application.userId
            },
            {
              headers: { Authorization: `Bearer ${jwtToken.getIdToken().getJwtToken()}` }
            }
          );
      
          // Assuming your lambda returns a success status when the rejection is processed
          if (response.status === 200) {
            // Dispatch action to update local state, removing the rejected application
            dispatch(setApplications(applications.filter(app => app.applicationId !== application.applicationId)));
            console.log('Application rejected successfully');
            dispatch(hideApplicationReviewModal());
          } else {
            // Handle the case where the lambda function does not return a success status
            console.error('Failed to reject application');
          }
        } catch (error) {
          console.error('Error rejecting application:', error);
          // Handle the error state in your UI
        }
        
      };
      

    // Logic for determining the author's published status and author ID presence
    const publishedStatus = application.published
        ? `A published author${application.authorId ? '' : ', who could not be identified'}.`
        : 'An unpublished author.';

    // Social media details if provided
    const socialMediaDetails = application.socialMedia.length
        ? `They have supplied ${application.socialMedia.length} social media link(s):`
        : 'They have not supplied any social media links.';

    // Magazine details if provided
    const magazineDetails = application.magPublished.length
        ? `They have supplied ${application.magPublished.length} magazine link(s):`
        : 'They have not supplied any magazine links.';

    // Last words or a message indicating absence
    const lastWordsSection = application.lastWords
        ? `Their last words were:\n\n${application.lastWords}`
        : 'They had no last words.';

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Application Review</h2>
                <p><strong>Applicant:</strong> {application.writersName}</p>
                <p><strong>Email:</strong> {application.email}</p>
                <p>{publishedStatus}</p>
                {socialMediaDetails}
                <ul>
                    {application.socialMedia.map((sm, index) => (
                        <li key={index}>Their handle on {sm.platform} is {sm.handle}</li>
                    ))}
                </ul>
                {magazineDetails}
                <ul>
                    {application.magPublished.map((mag, index) => (
                        <li key={index}>
                            Their piece at {mag.magazine} can be found at this link: {mag.link}
                        </li>
                    ))}
                </ul>
                <p>{lastWordsSection}</p>
                <button onClick={handleCloseClick}>Close</button>
                <button onClick={handleReject} className="reject-button">Reject</button>
                <button onClick={handleApprove} className="approve-button">Approve</button>
            </div>
        </div>
    );
};

export default ApplicationReviewModal;
