import React from 'react';
import './AboutWritersWords.css';
import turbo from '../../assets/images/me-profile.png';
import birdie from '../../assets/images/piece-summary.png';
import bookswide from '../../assets/images/books-wide.png';

function AboutWritersWords() {
    return (
        <div className="about-writers-words">
            <h3>ShorterStories - a new way to publish</h3>
            <p>ShorterStories is an artisanal network. A type of social network built for writers to benefit them and the industry at large. For the writer it is a place to publish pieces and earn money via the links to books they advertise.  A platform for building and strengthening your written brand.  </p>
            {/* <p>As a writer on ShorterStories, you are able to create a profile, publish written pieces and market books. In so doing, ShorterStories opens up opportunities designed to enhance your visibility and monetize your work effectively. Here's what's what:</p> */}
            
            <div className="profile-section">
                <p><strong>1. Build a profile:</strong></p>
                <img src={turbo} alt="Profile-turbo" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Profile card:</strong> A cool, clean intro that gives readers a reason to read on.</li>
                <li><strong>Portfolio:</strong> Build a portfolio of work that showcases you.</li>
                <li><strong>Open garden:</strong> Promote your profiles and published work outside the platform.</li>
            </ul>
            <div className="profile-section">
                <p><strong>2. Write and publish:</strong></p>
                <img src={birdie} alt="birdie" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Publish what you want:</strong> Craft anything from a quick 50-word poem to a 5,000-word short story, or serialize your entire novel.</li>
                <li><strong>Clean, consistent editing:</strong> Focus on your piece with our clean formatting and easy to use editor.</li>
                <li><strong>Publish your way:</strong> Release standalone works or keep readers hooked with serialized content.</li>
            </ul>
            <p><strong>3. Monetize through affiliate links:</strong> Earn from the affiliate links (tagged books) included in your pieces and on your profile page. We split affiliate revenue 75/25 in your favor, and reinvest the majority of what we take to build out the platform.</p>
            <img src={bookswide} alt="book-wide" className="uninlinable-image" />
            <p><strong>4. Get discovered:</strong> Readers can discover your work through genres, styles, region and literary inspirations and other rabbit trails that lead between authors.</p>
            <p><strong>5. Insights:</strong> Know if you're being read and gain an understanding of your audience.</p>
        </div>
    );
}

export default AboutWritersWords;