import React from 'react';
import BookCard from '../BookCard/BookCard';
import { useDispatch } from 'react-redux';
import ReusableBox from '../ReusableBoxContainer/ReusableBoxContainer';
import { hideGenericModal } from '../../store/modalSlice';
import './BookModalProfile.css';
import { useNavigate } from 'react-router-dom';
import { apiInstance } from '../../utils/axiosInstance';
import { useSelector } from 'react-redux';

const BookModalProfile = ({ book, position, summary }) => {
    const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.user.userId);

  function generateCustomTimestamp() {
    const now = new Date();
    
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
  
    return `${year}${month}${day}${hour}`;
  }

  const onButtonPress = () => {
    const generateHash = (length) => {
      return crypto.getRandomValues(new Uint8Array(length))
        .reduce((acc, i) => acc + ('0' + i.toString(36)).slice(-2), '')
        .slice(0, length);
    };
    
    const timestamp = generateCustomTimestamp();
    const uniqueHash = generateHash(12);
    const clickType = 'R'; // 'R' for Profile click
    const paddedPosition = position.toString().padStart(2, '0');
    const pieceHash = '00000000'; // 8 zeros for piece hash in profile clicks
    
    const sid = `${uniqueHash}-${clickType}-${paddedPosition}-${pieceHash}-${summary.profile_hash}-${timestamp}`;
    
    const encodedSid = encodeURIComponent(sid);

    console.log('booksiesajkdkdk',book);


    const url = new URL(book.latest_affiliate_url);
    
    url.searchParams.append('sid', sid);
    console.log('url',url);
    
    window.open(url.toString(), '_blank');

    const affiliateClickData = {
      unique_hash: uniqueHash,
      affiliate_id: book.affiliate_id,
      affiliate_url: book.latest_affiliate_url,
      book_author_name: book.book_author_name,
      book_image_url: book.book_image_url,
      book_title: book.book_title,
      product_link_url: book.latest_product_link_url,
      consuming_user: userId,
      retailer_id: book.retailer_id,
      click_type: 'PROFILE',
      position: position,
      publishing_user: summary.author_id || book.publishing_user,
      profile_hash: summary.profile_hash,
      piece_writer_name: summary.name
    };

    console.log('tidaaa',affiliateClickData);
  
    apiInstance.post('/clicks/affiliate/create', affiliateClickData)
      .then(response => console.log('API Call Successful:', response.data))
      .catch(error => console.error('Error in API Call:', error));


  };

  const handlePieceClick = () => {
    // Add your logic for what should happen when the piece box is clicked
    dispatch(hideGenericModal());
    console.log('Piece clicked:', book.most_recent_piece_title);
    navigate(`/piece/${book.most_recent_piece_hash}`, {
        state: {
            prevRoute: 'profile-recent-books',
            pieceData: null 

        }
      });
  };

  return (
    <div className="bookModalProfile">
      <BookCard 
        book={book}
      />
      <button 
        className="buttoninbox boldButton"
        onClick={onButtonPress}
      >
        Buy the Book
      </button>
      <div className="advertisement-piece-box" onClick={handlePieceClick}>
        <span className="advertisement-label">Or check out the piece it was advertised on: </span>
        <span className="piece-title">{book.most_recent_piece_title}</span>
      </div>
    </div>
  );
};

export default BookModalProfile;