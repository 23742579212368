import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, TimeScale, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import 'chartjs-adapter-date-fns'; 

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


const timeframeMapping = {
  '7days': 'seven_days_stats',
  '6weeks': 'six_weeks_stats',
  '12months': 'twelve_months_stats'
};

const ThreeLinedGraph = ({ timeframe = 'data7Days' }) => {
  const { stats, isLoading, error } = useSelector((state) => state.home);
  const selectedTime = useSelector(state => state.home.selectedTime);
  const getDataForTimeframe = () => {
    if (!stats) return [];
    const timeframeKey = timeframeMapping[selectedTime] || 'seven_days_stats'; // Default to seven days if no match
    return stats[timeframeKey] || [];
  };
  const data = getDataForTimeframe(selectedTime);

  console.log(selectedTime);

  const chartData = {
    labels: data.map(d => d.date_of_action),
    datasets: [
      {
        label: 'Pieces Visited',
        data: data.map(d => d.piece_views),
        borderColor: 'black',
        borderWidth: 1.5,
        pointRadius: 0,
        fill: false,
        borderDash: [],  // Solid line
      },
      {
        label: 'Pieces Read',
        data: data.map(d => d.piece_reads),
        borderColor: 'black',
        borderWidth: 1.5,
        pointRadius: 0,
        fill: false,
        borderDash: [10, 5],  // Long dash
      },
      {
        label: 'Clicks Out to Retailer',
        data: data.map(d => d.affiliate_clicks),
        borderColor: 'black',
        borderWidth: 1.5,
        pointRadius: 0,
        fill: false,
        borderDash: [3, 3],  // Short dash
      },
    ],
  };

  const options = {
    animation: { duration: 0 }, // Disables animations
    scales: {
      x: {
        type: 'time',
        time: {
          unit: selectedTime === '12months' ? 'month' : selectedTime === '6weeks' ? 'week' : 'day',
          tooltipFormat: selectedTime === '12months' ? 'MMMM yyyy' : 'MMM dd',
          isoWeekday: true,  
          displayFormats: {
            day: 'MMM dd',
            week: 'MMM dd',
            month: 'MMM yyyy'
          }
        },
        grid: {
          display: false
        }
      },
      y: {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
        suggestedMax: 10,

      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: false,
          generateLabels: (chart) => {
            return chart.data.datasets.map((dataset, index) => {
              return {
                datasetIndex: index,
                text: dataset.label,
                lineDash: dataset.borderDash,
                strokeStyle: dataset.borderColor,
                lineWidth: dataset.borderWidth,
                fillStyle: 'transparent',
              };
            });
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <div style={{ width: '400px', height: '250px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ThreeLinedGraph;
