import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedTime } from '../../store/homeSlice';
import './HomeSettingsModal.css';
import { Auth } from 'aws-amplify';
import { checkAuthenticationStatus } from '../../store/userSlice';
import { resetAuthorProfile } from '../../store/authorProfileSlice';
import { clearSelections } from '../../store/taggingSlice';
import { resetPublishingProcess } from '../../store/publishingSlice';
import { showGenericModal, hideGenericModal } from '../../store/modalSlice';
import { resetHomeStats } from '../../store/homeSlice';

const HomeSettingsModal = () => {
  const dispatch = useDispatch();
  const selectedTime = useSelector(state => state.home.selectedTime);
  const navigate = useNavigate();
  const stats = useSelector(state => state.home.stats);

  const timeOptions = [
    { label: "the last seven days", value: "7days" },
    { label: "the last six weeks", value: "6weeks" },
    { label: "the last 12 months", value: "12months" },
  ];

  const handleTimeSelection = (value) => {
    dispatch(setSelectedTime(value));
    dispatch(hideGenericModal()); 
  };

  const nameModal = () => {
    dispatch(hideGenericModal()); // Hide the current modal
    dispatch(showGenericModal({
      modalType: 'EDIT_WRITING_NAME',
    })); // Immediately show the next modal
  };

  const handleLogout = async () => {
    try {
      console.log('Stats before reset:', stats);
      await Auth.signOut();
      dispatch(checkAuthenticationStatus());
      navigate("/"); // Redirect to home page after logout
      dispatch(resetAuthorProfile());
      dispatch(clearSelections());
      dispatch(resetPublishingProcess());
      dispatch(hideGenericModal());  // Dispatch the function to hide the modal
      dispatch(resetHomeStats());

      console.log('Stats after reset:', stats);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleInfo = () => {
    console.log("Display Info");
    dispatch(hideGenericModal());
    navigate("/writers/finer-details");
  };

  return (
    <div className="homeSettingsModalContainer">
      <div className="homeSettingsModalOptions">
        <h3 className="homeSettingsModalTitle">See stats for:</h3>
        {timeOptions.map(option => (
          <button
            key={option.value}
            onClick={() => handleTimeSelection(option.value)}
            className={`homeSettingsModalButton ${selectedTime === option.value ? "selected" : ""}`}
          >
            {selectedTime === option.value ? `- ${option.label} •` : `- ${option.label}`}
          </button>
        ))}
        <h3 onClick={handleInfo} className="homeSettingsModalActionLink">
          A link to all the fine details you might be interested in knowing 
        </h3>
        {/* <h3 onClick={nameModal} className="homeSettingsModalActionLink">
          Edit the name you write under
        </h3> */}
        <h3 onClick={handleLogout} className="homeSettingsModalActionTitle">
          Log out
        </h3>
      </div>
    </div>
  );
};

export default HomeSettingsModal;