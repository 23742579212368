
import React, { useState } from 'react';
import './EditHeadshotModalContent.css';


import printingPressProfile from '../../assets/images/printingpressprofile-full.png';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setEditableAuthorsData, setProfileImageFile } from '../../store/editWriterSlice';
import EXIF from 'exif-js';



const EditHeadshotModalContent = () => {
  const dispatch = useDispatch();
  const authorData = useSelector((state) => state.editWriter.editableAuthorsData);
  const [isImageTouched, setIsImageTouched] = useState(false);
  const { profile_image_jpeg, profile_image_webp, ...restOfAuthorData } = authorData;

  const [selectedImage, setSelectedImage] = useState(authorData.profile_image || authorData.profile_image_webp || printingPressProfile);

  

  const fileInputRef = React.createRef();

  const [previousImage, setPreviousImage] = useState(authorData.profile_image_webp || printingPressProfile);
  const originalProfileImageFile = useSelector((state) => state.editWriter.profileImageFile);


const correctImageOrientation = (base64, orientation) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if ([6, 5].includes(orientation)) {
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((90 * Math.PI) / 180);
        ctx.translate(0, -canvas.width);
      } else if ([3, 4].includes(orientation)) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.rotate((180 * Math.PI) / 180);
        ctx.translate(-canvas.width, -canvas.height);
      } else if ([8, 7].includes(orientation)) {
        canvas.width = img.height;
        canvas.height = img.width;
        ctx.rotate((-90 * Math.PI) / 180);
        ctx.translate(-canvas.height, 0);
      } else {
        canvas.width = img.width;
        canvas.height = img.height;
      }

      if ([2, 4, 5, 7].includes(orientation)) {
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
      }

      ctx.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.src = base64;
  });
};

const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Read EXIF data and adjust orientation
    EXIF.getData(file, function () {
      const orientation = EXIF.getTag(this, 'Orientation');
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        correctImageOrientation(base64, orientation).then((correctedBase64) => {
          setSelectedImage(correctedBase64);
          dispatch(setProfileImageFile(correctedBase64));
          setIsImageTouched(true);
        });
      };
      reader.readAsDataURL(file);
    });
  }
};

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const revertToPreviousImage = () => {
    setSelectedImage(previousImage);
    setIsImageTouched(false); 
    dispatch(setProfileImageFile(originalProfileImageFile));
  };


  const revertToDefaultImage = () => {
    // Logic to revert to the default image
    setSelectedImage(printingPressProfile);
    setIsImageTouched(true); 
    dispatch(setProfileImageFile(null));

  };

  const handleUpdateAuthorData = () => {

    if (isImageTouched) {
      const updatedAuthorData = {
        ...restOfAuthorData,
        profile_image: selectedImage,
      };
      dispatch(setEditableAuthorsData(updatedAuthorData));
    }
    dispatch(hideGenericModal());
    // Here you could also close the modal or show a success message
  };

  return (
    <div className="modal">
      <div className="contentBox">

        <div className="titleContainer">
          <p className="modalTitle">Upload portrait:</p>
        </div>
        {selectedImage && <img src={selectedImage} alt="Selected" className="image-preview" />}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageChange}
          className="fileInput"
          accept="image/*"
          style={{ display: 'none' }} // Hide the actual file input
        />

        <button onClick={handleUploadClick} className='infosButton'>Upload image</button>
        <div className="textButtonContainer">
          <button onClick={revertToPreviousImage} className="textButton">Revert to previous image</button>
          <button onClick={revertToDefaultImage} className="textButton">Revert to default image</button>
        </div>
      </div>
      <button onClick={handleUpdateAuthorData} className='infosButton'>Update</button>

    </div>
    

  );
};

export default EditHeadshotModalContent;
