import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './ScreenshotsPage.css';

function AppScreenshots() {
  return (
    <div className="screenshot-page">
      <Header />
      <div className="screenshotspage-content">
        <div className="app-group">   
          <p>Readers' App</p>
          <div className="screenshot-group">
            <img className="screenshot" src="https://shrtrstrz-images.s3.amazonaws.com/all_purpose/screenshot_findbooks.png" alt="Reader Screenshot 1"/>
            <img className="screenshot" src="https://shrtrstrz-images.s3.amazonaws.com/all_purpose/screenshot_library.png" alt="Reader Screenshot 2"/>
          </div>
        </div>
        <div className="app-group">   
          <p>Writers' App</p>
          <div className="screenshot-group">
            <img className="screenshot" src="https://shrtrstrz-images.s3.amazonaws.com/all_purpose/screenshot_analytics.png" alt="Writer Screenshot 1"/>
            <img className="screenshot" src="https://shrtrstrz-images.s3.amazonaws.com/all_purpose/screenshot_publishing.png" alt="Writer Screenshot 2"/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AppScreenshots;