import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/Header/Header';
import AboutReadersWords from '../../components/AboutReadersWords/AboutReadersWords';
import Footer from '../../components/FooterAboutWriter/FooterAboutWriter';
import './AboutReaders.css';

function AboutReaders() {
  return (
    <div className="about-readers-page">
      <Helmet>
        <title>ShorterStories for readers: Discover writers, books and stories in a new kind of bookstore</title>
        <meta name="description" content="Imagine a bookstore where you don't browse shelves, but discover writers. Where each book recommendation comes with a story, and each story leads you to a new book. That's ShorterStories. " />
      </Helmet>
      <Header />
      <div className="about-readers-content">
        <AboutReadersWords />
      </div>
      <Footer />
    </div>
  );
}

export default AboutReaders;