import React from 'react';
import Header from '../../components/Header/Header';
import JobWords from '../../components/JobWords/JobWords';
import JobSubmit from '../../components/JobSubmit/JobSubmit'; // import the new component
import Footer from '../../components/Footer/Footer';
import './Jobs.css';

function Jobs() {
  return (
    <div className="jobpage">
      <Header />
      <div className="jobpage-content">
        <JobWords />
        
      </div>
      {/* <JobSubmit />  */}
      <Footer />
    </div>
  );
}

export default Jobs;