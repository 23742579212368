import React from 'react';
import {  useSelector, useDispatch } from 'react-redux';
import LoginBox from '../../components/LoginBox/LoginBox'; // Web version
import CreateAccountBox from '../../components/CreateAccountBoxHome/CreateAccountBoxHome'; // Web version
import ConfirmSignUp from '../../components/ConfirmSignUp/ConfirmSignUp'; // Web version
// import CreateAccount from '../components/CreateAccount'; // Web version
import Header from '../../components/Header/Header'; // Web version
// import ForgotPassword from '../components/ForgotPassword'; // Web version
// import ResetPassword from '../components/ResetPassword'; // Web version
// import NoEmailBox from '../components/NoEmailBox'; // Web version
// import FooterLogin from '../components/FooterLogin'; // Web version
import './HomeLogin.css'; // CSS for styling
import { useNavigate } from 'react-router-dom';


const HomeLoginScreen = () => {

  const currentLoginPage = useSelector((state) => state.user.currentLoginPage);



  return (
    <div className="container">
      <Header />
      <div className="contentContainer">
        {currentLoginPage === 'login' && (
          <>
            <div className="boxContainer">
              <LoginBox />
            </div>
            <div className="boxContainer">
              <CreateAccountBox o />
            </div>
          </>
        )}
        {currentLoginPage === 'confirmSignUp' && (
          <div className="boxContainer">
            <ConfirmSignUp  />
          </div>
        )}
        {/* Repeat for other conditions like 'createAccount', 'confirmSignUp', etc. */}
      </div>
      {/* <FooterLogin />  */}
    </div>
  );
};

export default HomeLoginScreen;

